import React, { useRef, useEffect, useState } from 'react'
import classnames from 'classnames'
// import { TextArea } from 'semantic-ui-react'
import JoditEditor from "jodit-react"
import 'jodit/build/jodit.min.css'

const TextAreaContainer = (props) => {
    const { input, meta: { touched, error } } = props
    const editor = useRef(null)
    const [editorValue, setEditorvalue] = useState('')

    const onBlurEditor = () => {
        setEditorvalue(editor.current.value)
    }

    const onEditorChange = (editorNewValue) => {
        input.onChange(editorNewValue)
        setEditorvalue(editorNewValue)
    }

    return(
        <div className="textarea-container">
            {/* <TextArea onChange={value => input.onChange(value)}
                      className={classnames({error:touched && error})}
                      {...input} /> */}
                <JoditEditor className={classnames({error:touched && error})}
                             {...input}
                             ref={editor}
                             onChange={onEditorChange}
                             onBlur={onBlurEditor} /> 
            <div>
                {touched && error && <span className="errorMsg">{error.message}</span>}
            </div>
        </div>
    )
        
}

export default TextAreaContainer