import { GET_QUIZ_CATEGORY_LIST_REQUEST, GET_QUIZ_CATEGORY_LIST_SUCCESS, GET_QUIZ_CATEGORY_LIST_ERROR, CURRENT_QUIZ_CATEGORY_SUCCESS, ADD_QUIZ_CATEGORY_REQUEST, ADD_QUIZ_CATEGORY_SUCCESS, ADD_QUIZ_CATEGORY_ERROR, DELETE_QUIZ_CATEGORY_SUCCESS, VIEW_SINGLE_QUIZ_CATEGORY_REQUEST, VIEW_SINGLE_QUIZ_CATEGORY_SUCCESS, UPDATE_QUIZ_CATEGORY_REQUEST, UPDATE_QUIZ_CATEGORY_SUCCESS, UPDATE_QUIZ_CATEGORY_ERROR, GET_ALL_CATEGORY_NAME_REQUEST, GET_ALL_CATEGORY_NAME_SUCCESS, GET_ALL_CATEGORY_NAME_ERROR } from '../constants/QuizCategoryConstants'

const initialState = {
    loading: false,
    viewQuizCategoryList: {name:"", status:"", created_at:"", created_by:"", modified_by:"", updated_at:"", isDeleted:""},
    quizCategoryList: [],
    error: '',
    disabled: false,
    count: [],
    categoryName: '',
    categoryValue: ''
}

const quizcategory = (state = initialState, action) => {
    switch(action.type){
        case GET_QUIZ_CATEGORY_LIST_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_QUIZ_CATEGORY_LIST_SUCCESS:
            return{
                ...state,
                loading: false,
                quizCategoryList: action.list,
                count: action.count,
                error: ""
            }
        case GET_QUIZ_CATEGORY_LIST_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case CURRENT_QUIZ_CATEGORY_SUCCESS:
            return{
                ...state,
                disabled: false,
                viewQuizCategoryList: initialState.viewQuizCategoryList
            }
        case ADD_QUIZ_CATEGORY_REQUEST:
            return{
                ...state,
                loading: true
            }
        case ADD_QUIZ_CATEGORY_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case ADD_QUIZ_CATEGORY_ERROR:
            return{
                ...state,
                loading: false,
                error: action.err
            }
        case DELETE_QUIZ_CATEGORY_SUCCESS:
            return{
                ...state,
                quizCategoryList: state.quizCategoryList.filter(category => category.id !== action.id)
            }
        case VIEW_SINGLE_QUIZ_CATEGORY_REQUEST:
            return{
                ...state,
                loading: true
            }
        case VIEW_SINGLE_QUIZ_CATEGORY_SUCCESS:
            return{
                ...state,
                loading: false,
                disabled: true,
                viewQuizCategoryList: action.obj
            }
        case UPDATE_QUIZ_CATEGORY_REQUEST:
            return{
                ...state,
                loading: true
            }
        case UPDATE_QUIZ_CATEGORY_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case UPDATE_QUIZ_CATEGORY_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case GET_ALL_CATEGORY_NAME_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_ALL_CATEGORY_NAME_SUCCESS:
            return{
                ...state,
                loading: false,
                categoryName: action.list,
                categoryValue: action.array
            }
        case GET_ALL_CATEGORY_NAME_ERROR:
            return{
                loading: false,
                error: action.err
            }
        default:
            return state
    }
}

export default quizcategory