import { GET_QUIZ_QUESTION_LIST_REQUEST, GET_QUIZ_QUESTION_LIST_SUCCESS, GET_QUIZ_QUESTION_LIST_ERROR, CURRENT_QUIZ_QUESTION_SUCCESS, ADD_QUIZ_QUESTION_REQUEST, ADD_QUIZ_QUESTION_SUCCESS, ADD_QUIZ_QUESTION_ERROR, VIEW_SINGLE_QUIZ_QUESTION_REQUEST, VIEW_SINGLE_QUIZ_QUESTION_SUCCESS, UPDATE_QUIZ_QUESTION_REQUEST, UPDATE_QUIZ_QUESTION_SUCCESS, UPDATE_QUIZ_QUESTION_ERROR, DELETE_QUIZ_QUESTION_SUCCESS, SAVE_IMAGE_REQUEST, CHANGE_UPLOAD_ANSWER_REQUEST, SAVE_QUESTION_TYPE, GET_FILTERED_QUESTION_LIST_REQUEST, GET_FILTERED_QUESTION_LIST_SUCCESS, GET_FILTERED_QUESTION_LIST_ERROR } from '../constants/QuizQuestionConstants'

const initialState = {
    loading: false,
    error: '',
    disabled: false,
    viewQuizQuestionList: {created_at:'', created_by:'', modified_by:'', updated_at:'', question:'', question_tags:'', question_category:'', level:'', featured:'', status:{ value: 'Published', label: 'Published' }, question_type:'', options:'', answer:'', answer_doc_url:'', answer_description:'', upload_answer:'', isDeleted:'', required:''},
    count: [],
    quizQuestionList: [],
    imageData: undefined,
    uploadAnswerValue: '',
    answerTypeValue: ''
}

const quizquestion = (state = initialState, action) => {
    switch(action.type){
        case GET_QUIZ_QUESTION_LIST_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_QUIZ_QUESTION_LIST_SUCCESS:
            return{
                ...state,
                loading: false,
                quizQuestionList: action.list,
                count: action.count,
                error: ''
            }
        case GET_QUIZ_QUESTION_LIST_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case CURRENT_QUIZ_QUESTION_SUCCESS:
            return{
                ...state,
                disabled: false,
                viewQuizQuestionList: initialState.viewQuizQuestionList,
                answerTypeValue: ''
            }
        case ADD_QUIZ_QUESTION_REQUEST:
            return{
                ...state,
                loading: true
            }
        case ADD_QUIZ_QUESTION_SUCCESS:
            return{
                ...state,
                loading: false,
                imageData: undefined
            }
        case ADD_QUIZ_QUESTION_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case VIEW_SINGLE_QUIZ_QUESTION_REQUEST:
            return{
                ...state,
                loading: true
            }
        case VIEW_SINGLE_QUIZ_QUESTION_SUCCESS:
            return{
                ...state,
                loading: false,
                disabled: true,
                viewQuizQuestionList: action.obj,
                uploadAnswerValue: action.obj.upload_answer,
                answerTypeValue: action.obj.question_type.value
            }
        case UPDATE_QUIZ_QUESTION_REQUEST:
            return{
                ...state,
                loading: true
            }
        case UPDATE_QUIZ_QUESTION_SUCCESS:
            return{
                ...state,
                loading: false,
                imageData: undefined
            }
        case UPDATE_QUIZ_QUESTION_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case DELETE_QUIZ_QUESTION_SUCCESS:
            return{
                ...state,
                quizQuestionList: state.quizQuestionList.filter(question => question.id !== action.id)
            }
        case SAVE_IMAGE_REQUEST:
            return{
                ...state,
                imageData: action.obj
            }
        case CHANGE_UPLOAD_ANSWER_REQUEST:
            return{
                ...state,
                uploadAnswerValue: action.value
            }
        case SAVE_QUESTION_TYPE:
            return{
                ...state,
                answerTypeValue: action.value
            }
        case GET_FILTERED_QUESTION_LIST_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_FILTERED_QUESTION_LIST_SUCCESS:
            return{
                ...state,
                loading: false,
                quizQuestionList: action.list,
                count: action.count,
                error: ''
            }
        case GET_FILTERED_QUESTION_LIST_ERROR:
            return{
                loading: false,
                error: action.err
            }
        default:
            return state
    }
}

export default quizquestion