import React, { useState } from 'react'
import { connect } from 'react-redux'
import { logoutUser } from '../../actions/UserActions'
import { Divider, Header, Icon, Popup, Image, Menu, Dropdown } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import opennaukriLogo from '../../assets/opennaukriLogo.png'

const HeaderContainer = (props) => {
    let currentUserRole = localStorage.getItem('currentUserRole')
    const [isOpen, setIsOpen] = useState(false)

    const handleClose = () => {
        setIsOpen(!isOpen)
    }

    const handleOpen = () => {
        setIsOpen(!isOpen)
    }

    const logout = () => {
        props.logoutUser()
    }

    return(
        <div className="header-container">
            <Header as='h3' block>
                <div className="sub-heading">
                    <div className="img-logo">
                        <Link to="/dashboard">
                            <Image src={opennaukriLogo} />
                        </Link>
                    </div>
                    <div className="list-container">
                        <Link to="/exams">
                            Exams
                        </Link>
                        <Link to="/disabilityList">
                            Disabilities
                        </Link>
                        <Link to="/courseList">
                            Courses
                        </Link>
                        <Link to="/discipline">
                            Discipline
                        </Link>
                        {currentUserRole === "Admin" ? 
                        <Link to="/users">
                            Users
                        </Link>
                        : null}
                        <Link to="/educationalQualificationList">
                            Education
                        </Link>
                        <Link to="/subjects">
                            Subjects
                        </Link>
                        <Link to="/analytics">
                            Analytics
                        </Link>
                        <Menu>
                            <Menu.Menu>
                                <Dropdown item text='Quiz'>
                                <Dropdown.Menu>
                                    <Menu.Item as={ Link } to='/tags'>
                                        Tags
                                    </Menu.Item>
                                    <Menu.Item as={ Link } to='/quizcategoryList'>
                                        Category
                                    </Menu.Item>
                                    <Menu.Item as={ Link } to='/quizquestionList'>
                                        Questions
                                    </Menu.Item>
                                    <Menu.Item as={ Link } to='/quizesList'>
                                        Quizzes
                                    </Menu.Item>
                                </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>              
                        </Menu>
                        
                        
                        
                        
                    </div>
                    
                </div>
                <div>
                <Header floated='right' className='profile-icon'>
                    <Popup trigger={<Icon name="user outline" size="large" />}
                        on='click'
                        open={isOpen}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        position='bottom center'>
                        <Popup.Content> 
                            {/* <Link to="/resetPassword">
                                <Icon name="key" /> Change Password
                            </Link> */}
                            <Divider hidden />
                            <Link to="/profile">
                                <Icon name="user" /> Profile
                            </Link>
                            <Divider hidden />
                            <div className="logout-btn" onClick={logout}>
                                <Icon name="share square outline" /> 
                                <span>Logout</span>
                            </div>
                        </Popup.Content>
                    </Popup>
                </Header>
                </div>
            </Header>
        </div>
    )
}

const mapDispatchToProps = {
    logoutUser
}

export default connect(null, mapDispatchToProps)(HeaderContainer)