import { call, put, takeLatest } from 'redux-saga/effects'
import { history } from '../_helpers'
import { REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_ERROR, LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT_USER, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR, UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_ERROR, CURRENT_USER_REQUEST, CURRENT_USER_SUCCESS, CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_ERROR, GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_ERROR, VIEW_SINGLE_USER_REQUEST, VIEW_SINGLE_USER_SUCCESS, UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_ERROR, GET_CURRENT_USER_PROFILE_REQUEST, GET_CURRENT_USER_PROFILE_SUCCESS, UPDATE_USER_STATUS_REQUEST, UPDATE_USER_STATUS_SUCCESS, SEND_INVITATION_REQUEST, SEND_INVITATION_SUCCESS, SEND_INVITATION_ERROR, SAVE_USER_QUIZ_REQUEST, SAVE_USER_QUIZ_SUCCESS, SAVE_USER_QUIZ_ERROR, GET_ASSIGNED_QUIZZES_REQUEST, GET_ASSIGNED_QUIZZES_SUCCESS, GET_ASSIGNED_QUIZZES_ERROR, UPDATE_USER_QUIZ_REQUEST, UPDATE_USER_QUIZ_SUCCESS, UPDATE_USER_QUIZ_ERROR, DELETE_USER_REQUEST, DELETE_USER_SUCCESS } from '../constants/UserConstants'
import firebase from '../components/firebase'
import "firebase/auth"
import { db } from '../components/firebase'
import { getcount , getData } from './PaginationWithSearch'
import _ from 'lodash'

async function getList(){
  let array = []
  let categoryList = db.collection("users")
  await categoryList.get()
  .then(snapshot => {
      if (snapshot.empty) {
        return;
      }
      snapshot.forEach(doc => {
          array.push({...doc.data(), id: doc.id})
      })
  })
  return array
}

function addUser(email){
  let currentTime = Math.round(new Date().getTime()/1000)
  let created_at = currentTime
  let updated_at = currentTime
  return firebase.firestore().collection("users").add({email, created_at, updated_at})
  .then(response => response)
}

function* registerRequest (data) {
  const { email, password } = data
  try{
      const auth = firebase.auth()
      yield call(
          [auth, auth.createUserWithEmailAndPassword],
          email,
          password
      )
      yield call(addUser, email)
      yield put({ type: REGISTER_SUCCESS })
      history.push('/login')
  }
  catch(error){
      const { email, password, name, phone } = data
      let errors
      if(!email && !password && !name && !phone ){
          errors = "All fields are mandatory"
         yield put({ type: REGISTER_ERROR, errors })
      }
      else{
          errors = error.message
          yield put({ type: REGISTER_ERROR, errors })
      }
  }
}

function* loginApi (data) {
  const { email, password } = data
  try{
    const auth = firebase.auth()
    const value = yield call(
      [auth, auth.signInWithEmailAndPassword],
      email,
      password
    )
    const token = value.user.refreshToken
    const uid = value.user.uid
    localStorage.setItem('token', token)
    localStorage.setItem('email', email)
    localStorage.setItem('uid', uid)
    if(token){
      const list = yield call(getUser, uid)
      const currentUserList = list.data()
      if(currentUserList === undefined || currentUserList.status === "Inactive" || currentUserList.role === "Aspirant"){
        const err = "You are not authorized to access this website"
        yield put({ type: LOGIN_ERROR, err })
      }
      else{
        localStorage.setItem('currentUserRole', currentUserList.role)
        let currentDate = new Date()
        localStorage.setItem('loginTime', currentDate)
        yield put({ type: LOGIN_SUCCESS })
        history.push('/dashboard')
      }
    }
    else{
      const err = "Invalid Password or username"
      yield put({ type: LOGIN_ERROR, err })
    }
  }
  catch(error){
    const err = error.message
    yield put({ type: LOGIN_ERROR, err })
  }
}

function logoutuser(){
  localStorage.removeItem('token')
  localStorage.removeItem('currentUserRole')
  history.push('/login')
}

function reauthenticate(newPassword){
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      user.updatePassword(newPassword)
      .then(response => response)
      .catch(function(error){
      })
    } else {}
  })
}

function* resetPassword(data){
  const { newPassword, currentPassword } = data
  try{
    yield call(reauthenticate, newPassword, currentPassword)
    const msg = "Password Changed Successfully"
    yield put({ type: RESET_PASSWORD_SUCCESS, msg })
    localStorage.removeItem('token')
    history.push("/login")
  }catch(error){
    const errorMsg = error.message
    yield put({ type: RESET_PASSWORD_ERROR, errorMsg })
  }
}

function updateUserProfile(data){
  let uid = localStorage.getItem('uid')
  return firebase.firestore().collection("users").doc(uid).update(data)
}

function* profileRequest(data) {
  try{
    yield call(updateUserProfile, data.data)
    yield put({ type: UPDATE_PROFILE_SUCCESS })
    history.push("/dashboard")
  }
  catch(error){
    const errMsg = error.message
    yield put({ type: UPDATE_PROFILE_ERROR, errMsg })
  }
}

function* getCurrentUser(){
  yield put({ type: CURRENT_USER_SUCCESS })
}

function updateUser(data, id){
  return firebase.firestore().collection("users").doc(id).set(data, {merge: true})
  .then(response => response)
}

function UpdateProfile(displayName, value){
  return value.user.updateProfile({ displayName: displayName })
  .then(function(){})
  .catch(function(error){})
}

function* createUser(data){
  try{
    const { email, displayName } = data.data
    let password = Math.random().toString(36).slice(-8)
    const auth = firebase.auth()
    const value = yield call(
      [auth, auth.createUserWithEmailAndPassword],
      email,
      password
    )
    yield call(UpdateProfile, displayName, value)
    let uId = value.user.uid
    data.data.role = data.data.role.value
    if(data.data.role !== "Employee"){
      data.data.company_name = ""
    }
    else{
      if(!_.isEmpty(data.data.company_name) && data.data.company_name !== undefined){
        data.data.company_name = data.data.company_name
      }
      else{
        data.data.company_name = "Webuters"
      }
    }
    yield call(updateUser, data.data, uId)
    yield put({ type: CREATE_USER_SUCCESS })
    history.push('/users')
  }
  catch(error){
    const err = error.message
    yield put({ type: CREATE_USER_ERROR, err })
  }
}

async function getTotalNodes(count, searchTerm, userList, activePage, deleteKey){
  const totalnodes = await getcount(searchTerm, "users", deleteKey)
  if(totalnodes.length === undefined){
      totalnodes.docs.map(doc => {
          return count.push(doc.id)
      })
  }
  else{
      totalnodes.map(id => {
          return count.push(id)
      })
  }
  await getData(activePage, userList, searchTerm, "users", deleteKey)
}

function* getUsersList(values){
  try{
    const { activePage, searchTerm, deleteKey } = values
    let userList = [], count = []
    yield call(getTotalNodes, count, searchTerm, userList, activePage, deleteKey)
    yield put({ type: GET_USERS_SUCCESS, userList, count })
  }
  catch(error){
    const err = error.message
    yield put({ type: GET_USERS_ERROR, err })
  }
}

function getUser(id){
  return firebase.firestore().collection("users").doc(id).get()
  .then(response => response)
}

function setLabel(data, obj){
  if(data.role !== ""){
    obj.role['label'] = data.role
    obj.role['value'] = data.role
    return obj
}
}

function* viewUser(data){
  const { id } = data
  const list = yield call(getUser, id)
  const singleUserList = list.data()
  let obj = {
  'first_name': singleUserList.first_name, 
  'last_name': singleUserList.last_name, 
  'email': singleUserList.email, 
  'role':{},
  'company_name': singleUserList.company_name}
  setLabel(singleUserList, obj)
  yield put({ type: VIEW_SINGLE_USER_SUCCESS, obj })
}

function* updateUserData(data){
  try{
    const { id } = data
    data.data.role = data.data.role.value
    if(data.data.role !== "Employee"){
      data.data.company_name = ""
    }
    else{
      if(!_.isEmpty(data.data.company_name) && data.data.company_name !== undefined){
        data.data.company_name = data.data.company_name
      }
      else{
        data.data.company_name = "Webuters"
      }
    }
    yield call(updateUser, data.data, id)
    yield put({ type: UPDATE_USER_SUCCESS })
    history.push('/users')
  }
  catch(error){
    const err = error.message
    yield put({ type: UPDATE_USER_ERROR, err })
  }
}

function* getCurrentProfile(){
  let uid = localStorage.getItem('uid')
  const list = yield call(getUser, uid)
  const currentUserList = list.data()
  yield put({ type: GET_CURRENT_USER_PROFILE_SUCCESS, currentUserList})
}

function* updateUserStatusData(data){
  const { id, value } = data
  let obj = {}
  obj['status'] = value.value
  yield call(updateUser, obj, id)
  yield put({ type: UPDATE_USER_STATUS_SUCCESS })
}

async function sendInvite(email, role){
  let actionCodeSettings
  if(role === "Admin" || role === "Operator"){
    actionCodeSettings = {
      url: process.env.REACT_APP_CMS_URL,
      handleCodeInApp: false
    }
  }
  else{
    actionCodeSettings = {
      url: process.env.REACT_APP_WEB_APP_URL,
      handleCodeInApp: false
    }
  }
  return await firebase.auth().sendPasswordResetEmail(email, actionCodeSettings)
}

function* sendinvite(data){
  try{
    const { email, id, role } = data
    yield call(sendInvite, email, role)
    let obj = {}
    obj['invite'] = true
    yield call(updateUser, obj, id)
    yield put({ type: SEND_INVITATION_SUCCESS })
  }
  catch(error){
    const err = error.message
    yield put({ type: SEND_INVITATION_ERROR, err })
  }
}

function updateUserQuizData(id, newdata){
  return firebase.firestore().collection("users").doc(id).collection("assigned_quizzes").add(newdata)
  .then(response => response)
}

function* saveQuizSetData(data){
  try{
    const { id, value } = data
    let obj = []
    value.map(item => {
      obj.push(item.id)
    })
    let newdata = {
      'quiz_id': obj,
      'user_id': id
    }
    let userdata = {
      'assigned_quiz': true
    }
    let values = {
      'activePage': 1,
      'searchTerm': "",
      'deleteKey': false
    }
    yield call(updateUserQuizData, id, newdata)
    yield call(updateUser, userdata, id)
    yield call(getUsersList, values)
    yield put({ type: SAVE_USER_QUIZ_SUCCESS })
  }
  catch(error){
    const err = error.message
    yield put({ type: SAVE_USER_QUIZ_ERROR, err })
  }
}

async function getQuizData(id){
  let array = []
  let list = db.collection("users").doc(id).collection("assigned_quizzes")
  await list.get()
  .then(snapshot => {
      if (snapshot.empty) {
        return;
      }
      snapshot.forEach(doc => {
          array.push({...doc.data(), id: doc.id})
      })
  })
  return array
}

function* getAssignedQuizData(data){
  try{
    const { id } = data
    const list = yield call(getQuizData, id)
    let newList = [], docId
    list.map(item => {
      docId = item.id
      item.quiz_id.map(value => {
        newList.push({ 'id': value, 'isChecked': true })
      })
    })
    yield put({ type: GET_ASSIGNED_QUIZZES_SUCCESS, newList, docId })
  }
  catch(error){
    const err = error.message
    yield put({ type: GET_ASSIGNED_QUIZZES_ERROR, err })
  }
}

function updateQuizData(id, newdata, docId){
  return firebase.firestore().collection("users").doc(id).collection("assigned_quizzes").doc(docId).update(newdata)
  .then(response => response)
}

function deleteQuizData(id, docId){
  return firebase.firestore().collection("users").doc(id).collection("assigned_quizzes").doc(docId).delete()
  .then(response => response)
}

function* updateUserAssignedQuizData(data){
  try{
    const { id, value, docId } = data
    let obj = [], userdata
    value.map(item => {
      obj.push(item.id)
    })
    let newdata = {
      'quiz_id': obj,
      'user_id': id
    }
    if(value.length !== 0){
      yield call(updateQuizData, id, newdata, docId)
    }
    if(value.length === 0 && _.isEmpty(value)){
      userdata = {
          'assigned_quiz': false
        }
      let values = {
        'activePage': 1,
        'searchTerm': "",
        'deleteKey': false
      }
      yield call(deleteQuizData, id, docId)
      yield call(updateUser, userdata, id)
      yield call(getUsersList, values)
    }
    yield put({ type: UPDATE_USER_QUIZ_SUCCESS })
  }
  catch(error){
    const err = error.message
    yield put({ type: UPDATE_USER_QUIZ_ERROR, err })
  }
}

function getNewList(list, id){
  let obj = {}
  _.forEach(list, function(value){
      if(value.id === id){
          obj = value
      }
  })
  return obj
}

function* deleteUserData(data){
  const { id } = data
  const list = yield call(getList)
  const newList = yield call(getNewList, list, id)
  newList.isDeleted = true
  firebase.firestore().collection("users").doc(id).update(newList)
  yield put({ type: DELETE_USER_SUCCESS, id })
}

export default () => {
    function * watcher() {
      yield takeLatest(REGISTER_REQUEST, registerRequest)
      yield takeLatest(LOGIN_REQUEST, loginApi)
      yield takeLatest(LOGOUT_USER, logoutuser)
      yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword)
      yield takeLatest(GET_CURRENT_USER_PROFILE_REQUEST, getCurrentProfile)
      yield takeLatest(UPDATE_PROFILE_REQUEST, profileRequest)
      yield takeLatest(CURRENT_USER_REQUEST, getCurrentUser)
      yield takeLatest(CREATE_USER_REQUEST, createUser)
      yield takeLatest(GET_USERS_REQUEST, getUsersList)
      yield takeLatest(VIEW_SINGLE_USER_REQUEST, viewUser)
      yield takeLatest(UPDATE_USER_REQUEST, updateUserData)
      yield takeLatest(UPDATE_USER_STATUS_REQUEST, updateUserStatusData)
      yield takeLatest(SEND_INVITATION_REQUEST, sendinvite)
      yield takeLatest(SAVE_USER_QUIZ_REQUEST, saveQuizSetData)
      yield takeLatest(GET_ASSIGNED_QUIZZES_REQUEST, getAssignedQuizData)
      yield takeLatest(UPDATE_USER_QUIZ_REQUEST, updateUserAssignedQuizData)
      yield takeLatest(DELETE_USER_REQUEST, deleteUserData)
    }
    return {watcher}
}

