import { call, put, takeLatest, all } from 'redux-saga/effects'
import "firebase/auth"
import firebase from '../components/firebase'
import { history } from '../_helpers'
import { db } from '../components/firebase'
import _ from 'lodash'
import { GET_COURSE_LIST_REQUEST, GET_COURSE_LIST_SUCCESS, GET_COURSE_LIST_ERROR, ADD_COURSE_REQUEST, ADD_COURSE_SUCCESS, ADD_COURSE_ERROR, DELETE_COURSE_DATA_REQUEST, DELETE_COURSE_DATA_SUCCESS, VIEW_SINGLE_COURSE_DATA_REQUEST, VIEW_SINGLE_COURSE_DATA_SUCCESS, UPDATE_COURSE_REQUEST, UPDATE_COURSE_SUCCESS, UPDATE_COURSE_ERROR, CURRENT_COURSE_REQUEST, CURRENT_COURSE_SUCCESS, ADD_COURSE_DETAILS_REQUEST, ADD_COURSE_DETAILS_SUCCESS, ADD_COURSE_DETAILS_ERROR, VIEW_COURSE_DETAILS_REQUEST, VIEW_COURSE_DETAILS_SUCCESS, UPDATE_COURSE_STATUS_REQUEST, UPDATE_COURSE_STATUS_SUCCESS, GET_ALL_COURSE_NAME_REQUEST, GET_ALL_COURSE_NAME_ERROR, GET_ALL_COURSE_NAME_SUCCESS, DELETE_COURSE_IMAGE_REQUEST, DELETE_COURSE_IMAGE_SUCCESS, DELETE_COURSE_IMAGE_ERROR } from '../constants/CourseConstants'
import { getcount , getData } from './PaginationWithSearch'
import { generateUniqueId } from './UniqueId'
import { deleteImageFromStorage } from './DeleteImage'

async function getList(){
    let array = []
    let categoryList = db.collection("courses").where("isDeleted", "==", false)
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

async function getTotalNodes(count, searchTerm, list, activePage){
    const totalnodes = await getcount(searchTerm, "courses")
    if(totalnodes.length === undefined){
        totalnodes.docs.map(doc => {
            return count.push(doc.id)
        })
    }
    else{
        totalnodes.map(id => {
            return count.push(id)
        })
    }
    await getData(activePage, list, searchTerm, "courses")
}

function* getCourseList(values){
    try{
        const { activePage, searchTerm } = values
        let list = [], count = []
        yield call(getTotalNodes, count, searchTerm, list, activePage)
        yield put({ type: GET_COURSE_LIST_SUCCESS, list, count })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_COURSE_LIST_ERROR, err })
    }
}

function* getAllCoursesName(){
    try{
        const list = yield call(getList)
        yield put({ type: GET_ALL_COURSE_NAME_SUCCESS, list })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_ALL_COURSE_NAME_ERROR, err })
    }
}

function addData(data){
    return firebase.firestore().collection('courses').add(data)
    .then(response => response)
}

function setvalue(data, obj){
    const {created_by, isDeleted, created_date_time, modified_by, modified_date_time, discipline, branch, name, display_name, program_level, duration, admin_procedure, description, required_program_level, required_course, required_marks_obtained, mode_of_degree, max_age, min_age, catergory_criteria, fees, status} = data
    if(required_course !== ""){
        const newVal = setValue(required_course)
        obj.elegibility_criteria['required_course'] = newVal
    }
    if(catergory_criteria !== ""){
        obj.catergory_criteria = catergory_criteria
    }
    if(admin_procedure !== ""){
        obj.course_details['admin_procedure'] = admin_procedure
    }else if(admin_procedure === ""){
        obj.course_details['admin_procedure'] = ""
    }
    if(fees !== ""){
        obj.course_details['fees'] = fees
    }else if(admin_procedure === ""){
        obj.course_details['fees'] = ""
    }
    if(description !== ""){
        obj.course_details['description'] = description
    }else if(description === ""){
        obj.course_details['description'] = ""
    }
    if(max_age !== ""){
        obj.elegibility_criteria['max_age'] = max_age
    }else if(max_age === ""){
        obj.elegibility_criteria['max_age'] = ""
    }
    if(min_age !== ""){
        obj.elegibility_criteria['min_age'] = min_age
    }else if(min_age === ""){
        obj.elegibility_criteria['min_age'] = ""
    }
    if(branch !== ""){
        obj.course_details['branch'] = branch
    }else if(branch === ""){
        obj.course_details['branch'] = ""
    }
    if(created_by !== "" && isDeleted !== "" && created_date_time !== "" && modified_by !== "" && modified_date_time !== "" && discipline !== "" && name !== "" && display_name !== "" && program_level !== "" && duration !== "" && required_program_level !== "" && mode_of_degree !== ""){
        obj['created_by'] = created_by
        obj['isDeleted'] = isDeleted
        obj['created_date_time'] = created_date_time
        obj['modified_by'] = modified_by
        obj['modified_date_time'] = modified_date_time
        obj.course_details['discipline'] = discipline.value
        obj['name'] = name
        obj['display_name'] = display_name
        obj.course_details['program_level'] = program_level.value
        obj.course_details['duration'] = duration
        obj.elegibility_criteria['required_program_level'] = required_program_level.value
        obj.elegibility_criteria['required_marks_obtained'] = required_marks_obtained
        obj.elegibility_criteria['mode_of_degree'] = mode_of_degree.value
        obj['status'] = status
        return obj
    }

    
}

function setValue(value){
    let array = []
    value.map((data) => {
        return array.push(data.value)
    })
    return array
}

function* addCourseData(data){
    try{
        let obj = {'course_details': {}, 'elegibility_criteria': {}}
        setvalue(data.data, obj)
        console.log('obje data::: ',obj)
        yield call(addData, obj)
        yield put({ type: ADD_COURSE_SUCCESS })
        history.push('/courseList')
    }
    catch(error){
        const err = error.message
        yield put({ type: ADD_COURSE_ERROR, err })
    }
}

function getNewList(list, id){
    let obj = {}
    _.forEach(list, function(value){
        if(value.id === id){
            obj = value
        }
    })
    return obj
}

function* deleteCourse(data){
    const { id } = data
    const list = yield call(getList)
    const newList = yield call(getNewList, list, id)
    newList.isDeleted = true
    firebase.firestore().collection("courses").doc(id).update(newList)
    yield put({ type: DELETE_COURSE_DATA_SUCCESS, id })
}

function viewData(id){
    return firebase.firestore().collection("courses").doc(id).get()
    .then(response => response)
}

function setLabel(data, obj){
    const { course_details } = data
    if(data.elegibility_criteria.required_course !== undefined){
        data.elegibility_criteria.required_course.map(val => {
            return obj.required_course.push({'label': val, 'value': val})
        })
    }
    if(course_details.discipline !== "" || course_details.program_level !== "" || data.elegibility_criteria.required_program_level !== "" || data.elegibility_criteria.mode_of_degree !== ""){
        obj.discipline['label'] = course_details.discipline
        obj.discipline['value'] = course_details.discipline
        obj.program_level['label'] = course_details.program_level
        obj.program_level['value'] = course_details.program_level
        // obj.duration['label'] = course_details.duration
        // obj.duration['value'] = course_details.duration
        obj.required_program_level['label'] = data.elegibility_criteria.required_program_level
        obj.required_program_level['value'] = data.elegibility_criteria.required_program_level
        obj.mode_of_degree['label'] = data.elegibility_criteria.mode_of_degree
        obj.mode_of_degree['value'] = data.elegibility_criteria.mode_of_degree
        return obj
    }
}

function* viewSingleCourse(data){
    const { id } = data
    let value = yield call(viewData, id)
    const singleCourse = value.data()
    console.log('single course:: ',singleCourse)
    const { course_details, elegibility_criteria } = singleCourse
    let obj = {'required_course':[], 'discipline':{}, 'program_level':{}, 'duration':singleCourse.course_details.duration, 'fees': singleCourse.course_details.fees,
    'required_program_level':{}, 'mode_of_degree':{}, 'admin_procedure': course_details.admin_procedure, 
    'description': course_details.description, 'max_age': elegibility_criteria.max_age, 
    'min_age': elegibility_criteria.min_age, 'required_marks_obtained': elegibility_criteria.required_marks_obtained,
    'branch': course_details.branch, 'created_by': singleCourse.created_by, 
    'created_date_time': singleCourse.created_date_time, 'isDeleted':singleCourse.isDeleted, 'modified_by': singleCourse.modified_by, 
    'modified_date_time': singleCourse.modified_date_time, 'name': singleCourse.name, 
    'display_name': singleCourse.display_name, 'catergory_criteria':singleCourse.catergory_criteria, 'status': singleCourse.status}
    console.log('final object::: ',obj)
    setLabel(singleCourse, obj)
    localStorage.setItem('course_id', id)
    yield put({ type: VIEW_SINGLE_COURSE_DATA_SUCCESS, obj })
}

function updateData (value){
    let id = localStorage.getItem('course_id')
    return firebase.firestore().collection("courses").doc(id).update(value)
}

function* updateCourse(data){
    try{
        let obj = {'course_details': {}, 'elegibility_criteria': {}}
        setvalue(data.data, obj)
        yield call(updateData, obj)
        yield put({ type: UPDATE_COURSE_SUCCESS })
        history.push('/courseList')
    }
    catch(error){
        const err = error.message
        yield put({ type: UPDATE_COURSE_ERROR, err })
    }
}

function* getCurrentCourse(){
    localStorage.removeItem('course_id')
    localStorage.removeItem('course_summary_img_url')
    localStorage.removeItem('entry_summary_img_url')
    localStorage.removeItem('apply_img_url')
    localStorage.removeItem('entrance_img_url')
    localStorage.removeItem('career_img_url')
    yield put({ type: CURRENT_COURSE_SUCCESS })
}

function setDatavalue(data, obj){
    const { tags } = data
    if(tags !== ""){
        const newVal = setValue(tags)
        obj.course_information.course_summary['tags'] = newVal
        return obj
    }
}

function setValues(data, obj){
    let new_course_summary_img_url, new_entry_summary_img_url, new_apply_img_url, new_entrance_img_url, new_career_img_url
    let course_summary_img_url = localStorage.getItem('course_summary_img_url')
    let entry_summary_img_url = localStorage.getItem('entry_summary_img_url')
    let apply_img_url = localStorage.getItem('apply_img_url')
    let entrance_img_url = localStorage.getItem('entrance_img_url')
    let career_img_url = localStorage.getItem('career_img_url')
    if(course_summary_img_url === "null"){
        new_course_summary_img_url = null
    }
    else{
        new_course_summary_img_url = course_summary_img_url
    }
    if(entry_summary_img_url === "null"){
        new_entry_summary_img_url = null
    }
    else{
        new_entry_summary_img_url = entry_summary_img_url
    }
    if(apply_img_url === "null"){
        new_apply_img_url = null
    }
    else{
        new_apply_img_url = apply_img_url
    }
    if(entrance_img_url === "null"){
        new_entrance_img_url = null
    }
    else{
        new_entrance_img_url = entrance_img_url
    }if(career_img_url === "null"){
        new_career_img_url = null
    }
    else{
        new_career_img_url = career_img_url
    }
    obj.course_information.course_summary['title'] = data.course_summary_title
    obj.course_information.course_summary['summary'] = data.course_summary
    obj.course_information.course_summary['img_url'] = new_course_summary_img_url
    obj.course_information.entry_requirement['title'] = data.entry_title
    obj.course_information.entry_requirement['summary'] = data.entry_summary
    obj.course_information.entry_requirement['img_url'] = new_entry_summary_img_url
    if(data.other_options_key !== undefined && data.other_options_value !== undefined){
        obj.course_information.entry_requirement.options.push({ 'label': data.other_options_key, 'value': data.other_options_value })
    }
    if(data.other_options !== undefined){
        data.other_options.map(value  => {
            if(!_.isEmpty(value)){
                return obj.course_information.entry_requirement.options.push({ 'label': value.key, 'value': value.value })
            }
        })
    }
    let array = []
    array.push({ 'level': data.highlights_key, 'value': data.highlights_value })
    if(data.highlights !== undefined){
        data.highlights.map(item => {
            if(!_.isEmpty(item)){
                return array.push({ 'level': item.key, 'value': item.value })
            }
        })
    }
    obj.course_information.highlights = array
    if(data.how_to_apply_key !== undefined && data.how_to_apply_value !== undefined){
        obj.course_information.how_to_apply.apply.push({ 'label': data.how_to_apply_key, 'value': data.how_to_apply_value })
    }
    if(data.how_to_apply !== undefined){
        data.how_to_apply.map(value  => {
            if(!_.isEmpty(value)){
                return obj.course_information.how_to_apply.apply.push({ 'label': value.key, 'value': value.value })
            }
        })
    }
    obj.course_information.how_to_apply['summary'] = data.apply_summary
    obj.course_information.how_to_apply['img_url'] = new_apply_img_url
    let entranceExamArray = []
    entranceExamArray.push(data.entrance_exam_key)
    if(data.entrance_exam_name !== undefined){
        data.entrance_exam_name.map(item => {
            if(!_.isEmpty(item)){
                return entranceExamArray.push(item.key)
            }
        })
    }
    obj.course_information.top_entrance_exam = {'exam': entranceExamArray}
    obj.course_information.top_entrance_exam['summary'] = data.top_entrance_summary
    obj.course_information.top_entrance_exam['img_url'] = new_entrance_img_url
    let careerArray = []
    careerArray.push(data.career_options_key)
    if(data.career_options !== undefined){
        data.career_options.map(item => {
            if(!_.isEmpty(item)){
                return careerArray.push(item.key)
            }
        })
    }
    obj.course_information.career_option = {'job_opportunity': careerArray}
    obj.course_information.career_option['summary'] = data.career_options_summary
    obj.course_information.career_option['img_url'] = new_career_img_url
    return obj
}

function addCourseDetails(obj, id){
    return firebase.firestore().collection("courses").doc(id).update(obj)
}

async function uploadImages(data, img_name, folderName){
    try{
        let name
        name = data.name
        let value = generateUniqueId()
        let newName=""
        newName = newName.concat(value, '_', name)
        var storageRef = firebase.storage().ref()
        const fileRef = storageRef.child(`${folderName}/${newName}`)
        var uploadTask = await fileRef.put(data)
        var downloadURL = await uploadTask.ref.getDownloadURL()
        localStorage.setItem(img_name, downloadURL)
    }
    catch(error){
        throw error
    }
}

function* addCourseDetailsData(data){
    try{
        const { id } = data.data
        let obj = {'course_information': {'career_option': {}, 'course_summary': {}, 'entry_requirement': {'options':[]},
                  'highlights': {}, 'how_to_apply': {'apply':[]}, 'top_entrance_exam': {} }}
        const { cropCourseImageData } = data
        if(!_.isEmpty(cropCourseImageData) || cropCourseImageData !== undefined){
            yield all(cropCourseImageData.map(item => {
                const { file, name, folderName } = item
                return call(uploadImages, file, name, folderName)
            }))
        }
        setDatavalue(data.data, obj)
        setValues(data.data, obj)
        const value = yield call(viewData, id)
        const list = value.data()
        obj['elegibility_criteria'] = list.elegibility_criteria
        obj['display_name'] = list.display_name
        obj['modified_by'] = list.modified_by
        obj['created_by'] = list.created_by
        obj['course_details'] = list.course_details
        obj['created_date_time'] = list.created_date_time
        obj['name'] = list.name
        obj['modified_date_time'] = new Date()
        obj['status'] = list.status
        yield call(addCourseDetails, obj, id)
        yield put({ type: ADD_COURSE_DETAILS_SUCCESS })
        history.push('/courseList')
    }
    catch(error){
        const err = error.message
        yield put({ type: ADD_COURSE_DETAILS_ERROR, err })
    }
}

function setDetailsLabel(data, obj){
    obj.tags = []
    if(data.course_summary.tags !== ""){
        data.course_summary.tags.map(val => {
            return obj.tags.push({'label': val, 'value': val})
        })
    }
}

function setDetailsValue(data, obj){
    obj['course_summary_title'] = data.course_summary.title
    obj['course_summary'] = data.course_summary.summary
    obj['course_summary_img_url'] = data.course_summary.img_url
    obj['entry_title'] = data.entry_requirement.title
    obj['entry_summary'] = data.entry_requirement.summary
    obj['entry_summary_img_url'] = data.entry_requirement.img_url
    if(data.entry_requirement.options !== undefined && data.entry_requirement.options.length !== 0){
        obj['other_options_key'] = data.entry_requirement.options[0].label
        obj['other_options_value'] = data.entry_requirement.options[0].value
        const other_options_length = data.entry_requirement.options.length
        obj.other_options = []
        if(other_options_length > 1){
            for(var i=1; i < other_options_length; i++){
                obj.other_options.push({ 'key':data.entry_requirement.options[i].label, 'value':data.entry_requirement.options[i].value })
            }
        }
    }
    obj['highlights_key'] = data.highlights[0].level
    obj['highlights_value'] = data.highlights[0].value
    const highlights_length = data.highlights.length
    obj.highlights = []
    if(highlights_length > 1){
        for(var j=1; j < highlights_length; j++){
            obj.highlights.push({ 'key':data.highlights[j].level, 'value':data.highlights[j].value })
        }
    }
    if(data.how_to_apply.apply !== undefined && data.how_to_apply.apply.length !== 0){
        obj['how_to_apply_key'] = data.how_to_apply.apply[0].label
        obj['how_to_apply_value'] = data.how_to_apply.apply[0].value
        const how_to_apply_length = data.how_to_apply.apply.length
        obj.how_to_apply = []
        if(how_to_apply_length > 1){
            for(var z=1; z < how_to_apply_length; z++){
                obj.how_to_apply.push({ 'key':data.how_to_apply.apply[z].label, 'value':data.how_to_apply.apply[z].value })
            }
        }
    }
    obj['apply_summary'] = data.how_to_apply.summary
    obj['apply_img_url'] = data.how_to_apply.img_url
    obj['entrance_exam_key'] = data.top_entrance_exam.exam[0]
    const entrance_exam_length = data.top_entrance_exam.exam.length
    obj.entrance_exam_name = []
    if(entrance_exam_length > 1){
        for(var k=1; k < entrance_exam_length; k++){
            obj.entrance_exam_name.push({ 'key':data.top_entrance_exam.exam[k] })
        }
    }
    obj['top_entrance_summary'] = data.top_entrance_exam.summary
    obj['entrance_img_url'] = data.top_entrance_exam.img_url
    obj['career_options_key'] = data.career_option.job_opportunity[0]
    const career_option_length = data.career_option.job_opportunity.length
    obj.career_options = []
    if(career_option_length > 1){
        for(var l=1; l < career_option_length; l++){
            obj.career_options.push({ 'key':data.career_option.job_opportunity[l] })
        }
    }
    obj['career_options_summary'] = data.career_option.summary
    obj['career_img_url'] = data.career_option.img_url
}

function* viewCourseDetailData(data){
    const { id } = data
    let value = yield call(viewData, id)
    const list = value.data()
    if(list.course_information !== undefined){
        localStorage.setItem('course_summary_img_url', list.course_information.course_summary.img_url)
        localStorage.setItem('entry_summary_img_url', list.course_information.entry_requirement.img_url)
        localStorage.setItem('apply_img_url', list.course_information.how_to_apply.img_url)
        localStorage.setItem('entrance_img_url', list.course_information.top_entrance_exam.img_url)
        localStorage.setItem('career_img_url', list.course_information.career_option.img_url)
    }
    let obj = {}, course_summary_image, entry_image, apply_image, entrance_image, career_image
    if(list.course_information !== undefined && list.course_information.course_summary.title !== ""){
        const { course_information } = list
        setDetailsLabel(course_information, obj)
        setDetailsValue(course_information, obj)
    }
    if(obj.course_summary_img_url !== null && obj.course_summary_img_url !== undefined){
        course_summary_image = true
    }
    if(obj.entry_summary_img_url !== null && obj.entry_summary_img_url !== undefined){
        entry_image = true
    }
    if(obj.apply_img_url !== null && obj.apply_img_url !== undefined){
        apply_image = true
    }
    if(obj.entrance_img_url !== null && obj.entrance_img_url !== undefined){
        entrance_image = true
    }
    if(obj.career_img_url !== null && obj.career_img_url !== undefined){
        career_image = true
    }
    yield put({ type: VIEW_COURSE_DETAILS_SUCCESS, obj, course_summary_image, entry_image, apply_image, entrance_image, career_image })
}

function updateList(id, list){
    return firebase.firestore().collection("courses").doc(id).update(list)
}

function* updateStatusData(data){
    const { id, value } = data
    let lists = yield call(viewData, id)
    const list = lists.data()
    list.status = value.value
    yield call(updateList, id, list)
    yield put({ type: UPDATE_COURSE_STATUS_SUCCESS })
}

async function deleteImage(file_url) {
    try{
        await deleteImageFromStorage(file_url)
    } 
    catch (error){
        throw error
    }
}

function* deleteSectionImageData(data){
    try{
        const { file_url, list, name, id } = data
        localStorage.removeItem(name)
        let obj = {'course_information': {'career_option': {}, 'course_summary': {}, 'entry_requirement': {'options':[]},
                  'highlights': {}, 'how_to_apply': {'apply':[]}, 'top_entrance_exam': {} }}
        setDatavalue(list, obj)
        setValues(list, obj)
        const value = yield call(viewData, id)
        const newList = value.data()
        obj['elegibility_criteria'] = newList.elegibility_criteria
        obj['display_name'] = newList.display_name
        obj['modified_by'] = newList.modified_by
        obj['created_by'] = newList.created_by
        obj['course_details'] = newList.course_details
        obj['created_date_time'] = newList.created_date_time
        obj['name'] = newList.name
        obj['modified_date_time'] = new Date()
        yield call(addCourseDetails, obj, id)
        yield call(deleteImage, file_url)
        yield put({ type: DELETE_COURSE_IMAGE_SUCCESS })
        yield put({ type: VIEW_COURSE_DETAILS_REQUEST, viewCourseDetailData, id })
    }
    catch(error){
        const err = error.message
        yield put({ type: DELETE_COURSE_IMAGE_ERROR, err })
    }
}

export default () => {
    function * watcher() {
      yield takeLatest(GET_COURSE_LIST_REQUEST, getCourseList)
      yield takeLatest(ADD_COURSE_REQUEST, addCourseData)
      yield takeLatest(DELETE_COURSE_DATA_REQUEST, deleteCourse)
      yield takeLatest(VIEW_SINGLE_COURSE_DATA_REQUEST, viewSingleCourse)
      yield takeLatest(UPDATE_COURSE_REQUEST, updateCourse)
      yield takeLatest(CURRENT_COURSE_REQUEST, getCurrentCourse)
      yield takeLatest(ADD_COURSE_DETAILS_REQUEST, addCourseDetailsData)
      yield takeLatest(VIEW_COURSE_DETAILS_REQUEST, viewCourseDetailData)
      yield takeLatest(UPDATE_COURSE_STATUS_REQUEST, updateStatusData)
      yield takeLatest(GET_ALL_COURSE_NAME_REQUEST, getAllCoursesName)
      yield takeLatest(DELETE_COURSE_IMAGE_REQUEST, deleteSectionImageData)
    }
    return {watcher}
}