export const status = [
    { value: 'Draft', label: 'Draft' },
    { value: 'Unpublished', label: 'Unpublished' },
    { value: 'Published', label: 'Published' }
]

export const userStatus = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' }
]

export const options = [
    { value: 'Deleted', label: 'Deleted' },
    { value: 'Not Deleted', label: 'Not Deleted' }
]