export const nationality=[
    {value: 'Bhutan', label: 'Bhutan'},
    {value: 'India', label: 'India'},
    {value: 'Nepal', label: 'Nepal'},
    {value: 'Sri lanka', label: 'Sri lanka'},
    {value: 'Any Country', label: 'Any Country'}
]

export const exam_language= [
    {value: 'English', label: 'English'},
    {value: 'Hindi', label: 'Hindi'},
    {value: 'Sanskrit', label: 'Sanskrit'},
    {value: 'Urdu', label: 'Urdu'},
    {value: 'States language', label: 'States language'},
    {value: 'Regional', label: 'Regional'},
]

export const job_type = [
    {value: 'Public Sector', label: 'Public Sector'},
    {value: 'Central govt', label: 'Central govt'}
]

export const level = [
    {value: 'National', label: 'National'},
    {value: 'International', label: 'International'},
    {value: 'State', label:'State'}
]

export const exam_time = [
    {value: 'Annually', label: 'Annually'},
    {value: 'Biannually', label: 'Biannually'},
    {value: '4 Times', label:'4 Times'},
    {value: 'Every 16 calendar days', label:'Every 16 calendar days'}
]