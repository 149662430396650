import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'
import { updateProfileData, getCurrentProfileData } from '../../actions/UserActions'
import Header from '../Header/Header'
import { Link } from 'react-router-dom'
import { authHeader, history } from '../../_helpers'
import ProfileForm from './ProfileForm'
import ReactLoading from "react-loading"

const Profile = (props) => {
    let pathname = props.location.pathname
    let { profileError, updateProfileData, getCurrentProfileData, loading } = props

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getCurrentProfileData()
            }
            else{
                history.push('/login') 
            }
        }
    },[pathname, getCurrentProfileData])

    const handleSubmit = (data) => {
        let currentTime = Math.round(new Date().getTime()/1000)
        data['updated_at'] = currentTime
        updateProfileData(data)
    }

    return(
        <div className="wrapper">
            <div className="container">
                {profileError !== "" ? profileError : null}
                {loading ?  <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                <Header />
                <Link className="link-container" to="/dashboard"> <Icon name="arrow left" /> Back to Dashboard </Link>
                <ProfileForm onSubmit={handleSubmit} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.userReducer.loading,
    profileError: state.userReducer.profileError
})

const mapDispatchToProps = {
    updateProfileData,
    getCurrentProfileData
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)