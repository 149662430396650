import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Header from '../../Header/Header'
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import FormContainer from './FormContainer'
import { authHeader, history } from '../../../_helpers'
import ReactLoading from "react-loading"
import _ from 'lodash'
import { currentQuizQuestion, addQuizQuestion, viewSingleQuizQuestion, updateQuizQuestion } from '../../../actions/QuizQuestionActions'

const AddQuizQuestion = (props) => {
    const { pathname } = props.location
    const { currentQuizQuestion, addQuizQuestion, viewSingleQuizQuestion, updateQuizQuestion, error, loading, imageData } = props

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
            }
            else{
                history.push('/login') 
            }
        }
        if(props.match && props.match.params && props.match.params.id){
            const newQuizQuestion = props.match.params.id
            viewSingleQuizQuestion(newQuizQuestion)
        }
        else{
            currentQuizQuestion()
        }
    },[pathname, props.match, currentQuizQuestion, viewSingleQuizQuestion])

    const handleSubmit = (data) => {
        let uid = localStorage.getItem('uid')
        let quizQuestion_id = localStorage.getItem('quizQuestion_id')
        if(quizQuestion_id === null || quizQuestion_id === ""){
            data['created_at'] = new Date()
            data['created_by'] = uid
            data['updated_at'] = new Date()
            data['modified_by'] = uid
            data['isDeleted'] = false
            if(data.upload_answer === false){
                addQuizQuestion(data, undefined)
            }
            else{
                addQuizQuestion(data, imageData) 
            }
        }
        else{
            data['updated_at'] = new Date()
            data['modified_by'] = uid
            if(data.upload_answer === false){
                updateQuizQuestion(data, undefined) 
            }
            else{
                if(_.isEmpty(imageData)){
                    updateQuizQuestion(data, undefined) 
                }
                else{
                    updateQuizQuestion(data, imageData) 
                }
            }
        }
    }

    return(
        <div className="wrapper">
            <div className="container">
                {error !== "" ? <h5 className="errorMsg"> {error} </h5>: null}
                {loading ?  <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                <Header />
                <Link className="link-container" to="/quizquestionList"> <Icon name="arrow left" /> Back to Question List </Link>
                <FormContainer onSubmit={handleSubmit} newQuizQuestion={props.match.params.id} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.quizQuestionReducer.loading,
    error: state.quizQuestionReducer.error,
    imageData: state.quizQuestionReducer.imageData
})

const mapDispatchToProps = {
    currentQuizQuestion,
    addQuizQuestion,
    viewSingleQuizQuestion,
    updateQuizQuestion
}

export default connect(mapStateToProps, mapDispatchToProps)(AddQuizQuestion)