import { call, put, takeLatest, all } from 'redux-saga/effects'
import "firebase/auth"
import firebase from '../components/firebase'
import { history } from '../_helpers'
import { db } from '../components/firebase'
import _ from 'lodash'
import { GET_DISCIPLINE_LIST_REQUEST, GET_DISCIPLINE_LIST_SUCCESS, GET_DISCIPLINE_LIST_ERROR, ADD_DISCIPLINE_REQUEST, ADD_DISCIPLINE_SUCCESS, ADD_DISCIPLINE_ERROR, DELETE_DISCIPLINE_DATA_REQUEST, DELETE_DISCIPLINE_DATA_SUCCESS, VIEW_SINGLE_DISCIPLINE_DATA_REQUEST, VIEW_SINGLE_DISCIPLINE_DATA_SUCCESS, UPDATE_DISCIPLINE_REQUEST, UPDATE_DISCIPLINE_SUCCESS, UPDATE_DISCIPLINE_ERROR, CURRENT_DISCIPLINE_REQUEST, CURRENT_DISCIPLINE_SUCCESS, VIEW_DISCIPLINE_DATA_REQUEST, VIEW_DISCIPLINE_DATA_SUCCESS, GET_ALL_DISCIPLINE_NAME_REQUEST, GET_ALL_DISCIPLINE_NAME_SUCCESS, GET_ALL_DISCIPLINE_NAME_ERROR, DELETE_DISCIPLINE_IMAGE_REQUEST, DELETE_DISCIPLINE_IMAGE_SUCCESS, DELETE_DISCIPLINE_IMAGE_ERROR } from '../constants/DisciplineConstants'
import { getcount , getData } from './PaginationWithSearch'
import { generateUniqueId } from './UniqueId'
import { deleteImageFromStorage } from './DeleteImage'

async function getList(){
    let array = []
    let categoryList = db.collection("discipline").where("isDeleted", "==", false)
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
    // return fetch('http://us-central1-opennaukri-49272.cloudfunctions.net/app/hello', {
    //     method: 'GET'
    // })
    //     .then(response => response.json())
    //     .catch((error) => { 
    //         console.log('error', error)
    //         throw error })
}

async function getTotalNodes(count, searchTerm, list, activePage){
    const totalnodes = await getcount(searchTerm, "discipline")
    if(totalnodes.length === undefined){
        totalnodes.docs.map(doc => {
            return count.push(doc.id)
        })
    }
    else{
        totalnodes.map(id => {
            return count.push(id)
        })
    }
    await getData(activePage, list, searchTerm, "discipline")
}

function* getDisciplineList(values){
    try{
        const { activePage, searchTerm } = values
        let list = [], count = []
        yield call(getTotalNodes, count, searchTerm, list, activePage)
        yield put({ type: GET_DISCIPLINE_LIST_SUCCESS, list, count })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_DISCIPLINE_LIST_ERROR, err })
    }
}

function addData(data){
    return firebase.firestore().collection('discipline').add(data)
    .then(response => response)
}

function setvalue(value){
    const { tags } = value
    if(tags !== ''){
        const newVal = setValue(tags)
        return newVal
    }
}

function setValue(value){
    let array = []
    value.map((data) => {
        return array.push(data.value)
    })
    return array
}

function setData(value, obj){
    const tagsValue = setvalue(value)
    let new_course_img_url, new_job_img_url
    let course_img_url = localStorage.getItem('course_img_url')
    let job_img_url = localStorage.getItem('job_img_url')
    if(course_img_url === "null"){
        new_course_img_url = null
    }
    else{
        new_course_img_url = course_img_url
    }
    if(job_img_url === "null"){
        new_job_img_url = null
    }
    else{
        new_job_img_url = job_img_url
    }
    obj['display_name'] = value.display_name
    obj['name'] = value.name
    obj['created_at'] = value.created_at
    obj['modified_at'] = value.modified_at
    obj['isDeleted'] = value.isDeleted
    obj.discipline_details = {'summary': value.summary, 'title': value.course_title, 'tags': tagsValue, 'img_url': new_course_img_url}
    let array = []
    if(value && value.prospects_level1_key !== "" && value.prospects_level1_key !== undefined){
        array.push({ 'level': value.prospects_level1_key, 'value': value.prospects_level1_value })
    }

    if(value && value.prospects_level2_key !== "" && value.prospects_level2_key !== undefined){
        array.push({ 'level': value.prospects_level2_key, 'value': value.prospects_level2_value })
    }
    if(value && value.prospects_level3_key !== "" && value.prospects_level3_key !== undefined){
        array.push({ 'level': value.prospects_level3_key, 'value': value.prospects_level3_value })
    }
    if(value && value.prospects_level4_key !== "" && value.prospects_level4_key !== undefined){
        array.push({ 'level': value.prospects_level4_key, 'value': value.prospects_level4_value })
    }
    /* if(value.prospects_level !== ''){
        value.prospects_level.map(item => {
            if(!_.isEmpty(item)){
                return array.push({ 'level': item.key, 'value': item.value })
            }
        })
    } */
    obj.growth_prospects={'title': value.growth_title, 'prospects_level': array}
    let newArray = []
    console.log('valueee:: ',value)
    // newArray.push({ 'level': value.salary_level1_key, 'text': value.salary_level1_text, 'value': value.salary_level1_value })
    // newArray.push({ 'level': value.salary_level2_key, 'text': value.salary_level2_text, 'value': value.salary_level2_value })
    // newArray.push({ 'level': value.salary_level3_key, 'text': value.salary_level3_text, 'value': value.salary_level3_value })
    // newArray.push({ 'level': value.salary_level4_key, 'text': value.salary_level4_text, 'value': value.salary_level4_value })
    if(value && value.salary_level1_text !== undefined && value.salary_level1_text !== "" && value.salary_level1_value !== undefined && value.salary_level1_value !== "" ){
        console.log('iffff one')
        newArray.push({ 'level': value.salary_level1_key ? value.salary_level1_key : "", 'text': value.salary_level1_text, 'value': value.salary_level1_value })
    }
    if(value && value.salary_level2_text !== undefined && value.salary_level2_text !== "" && value.salary_level2_value !== undefined && value.salary_level2_value !== "" ){
        newArray.push({ 'level': value.salary_level2_key ? value.salary_level2_key : "", 'text': value.salary_level2_text, 'value': value.salary_level2_value })
    }
    if(value && value.salary_level3_text !== undefined && value.salary_level3_text !== "" && value.salary_level3_value !== undefined && value.salary_level3_value !== ""){
        newArray.push({ 'level': value.salary_level3_key ? value.salary_level3_key : "", 'text': value.salary_level3_text, 'value': value.salary_level3_value })
    }
    if(value && value.salary_level4_text !== undefined && value.salary_level4_text !== "" && value.salary_level4_value !== undefined && value.salary_level4_value !== ""){
        newArray.push({ 'level': value.salary_level4_key ? value.salary_level4_key : "", 'text': value.salary_level4_text, 'value': value.salary_level4_value })
    }
    console.log('newarrray:: ',newArray)
    /* if(value.salary_range !== ''){
        value.salary_range.map(item => {
            if(!_.isEmpty(item)){
                return newArray.push({ 'level': item.key, 'text': item.text, 'value': item.value })
            }
        })
    } */
    obj.salary_range={'title': value.salary_title,'salary_level': newArray}
    let jobArray = []
    jobArray.push(value.job_level1_key)
    if(value.job_name !== ''){
        value.job_name.map(item => {
            if(!_.isEmpty(item)){
                return jobArray.push(item.key)
            }
        })
    }
    obj.jobs={'title': value.jobs_title_text, 'job_name': jobArray, 'img_url': new_job_img_url}
    let marketArray = []
    // marketArray.push({ 'level': value.opportunity_key, 'value': value.opportunity_value })
    marketArray.push({ 'value': value.opportunity_value })
    if(value.opportunity_service !== ''){
        value.opportunity_service.map(item => {
            if(!_.isEmpty(item)){
                // return marketArray.push({ 'level': item.key, 'value': item.value })
                return marketArray.push({ 'value': item.value })
            }
        })
    }
    obj.market_opportunity = {'title': value.market_title_text, 'opportunity_service': marketArray}
    console.log('datttta:: ',obj)
    return obj
}

async function uploadImages(data, img_name, folderName){
    try{
        let name
        name = data.name
        let value = generateUniqueId()
        let newName=""
        newName = newName.concat(value, '_', name)
        var storageRef = firebase.storage().ref()
        const fileRef = storageRef.child(`${folderName}/${newName}`)
        var uploadTask = await fileRef.put(data)
        var downloadURL = await uploadTask.ref.getDownloadURL()
        localStorage.setItem(img_name, downloadURL)
    }
    catch(error){
        throw error
    }
}

function* addDisciplineData(data){
    try{
        let obj = {
            'discipline_details': {},
            'growth_prospects': {},
            'salary_range': [],
            'jobs': {},
            'market_opportunity': {}
        }
        const { value, cropDisciplineImageData } = data
        if(!_.isEmpty(cropDisciplineImageData) || cropDisciplineImageData !== undefined){
            yield all(cropDisciplineImageData.map(item => {
                const { file, name, folderName } = item
                return call(uploadImages, file, name, folderName)
            }))
        }
        yield call(setData, value, obj)
        yield call(addData, obj)
        yield put({ type: ADD_DISCIPLINE_SUCCESS })
        history.push('/discipline')
    }
    catch(error){
        const err = error.message
        yield put({ type: ADD_DISCIPLINE_ERROR, err })
    }
}

function getNewList(list, id){
    let obj = {}
    _.forEach(list, function(value){
        if(value.id === id){
            obj = value
        }
    })
    return obj
}

function* deleteDiscipline(data){
    const { id } = data
    const list = yield call(getList)
    const newList = yield call(getNewList, list, id)
    newList.isDeleted = true
    firebase.firestore().collection("discipline").doc(id).update(newList)
    yield put({ type: DELETE_DISCIPLINE_DATA_SUCCESS, id })
}

function viewData(id){
    return firebase.firestore().collection("discipline").doc(id).get()
    .then(response => response)
}

function setLabel(singleDiscipline, obj){
    obj.tags = []
    if(singleDiscipline.discipline_details.tags !== ""){
        singleDiscipline.discipline_details.tags.map(val => {
            return obj.tags.push({'label': val, 'value': val})
        })
    }
}

function setViewData(singleDiscipline, obj){
    setLabel(singleDiscipline, obj)
    obj['display_name'] = singleDiscipline.display_name
    obj['name'] = singleDiscipline.name
    obj['created_at'] = singleDiscipline.created_at
    obj['modified_at'] = singleDiscipline.modified_at
    obj['isDeleted'] = singleDiscipline.isDeleted
    obj['summary'] = singleDiscipline.discipline_details.summary
    obj['course_title'] = singleDiscipline.discipline_details.title
    obj['course_img_url'] = singleDiscipline.discipline_details.img_url
    obj['growth_title'] = singleDiscipline.growth_prospects.title

    if(singleDiscipline && singleDiscipline.growth_prospects && singleDiscipline.growth_prospects.prospects_level && singleDiscipline.growth_prospects.prospects_level.length > 0){
        _.forEach(singleDiscipline.growth_prospects.prospects_level, function(item,index){
            obj[`prospects_level${index+1}_key`] = item.level
            obj[`prospects_level${index+1}_value`] = item.value
        })  
    }

    /* obj['prospects_level1_key'] = singleDiscipline.growth_prospects.prospects_level[0].level
    obj['prospects_level1_value'] = singleDiscipline.growth_prospects.prospects_level[0].value
    obj['prospects_level2_key'] = singleDiscipline.growth_prospects.prospects_level[1].level
    obj['prospects_level2_value'] = singleDiscipline.growth_prospects.prospects_level[1].value
    obj['prospects_level3_key'] = singleDiscipline.growth_prospects.prospects_level[2].level
    obj['prospects_level3_value'] = singleDiscipline.growth_prospects.prospects_level[2].value
    obj['prospects_level4_key'] = singleDiscipline.growth_prospects.prospects_level[3].level
    obj['prospects_level4_value'] = singleDiscipline.growth_prospects.prospects_level[3].value
 */

    const prospects_level_length = singleDiscipline.growth_prospects.prospects_level.length
    obj.prospects_level = []
    if(prospects_level_length > 4){
        for(var i=4; i < prospects_level_length; i++){
            obj.prospects_level.push({ 'key':singleDiscipline.growth_prospects.prospects_level[i].level, 'value':singleDiscipline.growth_prospects.prospects_level[i].value })
        }
    }
    obj['salary_title'] = singleDiscipline.salary_range.title

    if(singleDiscipline && singleDiscipline.salary_range && singleDiscipline.salary_range && singleDiscipline.salary_range.salary_level.length > 0){
        _.forEach(singleDiscipline.salary_range.salary_level, function(item,index){
            obj[`salary_level${index+1}_key`] = item.level
            obj[`salary_level${index+1}_text`] = item.text
            obj[`salary_level${index+1}_value`] = item.value
        })  
    }


    /* obj['salary_level1_key'] = singleDiscipline.salary_range.salary_level[0].level
    obj['salary_level1_text'] = singleDiscipline.salary_range.salary_level[0].text
    obj['salary_level1_value'] = singleDiscipline.salary_range.salary_level[0].value
    obj['salary_level2_key'] = singleDiscipline.salary_range.salary_level[1].level
    obj['salary_level2_text'] = singleDiscipline.salary_range.salary_level[1].text
    obj['salary_level2_value'] = singleDiscipline.salary_range.salary_level[1].value
    obj['salary_level3_key'] = singleDiscipline.salary_range.salary_level[2].level
    obj['salary_level3_text'] = singleDiscipline.salary_range.salary_level[2].text
    obj['salary_level3_value'] = singleDiscipline.salary_range.salary_level[2].value */
    // obj['salary_level4_key'] = singleDiscipline.salary_range.salary_level[3].level
    // obj['salary_level4_text'] = singleDiscipline.salary_range.salary_level[3].text
    // obj['salary_level4_value'] = singleDiscipline.salary_range.salary_level[3].value
    const salary_range_length = singleDiscipline.salary_range.salary_level.length
    obj.salary_range = []
    if(salary_range_length > 3){
        for(var j=4; j < salary_range_length; j++){
            obj.salary_range.push({ 'key':singleDiscipline.salary_range.salary_level[j].level, 'text':singleDiscipline.salary_range.salary_level[j].text, 'value':singleDiscipline.salary_range.salary_level[j].value })
        }
    }
    obj['jobs_title_text'] = singleDiscipline.jobs.title
    obj['job_level1_key'] = singleDiscipline.jobs.job_name[0]
    obj['job_img_url'] = singleDiscipline.jobs.img_url
    const job_length = singleDiscipline.jobs.job_name.length
    obj.job_name = []
    if(job_length > 1){
        for(var k=1; k < job_length ; k++){
            obj.job_name.push({ 'key': singleDiscipline.jobs.job_name[k] })
        }
    }
    obj['market_title_text'] = singleDiscipline.market_opportunity.title
    // obj['opportunity_key'] = singleDiscipline.market_opportunity.opportunity_service[0].level
    obj['opportunity_value'] = singleDiscipline.market_opportunity.opportunity_service[0].value
    const opportunity_length = singleDiscipline.market_opportunity.opportunity_service.length
    obj.opportunity_service = []
    if(opportunity_length > 1){
        for(var l=1; l < opportunity_length ; l++){
            // obj.opportunity_service.push({ 'key': singleDiscipline.market_opportunity.opportunity_service[l].level, 'value': singleDiscipline.market_opportunity.opportunity_service[l].value })
            obj.opportunity_service.push({ 'value': singleDiscipline.market_opportunity.opportunity_service[l].value })
        }
    }
    return obj
}

function* viewSingleDiscipline(data){
    const { id } = data
    let value = yield call(viewData, id)
    const singleDiscipline = value.data()
    localStorage.setItem('discipline_id', id)
    localStorage.setItem('course_img_url', singleDiscipline.discipline_details.img_url)
    localStorage.setItem('job_img_url', singleDiscipline.jobs.img_url)
    let obj = {}, courseImage = false, jobImage = false
    yield call(setViewData, singleDiscipline, obj)
    if(obj.course_img_url !== null && obj.course_img_url !== undefined){
        courseImage = true
    }
    if(obj.job_img_url !== null && obj.job_img_url !== undefined){
        jobImage = true
    }
    yield put({ type: VIEW_SINGLE_DISCIPLINE_DATA_SUCCESS, obj, courseImage, jobImage })
}

function updateData (value){
    let id = localStorage.getItem('discipline_id')
    return firebase.firestore().collection("discipline").doc(id).update(value)
}

function* updateDiscipline(data){
    try{
        let obj = {
            'discipline_details': {},
            'growth_prospects': {},
            'salary_range': [],
            'jobs': {},
            'market_opportunity': {}
        }
        const { value, cropDisciplineImageData } = data
        if(!_.isEmpty(cropDisciplineImageData) || cropDisciplineImageData !== undefined){
            yield all(cropDisciplineImageData.map(item => {
                const { file, name, folderName } = item
                return call(uploadImages, file, name, folderName)
            }))
        }
        yield call(setData, value, obj)
        yield call(updateData, obj)
        yield put({ type: UPDATE_DISCIPLINE_SUCCESS })
        history.push('/discipline')
    }
    catch(error){
        const err = error.message
        yield put({ type: UPDATE_DISCIPLINE_ERROR, err })
    }
}

function* getCurrentDiscipline(){
    localStorage.removeItem('discipline_id')
    localStorage.removeItem('course_img_url')
    localStorage.removeItem('job_img_url')
    yield put({ type: CURRENT_DISCIPLINE_SUCCESS })
}

function* viewDisciplinedata(data){
    const { id } = data
    let value = yield call(viewData, id)
    const singleList = value.data()
    yield put({ type: VIEW_DISCIPLINE_DATA_SUCCESS, singleList })
}

function* getAllDisciplineNames(){
    try{
        let list = yield call(getList)
        yield put({ type: GET_ALL_DISCIPLINE_NAME_SUCCESS, list })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_ALL_DISCIPLINE_NAME_ERROR, err })
    }
}

async function deleteImage(file_url) {
    try{
        await deleteImageFromStorage(file_url)
    } 
    catch (error){
        throw error
    }
}

function* deleteSectionImageData(data){
    try{
        const { file_url, list, name, id } = data
        localStorage.removeItem(name)
        let obj = {
            'discipline_details': {},
            'growth_prospects': {},
            'salary_range': [],
            'jobs': {},
            'market_opportunity': {}
        }
        yield call(setData, list, obj)
        yield call(updateData, obj)
        yield call(deleteImage, file_url)
        yield put({ type: DELETE_DISCIPLINE_IMAGE_SUCCESS })
        yield put({ type: VIEW_SINGLE_DISCIPLINE_DATA_REQUEST, viewSingleDiscipline, id })
    }
    catch(error){
        const err = error.message
        yield put({ type: DELETE_DISCIPLINE_IMAGE_ERROR, err })
    }
}

export default () => {
    function * watcher() {
      yield takeLatest(GET_DISCIPLINE_LIST_REQUEST, getDisciplineList)
      yield takeLatest(ADD_DISCIPLINE_REQUEST, addDisciplineData)
      yield takeLatest(DELETE_DISCIPLINE_DATA_REQUEST, deleteDiscipline)
      yield takeLatest(VIEW_SINGLE_DISCIPLINE_DATA_REQUEST, viewSingleDiscipline)
      yield takeLatest(UPDATE_DISCIPLINE_REQUEST, updateDiscipline)
      yield takeLatest(CURRENT_DISCIPLINE_REQUEST, getCurrentDiscipline)
      yield takeLatest(VIEW_DISCIPLINE_DATA_REQUEST, viewDisciplinedata)
      yield takeLatest(GET_ALL_DISCIPLINE_NAME_REQUEST, getAllDisciplineNames)
      yield takeLatest(DELETE_DISCIPLINE_IMAGE_REQUEST, deleteSectionImageData)
    }
    return {watcher}
}