import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { addExamData, updateExamData, viewSingleExamData, currentExam } from '../../actions/ExamActions'
import { getEducationalData, getSubjectList } from '../../actions/helperActions'
import { Icon } from 'semantic-ui-react'
import ReactLoading from "react-loading"
import { Link } from 'react-router-dom'
import Header from '../Header/Header'
import { authHeader, history } from '../../_helpers'
import FormContainer from './FormContainer'

const AddExam = (props) => {
    const { pathname } = props.location
    const { addExamData, loading, viewSingleExamData, currentExam, updateExamData, getEducationalData, getSubjectList } = props

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getEducationalData()
                getSubjectList()
            }
            else{
                history.push('/login') 
            }
        }
        if(props.match && props.match.params && props.match.params.id){
            const newUsers = props.match.params.id
            viewSingleExamData(newUsers)
        }
        else{
            currentExam()
        }
    },[viewSingleExamData, pathname, props.match, currentExam, getEducationalData, getSubjectList])

    const handleSubmit = (data) => {
        let currentTime = Math.round(new Date().getTime()/1000)
        let exam_id = localStorage.getItem('exam_id')
        if(exam_id === null || exam_id === ""){
            data['created_at'] = currentTime
            data['updated_at'] = currentTime
            data['isDeleted'] = false
            addExamData(data)
        }
        else {
            if(data.certification === "No"){
                data['certification_name'] = ""
            }
            data['updated_at'] = currentTime
            updateExamData(data)
        }
    }

    return(
        <div className="wrapper">
            <div className="container">
                {props.error !== "" ? <h5 className="errorMsg"> {props.error} </h5>: null}
                {loading ?  <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                <Header />
                <Link className="link-container" to="/exams"> <Icon name="arrow left" /> Back to Exams </Link>
                <FormContainer onSubmit={handleSubmit} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.examReducer.loading,
    error: state.examReducer.error
})

const mapDispatchToProps = {
    addExamData,
    updateExamData,
    viewSingleExamData,
    currentExam,
    getEducationalData,
    getSubjectList
}

export default connect(mapStateToProps, mapDispatchToProps)(AddExam)