export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_ERROR = 'REGISTER_ERROR'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const LOGOUT_USER = 'LOGOUT_USER'

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'

export const UPDATE_PROFILE_REQUEST= 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS='UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'

export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST'
export const CURRENT_USER_SUCCESS = 'CURRENT_USER_SUCCESS'

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_ERROR = 'GET_USERS_ERROR'

export const VIEW_SINGLE_USER_REQUEST = 'VIEW_SINGLE_USER_REQUEST'
export const VIEW_SINGLE_USER_SUCCESS = 'VIEW_SINGLE_USER_SUCCESS'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const GET_CURRENT_USER_PROFILE_REQUEST = 'GET_CURRENT_USER_PROFILE_REQUEST'
export const GET_CURRENT_USER_PROFILE_SUCCESS = 'GET_CURRENT_USER_PROFILE_SUCCESS'

export const UPDATE_USER_STATUS_REQUEST = 'UPDATE_USER_STATUS_REQUEST'
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS'

export const SEND_INVITATION_REQUEST = 'SEND_INVITATION_REQUEST'
export const SEND_INVITATION_SUCCESS = 'SEND_INVITATION_SUCCESS'
export const SEND_INVITATION_ERROR = 'SEND_INVITATION_ERROR'

export const SAVE_ROLE = 'SAVE_ROLE'

export const SAVE_COMPANY = 'SAVE_COMPANY'

export const SAVE_QUIZ_SET = 'SAVE_QUIZ_SET'

export const SAVE_USER_QUIZ_REQUEST = 'SAVE_USER_QUIZ_REQUEST'
export const SAVE_USER_QUIZ_SUCCESS = 'SAVE_USER_QUIZ_SUCCESS'
export const SAVE_USER_QUIZ_ERROR = 'SAVE_USER_QUIZ_ERROR'

export const GET_ASSIGNED_QUIZZES_REQUEST = 'GET_ASSIGNED_QUIZZES_REQUEST'
export const GET_ASSIGNED_QUIZZES_SUCCESS = 'GET_ASSIGNED_QUIZZES_SUCCESS'
export const GET_ASSIGNED_QUIZZES_ERROR = 'GET_ASSIGNED_QUIZZES_ERROR'

export const UPDATE_USER_QUIZ_REQUEST = 'UPDATE_USER_QUIZ_REQUEST'
export const UPDATE_USER_QUIZ_SUCCESS = 'UPDATE_USER_QUIZ_SUCCESS'
export const UPDATE_USER_QUIZ_ERROR = 'UPDATE_USER_QUIZ_ERROR'

export const REMOVE_QUIZ_SET = 'REMOVE_QUIZ_SET'

export const UPDATE_BUTTON_DATA = 'UPDATE_BUTTON_DATA'

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'