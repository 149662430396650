import { combineReducers } from 'redux'
import user from './user.reducer'
import exam from './exam.reducer'
import category from './category.reducer'
import disability from './disability.reducer'
import course from './course.reducer'
import discipline from './discipline.reducer'
import helper from './helper.reducer'
import education from './education.reducer'
import subject from './subject.reducer'
import tag from './tag.reducer'
import quizcategory from './quizcategory.reducer'
import quizquestion from './quizquestion.reducer'
import quiz from './quiz.reducer'
import { reducer as reduxFormReducer } from 'redux-form'

const rootReducer = combineReducers({
  userReducer: user,
  examReducer: exam,
  categoryReducer: category,
  disabilityReducer: disability,
  courseReducer: course,
  disciplineReducer: discipline,
  helperReducer: helper,
  educationReducer: education,
  subjectReducer: subject,
  tagReducer: tag,
  quizCategoryReducer: quizcategory,
  quizQuestionReducer: quizquestion,
  quizReducer: quiz,
  form: reduxFormReducer
})

export default rootReducer