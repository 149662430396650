import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Header from '../Header/Header'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import FormContainer from './FormContainer'
import ReactLoading from "react-loading"
import { authHeader, history } from '../../_helpers'
import { viewSingleDisciplineData, currentDiscipline, addDiscipline, updateDisciplineList } from '../../actions/DisciplineActions'

const AddDiscipline = (props) => {
    const { pathname } = props.location
    const { viewSingleDisciplineData, currentDiscipline, addDiscipline, updateDisciplineList, loading, error, cropDisciplineImageData, uploadError } = props
    const [showError, setShowError] = useState(false)

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
            }
            else{
                history.push('/login') 
            }
        }
        if(props.match && props.match.params && props.match.params.id){
            const newCategory = props.match.params.id
            viewSingleDisciplineData(newCategory)
        }
        else{
            currentDiscipline()
        }
    },[pathname, props.match, viewSingleDisciplineData, currentDiscipline])

    useEffect(() => {
        if(!uploadError){
            setShowError(false)
        }
    })

    const handleSubmit = (data) => {
        let discipline_id = localStorage.getItem('discipline_id')
        console.log('disicipline data:: ',data)
        if(discipline_id === null || discipline_id === ""){
            data['created_at'] = new Date()
            data['modified_at'] = new Date()
            data['isDeleted'] = false
            if(uploadError){
                setShowError(true)
            }
            else{
                setShowError(false)
                addDiscipline(data, cropDisciplineImageData)
            }
        }
        else{
            data['modified_at'] = new Date()
            if(uploadError){
                setShowError(true)
            }
            else{
                setShowError(false)
                updateDisciplineList(data, cropDisciplineImageData)
            }
        }
    }

    return(
        <div className="wrapper">
            <div className="container">
                {showError ? <h5 className="errorMsg"> Please click on upload button to upload image </h5>: null}
                {error !== "" ? <h5 className="errorMsg"> {error} </h5>: null}
                {loading ?  <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                <Header />
                <Link className="link-container" to="/discipline"> <Icon name="arrow left" /> Back to Discipline List </Link>
                <FormContainer onSubmit={handleSubmit} Id={props.match.params.id} />
            </div>
    </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.disciplineReducer.loading,
    error: state.disciplineReducer.error,
    cropDisciplineImageData: state.disciplineReducer.cropDisciplineImageData,
    uploadError: state.helperReducer.uploadError
})

const mapDispatchToProps = {
    viewSingleDisciplineData,
    currentDiscipline,
    addDiscipline,
    updateDisciplineList
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDiscipline)