import React, { Component } from 'react'
import { connect } from 'react-redux'
import Input from '../library/Input'
import Label from '../library/Label'
import { Button, Form, Grid } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form'
import classnames from 'classnames'
import validate from './validate'

class ProfileForm extends Component{
    renderField({ input, label, placeholder, type, meta: { touched, error }, disabled }) {
        return (
            <Form.Field className={classnames({error:touched && error})}>
                <Label>{label} </Label>
                <Input {...input} placeholder={placeholder} type={type} disabled={disabled} />
                {touched && error && <span className="errorMsg">{error.message}</span>}
            </Form.Field>
        )
    }

    render(){
        const { handleSubmit } = this.props
        return(
            <form onSubmit={handleSubmit}>
                <Grid className="profile-container">
                    <Grid.Row>
                        <Grid.Column width={8} textAlign='left'>
                            <Field name="first_name" component={this.renderField} type="text" label="First Name" placeholder="Enter First Name" />
                            <Field name="phone_number" component={this.renderField} type="number" label="Phone Number" placeholder="Enter Phone Number" />
                            <Field name="address" component={this.renderField} type="text" label="Address" placeholder="Enter Address" />
                            <Button size="large" primary> Submit </Button>
                        </Grid.Column>
                        <Grid.Column width={8} textAlign='left'>
                            <Field name="last_name" component={this.renderField} type="text" label="Last Name" placeholder="Enter Last Name" />
                            <Field name="email" component={this.renderField} disabled={true} type="email" label="Email" placeholder="Enter Email" />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </form>
        )
    }
}

const mapStateToProps = (state) => ({
    initialValues: state.userReducer.profileUserData
})

export default connect(mapStateToProps)(reduxForm({form: 'simple', validate, enableReinitialize: true })(ProfileForm))