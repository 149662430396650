import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Header from '../Header/Header'
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import FormContainer from './FormContainer'
import { authHeader, history } from '../../_helpers'
import { db } from '../firebase'
import { currentCategory, addCategoryList, viewSingleCategoryData, updateCategoryList } from '../../actions/CategoryActions'

const AddCategory = (props) => {
    const { pathname } = props.location
    const { viewSingleList, currentCategory, addCategoryList, viewSingleCategoryData, updateCategoryList } = props

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
            }
            else{
                history.push('/login') 
            }
        }
        if(props.match && props.match.params && props.match.params.id){
            const newCategory = props.match.params.id
            viewSingleCategoryData(newCategory)
        }
        else{
            currentCategory()
        }
    },[pathname, props.match, viewSingleCategoryData, currentCategory])

    const handleSubmit = (data) => {
        let uid = localStorage.getItem('uid')
        if(viewSingleList.id === undefined || viewSingleList.id === ""){
            data['createdBy'] = db.doc('users/' + uid)
            data['modifiedBy'] = db.doc('users/' + uid)
            data['createdDateTime'] = new Date()
            data['modifiedDateTime'] = new Date()
            addCategoryList(data)
        }
        else{
            data['modifiedDateTime'] = new Date()
            updateCategoryList(data)
        }
    }

    return(
        <div className="wrapper">
            <div className="container">
                <Header />
                <Link className="link-container" to="/categoryList"> <Icon name="arrow left" /> Back to Category List </Link>
                <FormContainer onSubmit={handleSubmit} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    viewSingleList: state.categoryReducer.viewSingleList
})

const mapDispatchToProps = {
    currentCategory,
    addCategoryList,
    viewSingleCategoryData,
    updateCategoryList
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCategory)