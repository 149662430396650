import { call, put, takeLatest } from 'redux-saga/effects'
import "firebase/auth"
import firebase from '../components/firebase'
import { history } from '../_helpers'
import { db } from '../components/firebase'
import _ from 'lodash'
import { getcount, getData } from './PaginationWithSearch'
import { GET_QUIZ_QUESTION_LIST_REQUEST, GET_QUIZ_QUESTION_LIST_SUCCESS, GET_QUIZ_QUESTION_LIST_ERROR, CURRENT_QUIZ_QUESTION_REQUEST, CURRENT_QUIZ_QUESTION_SUCCESS, ADD_QUIZ_QUESTION_REQUEST, ADD_QUIZ_QUESTION_SUCCESS, ADD_QUIZ_QUESTION_ERROR, VIEW_SINGLE_QUIZ_QUESTION_REQUEST, VIEW_SINGLE_QUIZ_QUESTION_SUCCESS, UPDATE_QUIZ_QUESTION_REQUEST, UPDATE_QUIZ_QUESTION_SUCCESS, UPDATE_QUIZ_QUESTION_ERROR, DELETE_QUIZ_QUESTION_REQUEST, DELETE_QUIZ_QUESTION_SUCCESS, GET_FILTERED_QUESTION_LIST_REQUEST, GET_FILTERED_QUESTION_LIST_SUCCESS, GET_FILTERED_QUESTION_LIST_ERROR } from '../constants/QuizQuestionConstants'
import { generateUniqueId } from './UniqueId'

async function getList(){
    let array = []
    let categoryList = db.collection("quiz_questions")
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

async function getTotalNodes(count, searchTerm, list, activePage, tagListForFilter){
    const totalnodes = await getcount(searchTerm, "quiz_questions", 'tag_search', tagListForFilter)
    if(totalnodes.length === undefined){
        totalnodes.docs.map(doc => {
            return count.push(doc.id)
        })
    }
    else{
        totalnodes.map(id => {
            return count.push(id)
        })
    }
    await getData(activePage, list, searchTerm, "quiz_questions", 'tag_search', tagListForFilter)
}

async function mapTagsInQuestions(tagList, questionList){
    _.forEach(questionList, function(question, qIdx){
            let arr = []
            if(question && question.question_tags && question.question_tags.length > 0){
                _.forEach(question.question_tags, function(questionTagId, qtIdx){
                    _.forEach(tagList, function(tag, tIdx){
                        if(questionTagId == tag.id){
                            arr.push({tag_name: tag.name, id:tag.id})
                        }
                    })
                    
                })
            }
            question['tags'] = arr;
    })
    return questionList
}
function* getQuizQuestionList(values){
    try{
        const { activePage, searchTerm, tagListForFilter } = values
        console.log('tagListForFilter in saaga:: ', tagListForFilter)
        let list = [], count = []
        const tagList = yield call(getTagList);
        //console.log('tagList in saga::',tagList)
        yield call(getTotalNodes, count, searchTerm, list, activePage, tagListForFilter)
        //console.log('list are:: ',list)
        let finalObj = yield call (mapTagsInQuestions, tagList, list)
        //console.log('final object:: ',finalObj)
        //console.log('final object list:: ',list)
        yield put({ type: GET_QUIZ_QUESTION_LIST_SUCCESS, list, count })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_QUIZ_QUESTION_LIST_ERROR, err })
    }
}

function* currentQuizQuestionData(){
    localStorage.removeItem('quizQuestion_id')
    yield put({ type: CURRENT_QUIZ_QUESTION_SUCCESS })
}

function addData(data){
    return firebase.firestore().collection('quiz_questions').add(data)
    .then(response => response)
}

function setvalue(data, obj, imageData){
    let array = [], newArray = []
    let answer_doc_url = localStorage.getItem('answer_doc_url')
    const { status, created_at, updated_at, created_by, modified_by, isDeleted, answer, answer_description, featured, level, question, question_category, question_type, upload_answer, question_tags, true_false_answer, required } = data
    if(question_tags !== ""){
        const newVal = setValue(question_tags)
        obj['question_tags'] = newVal
    }
    if(featured === "Yes"){
        obj['featured'] = true
    }
    else{
        obj['featured'] = false
    }
    if(upload_answer === "" || upload_answer === undefined){
        obj['upload_answer'] = false
    }else if(upload_answer !== ""){
        obj['upload_answer'] = upload_answer
    }
    if(required === "" || required === undefined){
        obj['required'] = false
    }else if(required !== ""){
        obj['required'] = required
    }
    if(question_type.value === "MCQ"){
        obj.options = []
        array.push({ 'option_value': _.trim(data.option_value1) })
        array.push({ 'option_value': _.trim(data.option_value2) })
        array.push({ 'option_value': _.trim(data.option_value3) })
        array.push({ 'option_value': _.trim(data.option_value4) })
        if(!_.isEmpty(data.option_value5)){
            array.push({ 'option_value': _.trim(data.option_value5) })
        }
        if(answer === "option_value1"){
            obj['answer'] = _.trim(data.option_value1)
        }
        else if(answer === "option_value2"){
            obj['answer'] = _.trim(data.option_value2)
        }
        else if(answer === "option_value3"){
            obj['answer'] = _.trim(data.option_value3)
        }
        else if(answer === "option_value4"){
            obj['answer'] = _.trim(data.option_value4)
        }
        else if(answer === "option_value5"){
            obj['answer'] = _.trim(data.option_value5)
        }
        array.map(item => {
            if(item.option_value === obj.answer){
                newArray.push({ 'option_value': item.option_value, 'answer': '1'})
            }
            else{
                newArray.push({ 'option_value': item.option_value, 'answer': '0'})
            }
        })
    }
    else{
        if(true_false_answer === "option_value1"){
            obj['answer'] = true
        }
        else if(true_false_answer === "option_value2"){
            obj['answer'] = false
        }
    }
    if(answer_doc_url === null){
        if(data.upload_answer){
            if(data.answer_doc_url !== null || data.answer_doc_url !== ''){
                if(! _.isEmpty(imageData) || imageData !== undefined){
                    obj.answer_doc_url['name'] = imageData.file.name
                    obj.answer_doc_url['size'] = imageData.file.size
                    obj.answer_doc_url['url'] = data.answer_doc_url
                }
                else{
                    obj.answer_doc_url = data.answer_doc_url
                }
            }
        }
    }
    else{
        obj.answer_doc_url['name'] = imageData.file.name
        obj.answer_doc_url['size'] = imageData.file.size
        obj.answer_doc_url['url'] = answer_doc_url
    }
    if(answer_description === "" || answer_description === undefined){
        obj['answer_description'] = ""
    }else if(answer_description !== ""){
        obj['answer_description'] = answer_description
    }
    obj.options = newArray
    obj['status'] = status.value
    obj['created_at'] = created_at
    obj['created_by'] = created_by
    obj['updated_at'] = updated_at
    obj['modified_by'] = modified_by
    obj['isDeleted'] = isDeleted
    obj['question'] = question
    obj.question_category['id'] = question_category.id
    obj.question_category['name'] = question_category.value
    obj['level'] = level.value
    obj['question_type'] = question_type.value
    return obj
}

function setValue(value){
    let array = []
    value.map((data) => {
        return array.push(data.id)
    })
    return array
}

async function uploadImages(data, img_name, folderName){
    try{
        let name
        name = data.name
        let value = generateUniqueId()
        let newName=""
        newName = newName.concat(value, '_', name)
        var storageRef = firebase.storage().ref()
        const fileRef = storageRef.child(`${folderName}/${newName}`)
        var uploadTask = await fileRef.put(data)
        var downloadURL = await uploadTask.ref.getDownloadURL()
        localStorage.setItem(img_name, downloadURL)
    }
    catch(error){
        throw error
    }
}

function* addQuizQuestionData(data){
    try{
        let obj = {
            question_category: {},
            answer_doc_url: {}
        }
        const { imageData } = data
        if(! _.isEmpty(imageData) || imageData !== undefined){
            const { file, name, folderName } = imageData
            yield call(uploadImages, file, name, folderName)
        }
        setvalue(data.data, obj, imageData)
        yield call(addData, obj)
        yield put({ type: ADD_QUIZ_QUESTION_SUCCESS })
        localStorage.removeItem('answer_doc_url')
        history.push('/quizquestionList')
    }
    catch(error){
        const err = error.message
        yield put({ type: ADD_QUIZ_QUESTION_ERROR, err })
    }
}

function viewData(id){
    return firebase.firestore().collection("quiz_questions").doc(id).get()
    .then(response => response)
}

function setLabel(data, obj, tagList, categoryList){
    if(data.question_category === "" || data.question_category === undefined){
        obj['question_category'] = ""
    }else if(data.question_category !== ""){
        categoryList.map(item => {
            if(item.name === data.question_category.name){
                obj.question_category['label'] = data.question_category.name
                obj.question_category['value'] = data.question_category.name
                obj.question_category['id'] = item.id
            }
        })
    }
    if(data.status === "" || data.status === undefined){
        obj['status'] = ""
    }else if(data.status !== ""){
        obj.status['label'] = data.status
        obj.status['value'] = data.status
    }
    if(data.level === "" || data.level === undefined){
        obj['level'] = ""
    }else if(data.level !== ""){
        obj.level['label'] = data.level
        obj.level['value'] = data.level
    }
    if(data.question_type === "" || data.question_type === undefined){
        obj['question_type'] = ""
    }else if(data.question_type !== ""){
        obj.question_type['label'] = data.question_type
        obj.question_type['value'] = data.question_type
    }
    if(data.featured === true){
        obj.featured = 'Yes'
    }
    else{
        obj.featured = 'No'
    }
    if(data.question_type === "MCQ"){
        data.options.map((item, index) => {
            obj[`option_value${index + 1}`] = item.option_value
            if(item.option_value === data.answer){
                obj['answer'] = `option_value${index + 1}`
            }
        })
    }
    else{
        if(data.answer === true){
            obj['true_false_answer'] = "option_value1"
        }
        else{
            obj['true_false_answer'] = "option_value2"
        } 
    }
    if(data.question_tags === "" || data.question_tags === undefined){
    }else if(data.question_tags !== ""){
        data.question_tags.map(val => {
            tagList.map(item => {
                if(item.id === val){
                    return obj.question_tags.push({'label': item.name, 'value': item.name, 'id': item.id})
                }
            })
        })
    }
}

async function getTagList(){
    let array = []
    let categoryList = db.collection("quiz_tags").where("isDeleted", "==", false).orderBy('created_at', 'desc')
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

async function getCategoryList(){
    let array = []
    let categoryList = db.collection("quiz_category")
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

function* viewSingleQuizQuestionData(data){
    const { id } = data
    let value = yield call(viewData, id)
    const singleQuizQuestion = value.data()
    let obj = {
        'question': singleQuizQuestion.question, 'question_category': {}, 'question_tags':[], 'level':{},
        'status':{}, 'question_type':{}, 'featured': '', 'answer_description': singleQuizQuestion.answer_description,
        'upload_answer': singleQuizQuestion.upload_answer, 'required': singleQuizQuestion.required, 'answer_doc_url': singleQuizQuestion.answer_doc_url,
        'created_at': singleQuizQuestion.created_at, 'created_by': singleQuizQuestion.created_by, 'updated_at': singleQuizQuestion.updated_at,
        'modified_by': singleQuizQuestion.modified_by, 'isDeleted': singleQuizQuestion.isDeleted, 'answer': singleQuizQuestion.answer
    }
    const tagList = yield call(getTagList)
    const categoryList = yield call(getCategoryList)
    setLabel(singleQuizQuestion, obj, tagList, categoryList)
    localStorage.setItem('quizQuestion_id', id)
    yield put({ type: VIEW_SINGLE_QUIZ_QUESTION_SUCCESS, obj })
}

function updateData (value){
    let id = localStorage.getItem('quizQuestion_id')
    return firebase.firestore().collection("quiz_questions").doc(id).update(value)
}

function* updateQuizQuestionData(data){
    try{
        let obj = {
            question_category: {},
            answer_doc_url: {}
        }
        const { imageData } = data
        if(! _.isEmpty(imageData) || imageData !== undefined){
            const { file, name, folderName } = imageData
            yield call(uploadImages, file, name, folderName)
        }
        setvalue(data.data, obj, imageData)
        yield call(updateData, obj)
        yield put({ type: UPDATE_QUIZ_QUESTION_SUCCESS })
        localStorage.removeItem('answer_doc_url')
        history.push('/quizquestionList')
    }
    catch(error){
        const err = error.message
        yield put({ type: UPDATE_QUIZ_QUESTION_ERROR, err })
    }
}

function getNewList(list, id){
    let obj = {}
    _.forEach(list, function(value){
        if(value.id === id){
            obj = value
        }
    })
    return obj
}

function* deletQuizQuestionData(data){
    const { id } = data
    const list = yield call(getList)
    const newList = yield call(getNewList, list, id)
    newList.isDeleted = true
    firebase.firestore().collection("quiz_questions").doc(id).update(newList)
    yield put({ type: DELETE_QUIZ_QUESTION_SUCCESS, id })
}

async function getFilterTotalNodes(count, searchTerm, list, activePage, obj){
    const totalnodes = await getcount(searchTerm, "quiz_questions", "filter", obj)
    console.log('objjj :: ',obj)
    if(totalnodes.length === undefined){
        totalnodes.docs.map(doc => {
            return count.push(doc.id)
        })
    }
    else{
        totalnodes.map(id => {
            return count.push(id)
        })
    }
    console.log('counttt before:: ',count)

    if(obj.selectTag !== "" && obj.selectTag !== undefined){
        count = _.filter(count, { question_tags : [obj.selectTag.id] })
    }
    console.log('counttt:: ',count)
   /*  _.forEach(count, function(item,index){
        _.forEach(item.question_tags, function(innerItem, idx){
            console.log('inner item:: ',innerItem)
            if(innerItem == id){
                resArr.push(item)
            }
        })
    }); */
    await getData(activePage, list, searchTerm, "quiz_questions", "filter", obj)
}

function* getFilteredQuestion(values){
    try{
        const { category, questionType, level, featured, activePage, searchTerm, selectTag } = values
        console.log('valuesss:: ',values);
        console.log('questionType:: ',questionType)
        console.log('selectTag:: ',selectTag)
        let obj = {
            'category': category,
            'type': questionType,
            'level': level,
            'featured': featured,
            'selectTag': selectTag
        }
        
        if(obj.level == undefined){
            obj['level'] = ""
        }
        if(obj.type == undefined){
            obj['type'] = ""
        }
        let list = [], count = []
        const tagList = yield call(getTagList);
        yield call(getFilterTotalNodes, count, searchTerm, list, activePage, obj)
        console.log('listt:: ',list)
        if(obj.selectTag !== "" && obj.selectTag !== undefined){
            list = _.filter(list, { question_tags : [obj.selectTag.id] })
        }
        console.log('listt tttt:: ',list)


        let finalObj = yield call (mapTagsInQuestions, tagList, list)

        yield put({ type: GET_FILTERED_QUESTION_LIST_SUCCESS, list, count })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_FILTERED_QUESTION_LIST_ERROR, err })
    }
}

export default () => {
    function * watcher() {
        yield takeLatest(GET_QUIZ_QUESTION_LIST_REQUEST, getQuizQuestionList)
        yield takeLatest(CURRENT_QUIZ_QUESTION_REQUEST, currentQuizQuestionData)
        yield takeLatest(ADD_QUIZ_QUESTION_REQUEST, addQuizQuestionData)
        yield takeLatest(VIEW_SINGLE_QUIZ_QUESTION_REQUEST, viewSingleQuizQuestionData)
        yield takeLatest(UPDATE_QUIZ_QUESTION_REQUEST, updateQuizQuestionData)
        yield takeLatest(DELETE_QUIZ_QUESTION_REQUEST, deletQuizQuestionData)
        yield takeLatest(GET_FILTERED_QUESTION_LIST_REQUEST, getFilteredQuestion)
    }
    return {watcher}
}