import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Grid, Form } from 'semantic-ui-react'
import { category } from '../docs/categoryData'
import { Field, reduxForm } from 'redux-form'
import classnames from 'classnames'
import ReduxFormSelect from '../library/select/ReduxFormSelect'
import Label from '../library/Label'
import Input from '../library/Input'
import ReactLoading from "react-loading"

const validate = (values) => {
    const errors = {errorName:{}};
    if(!values.name) {
      errors.name = {
        message: 'This field is Required'
      }
    }
    if(!values.display_name){
        errors.display_name = {
            message: 'This field is Required'
        }
    }
    return errors
}

class FormContainer extends Component {
    renderField({ input, label, type, meta: { touched, error } }) {
        return (
            <Form.Field className={classnames({error:touched && error})}>
                <Label>{label} </Label>
                <Input {...input} placeholder={label} type={type} />
                {touched && error && <span className="errorMsg">{error.message}</span>}
            </Form.Field>
        )
    }

    render(){
        const { loading, handleSubmit, initialValues, reset, submitting, pristine, disabled } = this.props
        return(
            <form onSubmit={handleSubmit}>
                {loading ? <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                <Grid className="addExam-container">
                    <Grid.Row>
                        <Grid.Column width={8} textAlign='left'>
                            <Label> Name </Label>
                            <Field name="name" disabled={disabled} isMulti={false} component={ReduxFormSelect} options={category} placeholder="Enter Category" />
                            {initialValues.id === "" ?
                                <Grid.Row className="btn-group"> 
                                    <Grid.Column width={6}>
                                        <Button size='large' primary disabled={loading || submitting}> Submit </Button>
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        <Button size='large' color="grey" type="button" disabled={pristine || submitting} className="reset-button" onClick={reset}> Reset </Button>
                                    </Grid.Column>
                                </Grid.Row>
                                :<Grid.Row className="btn-group"> 
                                    <Grid.Column width={6}>
                                        <Button size='large' primary disabled={loading}> Update </Button>
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        <Button size='large' color="grey" className="reset-button" onClick={reset}> Cancel </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            }
                        </Grid.Column>
                        <Grid.Column width={8} textAlign='left'>
                            <Field name="display_name" component={this.renderField} type="text" label="Display Name" />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </form>
        )
    }
}

const mapStateToProps = (state) => ({
    disabled: state.categoryReducer.disabled,
    loading: state.categoryReducer.loading,
    initialValues: state.categoryReducer.viewSingleList
})

export default connect(mapStateToProps)(reduxForm({form: 'simple', validate, enableReinitialize: true })(FormContainer))