const validate = (values) => {
    const errors = {errorName:{}};
    if(!values.name){
        errors.name = {
            message: 'This field is Required'
        }
    }
    if(!values.display_name) {
        errors.display_name = {
          message: 'This field is Required'
        }
    }
    if(!values.course_title) {
        errors.course_title = {
          message: 'This field is Required'
        }
    }
    if(!values.summary) {
        errors.summary = {
          message: 'This field is Required'
        }
    }
    if(!values.tags) {
        errors.tags = {
          message: 'This field is Required'
        }
    }
    if(!values.growth_title) {
        errors.growth_title = {
          message: 'This field is Required'
        }
    }
     if(!values.prospects_level1_key){
        errors.prospects_level1_key = 'This field is Required'
    }
    if(!values.prospects_level1_value){
        errors.prospects_level1_value = 'This field is Required'
    }
    /*if(!values.prospects_level2_key){
        errors.prospects_level2_key = 'This field is Required'
    }
    if(!values.prospects_level2_value){
        errors.prospects_level2_value = 'This field is Required'
    }
    if(!values.prospects_level3_key){
        errors.prospects_level3_key = 'This field is Required'
    }
    if(!values.prospects_level3_value){
        errors.prospects_level3_value = 'This field is Required'
    }
    if(!values.prospects_level4_key){
        errors.prospects_level4_key = 'This field is Required'
    }
    if(!values.prospects_level4_value){
        errors.prospects_level4_value = 'This field is Required'
    } */
    if(!values.salary_title){
        errors.salary_title = {
            message: 'This field is Required'
        }
    }
    // if(!values.salary_level1_key){
    //     errors.salary_level1_key = 'This field is Required'
    // }
    if(!values.salary_level1_text){
        errors.salary_level1_text = 'This field is Required'
    }
    if(!values.salary_level1_value){
        errors.salary_level1_value = 'This field is Required'
    }
    // if(!values.salary_level2_key){
    //     errors.salary_level2_key = 'This field is Required'
    // }
    // if(!values.salary_level2_text){
    //     errors.salary_level2_text = 'This field is Required'
    // }
    // if(!values.salary_level2_value){
    //     errors.salary_level2_value = 'This field is Required'
    // }
    // if(!values.salary_level3_key){
    //     errors.salary_level3_key = 'This field is Required'
    // }
    // if(!values.salary_level3_text){
    //     errors.salary_level3_text = 'This field is Required'
    // }
    // if(!values.salary_level3_value){
    //     errors.salary_level3_value = 'This field is Required'
    // }
    // if(!values.salary_level4_key){
    //     errors.salary_level4_key = 'This field is Required'
    // }
    // if(!values.salary_level4_text){
    //     errors.salary_level4_text = 'This field is Required'
    // }
    // if(!values.salary_level4_value){
    //     errors.salary_level4_value = 'This field is Required'
    // }
    if(!values.jobs_title_text) {
        errors.jobs_title_text = {
          message: 'This field is Required'
        }
    }
    if(!values.job_level1_key){
        errors.job_level1_key = 'This field is Required'
    }
    if(!values.market_title_text) {
        errors.market_title_text = {
          message: 'This field is Required'
        }
    }
    // if(!values.opportunity_key){
    //     errors.opportunity_key = 'This field is Required'
    // }
    if(!values.opportunity_value){
        errors.opportunity_value = 'This field is Required'
    }
    return errors 
}

export default validate