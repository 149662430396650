import { EXAM_DATA_REQUEST, VIEW_SINGLE_EXAM_REQUEST, ADD_EXAM_DATA, UPDATE_EXAM_DATA, DELETE_EXAM_DATA, CURRENT_EXAM_REQUEST, UPDATE_EXAM_STATUS_REQUEST } from '../constants/ExamConstants'

export const getExamData = (activePage, searchTerm) => {
    return{
        type: EXAM_DATA_REQUEST,
        activePage,
        searchTerm
    }
}

export const viewSingleExamData = (id) => {
    return{
        type: VIEW_SINGLE_EXAM_REQUEST,
        id
    }
}

export const addExamData = (data) => {
    return{
        type: ADD_EXAM_DATA,
        data
    }
}

export const updateExamData = (data) => {
    return{
        type: UPDATE_EXAM_DATA,
        data
    }
}

export const deleteExamData = (id) => {
    return{
        type: DELETE_EXAM_DATA,
        id
    }
}

export const currentExam = () => {
    return{
        type: CURRENT_EXAM_REQUEST
    }
}

export const updateExamStatus = (id, value) => {
    return{
        type: UPDATE_EXAM_STATUS_REQUEST,
        id, 
        value
    }
}