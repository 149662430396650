export const question_level = [
    { value: 'Easy', label: 'Easy' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Hard', label: 'Hard' }
]

export const question_type = [
    { value: 'MCQ', label: 'MCQ' },
    { value: 'True/False', label: 'True/False' },
    { value: 'Mirror Image', label: 'Mirror Image'}
]

export const access_type = [
    { value: 'Free', label: 'Free' },
    { value: 'Paid', label: 'Paid' }
]

export const allowed_user = [
    { value: 'Aspirant', label: 'Aspirant' },
    { value: 'Employee', label: 'Employee' }
]

export const minutes = [
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
    { value: 45, label: 45 },
    { value: 60, label: 60 },
    { value: 90, label: 90 },
    { value: 120, label: 120 },
    { value: 150, label: 150 },
    { value: 180, label: 180 }
]

export const featuredValue = [
    { value: String(true), label: String(true) },
    { value: String(false), label: String(false) }
]