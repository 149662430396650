export const GET_EDUCATIONAL_LIST_REQUEST = 'GET_EDUCATIONAL_LIST_REQUEST'
export const GET_EDUCATIONAL_LIST_SUCCESS = 'GET_EDUCATIONAL_LIST_SUCCESS'
export const GET_EDUCATIONAL_LIST_ERROR = 'GET_EDUCATIONAL_LIST_ERROR'

export const CURRENT_EDUCATION_REQUEST = 'CURRENT_EDUCATION_REQUEST'
export const CURRENT_EDUCATION_SUCCESS = 'CURRENT_EDUCATION_SUCCESS'

export const ADD_EDUCATION_REQUEST = 'ADD_EDUCATION_REQUEST'
export const ADD_EDUCATION_SUCCESS = 'ADD_EDUCATION_SUCCESS'
export const ADD_EDUCATION_ERROR = 'ADD_EDUCATION_ERROR'

export const DELETE_EDUCATION_DATA_REQUEST = 'DELETE_EDUCATION_DATA_REQUEST'
export const DELETE_EDUCATION_DATA_SUCCESS = 'DELETE_EDUCATION_DATA_SUCCESS'

export const VIEW_SINGLE_EDUCATION_REQUEST = 'VIEW_SINGLE_EDUCATION_REQUEST'
export const VIEW_SINGLE_EDUCATION_SUCCESS = 'VIEW_SINGLE_EDUCATION_SUCCESS'

export const UPDATE_EDUCATION_REQUEST = 'UPDATE_EDUCATION_REQUEST'
export const UPDATE_EDUCATION_SUCCESS = 'UPDATE_EDUCATION_SUCCESS'
export const UPDATE_EDUCATION_ERROR = 'UPDATE_EDUCATION_ERROR'
