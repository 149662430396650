import { GET_DISABILITY_LIST_REQUEST, CURRENT_DISABILITY_REQUEST, ADD_DISABILITY_REQUEST, DELETE_DISABILITY_DATA_REQUEST, VIEW_SINGLE_DISABILITY_REQUEST, UPDATE_DISABILITY_REQUEST } from '../constants/DisabilityConstants'

export const getDisabilityList = (activePage, searchTerm) => {
    return{
        type: GET_DISABILITY_LIST_REQUEST,
        activePage, 
        searchTerm
    }
}

export const currentDisability = () => {
    return{
        type: CURRENT_DISABILITY_REQUEST
    }
}

export const addDisability = (data) => {
    return{
        type: ADD_DISABILITY_REQUEST,
        data
    }
}

export const deleteDisabilityData = (id) => {
    return{
        type: DELETE_DISABILITY_DATA_REQUEST,
        id
    }
}

export const viewSingleDisability = (id) => {
    return{
        type: VIEW_SINGLE_DISABILITY_REQUEST,
        id
    }
}

export const updateDisability = (data) => {
    return{
        type: UPDATE_DISABILITY_REQUEST,
        data
    }
}