import React from 'react'
import AnalyticsHeader from './AnalyticsHeader'
import GraphContainer from './GraphContainer'
import PlayedUsers from './PlayedUsers'
import { Card, Divider, Header, Grid, Container, Table, Label } from 'semantic-ui-react'
import { BrowserRouter as Router } from 'react-router-dom'

const Analytics = () => {
    return (
        <Router>
            <Container >
                <AnalyticsHeader />
                <GraphContainer />
                <PlayedUsers />
            </Container>
        </Router>
    )
}
export default Analytics;