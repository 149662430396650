import { GET_TAG_LIST_REQUEST, DELETE_TAG_REQUEST, ADD_TAG_REQUEST, UPDATE_TAG_REQUEST, TAG_SEARCH_REQUEST } from '../constants/TagConstants'

export const getTagList = () => {
    return{
        type: GET_TAG_LIST_REQUEST
    }
}

export const deleteTagData = (id) => {
    return{
        type: DELETE_TAG_REQUEST,
        id
    }
}

export const addTagData = (data) => {
    return{
        type: ADD_TAG_REQUEST,
        data
    }
}

export const updateTagData = (Id, data) => {
    return{
        type: UPDATE_TAG_REQUEST,
        Id,
        data
    }
}

export const tagSearch = (searchTerm) => {
    return{
        type: TAG_SEARCH_REQUEST,
        searchTerm
    }
}