import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Header from '../Header/Header'
import { Link } from 'react-router-dom'
import { Button, Confirm, Icon, Input, Modal, Table } from 'semantic-ui-react'
import ReactLoading from "react-loading"
import { authHeader, history } from '../../_helpers'
import Select from 'react-select'
import { status } from '../docs/helperData'
import { getCourseList, deleteCourseData, viewSingleCourseData, updateCourseStatus } from '../../actions/courseActions'
import Pagination from 'react-js-pagination'
import { itemsCountPerPage, pageRangeDisplayed, pageSize } from '../../constants/HelperConstants'

const CourseContainer = (props) => {
    const { courseList, loading, error, getCourseList, deleteCourseData, viewSingleCourseData, viewSingleList, updateCourseStatus, count } = props
    let pathname = props.location.pathname
    const [isOpen, setIsOpen]= useState(false)
    const [deleteValue, setDeleteValue] = useState(true)
    const [open, setOpen] = useState(true)
    const [currentUserId, setCurrentUserId] = useState(null)
    const [Id, setId] = useState()
    const [selectedOption, setSelectedOption] = useState()
    const [activePage, setActivePage] = useState(1)
    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getCourseList(activePage, searchTerm)
            }
            else{
                history.push('/login') 
            }
        }
    }, [getCourseList, pathname, activePage, searchTerm])

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    }

    const handleChange = (event) => {
        const target =  event.target
        const { value } = target
        setSearchTerm(value)
    }

    const deleteCourse = (id) => {
        setDeleteValue(false)
        setOpen(true)
        setCurrentUserId(id)
    }

    const confirmDelete = (id) => {
        return (
            <Confirm open={open}
                    content='Are you sure want to delete this Course?'
                    onCancel={close}
                    className="delete-popup"
                    cancelButton='No, cancel it!'
                    confirmButton="Yes, delete it!"
                    onConfirm={() => renderDeleteCourse(id)} />
        )
    }

    const close = () => {
        setOpen(false)
        setDeleteValue(true)
    }

    const renderDeleteCourse = (id) => {
        deleteCourseData(id)
        setOpen(false)
    }

    const handleOpen = (id) => {
        viewSingleCourseData(id)
        setIsOpen(!isOpen)
    }

    const getModal = (id) => {
        if(viewSingleCourseData === ""){
            return null
        }
        return(
            <Modal trigger={
                    <Button icon onClick={() => handleOpen(id)} className="view-btn">
                        <Icon name="eye" />
                    </Button>}
                   size='large'
                   closeIcon>
                <Modal.Header> 
                    <span> Course Detail </span> 
                </Modal.Header>
                <Modal.Content className="modal-container">
                    <table>
                        <tbody>
                            <tr>
                                <td> Discipline: </td>
                                <td> 
                                    {viewSingleList.discipline.value} 
                                </td>
                            </tr>
                            <tr>
                                <td> Branch: </td>
                                <td> 
                                    {viewSingleList.branch} 
                                </td>
                            </tr>
                            <tr>
                                <td> Course Name: </td>
                                <td> 
                                    {viewSingleList.name} 
                                </td>
                            </tr>
                            <tr>
                                <td> Admission Procedure: </td>
                                <td>
                                    {viewSingleList.admin_procedure !== "" ? viewSingleList.admin_procedure : "--"} 
                                </td>
                            </tr>
                            <tr>
                                <td> Program Level: </td>
                                <td> 
                                    {viewSingleList.program_level.value} 
                                </td>
                            </tr>
                            <tr>
                                <td> Course Description: </td>
                                <td> 
                                    {viewSingleList.description !== "" ? viewSingleList.description : "--"} 
                                </td>
                            </tr>
                            <tr>
                                <td> Duration: </td>
                                <td> 
                                    {viewSingleList.duration.value} 
                                </td>
                            </tr>
                            <tr>
                                <td> Required Program Level: </td>
                                <td> 
                                    {viewSingleList.required_program_level.value} 
                                </td>
                            </tr>
                            <tr>
                                <td> Required Branch: </td>
                                <td> 
                                    {viewSingleList.required_course.length !== 0 ? 
                                    viewSingleList.required_course.map(value => value.value).join(',') : null} 
                                </td>
                            </tr>
                            <tr>
                                <td> Marks Obtained: </td>
                                <td> 
                                    {viewSingleList.required_marks_obtained} 
                                </td>
                            </tr>
                            <tr>
                                <td> Mode of Degree: </td>
                                <td> 
                                    {viewSingleList.mode_of_degree.value} 
                                </td>
                            </tr>
                            <tr>
                                <td> Minimum Age: </td>
                                <td> 
                                    {viewSingleList.min_age !== "" ? viewSingleList.min_age : "--"} 
                                </td>
                            </tr>
                            <tr>
                                <td> Maximum Age: </td>
                                <td> 
                                    {viewSingleList.max_age !== "" ? viewSingleList.max_age : "--"} 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Content>
            </Modal>
        )
    }

    const statusChange = (value, id) => {
        setId(id)
        setSelectedOption(value)
        courseList.map(item => {
            if(item.id === id){
                item.status = value.value
            }
            return item
        })
    }

    const updateStatusValue = (id) => {
        updateCourseStatus(id, selectedOption)
        setId('')
    }

    const getTableBody = () => {
        let serialNo = ((activePage - 1) * pageSize) + 1
        return(
            <Table.Body>
                {courseList.map((data, index) => {
                    return(
                        <Table.Row key={data.id} className="form-data">
                            <Table.Cell> {serialNo + index} </Table.Cell>
                            <Table.Cell> {data.name} </Table.Cell>
                            <Table.Cell> {data.display_name} </Table.Cell>
                            <Table.Cell>
                                {getModal(data.id)}
                                <Link to={{ pathname: `/editcourse/${data.id}`}}>
                                    <Button icon className="edit-btn">
                                        <Icon name='edit' />
                                    </Button>
                                </Link>
                                <Button icon onClick={() => deleteCourse(data.id)} className="delete-btn">
                                    <Icon name='trash' />
                                </Button>
                            </Table.Cell>
                            <Table.Cell>
                                <Link to={{ pathname: `/addcourseinformation/${data.id}`}} className="course-inf-link">
                                    Add Course Information 
                                </Link>
                            </Table.Cell>
                            <Table.Cell className="status-select">
                                <Select options={status} 
                                        onChange={(value) => statusChange(value, data.id)}
                                        value={status.filter(function(option) {
                                                return option.value === data.status
                                            })} 
                                />
                            </Table.Cell>
                            <Table.Cell className="status-update-btn">
                                {Id === data.id ? <Button primary key={data.id} onClick={() => updateStatusValue(data.id)}> Update </Button> : null}
                            </Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        )
    }

    const getTable = () => {
        return(
            <Table basic='very'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell> S.No </Table.HeaderCell>
                        <Table.HeaderCell> Course Name </Table.HeaderCell>
                        <Table.HeaderCell> Display Name </Table.HeaderCell>
                        <Table.HeaderCell> Actions </Table.HeaderCell>
                        <Table.HeaderCell> Course Information </Table.HeaderCell>
                        <Table.HeaderCell> Status </Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {getTableBody()}
                {deleteValue === false ? confirmDelete(currentUserId) : null}
            </Table>
        )
    }

    return(
        <div className="wrapper">
            <div className="container">
                <Header />
                <Link className="link-container exam-content" to="/dashboard">
                    <Icon name="arrow left" />
                    Back to Dashboard
                </Link>
                <div>
                    <Input type="text"
                           placeholder="Enter text"
                           className="search-box"
                           onChange={handleChange}
                           action={{
                            color: "blue",
                            icon: "search"
                          }}
                           value={searchTerm} />
                    <Link to="/addcourse">
                        <Button primary floated='right' className="add-btn">
                            <Icon name="plus" />
                            Add Course
                        </Button>
                    </Link>
                </div>
                {loading ? <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                {error !== "" ? <h3 className="errorMsg"> {error} </h3> : null}
                {courseList !== undefined ? courseList.length !== 0 ? getTable() : <h3> No Data </h3> :null}
                <div>
                    {error === "" && count.length > pageSize ?
                    <Pagination activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={count.length}
                                pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange} />
                    : error}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    courseList: state.courseReducer.courseList,
    loading: state.courseReducer.loading,
    error: state.courseReducer.error,
    viewSingleList: state.courseReducer.viewSingleList,
    count: state.courseReducer.count
})

const mapDispatchToProps = {
    getCourseList,
    deleteCourseData,
    viewSingleCourseData,
    updateCourseStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseContainer)