import { put, takeLatest, call } from 'redux-saga/effects'
import { EXAM_DATA_SUCCESS, EXAM_DATA_REQUEST, EXAM_DATA_ERROR, VIEW_SINGLE_EXAM_REQUEST, VIEW_SINGLE_EXAM_SUCCESS, ADD_EXAM_DATA, ADD_EXAM_DATA_SUCCESS, ADD_EXAM_ERROR, UPDATE_EXAM_DATA, UPDATE_EXAM_DATA_SUCCESS, UPDATE_EXAM_DATA_ERROR, DELETE_EXAM_DATA, DELETE_EXAM_DATA_SUCCESS, CURRENT_EXAM_REQUEST, CURRENT_EXAM_SUCCESS, UPDATE_EXAM_STATUS_REQUEST, UPDATE_EXAM_STATUS_SUCCESS } from '../constants/ExamConstants'
import firebase from '../components/firebase'
import { history } from '../_helpers'
import { db } from '../components/firebase'
import { sortBy, take } from 'lodash'
import _ from 'lodash'
import {pageSize} from '../components/exams/examConstants'

function filterBy(term){
    const escapeRegExp = (str) =>
    str.replace('[-A-Z0-9+&@#\/%?=~_|!:,.;]')

    const re = new RegExp(escapeRegExp(term), 'i')
    return person => {
        for (let prop in person) {
        if (!person.hasOwnProperty(prop)) {
            continue;
        }
        if (re.test(person[prop])) {
            return true;
        }
        }
        return false;        
    }
}

function paginate(array, searchTerm) {
    let newArray = []
     _.filter(array, function(o){
        if(_.startsWith(_.trim(_.lowerCase(o.exam_name)), _.lowerCase(searchTerm))){
            newArray.push(o)
        }
    })
    return newArray
}

async function searchItem(data, searchTerm, examData, activePage, demo){
    let array = []
    await data.get()
    .then(snapshot => {
        if (snapshot.empty) {
            return;
        }
        snapshot.forEach(doc => {
            array.push({ ...doc.data(), id: doc.id })
        })
    })
    if(array.length !== 0){
        var value = ""
        value = paginate(array, searchTerm)
        // const value = sortBy(array.filter(filterBy(searchTerm)), ['created_at'])
        if(activePage === 1){
            const item = take(value, pageSize)
            item.map(val => {
                return examData.push(val)
            })
        }
        else if(activePage > 1){
            if(demo  === true){
                const items = paginate(value, searchTerm)
                let getIndex = ((activePage) * pageSize) - pageSize
                _.forEach(items, function(item, index){
                    if(index >= getIndex && examData.length !== pageSize){
                        examData.push(item)
                    }
                })
            }
            return examData
        }
    }
}

async function getcount(searchTerm){
    let array = [], newArray = []
    if(searchTerm === ""){
        return db.collection("exams").where("isDeleted", "==", false).get()
        .then(response => response)
    }
    else{
        let examList = db.collection("exams").where("isDeleted", "==", false)
        await examList.get()
        .then(snapshot => {
            if (snapshot.empty) {
              return;
            }
            snapshot.forEach(doc => {
                newArray.push({...doc.data(), id: doc.id})
            })
        })
        if(newArray.length !== 0){
            // const value = newArray.filter(filterBy(searchTerm))
            const value = paginate(newArray, searchTerm)
            value.map(val => {
                return array.push(val.id)
            })
            return array
        }
    }
}

async function getData(activePage, examData, searchTerm){
    var lastIndex
    const field = 'created_at'
    let data = db.collection("exams").where("isDeleted", "==", false)
    if(activePage === 1){
        if(searchTerm !== ""){
            await searchItem(data, searchTerm, examData, activePage)
        }
        else{
            await data.orderBy(field).limit(pageSize).get()
            .then(snapshot => {
                if (snapshot.empty) {
                    return;
                } 
                snapshot.forEach(doc => {
                    lastIndex = doc.data().created_at
                    examData.push({ ...doc.data(), id:doc.id })
                })
            })
            .catch(err => {
                throw err
            })
        }
    } 
    else if(activePage > 1){
        const temPageSize = pageSize * (activePage - 1)
        if(searchTerm !== ""){
            await searchItem(data, searchTerm, examData, activePage)
        }
        else{
            await data.orderBy(field).limit(temPageSize).get()
            .then(snapshot => {
                if (snapshot.empty) {
                    return
                } 
                snapshot.forEach(doc => {
                    lastIndex = doc.data().created_at
                })
            })
            .catch(err => {
            })
        }
        if(searchTerm !== ""){
            await searchItem(data, searchTerm, examData, activePage, true)
        }
        else{
            await data.orderBy(field).startAfter(lastIndex).limit(pageSize).get()
            .then(snapshot => {
                if (snapshot.empty) {
                    return;
                } 
                snapshot.forEach(doc => {
                    examData.push({ ...doc.data(), id:doc.id })
                })
            })
            .catch(err => {
            })
        }
    }
        return examData
}

function* getExamData(values){
    try{
        const { activePage, searchTerm } = values
        let examData = [], count = []
        const totalnodes = yield call(getcount, searchTerm)
        if(totalnodes.length === undefined){
            totalnodes.docs.map(doc => {
                return count.push(doc.id)
            })
        }
        else{
            totalnodes.map(id => {
                return count.push(id)
            })
        }
        const array = yield call(getData, activePage, examData, searchTerm)
        yield put ({ type: EXAM_DATA_SUCCESS, array, count })
    }
    catch(error){
        const err = error.message
        yield put({ type: EXAM_DATA_ERROR, err })
    }
}

function viewData(id){
    return firebase.firestore().collection("exams").doc(id).get()
    .then(response => response)
}

function setLabel(data, obj){
    if(data.educational_qualification === "" || data.educational_qualification === undefined){
    }else if(data.educational_qualification !== ""){
        data.educational_qualification.map(val => {
            return obj.educational_qualification.push({'label': val, 'value': val})
        })
    }
    if(data.nationality === "" || data.nationality === undefined){
    }else if(data.nationality !== ""){
        data.nationality.map(val => {
            return obj.nationality.push({'label': val, 'value': val})
        })
    }
    if(data.subjects === "" || data.subjects === undefined){
    }else if(data.subjects !== ""){
        data.subjects.map(val => {
            return obj.subjects.push({'label': val, 'value': val})
        })
    }
    if(data.exam_language === "" || data.exam_language === undefined){
    }else if(data.exam_language !== ""){
        data.exam_language.map(val => {
            return obj.exam_language.push({'label': val, 'value': val})
        })
    }
    if(data.job_type === "" || data.job_type === undefined){
        obj['job_type'] = ""
    }else if(data.job_type !== ""){
        obj.job_type['label'] = data.job_type
        obj.job_type['value'] = data.job_type
    }
    if(data.level === "" || data.level === undefined){
        obj.level['label'] = ''
        obj.level['value'] = ''
    }else if(data.level !== ""){
        obj.level['label'] = data.level
        obj.level['value'] = data.level
    }
    if(data.exam_time === "" || data.exam_time === undefined){
        obj.exam_time['label'] = ''
        obj.exam_time['value'] = ''
    }else if(data.exam_time !== ""){
        obj.exam_time['label'] = data.exam_time
        obj.exam_time['value'] = data.exam_time
        return obj
    }
}

function* viewExamForm(data){
    let examData
    const { id } = data
    examData = yield call(viewData, id)
    const array = examData.data()
    let obj = {'educational_qualification': [], 'nationality': [], 'exam_language': [], 'subjects': [], 'level': {}, 'job_type': {}, 'exam_time': {},
    'job_sector': array.job_sector, 'job_department': array.job_department, 'job_position': array.job_position, 'exam_body': array.exam_body,
    'min_marks_obtained': array.min_marks_obtained, 'type_of_degree': array.type_of_degree, 'experience_required': array.experience_required, 'final_year_candidate': array.final_year_candidate,
    'other_requirement': array.other_requirement, 'marital_status': array.marital_status, 'min_body_weight': array.min_body_weight, 'max_body_weight': array.max_body_weight, 
    'eye_sight': array.eye_sight, 'handicapped': array.handicapped, 'color_blindness': array.color_blindness, 'certification_name': array.certification_name, 'certification': array.certification,
    'isDeleted': array.isDeleted, 'exam_name': array.exam_name, 'minimum_age': array.minimum_age, 'maximum_age': array.maximum_age, 'created_at': array.created_at, 'updated_at': array.updated_at}
    if(typeof(array.subjects) === 'string'){
        const newArray = array.subjects.split(' ')
        array.subjects = newArray
    }
    if(array.height === "" || array.height === undefined){
        obj['male_height'] = ''
        obj['female_height'] = ''
    }
    else{
        obj['male_height'] = array.height.male
        obj['female_height'] = array.height.female
    }
    if(array.chest_grid === "" || array.chest_grid === undefined){
        obj['male_chest'] = ''
        obj['female_chest'] = ''
    }
    else{
        obj['male_chest'] = array.chest_grid.male
        obj['female_chest'] = array.chest_grid.female
    }
    setLabel(array, obj)
    localStorage.setItem('exam_id', id)
    yield put({ type: VIEW_SINGLE_EXAM_SUCCESS, obj })
}

function addData(data){
    return firebase.firestore().collection("exams").add(data)
    .then(response => response)
}

function setvalue(data, obj){
    const { nationality, created_at, updated_at, exam_name, job_sector, job_department, job_position, exam_body, educational_qualification, min_marks_obtained, subjects, type_of_degree, other_requirement, experience_required, final_year_candidate, minimum_age, maximum_age, marital_status, male_height, female_height, male_chest, female_chest, min_body_weight, max_body_weight, eye_sight, handicapped, color_blindness, certification,certification_name, exam_language, level, job_type, exam_time, isDeleted } = data
    if(educational_qualification !== ""){
        const newVal = setValue(educational_qualification)
        obj['educational_qualification'] = newVal
    }
    if(subjects !== ""){
        const newVal = setValue(subjects)
        obj['subjects'] = newVal
    }
    if(nationality !== ""){
        const newVal = setValue(nationality)
        obj['nationality'] = newVal
    }
    if(exam_language !== ""){
        const newVal = setValue(exam_language)
        obj['exam_language'] = newVal
    }
    if(other_requirement === "" || other_requirement === undefined){
        obj['other_requirement'] = ""
    }else if(other_requirement !== ""){
        obj['other_requirement'] = other_requirement
    }
    if(male_height === "" || male_height === undefined){
        obj.height['male'] = ""
    }else if(male_height !== ""){
        obj.height['male'] = male_height
    }
    if(female_height === "" || female_height === undefined){
        obj.height['female'] = ""
    }else if(female_height !== ""){
        obj.height['female'] = female_height
    }
    if(male_chest === "" || male_chest === undefined){
        obj.chest_grid['male'] = ""
    }else if(male_chest !== ""){
        obj.chest_grid['male'] = male_chest
    }
    if(female_chest === "" || female_chest === undefined){
        obj.chest_grid['female'] = ""
    }else if(female_chest !== ""){
        obj.chest_grid['female'] = female_chest
    }
    if(min_body_weight === "" || min_body_weight === undefined){
        obj['min_body_weight'] = ""
    }else if(min_body_weight !== ""){
        obj['min_body_weight'] = min_body_weight
    }
    if(max_body_weight === "" || max_body_weight === undefined){
        obj['max_body_weight'] = ""
    }else if(max_body_weight !== ""){
        obj['max_body_weight'] = max_body_weight
    }
    if(eye_sight === "" || eye_sight === undefined){
        obj['eye_sight'] = ""
    }else if(eye_sight !== ""){
        obj['eye_sight'] = eye_sight
    }
    if(handicapped === "" || handicapped === undefined){
        obj['handicapped'] = ""
    }else if(handicapped !== ""){
        obj['handicapped'] = handicapped
    }
    if(color_blindness === "" || color_blindness === undefined){
        obj['color_blindness'] = ""
    }else if(color_blindness !== ""){
        obj['color_blindness'] = color_blindness
    }
    if(certification_name === "" || certification_name === undefined){
        obj['certification_name'] = ""
    }else if(certification_name !== ""){
        obj['certification_name'] = certification_name
    }
    if(job_department === "" || job_department === undefined){
        obj['job_department'] = ""
    }else if(job_department !== ""){
        obj['job_department'] = job_department
    }
    if(type_of_degree === "" || type_of_degree === undefined){
        obj['type_of_degree'] = ""
    }else if(type_of_degree !== ""){
        obj['type_of_degree'] = type_of_degree
    }
    if(min_marks_obtained === "" || min_marks_obtained === undefined){
        obj['min_marks_obtained'] = ""
    }else if(min_marks_obtained !== ""){
        obj['min_marks_obtained'] = min_marks_obtained
    }
    if(experience_required === "" || experience_required === undefined){
        obj['experience_required'] = ""
    }else if(experience_required !== ""){
        obj['experience_required'] = experience_required
    }
    if(final_year_candidate === "" || final_year_candidate === undefined){
        obj['final_year_candidate'] = ""
    }else if(final_year_candidate !== ""){
        obj['final_year_candidate'] = final_year_candidate
    }
    if(created_at !== "" && updated_at !== "" && level !== "" && job_type !== "" && exam_name !== "" && job_sector !== "" && job_position !== "" && exam_body !== "" && minimum_age !== "" && maximum_age !== "" && marital_status !== "" && certification !== "" && exam_time !== "" && isDeleted !== ""){
        obj['isDeleted'] = isDeleted
        obj['level'] = level.value
        obj['job_type'] = job_type.value
        obj['created_at'] = created_at
        obj['updted_at'] = updated_at
        obj['exam_name'] = exam_name
        obj['job_sector'] = job_sector
        obj['job_position'] = job_position
        obj['exam_body'] = exam_body
        obj['minimum_age'] = minimum_age
        obj['maximum_age'] = maximum_age
        obj['marital_status'] = marital_status
        obj['certification'] = certification
        obj['exam_time'] = exam_time.value
        return obj
    }
}

function setValue(value){
    let array = []
    value.map((data) => {
        return array.push(data.value)
    })
    return array
}

function* addExam(data){
    try{
        let obj = {
            'height': {},
            'chest_grid': {}
        }
        setvalue(data.data, obj)
        yield call(addData, obj)
        yield put({ type: ADD_EXAM_DATA_SUCCESS })
        history.push("/exams")
    }
    catch(error){
        const err = error.message
        yield put({ type: ADD_EXAM_ERROR, err })
    }
}

function updateData (data){
    const id = localStorage.getItem('exam_id')
    return firebase.firestore().collection("exams").doc(id).update(data)
}

function* updateExam(data){
    try{
        let obj = {
            'height': {},
            'chest_grid': {}
        }
        setvalue(data.data, obj)
        yield call(updateData, obj)
        yield put({ type: UPDATE_EXAM_DATA_SUCCESS })
        history.push("/exams")
    }
    catch(error){
        const err = error.message
        yield put({ type: UPDATE_EXAM_DATA_ERROR, err })
    }
}

async function getList(){
    let array = []
    let categoryList = db.collection("exams").where("isDeleted", "==", false)
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

function getNewList(list, id){
    let obj = {}
    _.forEach(list, function(value){
        if(value.id === id){
            obj = value
        }
    })
    return obj
}

function* deleteExam(data){
    const { id } = data
    const list = yield call(getList)
    const newList = yield call(getNewList, list, id)
    newList.isDeleted = true
    firebase.firestore().collection("exams").doc(id).update(newList)
    yield put({ type: DELETE_EXAM_DATA_SUCCESS, id })
}

function* getCurrentExam(){
    localStorage.removeItem('exam_id')
    yield put({ type: CURRENT_EXAM_SUCCESS })
}

function updateList(id, list){
    return firebase.firestore().collection("exams").doc(id).update(list)
}

function* updateStatusData(data){
    const { id, value } = data
    let lists = yield call(viewData, id)
    const list = lists.data()
    list.status = value.value
    yield call(updateList, id, list)
    yield put({ type: UPDATE_EXAM_STATUS_SUCCESS })
}

export default () => {
    function * watcher() {
      yield takeLatest(EXAM_DATA_REQUEST, getExamData)
      yield takeLatest(VIEW_SINGLE_EXAM_REQUEST, viewExamForm)
      yield takeLatest(ADD_EXAM_DATA, addExam)
      yield takeLatest(UPDATE_EXAM_DATA, updateExam)
      yield takeLatest(DELETE_EXAM_DATA, deleteExam)
      yield takeLatest(CURRENT_EXAM_REQUEST, getCurrentExam)
      yield takeLatest(UPDATE_EXAM_STATUS_REQUEST, updateStatusData)
    }
    return {watcher}
}