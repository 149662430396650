import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { saveImage } from '../../../actions/QuizQuestionActions'

const ImageContainer = (props) => {
    const { input, saveImage, folderName, meta: { touched, error } } = props
    const { name, value } = input
    const [imageUrl, setImageUrl] = useState('')
    const [imageError, setError] = useState('')

    useEffect(() => {
        setImageUrl(value)
    }, [value])

    const handleImageChange = (e) => {
        e.preventDefault()
        let reader = new FileReader()
        let file = e.target.files[0]
        reader.onloadend = () => {
            setImageUrl(reader.result)
        }
        reader.readAsDataURL(file)
        var fileSize = file.size / 1024 /1024
        if(file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "application/msword" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.type === "application/pdf"){
            if(fileSize < 1){
                let obj = {
                    'file': file,
                    'name': name,
                    'folderName': folderName
                }
                saveImage(obj)
                input.onChange(file)
                setError('')
            }
            else{
                setError('Please choose a valid image with size less than 1MB')
            }
        }
        else{
            setError('Please choose a valid image with size less than 1MB')
        }
    }

    return(
        <div>
            {imageError && <div className="errorMsg">{imageError}</div>}
            <input type="file" onChange={handleImageChange} accept="image/gif, image/jpeg, image/png, .doc, .docx, .pdf" id={name} name="imagee" />
            {/* <img src={imageUrl} className="display-image" alt="" /> */}
            {!_.isEmpty(imageUrl) ? imageUrl.name : null}
            <div>
                {touched && error && <span className="errorMsg">{error.message}</span>}
            </div>
        </div>   
    )
}

const mapDispatchToProps = {
    saveImage
}

export default connect(null, mapDispatchToProps)(ImageContainer)
    

