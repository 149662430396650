import React, { Component } from 'react'

class Button extends Component{
    render(){
        const { children, onClick } = this.props
        return(
            <div>
                <button onClick={onClick} className="btn-container"> {children} </button>
            </div>
        )
    }
}

export default Button