import React, { useEffect } from 'react'
import { Card, Divider, Header, Grid, Container, Table, Label } from 'semantic-ui-react'
import Chart from 'chart.js';



const GraphContainer = () => {

    useEffect(() => {
        const ctx = document.getElementById("bar-chart-grouped");
        new Chart(ctx, {
            
            type: "bar",
            data: {
                labels: ["mon", "tue", "wed","thu", "fri", "sat"],
                datasets: [
                  {
                    label: "July",
                    backgroundColor: "DarkCyan",
                    data: [133,221,783,2478,221,783]
                  }, {
                    label: "August",
                    backgroundColor: "DodgerBlue",
                    data: [408,547,675,734,547,675]
                  }
                ]
              },
             
        });
    });



    useEffect(() => {
        const ctx = document.getElementById("myChartt");
        new Chart(ctx, {
            type: "pie",
            data: {
                labels: ["Google", "Yahoo", "Yandex"],
                datasets: [
                    {
                        label: "# of Votes",
                        data: [25, 8, 4],
                        backgroundColor: [
                            "DarkCyan",
                            "DodgerBlue",
                            "MidnightBlue"
                        ],
                        borderColor: ["DarkCyan", "DodgerBlue", "MidnightBlue"],
                        borderWidth: 1
                    }
                ]
            }
        });
    });

    useEffect(() => {
        const ctx = document.getElementById("myCharttm");
        new Chart(ctx, {
            type: "doughnut",
            cutoutPercentage: 25,
            data: {
                labels: ["Organic", "Direct", "Paid"],
                datasets: [
                    {
                        label: "# of Votes",
                        data: [12, 19, 3],
                        backgroundColor: [
                            "DodgerBlue",
                            "MidnightBlue",
                            "DarkCyan"
                        ],
                        borderColor: ["DodgerBlue", "MidnightBlue", "DarkCyan"],
                        borderWidth: 1
                    }
                ]
            }
        });
    });





    return (
        <Container >
            <div className="wrapper">
                <div className="container">
                    <Header />
                    
                        <Card.Group >
                            <Card >
                                <Card.Content>
                                    <Card.Header><h5 class='left floated right aligned six wide column'>Total orders</h5></Card.Header>
                                    <Card.Description>
                                        <span className='date left floated right aligned six wide column'><h3>452</h3></span>
                                    </Card.Description>
                                    <Card.Description>
                                        <span class="text-success bold left floated right aligned six wide column"><i class="angle up icon" ></i>18.2%</span>
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content  >
                                <canvas id="bar-chart-grouped" width="400" height="400"></canvas>
                                </Card.Content>
                            </Card>
                            <Card >
                            <Card.Content>
                                    <Card.Header><h5 class='left floated right aligned six wide column'>Traffic by Source</h5></Card.Header>
                                    <Card.Description>
                                        <span className='date left floated right aligned six wide column'><h3>Google</h3></span>
                                    </Card.Description>
                                    <Card.Description>
                                        <span class="text-success bold left floated right aligned six wide column"><i class="angle up icon" ></i>70%</span>
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content  >
                                    <div><canvas id="myChartt" width="400" height="400" /></div>
                                </Card.Content>
                            </Card>
                            <Card >
                            <Card.Content>
                                    <Card.Header><h5 class='left floated right aligned six wide column'>Organic vs Paid Search</h5></Card.Header>
                                    <Card.Description>
                                        <span className='date left floated right aligned six wide column'><h3>Trafic Distibution</h3></span>
                                    </Card.Description>
                                    <Card.Description>
                                        <span class="text-success bold left floated right aligned six wide column"><i class="angle up icon" ></i>10.57%</span>
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content  >
                                    <div><canvas id="myCharttm" width="400" height="400" /></div>
                                </Card.Content>
                            </Card>
                        </Card.Group>
                   
                </div>
            </div>
        </Container>
    )

}

export default GraphContainer