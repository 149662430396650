import { fork } from 'redux-saga/effects'
import UserSaga from './UserSaga'
import ExamSaga from './ExamSaga'
import CategorySaga from './CategorySaga'
import DisabilitySaga from './DisabilitySaga'
import CourseSaga from './CourseSaga'
import DisciplineSaga from './DisciplineSaga'
import HelperSaga from './HelperSaga'
import EducationSaga from './EducationSaga'
import SubjectSaga from './SubjectSaga'
import TagSaga from './TagSaga'
import QuizCategorySaga from './QuizCategorySaga'
import QuizQuestionSaga from './QuizQuestionSaga'
import QuizSaga from './QuizSaga'

export default function * sagas() {
  yield fork(UserSaga().watcher)
  yield fork(ExamSaga().watcher)
  yield fork(CategorySaga().watcher)
  yield fork(DisabilitySaga().watcher)
  yield fork(CourseSaga().watcher)
  yield fork(DisciplineSaga().watcher)
  yield fork(HelperSaga().watcher)
  yield fork(EducationSaga().watcher)
  yield fork(SubjectSaga().watcher)
  yield fork(TagSaga().watcher)
  yield fork(QuizCategorySaga().watcher)
  yield fork(QuizQuestionSaga().watcher)
  yield fork(QuizSaga().watcher)
}