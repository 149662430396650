import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Form, Grid } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form'
import Label from '../library/Label'
import Input from '../library/Input'
import classnames from 'classnames'
import ReduxFormSelect from '../library/select/ReduxFormSelect'
import { role } from '../docs/userData'
import validate from './validate'
import { history } from '../../_helpers'
import { saveRole, saveCompany } from '../../actions/UserActions'

class UserForm extends Component{
    constructor(){
        super()
        this.state = {}
        this.changeRole = this.changeRole.bind(this)
        this.resetForm = this.resetForm.bind(this)
    }

    renderField({ input, label, placeholder, type, meta: { touched, error }, disabled, className }) {
        return (
            <Form.Field className={classnames({error:touched && error})}>
                <Label className={className}>{label} </Label>
                <Input {...input} placeholder={placeholder} type={type} disabled={disabled} />
                {touched && error && <span className="errorMsg">{error.message}</span>}
            </Form.Field>
        )
    }

    cancelForm(){
        history.push('/users')
    }

    changeRole(name){
        this.props.saveRole(name.value)
        if(name.value === "Employee"){
            this.props.saveCompany("Webuters")
        }
    }

    resetForm(){
        this.props.reset()
        this.props.saveRole()
    }

    render(){
        const { loading, handleSubmit, reset, submitting, pristine, disabled, id, roleValue } = this.props
        return(
            <form onSubmit={handleSubmit}>
                <Grid className="addExam-container">
                    <Grid.Row>
                        <Grid.Column width={8} textAlign='left'>
                            {/* {roleValue === "Employee" ? 
                            <Field name="company_name" component={this.renderField} type="text" label="Company Name" placeholder="Enter Company Name" />
                            : null} */}
                            <Field name="first_name" component={this.renderField} type="text" label="First Name" placeholder="Enter First Name" className="required_field" />
                            <Field name="email" component={this.renderField} type="email" disabled={disabled} label="Email" placeholder="Enter Email" className="required_field" />
                            {id === "" || id === null || id === undefined ?
                                <Grid.Row className="btn-group"> 
                                    <Grid.Column width={6}>
                                        <Button size='large' primary disabled={loading || submitting}> Submit </Button>
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        <Button size='large' color="grey" type="button" disabled={pristine || submitting} onClick={this.resetForm} className="reset-button"> Reset </Button>
                                    </Grid.Column>
                                </Grid.Row>
                                :<Grid.Row className="btn-group"> 
                                    <Grid.Column width={6}>
                                        <Button size='large' primary disabled={loading}> Update </Button>
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        <Button size='large' color="grey" onClick={this.cancelForm} className="reset-button"> Cancel </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            }
                        </Grid.Column>
                        <Grid.Column width={8} textAlign='left'>
                            <Field name="last_name" component={this.renderField} type="text" label="Last Name" placeholder="Enter Last Name" className="required_field" />
                            <Label className="required_field"> Role </Label>
                            <Field name="role" component={ReduxFormSelect} options={role} placeholder="Enter Role" onChange={this.changeRole} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </form>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.userReducer.loading,
    disabled: state.userReducer.disabled,
    initialValues: state.userReducer.viewUser,
    roleValue: state.userReducer.roleValue
})

const mapDispatchToProps = {
    saveRole,
    saveCompany
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: 'simple', validate, enableReinitialize: true })(UserForm))