import { GET_QUIZ_CATEGORY_LIST_REQUEST, CURRENT_QUIZ_CATEGORY_REQUEST, ADD_QUIZ_CATEGORY_REQUEST, DELETE_QUIZ_CATEGORY_REQUEST, VIEW_SINGLE_QUIZ_CATEGORY_REQUEST, UPDATE_QUIZ_CATEGORY_REQUEST, GET_ALL_CATEGORY_NAME_REQUEST } from '../constants/QuizCategoryConstants'

export const getQuizCategoryList = (activePage, searchTerm) => {
    return{
        type: GET_QUIZ_CATEGORY_LIST_REQUEST,
        activePage, 
        searchTerm
    }
}

export const currentQuizCategory = () => {
    return{
        type: CURRENT_QUIZ_CATEGORY_REQUEST
    }
}

export const addQuizCategory = (data) => {
    return{
        type: ADD_QUIZ_CATEGORY_REQUEST,
        data
    }
}

export const deleteQuizCategory = (id) => {
    return{
        type: DELETE_QUIZ_CATEGORY_REQUEST,
        id
    }
}

export const viewSingleQuizCategory = (id) => {
    return{
        type: VIEW_SINGLE_QUIZ_CATEGORY_REQUEST,
        id
    }
}

export const updateQuizCategory = (data) => {
    return{
        type: UPDATE_QUIZ_CATEGORY_REQUEST,
        data
    }
}

export const getAllCategoryName = () => {
    return{
        type: GET_ALL_CATEGORY_NAME_REQUEST
    }
}