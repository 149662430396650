import { GET_EDUCATIONAL_DATA_REQUEST, GET_EDUCATIONAL_DATA_SUCCESS, GET_EDUCATIONAL_DATA_ERROR, GET_SUBJECT_REQUEST, GET_SUBJECT_SUCCESS,GET_SUBJECT_ERROR, IMAGE_ERROR, REMOVE_CROP_IMAGE } from '../constants/HelperConstants'

const initialState = {
    loading: false,
    error: '',
    educationalList: [
        { value: '', label: ''},
        { value: '', label: '' },
        { value: 'All', label: 'All'},
        { value: '', label: ''},
        { value: '', label: ''},
        { value: '', label: ''},
        { value: '', label: ''},
        { value: '', label: ''}
    ],
    subjectList: [],
    uploadError: false,
    removeCourseImage: false,
    removeJobImage: false,
    removeCourseSummaryImage: false,
    removeEntryImage: false,
    removeApplyImage: false,
    removeEntranceImage: false,
    removeCareerImage: false
}

const Helper = (state = initialState, action) => {
    switch(action.type){
        case GET_EDUCATIONAL_DATA_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_EDUCATIONAL_DATA_SUCCESS:
            return{
                ...state,
                loading: false,
                educationalList: action.educationallist
            }
        case GET_EDUCATIONAL_DATA_ERROR:
            return{
                ...state,
                loading: false,
                error: action.err
            }
        case GET_SUBJECT_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_SUBJECT_SUCCESS:
            return{
                ...state,
                loading: false,
                subjectList: action.array
            }
        case GET_SUBJECT_ERROR:
            return{
                ...state,
                loading: false,
                error: action.err
            }
        case IMAGE_ERROR:
            return{
                ...state,
                uploadError: action.value
            }
        case REMOVE_CROP_IMAGE:
            let course_image, job_image, course_summary_image, entry_image, apply_image, entrance_image, career_image
            if(action.name === "course_img_url"){
                course_image = action.value
            }
            else if(action.name === "job_img_url"){
                job_image = action.value
            }
            else if(action.name === "course_summary_img_url"){
                course_summary_image = action.value
            }
            else if(action.name === "entry_summary_img_url"){
                entry_image = action.value
            }
            else if(action.name === "apply_img_url"){
                apply_image = action.value
            }
            else if(action.name === "entrance_img_url"){
                entrance_image = action.value
            }
            else if(action.name === "career_img_url"){
                career_image = action.value
            }
            return{
                ...state,
                removeCourseImage: course_image,
                removeJobImage: job_image,
                removeCourseSummaryImage: course_summary_image,
                removeEntryImage: entry_image,
                removeApplyImage: apply_image,
                removeEntranceImage: entrance_image,
                removeCareerImage: career_image
            }
        default:
            return state
    }
}

export default Helper