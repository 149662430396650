import "firebase/auth"
import firebase from '../components/firebase'

export const deleteImageFromStorage = async (file_url) => {
    try{
        var storageRef = firebase.storage().ref()
        let fileUrl = `${file_url}`;
        storageRef.child(fileUrl).delete()
        return true
    }
    catch(error){
        throw error
    }
}



