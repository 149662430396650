import { GET_TAG_LIST_REQUEST, GET_TAG_LIST_SUCCESS, GET_TAG_LIST_ERROR, ADD_TAG_REQUEST, ADD_TAG_SUCCESS, ADD_TAG_ERROR, DELETE_TAG_SUCCESS, UPDATE_TAG_REQUEST, UPDATE_TAG_SUCCESS, UPDATE_TAG_ERROR, TAG_SEARCH_REQUEST, TAG_SEARCH_SUCCESS, TAG_SEARCH_ERROR } from '../constants/TagConstants'
import { tagSearch } from '../actions/TagActions'
import _ from 'lodash'

const initialState = {
    loading: false,
    tagList: [],
    tagListForFilter: [],
    error: '',
    allTagList: []
}

const tag = (state = initialState, action) => {
    switch(action.type){
        case GET_TAG_LIST_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_TAG_LIST_SUCCESS:
            let tag_list = []
            let filteredValues = action.list.map(tags => {
                //return any product whose name or designer contains the input box string
                //console.log('tagsss:: ',tags)
                tag_list.push({value:tags.name, label: tags.name, id: tags.id})
                // return product.name.toLowerCase().includes(value) ||
                //     product.designer.toLowerCase().includes(value);
                return tag_list;
            });
            console.log('filteredValues:: ',filteredValues)
            return{
                ...state,
                loading: false,
                tagList: action.list,
                allTagList: action.list,
                tagListForFilter: filteredValues[0]
            }
        case GET_TAG_LIST_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case ADD_TAG_REQUEST:
            return{
                ...state,
                loading: true
            }
        case ADD_TAG_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case ADD_TAG_ERROR:
            return{
                ...state,
                loading: false,
                error: action.err
            }
        case DELETE_TAG_SUCCESS:
            return{
                ...state,
                tagList: state.tagList.filter(tag => tag.id !== action.id)
            }
        case UPDATE_TAG_REQUEST:
            return{
                ...state,
                loading: true
            }
        case UPDATE_TAG_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case UPDATE_TAG_ERROR:
            return{
                ...state,
                loading: false,
                error: action.err
            }
        case TAG_SEARCH_REQUEST:
            let finalArray = []
            let tagSearch = _.filter(state.allTagList, function(o){
                if(_.startsWith(_.trim(_.lowerCase(o.name)), _.lowerCase(action.searchTerm))){
                    finalArray.push(o)
                }
            })
            return{
                ...state,
                loading: false,
                tagList :finalArray
            }
        default:
            return state
    }
}

export default tag