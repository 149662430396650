import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getExamData, viewSingleExamData, deleteExamData, updateExamStatus } from '../../actions/ExamActions'
import {  Button, Confirm, Icon, Input, Modal, Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Header from '../Header/Header'
import './exam.scss'
import { authHeader, history } from '../../_helpers'
import ReactLoading from "react-loading"
import Pagination from 'react-js-pagination'
import { itemsCountPerPage, pageRangeDisplayed, pageSize } from './examConstants'
import _ from 'lodash'
import Select from 'react-select'
import { status } from '../docs/helperData'

const ExamContainer = (props) => {
    let pathname = props.location.pathname
    const { getExamData, examData, viewSingleData, viewSingleExamData, deleteExamData, getPageRequest, loading, error, getExamError, isLoading, count, updateExamStatus } = props
    const [isOpen, changeValue]= useState(false)
    const [deleteValue, setDeleteValue] = useState(true)
    const [open, setOpen] = useState(true)
    const [currentUserId, setCurrentUserId] = useState(null)
    const [searchTerm, setSearchTerm] = useState("")
    const [activePage, setActivePage] = useState(1)
    const [Id, setId] = useState()
    const [selectedOption, setSelectedOption] = useState()

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getExamData(activePage, searchTerm)
            }
            else{
                history.push('/login') 
            }
        }
    }, [getExamData, pathname, getPageRequest, activePage, searchTerm])

    const handleOpen = (id) => {
        viewSingleExamData(id)
        changeValue(!isOpen)
    }

    const handleChange = (event) => {
        const target =  event.target
        const { value } = target
        setSearchTerm(value)
    }

    const getModal = (id) => {
        if(viewSingleData === ""){
            return null
        }
        viewSingleData.marital_status = _.toString(viewSingleData.marital_status)
        return(
            <Modal trigger={
                    <Button icon onClick={() => handleOpen(id)} className="view-btn">
                        <Icon name="eye" />
                    </Button>}
                   size='large'
                   closeIcon>
                <Modal.Header> <span>Exam Detail</span> </Modal.Header>
                <Modal.Content className="modal-container">
                    <table>
                        <tbody>
                            <tr>
                                <td> Exam Name: </td>
                                <td>
                                    {viewSingleData.exam_name}
                                </td>
                            </tr>
                            <tr>
                                <td> Job Sector: </td>
                                <td>
                                    {viewSingleData.job_sector === "" || viewSingleData.job_sector === undefined ? '--' : viewSingleData.job_sector}
                                </td>
                            </tr>
                            <tr>
                                <td> Job Department: </td>
                                <td>
                                    {viewSingleData.job_department === "" || viewSingleData.job_department === undefined ? '--' : viewSingleData.job_department}
                                </td>
                            </tr>
                            <tr>
                                <td> Job Position: </td>
                                <td>
                                    {viewSingleData.job_position === "" || viewSingleData.job_position === undefined ? '--' : viewSingleData.job_position}
                                </td>
                            </tr>
                            <tr>
                                <td> Exam Body: </td>
                                <td>
                                    {viewSingleData.exam_body === "" || viewSingleData.exam_body === undefined ? '--' : viewSingleData.exam_body}
                                </td>
                            </tr>
                            <tr>
                                <td> Minimum Qualification: </td>
                                <td>
                                    {viewSingleData.educational_qualification.length !== 0 ? viewSingleData.educational_qualification.map(value => value.value).join(',') : '--'}
                                </td>
                            </tr>
                            <tr>
                                <td> Minimum Marks: </td>
                                <td>
                                    {viewSingleData.min_marks_obtained === "" || viewSingleData.min_marks_obtained === undefined ? '--' : viewSingleData.min_marks_obtained}
                                </td>
                            </tr>
                            <tr>
                                <td> Subjects: </td>
                                <td>
                                    {viewSingleData.subjects !== '' ? viewSingleData.subjects.map(value => value.value).join(',') : null}
                                </td>
                            </tr>
                            <tr>
                                <td> Type of Degree: </td>
                                <td>
                                    {viewSingleData.type_of_degree === "" || viewSingleData.type_of_degree === undefined ? '--' : viewSingleData.type_of_degree}
                                </td>
                            </tr>
                            <tr>
                                <td> Other Requirements: </td>
                                <td>
                                    {viewSingleData.other_requirement === "" || viewSingleData.other_requirement === undefined ? "--" : viewSingleData.other_requirement}
                                </td>
                            </tr>
                            <tr>
                                <td> Experience: </td>
                                <td>
                                    {viewSingleData.experience_required === "" || viewSingleData.experience_required === undefined ? '--' : viewSingleData.experience_required}
                                </td>
                            </tr>
                            <tr>
                                <td> Final Year Candidate: </td>
                                <td>
                                    {viewSingleData.final_year_candidate ? 'Yes' : 'No'}
                                </td>
                            </tr>
                            <tr>
                                <td> Nationality: </td>
                                <td>
                                    {viewSingleData.nationality.length !== 0 ? viewSingleData.nationality.map(value => value.value).join(',') : '--'}
                                </td>
                            </tr>
                            <tr>
                                <td> Minimum Age: </td>
                                <td>
                                    {viewSingleData.minimum_age}
                                </td>
                            </tr>
                            <tr>
                                <td> Maximum Age: </td>
                                <td>
                                    {viewSingleData.maximum_age}
                                </td>
                            </tr>
                            <tr>
                                <td> Marital Status: </td>
                                <td>
                                    {viewSingleData.marital_status === '' ? '--' : viewSingleData.marital_status}
                                </td>
                            </tr>
                            <tr>
                                <td> Height of Male: </td>
                                <td>
                                    {viewSingleData.male_height === "" || viewSingleData.male_height === undefined ? "--" : viewSingleData.male_height}
                                </td>
                            </tr>
                            <tr>
                                <td> Height of Female: </td>
                                <td>
                                    {viewSingleData.female_height === "" || viewSingleData.female_height === undefined ? "--" : viewSingleData.female_height}
                                </td>
                            </tr>
                            <tr>
                                <td> Male Chest: </td>
                                <td>
                                    {viewSingleData.male_chest === "" || viewSingleData.male_chest === undefined ? "--" : viewSingleData.male_chest}
                                </td>
                            </tr>
                            <tr>
                                <td> Female Chest: </td>
                                <td>
                                    {viewSingleData.female_chest === "" || viewSingleData.female_chest === undefined ? "--" : viewSingleData.female_chest}
                                </td>
                            </tr>
                            <tr>
                                <td> Minimum Body Weight: </td>
                                <td>
                                    {viewSingleData.min_body_weight === "" || viewSingleData.min_body_weight === undefined ? "--" : viewSingleData.min_body_weight}
                                </td>
                            </tr>
                            <tr>
                                <td> Maximum Body Weight: </td>
                                <td>
                                    {viewSingleData.max_body_weight === "" || viewSingleData.max_body_weight === undefined ? "--" : viewSingleData.max_body_weight}
                                </td>
                            </tr>
                            <tr>
                                <td> Eye Sight: </td>
                                <td>
                                    {viewSingleData.eye_sight === "" || viewSingleData.eye_sight === undefined ? "--" : viewSingleData.eye_sight}
                                </td>
                            </tr>
                            <tr>
                                <td> Handicapped Allowed: </td>
                                <td>
                                    {viewSingleData.handicapped === "" || viewSingleData.handicapped === undefined ? "--" : viewSingleData.handicapped ? 'Yes': 'No'}
                                </td>
                            </tr>
                            <tr>
                                <td> Color Blindness Allowed: </td>
                                <td>
                                    {viewSingleData.color_blindness === "" || viewSingleData.color_blindness === undefined ? "--" : viewSingleData.color_blindness ? 'Yes': 'No'}
                                </td>
                            </tr>
                            <tr>
                                <td> Certification: </td>
                                <td>
                                    {viewSingleData.certification === "" || viewSingleData.certification === undefined ? '--' : viewSingleData.certification}
                                </td>
                            </tr>
                            <tr>
                                <td> Name of Certification: </td>
                                <td>
                                    {viewSingleData.certification_name === "" || viewSingleData.certification_name === undefined ? "--" : viewSingleData.certification_name}
                                </td>
                            </tr>
                            <tr>
                                <td> Exam Language: </td>
                                <td>
                                    {viewSingleData.exam_language !== '' ? viewSingleData.exam_language.map(value => value.value).join(',') : null}
                                </td>
                            </tr>
                            <tr>
                                <td> Level: </td>
                                <td>
                                    {viewSingleData.level !== '' ? viewSingleData.level.value : null}
                                </td>
                            </tr>
                            <tr>
                                <td> Job Type: </td>
                                <td>
                                    {viewSingleData.job_type !== '' ? viewSingleData.job_type.value : '--'}
                                </td>
                            </tr>
                            <tr>
                                <td> Exam Frequency: </td>
                                <td>
                                    {viewSingleData.exam_time === '' || viewSingleData.exam_time === undefined ? '--' : viewSingleData.exam_time.value}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Content>
            </Modal>
        )
    }

    const deleteExam = (id) => {
        setDeleteValue(false)
        setOpen(true)
        setCurrentUserId(id)
    }

    const confirmDelete = (id) => {
        return (
            <Confirm open={open}
                    content='Are you sure want to delete this Exam?'
                    onCancel={close}
                    className="delete-popup"
                    cancelButton='No, cancel it!'
                    confirmButton="Yes, delete it!"
                    onConfirm={() => renderDeleteUser(id)} />
        )
    }

    const close = () => {
        setOpen(false)
        setDeleteValue(true)
    }

    const renderDeleteUser = (id) => {
        deleteExamData(id)
        setOpen(false)
    }

    const statusChange = (value, id) => {
        setId(id)
        setSelectedOption(value)
        examData.map(item => {
            if(item.id === id){
                item.status = value.value
            }
            return item
        })
    }

    const updateStatusValue = (id) => {
        updateExamStatus(id, selectedOption)
        setId('')
    }

    const getTableBody = () => {
        let serialNo = ((activePage - 1) * pageSize) + 1
        return(
            examData.map((data, index) => {
                return(
                    <Table.Row key={index} className="form-data">
                        <Table.Cell>{serialNo + index}</Table.Cell>
                        <Table.Cell> {data.exam_name} </Table.Cell>
                        <Table.Cell> 
                            {data.educational_qualification === undefined || data.educational_qualification === "" ? '--' : data.educational_qualification.join(',')}
                        </Table.Cell>
                        <Table.Cell> {data.level} </Table.Cell>
                        <Table.Cell> {data.exam_language.join(',')} </Table.Cell>
                        <Table.Cell>
                            {getModal(data.id)}
                            <Link to={{ 
                                pathname: `/editexam/${data.id}`
                            }}>
                                <Button icon className="edit-btn">
                                    <Icon name='edit' />
                                </Button>
                            </Link>
                            <Button icon onClick={() => deleteExam(data.id)} className="delete-btn">
                                <Icon name='trash' />
                            </Button>
                        </Table.Cell>
                        <Table.Cell className="status-select">
                            <Select options={status} 
                                    onChange={(value) =>  statusChange(value, data.id)}
                                    value={status.filter(function(option) {
                                            return option.value === data.status
                                        })} 
                            />
                        </Table.Cell>
                        <Table.Cell className="status-update-btn">
                            {Id === data.id ? <Button primary key={data.id} onClick={() => updateStatusValue(data.id)}> Update </Button> : null}
                        </Table.Cell>
                    </Table.Row>
                )
            })
        )
    }

    const getTable = () => {
        return(
            <Table basic='very' className="exam-table">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell> S.No </Table.HeaderCell>
                        <Table.HeaderCell> Exam Name </Table.HeaderCell>
                        <Table.HeaderCell> Education </Table.HeaderCell>
                        <Table.HeaderCell> Level </Table.HeaderCell>
                        <Table.HeaderCell> Language </Table.HeaderCell>
                        <Table.HeaderCell> Actions </Table.HeaderCell>
                        <Table.HeaderCell> Status </Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {getTableBody()}
                    {deleteValue === false ? confirmDelete(currentUserId) : null}
                </Table.Body>
            </Table>
        )
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    }

    return(
        <div className="wrapper">
            <div className="container">
                {loading && searchTerm === "" ?  <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                {isLoading ? <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                <Header />
                <div>
                <Link className="link-container exam-content" to="/dashboard">
                    <Icon name="arrow left" />
                    Back to Dashboard
                </Link>
                </div>
                <div className="cs-search">
                    <Input type="text"
                           placeholder="Enter text"
                           className="search-box"
                           onChange={handleChange}
                           action={{
                            color: "blue",
                            icon: "search"
                          }}
                           value={searchTerm} />
                    <Link to="/addexam">
                        <Button primary className="exam-btn" floated='right'>
                            <Icon name="add" />
                            Add Exam 
                        </Button>
                    </Link>
                </div>
                {error !== "" ? <h3 className="errorMsg"> {error} </h3> : null}
                {examData !== undefined ? examData.length !== 0 ? getTable() : <h3> No Data </h3> :null}
                <div>
                    {getExamError === "" && count.length > pageSize ? 
                    <Pagination activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={count.length}
                                pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange} />
                    : getExamError}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    examData: state.examReducer.examData,
    viewSingleData: state.examReducer.viewSingleData,
    loading: state.examReducer.loading,
    error: state.examReducer.error,
    getExamError: state.examReducer.getExamError,
    isLoading: state.examReducer.isLoading,
    count: state.examReducer.count
})

const mapDispatchToProps = {
    getExamData,
    viewSingleExamData,
    deleteExamData,
    updateExamStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(ExamContainer)