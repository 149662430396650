import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Header from '../Header/Header'
import { Link } from 'react-router-dom'
import { Button, Confirm, Icon, Input, Table } from 'semantic-ui-react'
import { authHeader, history } from '../../_helpers'
import ReactLoading from "react-loading"
import Pagination from 'react-js-pagination'
import { itemsCountPerPage, pageRangeDisplayed, pageSize } from '../../constants/HelperConstants'
import { getDisabilityList, deleteDisabilityData } from '../../actions/DisabilityActions'

const DisabilityList = (props) => {
    let pathname = props.location.pathname
    const { getDisabilityList, disabilityList, deleteDisabilityData, loading, error, count } = props
    const [deleteValue, setDeleteValue] = useState(true)
    const [open, setOpen] = useState(true)
    const [currentUserId, setCurrentUserId] = useState(null)
    const [activePage, setActivePage] = useState(1)
    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getDisabilityList(activePage, searchTerm)
            }
            else{
                history.push('/login') 
            }
        }
    }, [getDisabilityList, pathname, activePage, searchTerm])

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    }

    const handleChange = (event) => {
        const target =  event.target
        const { value } = target
        setSearchTerm(value)
    }

    const deleteDisability = (id) => {
        setDeleteValue(false)
        setOpen(true)
        setCurrentUserId(id)
    }

    const confirmDelete = (id) => {
        return (
            <Confirm open={open}
                    content='Are you sure want to delete this Disability?'
                    onCancel={close}
                    className="delete-popup"
                    cancelButton='No, cancel it!'
                    confirmButton="Yes, delete it!"
                    onConfirm={() => renderDeleteDisability(id)} />
        )
    }

    const close = () => {
        setOpen(false)
        setDeleteValue(true)
    }

    const renderDeleteDisability = (id) => {
        deleteDisabilityData(id)
        setOpen(false)
    }

    const getTableBody = () => {
        let serialNo = ((activePage - 1) * pageSize) + 1
        return(
            disabilityList.map((data, index) => {
                return(
                    <Table.Row key={data.id} className="form-data">
                        <Table.Cell> {serialNo + index} </Table.Cell>
                        <Table.Cell> {data.name} </Table.Cell>
                        <Table.Cell> {data.display_name} </Table.Cell>
                        <Table.Cell>
                            <Link to={{ pathname: `/editdisability/${data.id}`}}>
                                <Button icon className="edit-btn">
                                    <Icon name='edit' />
                                </Button>
                            </Link>
                            <Button icon onClick={() => deleteDisability(data.id)} className="delete-btn">
                                <Icon name='trash' />
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                )
            })
        )
    }

    const getTable = () => {
        return(
            <Table basic='very'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell> S.No </Table.HeaderCell>
                        <Table.HeaderCell> Disability name </Table.HeaderCell>
                        <Table.HeaderCell> Display Name </Table.HeaderCell>
                        <Table.HeaderCell> Actions </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {getTableBody()}
                    {deleteValue === false ? confirmDelete(currentUserId) : null}
                </Table.Body>
            </Table>
        )
    }

    return(
        <div className="wrapper">
            <div className="container">
                <Header />
                <Link className="link-container exam-content" to="/dashboard">
                    <Icon name="arrow left" />
                    Back to Dashboard
                </Link>
                <div>
                    <Input type="text"
                           placeholder="Enter text"
                           className="search-box"
                           onChange={handleChange}
                           action={{
                            color: "blue",
                            icon: "search"
                          }}
                           value={searchTerm} />
                    <Link to="/adddisability">
                        <Button primary floated='right' className="add-btn">
                            <Icon name="plus" />
                            Add Disability Type
                        </Button>
                    </Link>
                </div>
                {loading ? <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                {error !== "" ? <h3 className="errorMsg"> {error} </h3> : null}
                {disabilityList !== undefined ? disabilityList.length !== 0 ? getTable() : <h3> No Data </h3> :null}
                <div>
                    {error === "" && count.length > pageSize ?
                    <Pagination activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={count.length}
                                pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange} />
                    : error}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    disabilityList: state.disabilityReducer.disabilityList,
    loading: state.disabilityReducer.loading,
    error: state.disabilityReducer.error,
    count: state.disabilityReducer.count
})

const mapDispatchToProps = {
    getDisabilityList,
    deleteDisabilityData
}

export default connect(mapStateToProps, mapDispatchToProps)(DisabilityList)