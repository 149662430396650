import React ,{ useEffect }from 'react'
import classnames from 'classnames'
import { Checkbox } from 'semantic-ui-react'

const RadioContainer = (props) => {
    const { meta: { touched, error }, option1, option2, option3, show3Options, radio, input, callback, disabled } = props
    const { value, name } = input

    useEffect(() => {
        if(callback !== undefined){
            callback(name, value)
        }
    })
    
    const handleChange = (event, {value}) => {
        input.onChange(value)
        if(callback !== undefined){
            callback(name, value)
        }
    }

    return(
        <div className="checkbox-container">
            <Checkbox label={option1}
                      radio={radio}
                      name='radioGroup'
                      disabled={disabled}
                      value={option1}
                      checked={value === option1}
                      className={classnames({error:touched && error})}
                      onChange={handleChange} />
            <Checkbox label={option2}
                      radio={radio}
                      name='radioGroup'
                      value={option2}
                      disabled={disabled}
                      className={classnames({error:touched && error})}
                      checked={value === option2}
                      onChange={handleChange} />
            {show3Options ? 
            <Checkbox label={option3}
                      radio={radio}
                      name='radioGroup'
                      value={option3}
                      disabled={disabled}
                      className={classnames({error:touched && error})}
                      checked={value === option3}
                      onChange={handleChange} />
            : null}
            <div>
                {touched && error && <span className="errorMsg">{error.message}</span>}
            </div>
        </div>
    )    
}

export default RadioContainer