import { GET_EDUCATIONAL_LIST_REQUEST, CURRENT_EDUCATION_REQUEST, ADD_EDUCATION_REQUEST, DELETE_EDUCATION_DATA_REQUEST, VIEW_SINGLE_EDUCATION_REQUEST, UPDATE_EDUCATION_REQUEST } from '../constants/EducationConstants'

export const getEducationalList = (activePage, searchTerm) => {
    return{
        type: GET_EDUCATIONAL_LIST_REQUEST,
        activePage, 
        searchTerm
    }
}

export const currentEducation = () => {
    return{
        type: CURRENT_EDUCATION_REQUEST
    }
}

export const addEducation = (data) => {
    return{
        type: ADD_EDUCATION_REQUEST,
        data
    }
}

export const deleteEducationData = (id) => {
    return{
        type: DELETE_EDUCATION_DATA_REQUEST,
        id
    }
}

export const viewSingleEducation = (id) => {
    return{
        type: VIEW_SINGLE_EDUCATION_REQUEST,
        id
    }
}

export const updateEducation = (value, id) => {
    return{
        type: UPDATE_EDUCATION_REQUEST,
        value,
        id
    }
}