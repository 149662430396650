import React, { Component } from 'react'

class Input extends Component{
    render(){
        const { name, type, placeholder, onChange, value, disabled, className } = this.props
        return(
            <div className={className}>
                <input type={type} 
                       name={name}
                       className="input-container"
                       value={value}
                       disabled={disabled}
                       placeholder={placeholder}
                       onChange={onChange} />
            </div>
        )
    }
}

export default Input