import { GET_DISABILITY_LIST_REQUEST, GET_DISABILITY_LIST_SUCCESS, GET_DISABILITY_LIST_ERROR, CURRENT_DISABILITY_SUCCESS, ADD_DISABILITY_REQUEST, ADD_DISABILITY_SUCCESS, ADD_DISABILITY_ERROR, DELETE_DISABILITY_DATA_SUCCESS, VIEW_SINGLE_DISABILITY_REQUEST, VIEW_SINGLE_DISABILITY_SUCCESS, UPDATE_DISABILITY_REQUEST, UPDATE_DISABILITY_SUCCESS, UPDATE_DISABILITY_ERROR } from '../constants/DisabilityConstants'

const initialState = {
    loading: false,
    viewDisabilityList: {name:"", display_name:"", createdBy:"", created_date_time:"", modifiedBy:"", modified_date_time:""},
    disabilityList: [],
    error: '',
    disabled: false,
    count: []
}

const disability = (state = initialState, action) => {
    switch(action.type){
        case GET_DISABILITY_LIST_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_DISABILITY_LIST_SUCCESS:
            return{
                ...state,
                loading: false,
                disabilityList: action.list,
                count: action.count,
                error: ""
            }
        case GET_DISABILITY_LIST_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case CURRENT_DISABILITY_SUCCESS:
            return{
                ...state,
                disabled: false,
                viewDisabilityList: initialState.viewDisabilityList
            }
        case ADD_DISABILITY_REQUEST:
            return{
                ...state,
                loading: true
            }
        case ADD_DISABILITY_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case ADD_DISABILITY_ERROR:
            return{
                ...state,
                loading: false,
                error: action.err
            }
        case DELETE_DISABILITY_DATA_SUCCESS:
            return{
                ...state,
                disabilityList: state.disabilityList.filter(disability => disability.id !== action.id)
            }
        case VIEW_SINGLE_DISABILITY_REQUEST:
            return{
                ...state,
                loading: true
            }
        case VIEW_SINGLE_DISABILITY_SUCCESS:
            return{
                ...state,
                loading: false,
                disabled: true,
                viewDisabilityList: action.singleDisability
            }
        case UPDATE_DISABILITY_REQUEST:
            return{
                ...state,
                loading: true
            }
        case UPDATE_DISABILITY_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case UPDATE_DISABILITY_ERROR:
            return{
                loading: false,
                error: action.err
            }
        default:
            return state
    }
}

export default disability