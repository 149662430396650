import { GET_QUIZ_LIST_REQUEST, GET_QUIZ_LIST_SUCCESS, GET_QUIZ_LIST_ERROR, CURRENT_QUIZ_SUCCESS, GET_QUESTIONS_REQUEST, GET_QUESTIONS_SUCCESS, GET_QUESTIONS_ERROR, ADD_QUIZ_REQUEST, ADD_QUIZ_SUCCESS, ADD_QUIZ_ERROR, DELETE_QUIZ_SUCCESS, GET_ALL_QUESTIONS_REQUEST, GET_ALL_QUESTIONS_SUCCESS, GET_ALL_QUESTIONS_ERROR, GET_SAVED_QUESTIONS_SUCCESS, VIEW_SINGLE_QUIZ_REQUEST, VIEW_SINGLE_QUIZ_SUCCESS, UPDATE_QUIZ_REQUEST, UPDATE_QUIZ_SUCCESS, UPDATE_QUIZ_ERROR, MAKE_QUESTION_SET_REQUEST, MAKE_QUESTION_SET_SUCCESS, SET_CATEGORY_VALUE, SET_QUESTION_TYPE_VALUE, SET_LEVEL_VALUE, SET_FEATURED_VALUE, SAVE_TOTAL_QUESTIONS, SHOW_NO_OF_QUESTIONS, RANDOM_QUESTION_VALUE, SAVE_TIME_LIMIT, GENERATE_RANDOM_QUESTIONS_REQUEST, GENERATE_RANDOM_QUESTIONS_SUCCESS, GENERATE_RANDOM_QUESTIONS_ERROR, SAVE_ACCESS_TYPE, SHOW_QUESTIONS, SAVE_CROP_IMAGE_DATA, REMOVE_CROP_IMAGE, DELETE_BANNER_IMAGE_REQUEST, DELETE_BANNER_IMAGE_SUCCESS, DELETE_BANNER_IMAGE_ERROR, BANNER_IMAGE_EXISTS, SET_TAG_VALUE } from '../constants/QuizConstants'

const initialState = {
    loading: false,
    error: '',
    disabled: false,
    viewQuizList: {
        created_at: '',
        created_by: '',
        modified_by: '',
        updated_at: '',
        category_id: '',
        name: '',
        category_name: '',
        level: '',
        tag:'',
        featured: '',
        access_type: '',
        amount: '',
        description: '',
        minutes: '',
        required: '',
        random_questions: '',
        number_of_questions: '',
        pausable: '',
        previous: '',
        quiz_stop: '',
        next: '',
        eligibility: {
          label: "All",
          value: "All"
        },
        isDeleted: '',
        question_type: '',
        questions: '',
        banner_image: '',
        id: '',
        allowed_user: "",
        isToggle: false,
        status: "Unpublished"
      },
    count: [],
    questionsList: '',
    quizList: '',
    allQuestionsList: '',
    savedQuestions: [],
    questionSet: '',
    categoryValue: null,
    questionTypeValue: null,
    levelValue: null,
    tagValue: null,
    featuredValue: null,
    totalQuestions: '',
    showNumberOfQuestions: false,
    randomQuestionvalue: false,
    timeLimitValue: false,
    accessTypeValue: '',
    generateButtonDisabled: false,
    showQuestions: false,
    cropImageData: undefined,
    removeImage: false,
    bannerImageValue: false
}

const quiz = (state = initialState, action) => {
    switch(action.type){
        case GET_QUIZ_LIST_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_QUIZ_LIST_SUCCESS:
            return{
                ...state,
                loading: false,
                quizList: action.list,
                count: action.count,
                error: ''
            }
        case GET_QUIZ_LIST_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case CURRENT_QUIZ_SUCCESS:
            return{
                ...state,
                loading: true,
                disabled: false,
                viewQuizList: initialState.viewQuizList,
                showNumberOfQuestions: false,
                randomQuestionvalue: false,
                timeLimitValue: '',
                categoryValue: null,
                questionTypeValue: null,
                levelValue: null,
                tagValue: null,
                featuredValue: null,
                accessTypeValue: '',
                showQuestions: false,
                cropImageData: undefined,
                questionSet: '',
                bannerImageValue: false
            }
        case GET_QUESTIONS_REQUEST:
            return{
                ...state,
                // loading: true
            }
        case GET_QUESTIONS_SUCCESS:
            return{
                ...state,
                loading: false,
                questionsList: action.list
            }
        case GET_QUESTIONS_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case ADD_QUIZ_REQUEST:
            return{
                ...state,
                loading: true
            }
        case ADD_QUIZ_SUCCESS:
            return{
                ...state,
                loading: false,
                cropImageData: undefined
            }
        case ADD_QUIZ_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case DELETE_QUIZ_SUCCESS:
            return{
                ...state,
                quizList: state.quizList.filter(quiz => quiz.id !== action.id)
            }
        case GET_ALL_QUESTIONS_REQUEST:
            return{
                ...state,
                // loading: true
            }
        case GET_ALL_QUESTIONS_SUCCESS:
            return{
                ...state,
                loading: false,
                allQuestionsList: action.list
            }
        case GET_ALL_QUESTIONS_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case GET_SAVED_QUESTIONS_SUCCESS:
            return{
                ...state,
                // questionsList: action.questions,
                savedQuestions: action.questionId,
                questionSet: action.questions
            }
        case VIEW_SINGLE_QUIZ_REQUEST:
            return{
                ...state,
                loading: true
            }
        case VIEW_SINGLE_QUIZ_SUCCESS:
            let array = [], tagArr = [], value, newValue, showQuestionValue
            if(action.feature === "Yes"){
                value = true
            }
            else if(action.feature === "No"){
                value = false
            }
            else{
                value = action.feature
            }
            if(action.category === null){
                array = action.category
            }
            else{
                action.obj.category_name.map(data => {
                    array.push(data.id)
                })
            }
            if(action.tag === null){
                tagArr = action.tag
            }
            else{
                action.obj.tag_name.map(data => {
                    tagArr.push(data.id)
                })
            }
            if(action.obj.random_questions === "Generate Question from frontend"){
                newValue = true
            }
            else if(action.obj.random_questions === "Generate Question from backend"){
                newValue = false
            }
            else{
                newValue = action.obj.random_questions
            }
            if(action.obj.questions.length !== 0){
                showQuestionValue = true
            }
            else{
                showQuestionValue = false
            }
            return{
                ...state,
                // loading: false,
                disabled: true,
                viewQuizList: action.obj,
                questionsList: action.obj.questions,
                questionSet: action.obj.questions,
                categoryValue: array,
                tag_name: tagArr,
                questionTypeValue: action.questionType,
                levelValue: action.level,
                featuredValue: value,
                savedQuestions: action.questionId,
                totalQuestions: action.obj.number_of_questions,
                showNumberOfQuestions: true,
                randomQuestionvalue: newValue,
                timeLimitValue: action.obj.required,
                accessTypeValue: action.obj.access_type.value,
                showQuestions: showQuestionValue,
                bannerImageValue: action.bannerImage
            }
        case UPDATE_QUIZ_REQUEST:
            return{
                ...state,
                loading: true
            }
        case UPDATE_QUIZ_SUCCESS:
            return{
                ...state,
                loading: false,
                cropImageData: undefined
            }
        case UPDATE_QUIZ_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case MAKE_QUESTION_SET_REQUEST:
            return{
                ...state,
                loading: true
            }
        case MAKE_QUESTION_SET_SUCCESS:
            return{
                ...state,
                loading: false,
                questionSet: action.list
            }
        case SET_CATEGORY_VALUE:
            return{
                ...state,
                categoryValue: action.value
            }
        case SET_QUESTION_TYPE_VALUE:
            return{
                ...state,
                questionTypeValue: action.value
            }
        case SET_LEVEL_VALUE:
            return{
                ...state,
                levelValue: action.value
            }
        case SET_TAG_VALUE:
            return{
                ...state,
                tagValue: action.value
            }
        case SET_FEATURED_VALUE:
            return{
                ...state,
                featuredValue: action.value
            }
        case SAVE_TOTAL_QUESTIONS:
            return{
                ...state,
                totalQuestions: action.value
            }
        case SHOW_NO_OF_QUESTIONS:
            return{
                ...state,
                showNumberOfQuestions: action.value
            }
        case RANDOM_QUESTION_VALUE:
            return{
                ...state,
                randomQuestionvalue: action.value
            }
        case SAVE_TIME_LIMIT:
            return{
                ...state,
                timeLimitValue: action.value
            }
        case GENERATE_RANDOM_QUESTIONS_REQUEST:
            return{
                ...state,
                loading: true,
                generateButtonDisabled: true
            }
        case GENERATE_RANDOM_QUESTIONS_SUCCESS:
            return{
                ...state,
                loading: false,
                generateButtonDisabled: false,
                questionSet: action.data,
                savedQuestions: action.questionId
            }
        case GENERATE_RANDOM_QUESTIONS_ERROR:
            return{
                loading: false,
                generateButtonDisabled: false,
                error: action.err
            }
        case SAVE_ACCESS_TYPE:
            return{
                ...state,
                accessTypeValue: action.value
            }
        case SHOW_QUESTIONS:
            return{
                ...state,
                showQuestions: action.value
            }
        case SAVE_CROP_IMAGE_DATA:
            return{
                ...state,
                cropImageData: action.newObj
            }
        case REMOVE_CROP_IMAGE:
            return{
                ...state,
                removeImage: action.value
            }
        case DELETE_BANNER_IMAGE_REQUEST:
            return{
                ...state,
                loading: true
            }
        case DELETE_BANNER_IMAGE_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case DELETE_BANNER_IMAGE_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case BANNER_IMAGE_EXISTS:
            return{
                ...state,
                bannerImageValue: action.value
            }
        default:
            return state
    }
}

export default quiz