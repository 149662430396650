import { GET_DISCIPLINE_LIST_REQUEST, CURRENT_DISCIPLINE_REQUEST, ADD_DISCIPLINE_REQUEST, DELETE_DISCIPLINE_DATA_REQUEST, VIEW_SINGLE_DISCIPLINE_DATA_REQUEST, UPDATE_DISCIPLINE_REQUEST, VIEW_DISCIPLINE_DATA_REQUEST, GET_ALL_DISCIPLINE_NAME_REQUEST, SAVE_DISCIPLINE_CROP_IMAGE_DATA, SECTION_DISCIPLINE_IMAGE_EXISTS, DELETE_DISCIPLINE_IMAGE_REQUEST } from '../constants/DisciplineConstants'

export const getDisciplineList = (activePage, searchTerm) => {
    return{
        type: GET_DISCIPLINE_LIST_REQUEST,
        activePage, 
        searchTerm
    }
}

export const currentDiscipline = () => {
    return{
        type: CURRENT_DISCIPLINE_REQUEST
    }
}

export const addDiscipline = (value, cropDisciplineImageData) => {
    return{
        type: ADD_DISCIPLINE_REQUEST,
        value,
        cropDisciplineImageData
    }
}

export const deleteDisciplineData = (id) => {
    return{
        type: DELETE_DISCIPLINE_DATA_REQUEST,
        id
    }
}

export const viewSingleDisciplineData = (id) => {
    return{
        type: VIEW_SINGLE_DISCIPLINE_DATA_REQUEST,
        id
    }
}

export const updateDisciplineList = (value, cropDisciplineImageData) => {
    return{
        type: UPDATE_DISCIPLINE_REQUEST,
        value,
        cropDisciplineImageData
    }
}

export const viewDisciplineData = (id) => {
    return{
        type: VIEW_DISCIPLINE_DATA_REQUEST,
        id
    }
}

export const getAllDisciplineName = () => {
    return{
        type: GET_ALL_DISCIPLINE_NAME_REQUEST
    }
}

export const saveDisciplineCropImageData = (newObj, key) => {
    return{
        type: SAVE_DISCIPLINE_CROP_IMAGE_DATA,
        newObj,
        key
    }
}

export const sectionDisciplineImageExists = (value, name) => {
    return{
        type: SECTION_DISCIPLINE_IMAGE_EXISTS,
        value,
        name
    }
}

export const deleteDisciplineImage = (file_url, list, name, id) => {
    return{
        type: DELETE_DISCIPLINE_IMAGE_REQUEST,
        file_url,
        list,
        name,
        id
    }
}