import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Grid, Header, Form, Segment } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form'
import classnames from 'classnames'
import ReduxFormSelect from '../library/select/ReduxFormSelect'
import TextAreaContainer from '../library/textarea/TextAreaContainer'
import Label from '../library/Label'
import Input from '../library/Input'
import ReactLoading from "react-loading"
import { getAllCourseName } from '../../actions/courseActions'
import { getAllDisciplineName } from '../../actions/DisciplineActions'
import { degree, duration } from '../docs/courseData'
import { getSubjectList } from '../../actions/helperActions'
import { history } from '../../_helpers/history'

const validate = (values) => {
    const errors = { errorName: {} };
    if (!values.discipline) {
        errors.discipline = {
            message: 'This field is Required'
        }
    }
    if (!values.name) {
        errors.name = {
            message: 'This field is Required'
        }
    }
    if (!values.display_name) {
        errors.display_name = {
            message: 'This field is Required'
        }
    }
    if (!values.program_level) {
        errors.program_level = {
            message: 'This field is Required'
        }
    }
    if (!values.duration) {
        errors.duration = {
            message: 'This field is Required'
        }
    }
    if (!values.required_program_level) {
        errors.required_program_level = {
            message: 'This field is Required'
        }
    }
    if (!values.required_marks_obtained) {
        errors.required_marks_obtained = {
            message: 'This field is Required'
        }
    }
    if (!values.mode_of_degree) {
        errors.mode_of_degree = {
            message: 'This field is Required'
        }
    }
    return errors
}

class FormContainer extends Component {
    componentDidMount() {
        this.props.getAllCourseName()
        this.props.getAllDisciplineName()
        this.props.getSubjectList()
    }

    renderField({ input, label, placeholder, type, meta: { touched, error }, disabled, className }) {
        return (
            <Form.Field className={classnames({ error: touched && error })}>
                <Label className={className}>{label} </Label>
                <Input {...input} placeholder={placeholder} type={type} disabled={disabled} />
                {touched && error && <span className="errorMsg">{error.message}</span>}
            </Form.Field>
        )
    }

    render() {
        const { loading, handleSubmit, reset, submitting, pristine, educationalList, courseName, disciplineNames, disabled, subjectList, viewSingleList } = this.props
        let course_id = localStorage.getItem('course_id')
        let requiredBranch = []
        if (courseName !== undefined) {
            if (courseName.length !== 0) {
                courseName.map(item => {
                    requiredBranch.push({ 'label': item.name, 'value': item.name })
                })
            }
        }
        if (subjectList !== undefined) {
            if (subjectList.length !== 0) {
                subjectList.map(item => {
                    requiredBranch.push(item)
                })
            }
        }
        requiredBranch.push({ 'label': 'Any', 'value': 'Any' })
        let disciplineName = []
        if (disciplineNames !== undefined) {
            if (disciplineNames.length !== 0) {
                disciplineNames.map(item => {
                    disciplineName.push({ 'label': item.name, 'value': item.name })
                })
            }
        }
        return (
            <form onSubmit={handleSubmit}>
                {loading ? <ReactLoading className="loader-container modal-loader" type="spin" color="#007bff" height={30} width={30} /> : null}
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container"> Course Details </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="required_field"> Discipline </Label>
                                <Field name="discipline" component={ReduxFormSelect} isMulti={false} options={disciplineName} placeholder="Enter Discipline" />
                                <Field name="name" component={this.renderField} type="text" label="Name of course" placeholder="Enter course" disabled={viewSingleList.status === "Published" ? true : false} className="required_field" />
                                <Label> Admission Procedure </Label>
                                <Field name="admin_procedure" component={TextAreaContainer} />
                                <Label> Course Description </Label>
                                <Field name="description" component={TextAreaContainer} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Field name="branch" component={this.renderField} type="text" label="Branch / Subject" placeholder="Enter Branch" />
                                <Field name="display_name" component={this.renderField} type="text" label="Display Name of course" placeholder="Enter Name" className="required_field" />
                                <Label className="required_field"> Program Level (Degree) </Label>
                                <Field name="program_level" component={ReduxFormSelect} isMulti={false} options={educationalList} placeholder="Enter Program Level" />
                                {/* <Label className="required_field"> Duration </Label> */}
                                {/* <Field name="duration" component={ReduxFormSelect} isMulti={false} options={duration} placeholder="Enter Duration" /> */}
                                <Field name="duration" component={this.renderField} className="required_field" type="number" label="Duration in month" placeholder="Enter Duration in month" />
                                <Field name="fees" component={this.renderField} type="text" label="Fees" placeholder="Enter Fees" />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container"> Eligibility Criteria </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="required_field"> Required Program Level </Label>
                                <Field name="required_program_level" component={ReduxFormSelect} isMulti={false} options={educationalList} placeholder="Enter Program Level" />
                                {/* <Field name="required_marks_obtained" component={this.renderField} type="text" label="Marks Obtained" placeholder="Enter Marks Obtained" className="required_field" />
                                <Field name="min_age" component={this.renderField} type="text" label="Minimum Age" placeholder="Enter Minimum Age" /> */}
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Label> Required Branch / Subject </Label>
                                <Field name="required_course" component={ReduxFormSelect} isMulti={true} options={requiredBranch} placeholder="Enter Branch" />
                                <Label className="required_field"> Mode of Degree </Label>
                                <Field name="mode_of_degree" component={ReduxFormSelect} isMulti={false} options={degree} placeholder="Enter Mode of degree" />
                                {/* <Field name="max_age" component={this.renderField} type="text" label="Maximum Age" placeholder="Enter Maximum Age" /> */}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container"> Age & Marks Criteria </Header>
                    <Grid columns={3}>
                        <Grid.Row>
                            <Grid.Column>
                                <Segment>
                                    <Header as='h2' textAlign='left' className="heading-container">For General </Header>
                                    <Grid.Row>
                                        <Grid.Column width={8} textAlign='left'>
                                            <Field name="catergory_criteria[0].required_marks_obtained" component={this.renderField} type="text" label="Marks Obtained" placeholder="Enter Marks Obtained" />
                                            <Field name="catergory_criteria[0].min_age" component={this.renderField} type="text" label="Minimum Age" placeholder="Enter Minimum Age" />
                                        </Grid.Column>
                                        <Grid.Column width={8} textAlign='left'>
                                            <Field name="catergory_criteria[0].max_age" component={this.renderField} type="text" label="Maximum Age" placeholder="Enter Maximum Age" />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment>
                                    <Header as='h2' textAlign='left' className="heading-container">For OBC</Header>
                                    <Grid.Row>
                                        <Grid.Column width={8} textAlign='left'>
                                            <Field name="catergory_criteria[1].required_marks_obtained" component={this.renderField} type="text" label="Marks Obtained" placeholder="Enter Marks Obtained"/>
                                            <Field name="catergory_criteria[1].min_age" component={this.renderField} type="text" label="Minimum Age" placeholder="Enter Minimum Age" />
                                        </Grid.Column>
                                        <Grid.Column width={8} textAlign='left'>
                                            <Field name="catergory_criteria[1].max_age" component={this.renderField} type="text" label="Maximum Age" placeholder="Enter Maximum Age" />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment>
                                    <Header as='h2' textAlign='left' className="heading-container">For SC/ST</Header>
                                    <Grid.Row>
                                        <Grid.Column width={8} textAlign='left'>
                                            <Field name="catergory_criteria[2].required_marks_obtained" component={this.renderField} type="text" label="Marks Obtained" placeholder="Enter Marks Obtained" />
                                            <Field name="catergory_criteria[2].min_age" component={this.renderField} type="text" label="Minimum Age" placeholder="Enter Minimum Age" />
                                        </Grid.Column>
                                        <Grid.Column width={8} textAlign='left'>
                                            <Field name="catergory_criteria[2].max_age" component={this.renderField} type="text" label="Maximum Age" placeholder="Enter Maximum Age" />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Segment >
                            </Grid.Column >
                        </Grid.Row >
                    </Grid >
                </Segment >
                {course_id === "" || course_id === null ?
                    <div className='btns-container'>
                        <Button size='large' primary disabled={loading || submitting}> Submit </Button>
                        <Button size='large' color="grey" type="button" disabled={pristine || submitting} className="reset-button" onClick={reset}> Reset </Button>
                    </div>
                    : <div className='btns-container'>
                        <Button size='large' primary disabled={loading}> Update </Button>
                        <Button size='large' color="grey" onClick={()=>{history.push("/courseList")}} className="reset-button"> Cancel </Button>
                    </div>
                }
            </form>
        )
    }
}

const mapStateToProps = (state) => ({
    courseName: state.courseReducer.courseName,
    disciplineNames: state.disciplineReducer.disciplineNames,
    disabled: state.courseReducer.disabled,
    loading: state.courseReducer.loading,
    educationalList: state.helperReducer.educationalList,
    initialValues: state.courseReducer.viewSingleList,
    subjectList: state.helperReducer.subjectList,
    viewSingleList: state.courseReducer.viewSingleList
})

const mapDispatcchToProps = {
    getAllCourseName,
    getAllDisciplineName,
    getSubjectList
}

export default connect(mapStateToProps, mapDispatcchToProps)(reduxForm({ form: 'simple', validate, enableReinitialize: true })(FormContainer))