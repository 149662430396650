const validate = (values) => {
    const errors = {errorName:{}};
    if(!values.course_summary_title){
        errors.course_summary_title = {
            message: 'This field is Required'
        }
    }
    if(!values.course_summary) {
        errors.course_summary = {
          message: 'This field is Required'
        }
    }
    if(!values.tags) {
        errors.tags = {
          message: 'This field is Required'
        }
    }
    if(!values.entry_title) {
        errors.entry_title = {
          message: 'This field is Required'
        }
    }
    if(!values.entry_summary) {
        errors.entry_summary = {
          message: 'This field is Required'
        }
    }
    if(!values.highlights_key){
        errors.highlights_key = 'This field is Required'
    }
    if(!values.highlights_value){
        errors.highlights_value = 'This field is Required'
    }
    if(!values.apply_summary) {
        errors.apply_summary = {
          message: 'This field is Required'
        }
    }
    if(!values.entrance_exam_key){
        errors.entrance_exam_key = 'This field is Required'
    }
    if(!values.top_entrance_summary) {
      errors.top_entrance_summary = {
        message: 'This field is Required'
      }
    }
    if(!values.career_options_key){
        errors.career_options_key = 'This field is Required'
    }
    if(!values.career_options_summary) {
      errors.career_options_summary = {
        message: 'This field is Required'
      }
    }
    return errors 
}

export default validate