import { REGISTER_REQUEST, LOGIN_REQUEST, LOGOUT_USER, RESET_PASSWORD_REQUEST, UPDATE_PROFILE_REQUEST, CURRENT_USER_REQUEST, CREATE_USER_REQUEST, GET_USERS_REQUEST, VIEW_SINGLE_USER_REQUEST, UPDATE_USER_REQUEST, GET_CURRENT_USER_PROFILE_REQUEST, UPDATE_USER_STATUS_REQUEST, SEND_INVITATION_REQUEST, SAVE_ROLE, SAVE_COMPANY, SAVE_QUIZ_SET, SAVE_USER_QUIZ_REQUEST, GET_ASSIGNED_QUIZZES_REQUEST, UPDATE_USER_QUIZ_REQUEST, REMOVE_QUIZ_SET, UPDATE_BUTTON_DATA, DELETE_USER_REQUEST } from "../constants/UserConstants"

export const loginUser = (data) => {
    const { email, password } = data
    return{
        type: LOGIN_REQUEST,
        email,
        password
    }
}

export const register = (data) => {
    return{
        type: REGISTER_REQUEST,
        name: data.name,
        phone: data.phone,
        email: data.email,
        password: data.password
    }
}

export const logoutUser = () => {
    return{
        type: LOGOUT_USER
    }
}

export const resetPassword = (newPassword, currentPassword) => {
    return{
        type: RESET_PASSWORD_REQUEST,
        newPassword,
        currentPassword
    }
}

export const updateProfileData = (data) => {
    return {
        type: UPDATE_PROFILE_REQUEST,
        data
    }
}

export const currentUser = () => {
    return{
        type: CURRENT_USER_REQUEST
    }
}

export const createUser = (data) => {
    return{
        type: CREATE_USER_REQUEST,
        data
    }
}

export const getUsersList = (activePage, searchTerm, deleteKey) => {
    return{
        type: GET_USERS_REQUEST,
        activePage, 
        searchTerm,
        deleteKey
    }
}

export const viewSingleUserData = (id) => {
    return{
        type: VIEW_SINGLE_USER_REQUEST,
        id
    }
}

export const updateUser = (data, id) => {
    return{
        type: UPDATE_USER_REQUEST,
        data,
        id
    }
}

export const getCurrentProfileData = () => {
    return{
        type: GET_CURRENT_USER_PROFILE_REQUEST
    }
}

export const updateUserStatus = (id, value) => {
    return{
        type: UPDATE_USER_STATUS_REQUEST,
        id,
        value
    }
}

export const sendInvitation = (email, id, role) => {
    return{
        type: SEND_INVITATION_REQUEST,
        email,
        id,
        role
    }
}

export const saveRole = (value) => {
    return{
        type: SAVE_ROLE,
        value
    }
}

export const saveCompany = (value) => {
    return{
        type: SAVE_COMPANY,
        value
    }
}

export const saveQuizSet = (value) => {
    return{
        type: SAVE_QUIZ_SET,
        value
    }
}

export const saveUserQuiz = (id, value) => {
    return{
        type: SAVE_USER_QUIZ_REQUEST,
        id, 
        value
    }
}

export const getAssignedQuizzes = (id) => {
    return{
        type: GET_ASSIGNED_QUIZZES_REQUEST,
        id
    }
}

export const updateUserQuiz = (id, value, docId) => {
    return{
        type: UPDATE_USER_QUIZ_REQUEST,
        id, 
        value,
        docId
    }
}

export const removeQuizSet = () => {
    return{
        type: REMOVE_QUIZ_SET
    }
}

export const updateButtonData = (value) => {
    return{
        type: UPDATE_BUTTON_DATA,
        value
    }
}

export const deleteUser = (id) => {
    return{
        type: DELETE_USER_REQUEST,
        id
    }
}