
import React from 'react'
import { Card, Divider, Header, Grid, Container, Table, Label, Button, Menu, Icon } from 'semantic-ui-react'

const PlayedUsers = () => {

    
    return (
        <Container >
            <div className="wrapper">
                <div className="container" width= {3}>
                    <Header />
                    <Grid   >
                            <Table celled size="medium"   >
                                <Table.Header >
                                    <Table.Row>
                                        <Table.HeaderCell colSpan='3'>Page visits
                                            <Button color='teal' floated='right'>See All</Button></Table.HeaderCell>

                                    </Table.Row>
                                </Table.Header>
                                <Table.Header width={2}>
                                    <Table.Row >
                                        <Table.HeaderCell width={4}>PAGE NAME</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>PAGE VIEWS</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>PAGE VALUE</Table.HeaderCell>
                                        <Table.HeaderCell >PAGE VALUE</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>  
                                    <Table.Row>
                                        <Table.Cell><Header as="h3">/demo/admin/index.html</Header></Table.Cell>
                                        <Table.Cell>4.525</Table.Cell>
                                        <Table.Cell>$255</Table.Cell>
                                        <Table.Cell><i class="green long arrow up icon"></i>42.55%</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell><Header as="h3">/demo/admin/forms.html</Header></Table.Cell>
                                        <Table.Cell>2.987</Table.Cell>
                                        <Table.Cell>$139</Table.Cell>
                                        <Table.Cell> <i class="red long arrow down icon"></i>43.52%</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell><Header as="h3">/demo/admin/util.html</Header></Table.Cell>
                                        <Table.Cell>2.844</Table.Cell>
                                        <Table.Cell>$124</Table.Cell>
                                        <Table.Cell><i class="red long arrow down icon"></i>32.35%</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell><Header as="h3">/demo/admin/validation.html</Header></Table.Cell>
                                        <Table.Cell>1.22</Table.Cell>
                                        <Table.Cell>$55</Table.Cell>
                                        <Table.Cell><i class="green long arrow up icon"></i>15.78%</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>  <Header as="h3">/demo/admin/modals.html</Header></Table.Cell>
                                        <Table.Cell>505</Table.Cell>
                                        <Table.Cell>$3</Table.Cell>
                                        <Table.Cell> <i class=" red long arrow down icon"></i>75.12%</Table.Cell>
                                    </Table.Row>
                                </Table.Body>

                            </Table>

                           

                        

                    </Grid>



                </div>
            </div>
        </Container>
    )

}
export default PlayedUsers
