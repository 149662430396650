import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Header from '../Header/Header'
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import FormContainer from './FormContainer'
import { authHeader, history } from '../../_helpers'
import { db } from '../firebase'
import ReactLoading from "react-loading"
import { currentDisability, addDisability, viewSingleDisability, updateDisability } from '../../actions/DisabilityActions'

const AddDisabilityType = (props) => {
    const { pathname } = props.location
    const { currentDisability, addDisability, viewSingleDisability, updateDisability, error, loading } = props

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
            }
            else{
                history.push('/login') 
            }
        }
        if(props.match && props.match.params && props.match.params.id){
            const newDisability = props.match.params.id
            viewSingleDisability(newDisability)
        }
        else{
            currentDisability()
        }
    },[pathname, props.match, viewSingleDisability, currentDisability])

    const handleSubmit = (data) => {
        let uid = localStorage.getItem('uid')
        let disability_id = localStorage.getItem('disability_id')
        if(disability_id === null || disability_id === ""){
            data['createdBy'] = db.doc('users/' + uid)
            data['modifiedBy'] = db.doc('users/' + uid)
            data['created_date_time'] = new Date()
            data['modified_date_time'] = new Date()
            data['isDeleted'] = false
            addDisability(data)
        }
        else{
            data['modified_date_time'] = new Date()
            updateDisability(data)
        }
    }

    return(
        <div className="wrapper">
            <div className="container">
                {error !== "" ? <h5 className="errorMsg"> {error} </h5>: null}
                {loading ?  <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                <Header />
                <Link className="link-container" to="/disabilityList"> <Icon name="arrow left" /> Back to Disability List </Link>
                <FormContainer onSubmit={handleSubmit} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.disabilityReducer.loading,
    error: state.disabilityReducer.error
})

const mapDispatchToProps = {
    currentDisability,
    addDisability,
    viewSingleDisability,
    updateDisability
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDisabilityType)