export const GET_QUIZ_LIST_REQUEST = 'GET_QUIZ_LIST_REQUEST'
export const GET_QUIZ_LIST_SUCCESS = 'GET_QUIZ_LIST_SUCCESS'
export const GET_QUIZ_LIST_ERROR = 'GET_QUIZ_LIST_ERROR'

export const CURRENT_QUIZ_REQUEST = 'CURRENT_QUIZ_REQUEST'
export const CURRENT_QUIZ_SUCCESS = 'CURRENT_QUIZ_SUCCESS'

export const ADD_QUIZ_REQUEST = 'ADD_QUIZ_REQUEST'
export const ADD_QUIZ_SUCCESS = 'ADD_QUIZ_SUCCESS'
export const ADD_QUIZ_ERROR = 'ADD_QUIZ_ERROR'

export const DELETE_QUIZ_REQUEST = 'DELETE_QUIZ_REQUEST'
export const DELETE_QUIZ_SUCCESS = 'DELETE_QUIZ_SUCCESS'

export const VIEW_SINGLE_QUIZ_REQUEST = 'VIEW_SINGLE_QUIZ_REQUEST'
export const VIEW_SINGLE_QUIZ_SUCCESS = 'VIEW_SINGLE_QUIZ_SUCCESS'

export const UPDATE_QUIZ_REQUEST = 'UPDATE_QUIZ_REQUEST'
export const UPDATE_QUIZ_SUCCESS = 'UPDATE_QUIZ_SUCCESS'
export const UPDATE_QUIZ_ERROR = 'UPDATE_QUIZ_ERROR'

export const GET_QUESTIONS_REQUEST = 'GET_QUESTIONS_REQUEST'
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS'
export const GET_QUESTIONS_ERROR = 'GET_QUESTIONS_ERROR'

export const GET_ALL_QUESTIONS_REQUEST = 'GET_ALL_QUESTIONS_REQUEST'
export const GET_ALL_QUESTIONS_SUCCESS = 'GET_ALL_QUESTIONS_SUCCESS'
export const GET_ALL_QUESTIONS_ERROR = 'GET_ALL_QUESTIONS_ERROR'

export const GET_SAVED_QUESTIONS_REQUEST = 'GET_SAVED_QUESTIONS_REQUEST'
export const GET_SAVED_QUESTIONS_SUCCESS = 'GET_SAVED_QUESTIONS_SUCCESS'

export const MAKE_QUESTION_SET_REQUEST = 'MAKE_QUESTION_SET_REQUEST'
export const MAKE_QUESTION_SET_SUCCESS = 'MAKE_QUESTION_SET_SUCCESS'

export const SET_CATEGORY_VALUE = 'SET_CATEGORY_VALUE'

export const SET_QUESTION_TYPE_VALUE = 'SET_QUESTION_TYPE_VALUE'

export const SET_LEVEL_VALUE = 'SET_LEVEL_VALUE'

export const SET_TAG_VALUE = 'SET_TAG_VALUE'

export const SET_FEATURED_VALUE = 'SET_FEATURED_VALUE'

export const SAVE_TOTAL_QUESTIONS = 'SAVE_TOTAL_QUESTIONS'

export const SHOW_NO_OF_QUESTIONS = 'SHOW_NO_OF_QUESTIONS'

export const RANDOM_QUESTION_VALUE = 'RANDOM_QUESTION_VALUE'

export const SAVE_TIME_LIMIT = 'SAVE_TIME_LIMIT'

export const GENERATE_RANDOM_QUESTIONS_REQUEST = 'GENERATE_RANDOM_QUESTIONS_REQUEST'
export const GENERATE_RANDOM_QUESTIONS_SUCCESS = 'GENERATE_RANDOM_QUESTIONS_SUCCESS'
export const GENERATE_RANDOM_QUESTIONS_ERROR = 'GENERATE_RANDOM_QUESTIONS_ERROR'

export const SAVE_ACCESS_TYPE = 'SAVE_ACCESS_TYPE'
export const SAVE_ALLOWED_USER = 'SAVE_ALLOWED_USER'

export const SHOW_QUESTIONS = 'SHOW_QUESTIONS'

export const SAVE_CROP_IMAGE_DATA = 'SAVE_CROP_IMAGE_DATA'

export const REMOVE_CROP_IMAGE = 'REMOVE_CROP_IMAGE'

export const DELETE_BANNER_IMAGE_REQUEST = 'DELETE_BANNER_IMAGE_REQUEST'
export const DELETE_BANNER_IMAGE_SUCCESS = 'DELETE_BANNER_IMAGE_SUCCESS'
export const DELETE_BANNER_IMAGE_ERROR = 'DELETE_BANNER_IMAGE_ERROR'

export const BANNER_IMAGE_EXISTS = 'BANNER_IMAGE_EXISTS'