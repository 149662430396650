export const GET_DISABILITY_LIST_REQUEST = 'GET_DISABILITY_LIST_REQUEST'
export const GET_DISABILITY_LIST_SUCCESS = 'GET_DISABILITY_LIST_SUCCESS'
export const GET_DISABILITY_LIST_ERROR = 'GET_DISABILITY_LIST_ERROR'

export const CURRENT_DISABILITY_REQUEST = 'CURRENT_DISABILITY_REQUEST'
export const CURRENT_DISABILITY_SUCCESS = 'CURRENT_DISABILITY_SUCCESS'

export const ADD_DISABILITY_REQUEST = 'ADD_DISABILITY_REQUEST'
export const ADD_DISABILITY_SUCCESS = 'ADD_DISABILITY_SUCCESS'
export const ADD_DISABILITY_ERROR = 'ADD_DISABILITY_ERROR'

export const DELETE_DISABILITY_DATA_REQUEST = 'DELETE_DISABILITY_DATA_REQUEST'
export const DELETE_DISABILITY_DATA_SUCCESS = 'DELETE_DISABILITY_DATA_SUCCESS'

export const VIEW_SINGLE_DISABILITY_REQUEST = 'VIEW_SINGLE_DISABILITY_REQUEST'
export const VIEW_SINGLE_DISABILITY_SUCCESS = 'VIEW_SINGLE_DISABILITY_SUCCESS'

export const UPDATE_DISABILITY_REQUEST = 'UPDATE_DISABILITY_REQUEST'
export const UPDATE_DISABILITY_SUCCESS = 'UPDATE_DISABILITY_SUCCESS'
export const UPDATE_DISABILITY_ERROR = 'UPDATE_DISABILITY_ERROR'
