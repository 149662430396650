import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Form, Grid, Header, Modal, Segment, Checkbox, Radio } from 'semantic-ui-react'
import classnames from 'classnames'
import { Field, reduxForm } from 'redux-form'
import Label from '../../library/Label'
import Input from '../../library/Input'
import ReduxFormSelect from '../../library/select/ReduxFormSelect'
import TextAreaContainer from '../../library/textarea/TextAreaContainer'
import RadioContainer from '../../library/radio/RadioContainer'
import CheckboxContainer from '../../library/checkbox/CheckboxContainer'
import StatusCheckebbox from '../../library/checkbox/StatusCheckebbox'
import UploadCropImage from '../../library/uploadCropImage/UploadCropImage'
import { access_type, allowed_user, question_type, question_level, minutes } from '../../docs/quizData'
import { getAllCategoryName } from '../../../actions/QuizCategoryActions'
import { getTagList } from '../../../actions/TagActions'

import { getQuestions, getAllQuestions, getSavedQuestions, makeQuestionSet, setCategoryValue, setQuestionTypeValue, setLevelValue,setTagValue,  setFeaturedValue, saveTotalQuestions, showNoOfQuestions, randomQuestionValue, saveTimeLimit, generateRandomQuestions, saveAccessType, saveAllowedUser, showQuestionsData, saveCropImageData, removeCropImage } from '../../../actions/QuizActions'
import _ from 'lodash'
import { history } from '../../../_helpers'

const validate = (values) => {
    const errors = {errorName:{}};
    if(!values.name) {
      errors.name = {
        message: 'This field is Required'
      }
    }
    if(!values.number_of_questions){
        errors.number_of_questions = {
            message: 'This field is Required'
        }
    }
    if(!values.time){
        errors.time = {
            message: 'This field is Required'
        }
    }
    if(!values.access_type){
        errors.access_type = {
            message: 'This field is Required'
        }
    }
    if(!values.allowed_user){
        errors.allowed_user = {
            message: 'This field is Required'
        }
    }

    if(!values.random_questions){
        errors.random_questions = {
            message: 'This field is Required'
        }
    }
    if(values.required){
        if(!values.minutes){
            errors.minutes = {
                message: 'This field is Required'
            }
        }
        else if(values.minutes){
            if(values.minutes.value === 0){
                errors.minutes = {
                    message: 'Please select Minutes'
                }
            }
        }
    }
    if(values.access_type){
        if(values.access_type.value === "Paid"){
            if(!values.amount){
                errors.amount = {
                    message: 'This field is Required'
                }
            }
        }
    }
    if(values.pausable){
        if(!values.required){
            errors.pausable = {
                message: 'Pausable cannot be selected if no time limit is selected'
            }
        }
    }
    return errors
}

class FormContainer extends Component {
    constructor(){
        super()
        this.state = {
            isOpen: false,
            saveQuestionsList: '',
            questionError: '',
            status: '',
            //isToggle: true
        }

       
        this.handleChangeStatus = this.handleChangeStatus.bind(this);

        this.handleChange = this.handleChange.bind(this)
        this.generateQuestions = this.generateQuestions.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.saveList = this.saveList.bind(this)
        this.renderCategory = this.renderCategory.bind(this)
        this.renderQuestionType = this.renderQuestionType.bind(this)
        this.renderLevel = this.renderLevel.bind(this)
        this.renderTag = this.renderTag.bind(this)
        this.renderFeatured = this.renderFeatured.bind(this)
        this.closePopup = this.closePopup.bind(this)
        this.resetForm = this.resetForm.bind(this)
        this.renderRandomQuestions = this.renderRandomQuestions.bind(this)
        this.clearFilters = this.clearFilters.bind(this)
        this.renderTimeLimit = this.renderTimeLimit.bind(this)
        this.renderAccessType = this.renderAccessType.bind(this)
        this.removeErrorValue = this.removeErrorValue.bind(this)
    }
    
    componentDidMount(){
        const { categoryValue, questionTypeValue, levelValue, featuredValue, newQuiz, tagValue } = this.props
        console.log('tagValue in componentdid mount:: ',tagValue)
        console.log('categoryValue in componentdid mount:: ',categoryValue)
        this.props.getAllCategoryName()
        this.props.getTagList()
        this.props.getQuestions(categoryValue, questionTypeValue, levelValue, featuredValue, tagValue)
        this.props.getAllQuestions(categoryValue, questionTypeValue, levelValue, featuredValue, tagValue)
    }

    componentDidUpdate(prevProps){
        const { categoryValue, questionTypeValue, levelValue, featuredValue, tagValue } = this.props
        console.log('prevProps:: ',prevProps.initialValues)
        console.log('props:: ',prevProps.initialValues)
        console.log('categoryValue in componentdid update:: ',categoryValue)
        //console.log('tagValue component in did update:: ',tagValue)
        if(prevProps.initialValues.id !== this.props.initialValues.id){
            console.log('dfdfdfdf')
            this.props.getQuestions(categoryValue, questionTypeValue, levelValue, featuredValue, tagValue)
            this.props.getAllQuestions(categoryValue, questionTypeValue, levelValue, featuredValue, tagValue)
        }
    }

    renderField({ input, label, type, meta: { touched, error }, disabled, className }) {
        return (
            <Form.Field className={classnames({error:touched && error})}>
                <Label className={className}>{label} </Label>
                <Input {...input} placeholder={label} type={type} disabled={disabled} />
                {touched && error && <span className="errorMsg">{error.message}</span>}
            </Form.Field>
        )
    }
    // <Checkbox toggle 
    //             name = "willing"
    //             defaultChecked={this.state.isToggle}
    //             onClick={this.handleChangeStatus.bind(this, !this.state.isToggle)}
    //             />
    // renderStatusField({ input, label, type, meta: { touched, error }, disabled, className }) {
    //     return (
    //         <Form.Field className={classnames({error:touched && error})}>
    //             <Label className={className}>{label} </Label>
    //             <StatusCheckebbox {...input} placeholder={label} type={type} disabled={disabled} />
    //             {touched && error && <span className="errorMsg">{error.message}</span>}
    //         </Form.Field>
    //     )
    // }

    handleChange(event){
        const { minimumQuestion, maximumQuestion, allQuestionsList, categoryValue, questionTypeValue, levelValue, featuredValue, tagValue } = this.props
        console.log('tagValue:: ',tagValue)
        const target =  event.target
        const { value } = target
        this.props.saveTotalQuestions(value)
        this.props.removeError(false)
        this.setState({ saveQuestionsList: '' })
        if(value >= minimumQuestion && value < maximumQuestion && _.toNumber(value) <= allQuestionsList.length){
            this.props.changeMsg('success')
        }
        this.props.getQuestions(categoryValue, questionTypeValue, levelValue, featuredValue, tagValue)
        this.props.getAllQuestions(categoryValue, questionTypeValue, levelValue, featuredValue, tagValue)
    }
    handleChangeStatus(name){
        console.log('event:: ',name)
        //this.setState({value: event.target.value})
       // let name = event.target.name
// let value = event.target.value
// console.log('value:: ',value)

this.setState({
    isToggle: name
})
    }

    generateQuestions(){
        const { categoryValue, questionTypeValue, levelValue, featuredValue, totalQuestions } = this.props
        // let list = _.sampleSize(this.props.questionsList, this.props.totalQuestions)
        this.props.showQuestionsData(true)
        // const newList = list.map(item => {
        //     item.isChecked = true
        //     return item
        // })
        // this.props.getSavedQuestions(newList)
        // this.props.makeQuestionSet(list)
        this.props.changeMsg('success')
        // this.setState({ saveQuestionsList: this.props.questionsList })
        this.props.generateRandomQuestions(categoryValue, questionTypeValue, levelValue, featuredValue, totalQuestions)
    }

    handleOpen(){
        const { isOpen, categoryValue, questionTypeValue, levelValue, featuredValue, allQuestionsList, questionSet, questionsList } = this.props
        let quiz_id = localStorage.getItem('quiz_id')
        this.setState({ isOpen: !isOpen })
        this.props.getQuestions(categoryValue, questionTypeValue, levelValue, featuredValue)
        this.props.getAllQuestions(categoryValue, questionTypeValue, levelValue, featuredValue)
        if(!_.isEmpty(questionSet) && questionsList !== undefined){
            questionsList.map(questions => {
                questionSet.map(set => {
                    if(set.id === questions.id){
                        questions.isChecked = true
                        // return questions
                    }
                    // else{
                    //     questions.isChecked = false
                    //     // return questions
                    // }
                    return questions
                })
            })
            this.setState({ saveQuestionsList: questionsList })
        }
        // else{
        //     if(_.isEmpty(questionSet)){
        //         this.setState({ saveQuestionsList: questionsList })
        //     }
        // }
        if(!_.isEmpty(quiz_id) && quiz_id !== undefined){
            if(!_.isEmpty(allQuestionsList) && allQuestionsList !== undefined && questionSet !== undefined){
                allQuestionsList.map(item => {
                    questionSet.map(id => {
                        if(item.id === id.id){
                            item.isChecked = true
                        }
                        return item
                    })
                })
            } 
            this.setState({ saveQuestionsList: allQuestionsList })
        }
    }

    closeModal(){
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen, questionError:'' })
    }

    getModal(){
        const { allQuestionsList } = this.props
        return(
            <Modal trigger={
                    <Button type="button" onClick={this.handleOpen} className="view-btn" floated='right'>
                        Update Question
                    </Button>}
                    closeOnDimmerClick={false}
                    size='large'
                    open={this.state.isOpen}
                    onOpen={this.handleOpen}
                    onClose={this.closeModal}
                    closeIcon>
                <Modal.Header> 
                    <span> Select Questions </span> 
                </Modal.Header>
                {allQuestionsList !== undefined ? allQuestionsList.length !== 0 ? this.getModalContent(allQuestionsList) : <Modal.Content className="modal-container"><Header as='h2' className="question-error">No Questions Found</Header></Modal.Content> :null}
            </Modal>
        )
    }

    getChooseQuestionsModal(){
        const { allQuestionsList } = this.props
        return(
            <Modal  open={this.state.isOpen}
                    closeOnDimmerClick={false}
                    trigger={
                        <Button type="button" onClick={this.handleOpen} className="view-btn">
                            Choose Question
                        </Button>}
                    size='large'
                    onClose={this.closeModal}
                    onOpen={this.handleOpen}
                    closeIcon>
                <Modal.Header> 
                    <span> Select Questions </span> 
                </Modal.Header>
                {allQuestionsList !== undefined ? allQuestionsList.length !== 0 ? this.getModalContent(allQuestionsList) : <Modal.Content className="modal-container"> <Header as='h2' className="question-error">No Questions Found </Header></Modal.Content>:null}
            </Modal>
        )
    }

    createJson(evt, newquestionsList, id){
        const { saveQuestionsList } = this.state
        if(evt.target.checked){
            if(saveQuestionsList === '' || saveQuestionsList === undefined){
                newquestionsList.map(item => {
                    if(item.id === id){
                        item.isChecked = true
                    }
                    return item
                })
                this.setState({ saveQuestionsList: newquestionsList })
            }
            else{
                saveQuestionsList.map(item => {
                    if(item.id === id){
                        item.isChecked = true
                    }
                    return item
                })
                this.setState({ saveQuestionsList: saveQuestionsList })
            }
        }
        else{
            saveQuestionsList.map(item => {
                if(item.id === id){
                    item.isChecked = false
                }
                return item
            })
            this.setState({ saveQuestionsList: saveQuestionsList, questionError:'' })
        }
    }

    saveList(){
        const { isOpen } = this.state
        let list = _.filter(this.state.saveQuestionsList, ['isChecked', true])
        if(list.length > this.props.totalQuestions){
            this.setState({ questionError: 'Questions selected should be equal no of questions' })
        }
        else{
            this.props.getSavedQuestions(list)
            this.props.makeQuestionSet(list)
            this.props.showQuestionsData(true)
            this.setState({ isOpen: !isOpen, questionError: '' })
            this.props.changeMsg('success')
        }
    }

    closePopup(){
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen, questionError:'' })
    }

    getModalContent(allQuestionsList){
        const regex = /(<([^>]+)>)/ig
        const { questionError } = this.state
        const newquestionsList = _.cloneDeep(allQuestionsList)
        newquestionsList.map(list => {
            if(!_.isEmpty(this.props.questionSet) && this.props.questionSet !== undefined){
                this.props.questionSet.map(data => {
                    if(list.id === data.id){
                        list.isChecked = true
                    }
                })
            }
        })
        return(
            <Modal.Content className="modal-container">
                {questionError !== '' ? <h3 className="question-error">{questionError}</h3> : null}
                {newquestionsList.map((list) => {
                    return(
                        <div key={list.id}>
                            <input type="checkbox" 
                                      value={list.question.replace(regex, '')}
                                      defaultChecked={list.isChecked}
                                      onChange={(evt, data) => this.createJson(evt, newquestionsList, list.id, list)} />
                            <label>{list.question.replace(regex, '')}</label>
                        </div>
                    )
                })}
                <Button type="button" onClick={this.saveList}>Save</Button>
                <Button type="button" onClick={this.closePopup}>Cancel</Button>
            </Modal.Content>
        )
    }

    onKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); //<===== This stops the form from being submitted
        } else {}
    }

    renderCategory(name){
        console.log('render category')
        const { questionTypeValue, levelValue, featuredValue, tagValue } = this.props
        let array = []
        if(name !== null){
            name.map(data => {
                array.push(data.id)
            })
        }
        this.props.removeError(false)
        this.props.setCategoryValue(array)
        if(array.length !== 0){
            this.props.getQuestions(array, questionTypeValue, levelValue, featuredValue, tagValue)
            this.props.getAllQuestions(array, questionTypeValue, levelValue, featuredValue, tagValue)
        }
        else{
            this.props.getQuestions(null, questionTypeValue, levelValue, featuredValue, tagValue)
            this.props.getAllQuestions(null, questionTypeValue, levelValue, featuredValue, tagValue)
        }
        this.props.getSavedQuestions()
        this.setState({ saveQuestionsList: '' })
        this.props.showQuestionsData(false)
    }

    renderQuestionType(name){ 
        const { categoryValue, levelValue, featuredValue , tagValue} = this.props
        this.props.removeError(false)
        this.props.setQuestionTypeValue(name.value)
        this.props.getQuestions(categoryValue, name.value, levelValue, featuredValue, tagValue)
        this.props.getAllQuestions(categoryValue, name.value, levelValue, featuredValue, tagValue)
        this.props.getSavedQuestions()
        this.setState({ saveQuestionsList: '' })
        this.props.showQuestionsData(false)
    }

    renderLevel(name){
        const { categoryValue, questionTypeValue, featuredValue, tagListForFilter, tagValue } = this.props
        console.log('tag are:: ',tagListForFilter)
        this.props.removeError(false)
        this.props.setLevelValue(name.value)
        this.props.getQuestions(categoryValue, questionTypeValue, name.value, featuredValue, tagValue)
        this.props.getAllQuestions(categoryValue, questionTypeValue, name.value, featuredValue, tagValue)
        this.props.getSavedQuestions()
        this.setState({ saveQuestionsList: '' })
        this.props.showQuestionsData(false)
    }

    renderFeatured(name){
        let value, category
        if(name === "Yes"){
            value = true
        }
        else{
            value = false
        }
        const { categoryValue, questionTypeValue, levelValue, tagValue } = this.props
        this.props.removeError(false)
        if(categoryValue === null){
            category = null
        }
        else if(categoryValue !== null){
            if(categoryValue.length === 0){
                category = null
            }
            else{
                category = categoryValue
            }
        }
        else{
            category = categoryValue
        }
        this.props.setFeaturedValue(value)
        this.props.getQuestions(category, questionTypeValue, levelValue, value, tagValue)
        this.props.getAllQuestions(category, questionTypeValue, levelValue, value, tagValue)
        this.props.getSavedQuestions()
        this.setState({ saveQuestionsList: '' })
        this.props.showQuestionsData(false)
    }

    resetForm(){
        this.props.reset()
        this.props.getSavedQuestions()
        this.props.saveTotalQuestions('')
        this.props.showQuestionsData(false)
        this.setState({ saveQuestionsList: '' })
        this.props.saveCropImageData(undefined)
        this.props.removeCropImage(true)
        this.props.removeError(false)
    }

    renderRandomQuestions(value){
        let newValue
        const { categoryValue, questionTypeValue, levelValue, featuredValue } = this.props
        this.props.removeError(false)
        if(value === "Generate Question from frontend"){
            newValue = true
            this.props.getSavedQuestions()
            this.setState({ saveQuestionsList: '' })
        }
        else{
            newValue = false
            this.props.getQuestions(categoryValue, questionTypeValue, levelValue, featuredValue)
            this.props.getAllQuestions(categoryValue, questionTypeValue, levelValue, featuredValue)
        }
        this.props.randomQuestionValue(newValue)
        this.props.showNoOfQuestions(true)
    }

    clearFilters(){
        this.props.change("category_name", null)
        this.props.change("question_type", null)
        this.props.change("level", null)
        this.props.change("featured", null)
        this.props.change("tag_name", null)
        this.props.setCategoryValue(null)
        this.props.setQuestionTypeValue(null)
        this.props.setLevelValue(null)
        this.props.setTagValue(null)
        this.props.setFeaturedValue(null)
        this.props.getSavedQuestions()
        this.setState({ saveQuestionsList: '' })
        this.props.showQuestionsData(false)
        this.props.removeError(false)
    }

    renderTimeLimit(name){
        this.props.saveTimeLimit(name)
        this.props.removeError(false)
    }

    renderAccessType(name){
        this.props.saveAccessType(name.value)
        this.props.removeError(false)
    }

    renderTag(name){
        console.log('tag value are:: ',name)
        const { categoryValue, questionTypeValue, featuredValue, tagListForFilter, levelValue, tagValue } = this.props
        let array = []
        if(name !== null){
            array = name
        }
        this.props.removeError(false)
        this.props.setTagValue(array)
        if(array.length !== 0){
            this.props.getQuestions(categoryValue, questionTypeValue, levelValue, featuredValue, name)
            this.props.getAllQuestions(categoryValue, questionTypeValue, levelValue, featuredValue, name)
        }
        else{
            this.props.getQuestions(categoryValue, questionTypeValue, levelValue, featuredValue, null)
            this.props.getAllQuestions(categoryValue, questionTypeValue, levelValue, featuredValue, null)
        }

        // this.props.getQuestions(categoryValue, questionTypeValue, levelValue, featuredValue, name)
        // this.props.getAllQuestions(categoryValue, questionTypeValue, levelValue, featuredValue, name)
    }

    renderAllowedUser(name){
        this.props.saveAllowedUser(name.value)
        this.props.removeError(false)
    }

    removeErrorValue(){
        this.props.removeError(false)
    }

    cancelForm(){
        history.push('/quizesList')
    }

    render(){
        const { loading, handleSubmit, reset, submitting, pristine, categoryName, questionsList, educationalList, questionSet, totalQuestions, maximumQuestion, showNumberOfQuestions, randomQuestionvalue, allQuestionsList, timeLimitValue, newQuiz, accessTypeValue, generateButtonDisabled, showQuestions, tagListForFilter } = this.props
        console.log('tagListForFilter:: ',tagListForFilter)
        const regex = /(<([^>]+)>)/ig
        let quiz_id = localStorage.getItem('quiz_id')
        let quiz_category = []
        if(categoryName !== undefined){
            if(categoryName.length !== 0){
                categoryName.map(item => {
                    if(!item.isDeleted){
                        quiz_category.push({ 'label': item.name, 'value': item.name, 'id': item.id })
                    }
                })
            }
        }
        return(
            <form onSubmit={handleSubmit} onKeyPress={this.onKeyPress}>
                <Segment>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Label> Category </Label>
                                <Field name="category_name" component={ReduxFormSelect} isMulti={true} options={quiz_category} placeholder="Enter Category" onChange={this.renderCategory} />
                                <Label> Question Type </Label>
                                <Field name="question_type" component={ReduxFormSelect} isMulti={false} options={question_type} placeholder="Enter Type" onChange={this.renderQuestionType} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Button type="button" floated='right' onClick={this.clearFilters}>Clear Filter</Button>
                                <Label> Level </Label>
                                <Field name="level" component={ReduxFormSelect} isMulti={false} options={question_level} placeholder="Enter Level" onChange={this.renderLevel} />
                                <Label> Featured </Label>
                                <Field name="featured" component={RadioContainer} radio={false} option1="Yes" option2="No" show3Options={false} onChange={this.renderFeatured} />
                                <Label> Tags </Label>
                                <Field name="tag_name" component={ReduxFormSelect} isMulti={true} options={tagListForFilter} placeholder="Enter Tag" onChange={this.renderTag} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Field name="name" component={this.renderField} type="text" label="Quiz Name" className="required_field" onChange={this.removeErrorValue} />
                                <Label> Description </Label>
                                <Field name="description" component={TextAreaContainer} onChange={this.removeErrorValue} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Label> Eligibility </Label>
                                <Field name="eligibility" component={ReduxFormSelect} isMulti={false} options={educationalList} placeholder="Enter Eligibility" values="eligibility_value" newQuiz={newQuiz} onChange={this.removeErrorValue} />
                                <Label> Banner Image </Label>
                                <Field name="banner_image" folderName="Quiz" subFolderName="banner_images" maxWidth={300} maxHeight={190} component={UploadCropImage} onChange={this.removeErrorValue} Id={newQuiz} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Label> Duration </Label>
                                <div style={{ width: '50%'}}>  
                                    {timeLimitValue ?        
                                    <>            
                                    <Field name="minutes" component={ReduxFormSelect} isMulti={false} options={minutes} placeholder="Enter Minutes" onChange={this.removeErrorValue} />
                                    <br />
                                    </>
                                    : null}
                                    <Field name="required" component={CheckboxContainer} label="Time limit for the quiz" onChange={this.renderTimeLimit} />
                                </div>
                                <Field name="previous" component={CheckboxContainer} label="Previous Allowed" onChange={this.removeErrorValue} />
                                <Field name="next" component={CheckboxContainer} label="Next Allowed" onChange={this.removeErrorValue} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="required_field"> Access Type </Label>
                                <Field name="access_type" component={ReduxFormSelect} isMulti={false} options={access_type} placeholder="Enter Access Type" onChange={this.renderAccessType} />
                                {accessTypeValue === "Paid" ? 
                                <Field name="amount" component={this.renderField} type="number" label="Amount" onChange={this.removeErrorValue} />
                                : null}
                                <Field name="pausable" component={CheckboxContainer} label="Pausable Allowed" onChange={this.removeErrorValue}/>
                                <Field name="quiz_stop" component={CheckboxContainer} label="Quit Quiz" onChange={this.removeErrorValue} />
                                <Label className="required_field"> Allowed User </Label>
                                <Field name="allowed_user" component={ReduxFormSelect} isMulti={false} options={allowed_user} placeholder="Enter Access Type" onChange={this.renderAccessType} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left' className="random-label-section">
                                <Label className="required_field"> Generate Random Questions </Label>
                                <Field name="random_questions" component={RadioContainer} radio={false} option1="Generate Question from frontend" option2="Generate Question from backend" show3Options={false} onChange={this.renderRandomQuestions} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left' className="random-label-section">
                                <Label className=""> Status </Label>
                                <Field name="isToggle" component={StatusCheckebbox} radio={true} />
                            </Grid.Column>
                            {/* <Grid.Column width={8} textAlign='left' radio={false}></Grid.Column> */}
                        </Grid.Row>
                    </Grid>
                </Segment>
                {showNumberOfQuestions ? 
                <Segment>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Field name="number_of_questions" component={this.renderField} type="text" label="Number of Questions" onChange={this.handleChange} className="required_field" />
                                {totalQuestions !== null && totalQuestions <= maximumQuestion ? null : <h5 className="errorMsg"> Maximum Questions should be equal to less than {maximumQuestion} </h5>}
                                {totalQuestions !== null && allQuestionsList.length !== 0 && totalQuestions > allQuestionsList.length ? <h5 className="errorMsg"> Only {allQuestionsList.length} Questions can be added based on filter added </h5> : null}
                                {totalQuestions !== null && allQuestionsList.length === 0 && randomQuestionvalue ? <h5 className="errorMsg"> No Questions can be added based on filter added </h5> : null}
                                {totalQuestions !== '' && !randomQuestionvalue ? 
                                <>
                                    <Button type="button" disabled={generateButtonDisabled} onClick={this.generateQuestions}>Generate Automatic Questions</Button>
                                    {this.getChooseQuestionsModal()}
                                </>
                                : null}
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'> 
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                : null}
                {showQuestions ? 
                // questionsList !== undefined ? questionsList.length !== 0 ?
                questionSet !== undefined && questionSet.length !== 0 ?
                <Segment>
                    {/* {this.getModal()} */}
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16} textAlign='left'>
                                {questionSet.map((list, index) => {
                                    return(
                                        <Grid.Row key={list.id}>
                                            <span className="questions-format">{index + 1} &nbsp; {list.question.replace(regex, '')}</span>
                                        </Grid.Row>
                                    )
                                })}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                // : !randomQuestionvalue ? <Header as='h2' className="question-error">No Questions Found based on filter added</Header> : null : null
                : !randomQuestionvalue && !loading ? <Header as='h2' className="question-error">No Questions Found based on filter added</Header> :null :null}
                {quiz_id === "" || quiz_id === null ?
                    <div className='btns-container'>   
                        <Button size='large' primary disabled={loading || submitting}> Submit </Button>
                        <Button size='large' color="grey" type="button" disabled={pristine || submitting} onClick={this.resetForm} className="reset-button"> Reset </Button>           
                    </div>             
                    :<div className='btns-container'>
                        <Button size='large' primary disabled={loading}> Update </Button>
                        <Button size='large' color="grey" onClick={this.cancelForm} className="reset-button"> Cancel </Button>
                    </div>
                }
            </form>
        )
    }
}

const mapStateToProps = (state) => ({
    disabled: state.quizReducer.disabled,
    loading: state.quizReducer.loading,
    initialValues: state.quizReducer.viewQuizList,
    categoryName: state.quizCategoryReducer.categoryName,
    questionsList: state.quizReducer.questionsList,
    allQuestionsList: state.quizReducer.allQuestionsList,
    educationalList: state.helperReducer.educationalList,
    questionSet: state.quizReducer.questionSet,
    categoryValue: state.quizReducer.categoryValue,
    questionTypeValue: state.quizReducer.questionTypeValue,
    levelValue: state.quizReducer.levelValue,
    tagValue: state.quizReducer.tagValue,
    featuredValue: state.quizReducer.featuredValue,
    totalQuestions: state.quizReducer.totalQuestions,
    showNumberOfQuestions: state.quizReducer.showNumberOfQuestions,
    randomQuestionvalue: state.quizReducer.randomQuestionvalue,
    timeLimitValue: state.quizReducer.timeLimitValue,
    accessTypeValue: state.quizReducer.accessTypeValue,
    generateButtonDisabled: state.quizReducer.generateButtonDisabled,
    showQuestions: state.quizReducer.showQuestions,
    tagListForFilter: state.tagReducer.tagListForFilter
})

const mapDispatchToProps = {
    getAllCategoryName,
    getQuestions,
    getAllQuestions,
    getSavedQuestions,
    makeQuestionSet,
    setCategoryValue, 
    setQuestionTypeValue, 
    setLevelValue, 
    setTagValue,
    setFeaturedValue,
    saveTotalQuestions,
    showNoOfQuestions,
    randomQuestionValue,
    saveTimeLimit,
    generateRandomQuestions,
    saveAccessType,
    saveAllowedUser,
    showQuestionsData,
    saveCropImageData,
    removeCropImage,
    getTagList
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: 'simple', validate, enableReinitialize: true })(FormContainer))