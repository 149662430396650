import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR, REGISTER_SUCCESS, REGISTER_ERROR, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_ERROR, RESET_PASSWORD_REQUEST, RESET_PASSWORD_ERROR, RESET_PASSWORD_SUCCESS, CURRENT_USER_SUCCESS, CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_ERROR, GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_ERROR, VIEW_SINGLE_USER_REQUEST, VIEW_SINGLE_USER_SUCCESS, UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_ERROR, GET_CURRENT_USER_PROFILE_REQUEST, GET_CURRENT_USER_PROFILE_SUCCESS, UPDATE_USER_STATUS_REQUEST, UPDATE_USER_STATUS_SUCCESS, SEND_INVITATION_REQUEST, SEND_INVITATION_SUCCESS, SEND_INVITATION_ERROR, SAVE_ROLE, SAVE_COMPANY, SAVE_QUIZ_SET, SAVE_USER_QUIZ_REQUEST, SAVE_USER_QUIZ_SUCCESS, SAVE_USER_QUIZ_ERROR, GET_ASSIGNED_QUIZZES_REQUEST, GET_ASSIGNED_QUIZZES_SUCCESS, GET_ASSIGNED_QUIZZES_ERROR, UPDATE_USER_QUIZ_REQUEST, UPDATE_USER_QUIZ_SUCCESS, UPDATE_USER_QUIZ_ERROR, REMOVE_QUIZ_SET, UPDATE_BUTTON_DATA, DELETE_USER_SUCCESS } from '../constants/UserConstants'

const initialState = {
  loginerror: '',
  registererror: "",
  loggingIn: false,
  msg: "",
  profileError: "",
  passwordError: "",
  viewUser: {created_at:'', updated_at:'', first_name:'', last_name:'', email:'', role:'', company_name:'Webuters'},
  profileUserData: {created_at:'', updated_at:'', first_name:'', last_name:'', email:'', role:'', address:'', phone_number:''},
  error: '',
  loading: false,
  usersList: [],
  disabled: false,
  count: [],
  roleValue: '',
  quizSet: [],
  userDocId: '',
  updateButton: false
}

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loggingIn: !state.loggingIn
      }
    case LOGIN_SUCCESS:
      return {
        loggingIn: !state.loggingIn
      }
    case LOGIN_ERROR:
      return {
        loginerror: action.err
      }
    case REGISTER_SUCCESS:
      return{
        loginerror: ""
      }
    case REGISTER_ERROR:
      return{
        registererror: action.errors
      }
    case UPDATE_PROFILE_SUCCESS:
      return{
        ...state
      }
    case UPDATE_PROFILE_ERROR:
      return{
        profileError: action.errMsg
      }
    case RESET_PASSWORD_REQUEST:
      return{
        loggingIn: !state.loggingIn
      }
    case RESET_PASSWORD_SUCCESS:
      return{
        loggingIn: !state.loggingIn
      }
    case RESET_PASSWORD_ERROR:
      return{
        passwordError: action.errorMsg
      }
    case CURRENT_USER_SUCCESS:
      return{
        ...state,
        disabled: false,
        roleValue: '',
        viewUser: initialState.viewUser
      }
    case CREATE_USER_REQUEST:
      return{
        ...state,
        loading: true
      }
    case CREATE_USER_SUCCESS:
      return{
        ...state,
        loading: false
      }
    case CREATE_USER_ERROR:
      return{
        loading: false,
        error: action.err
      }
    case GET_USERS_REQUEST:
      return{
        ...state,
        loading: true
      }
    case GET_USERS_SUCCESS:
      return{
        ...state,
        loading: false,
        usersList: action.userList,
        disabled: false,
        count: action.count,
        error: ""
      }
    case GET_USERS_ERROR:
      return{
        loading: false,
        error: action.err
      }
    case VIEW_SINGLE_USER_REQUEST:
      return{
        ...state,
        loading: true,
      }
    case VIEW_SINGLE_USER_SUCCESS:
      return{
        ...state,
        viewUser: action.obj,
        loading: false,
        disabled: true,
        roleValue: action.obj.role.value
      }
    case UPDATE_USER_REQUEST:
      return{
        ...state,
        loading: true
      }
    case UPDATE_USER_SUCCESS:
      return{
        ...state,
        loading: false
      }
    case UPDATE_USER_ERROR:
      return{
        loading: false,
        error: action.err
      }
    case GET_CURRENT_USER_PROFILE_REQUEST:
      return{
        ...state,
        loading: true
      }
    case GET_CURRENT_USER_PROFILE_SUCCESS:
      return{
        ...state,
        loading: false,
        profileUserData: action.currentUserList
      }
    case UPDATE_USER_STATUS_REQUEST:
      return{
        ...state,
        loading: true
      }
    case UPDATE_USER_STATUS_SUCCESS:
      return{
        ...state,
        loading: false
      }
    case SEND_INVITATION_REQUEST:
      return{
        ...state,
        loading: true
      }
    case SEND_INVITATION_SUCCESS:
      return{
        ...state,
        loading: false,
        disabled: true
      }
    case SEND_INVITATION_ERROR:
      return{
        loading: false,
        error: action.err
      }
    case SAVE_ROLE:
      return{
        ...state,
        roleValue: action.value
      }
    case SAVE_COMPANY:
      let obj = state.viewUser
      obj.company_name = action.value
      return{
        ...state,
        viewUser: obj
      }
    case SAVE_QUIZ_SET:
      return{
        ...state,
        quizSet: state.quizSet.concat(action.value)
      }
    case SAVE_USER_QUIZ_REQUEST:
      return{
        ...state,
        loading: true
      }
    case SAVE_USER_QUIZ_SUCCESS:
      return{
        ...state,
        loading: false
      }
    case SAVE_USER_QUIZ_ERROR:
      return{
        ...state,
        loading: false,
        error: action.err
      }
    case GET_ASSIGNED_QUIZZES_REQUEST:
      return{
        ...state,
        loading: true
      }
    case GET_ASSIGNED_QUIZZES_SUCCESS:
      return{
        ...state,
        loading: false,
        quizSet: action.newList,
        userDocId: action.docId,
        updateButton: true
      }
    case GET_ASSIGNED_QUIZZES_ERROR:
      return{
        ...state,
        loading: false,
        error: action.err
      }
    case UPDATE_USER_QUIZ_REQUEST:
      return{
        ...state,
        loading: true
      }
    case UPDATE_USER_QUIZ_SUCCESS:
      return{
        ...state,
        loading: false
      }
    case UPDATE_USER_QUIZ_ERROR:
      return{
        ...state,
        loading: false,
        error: action.err
      }
    case REMOVE_QUIZ_SET:
      return{
        ...state,
        quizSet: []
      }
    case UPDATE_BUTTON_DATA:
      return{
        ...state,
        updateButton: action.value
      }
    case DELETE_USER_SUCCESS:
      return{
        ...state,
        usersList: state.usersList.filter(category => category.id !== action.id)
      }
    default:
      return state
  }
} 

export default authentication;