import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Grid, Form } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form'
import classnames from 'classnames'
import Label from '../library/Label'
import Input from '../library/Input'
import ReactLoading from "react-loading"

const validate = (values) => {
    const errors = {errorName:{}};
    if(!values.name) {
      errors.name = {
        message: 'This field is Required'
      }
    }
    if(!values.display_name){
        errors.display_name = {
            message: 'This field is Required'
        }
    }
    return errors
}

class EducationForm extends Component {
    renderField({ input, label, type, meta: { touched, error }, disabled, className }) {
        return (
            <Form.Field className={classnames({error:touched && error})}>
                <Label className={className}>{label} </Label>
                <Input {...input} placeholder={label} type={type} disabled={disabled} />
                {touched && error && <span className="errorMsg">{error.message}</span>}
            </Form.Field>
        )
    }

    render(){
        const { loading, handleSubmit, reset, submitting, pristine, disabled, id } = this.props
        return(
            <form onSubmit={handleSubmit}>
                {loading ? <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                <Grid className="addExam-container">
                    <Grid.Row>
                        <Grid.Column width={8} textAlign='left'>
                            <Field name="display_name" component={this.renderField} type="text" label="Display Name" className="required_field" />
                            <Field name="rank" component={this.renderField} type="text" label="Rank" />
                            {id === "" || id === null || id === undefined ?
                                <Grid.Row className="btn-group"> 
                                    <Grid.Column width={6}>
                                        <Button size='large' primary disabled={loading || submitting}> Submit </Button>
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        <Button size='large' color="grey" type="button" disabled={pristine || submitting} onClick={reset} className="reset-button"> Reset </Button>
                                    </Grid.Column>
                                </Grid.Row>
                                :<Grid.Row className="btn-group"> 
                                    <Grid.Column width={6}>
                                        <Button size='large' primary disabled={loading}> Update </Button>
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        <Button size='large' color="grey" onClick={reset} className="reset-button"> Cancel </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            }
                        </Grid.Column>
                        <Grid.Column width={8} textAlign='left'>
                            <Field name="name" component={this.renderField} type="text" disabled={disabled} label=" Education Name" className="required_field" />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </form>
        )
    }
}

const mapStateToProps = (state) => ({
    disabled: state.educationReducer.disabled,
    loading: state.educationReducer.loading,
    initialValues: state.educationReducer.viewEducationList
})

export default connect(mapStateToProps)(reduxForm({form: 'simple', validate, enableReinitialize: true })(EducationForm))