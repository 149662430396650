import React, { useEffect, useState }  from 'react'
import { connect } from 'react-redux'
import Header from '../Header/Header'
import { Link } from 'react-router-dom'
import ReactLoading from "react-loading"
import { Button, Confirm, Icon, Input, Table } from 'semantic-ui-react'
import { authHeader, history } from '../../_helpers'
import { getSubjectList, deleteSubjectData, updateSubjectData, addSubjectData } from '../../actions/SubjectActions'
import Pagination from 'react-js-pagination'
import { itemsCountPerPage, pageRangeDisplayed, pageSize } from '../../constants/HelperConstants'
import _ from 'lodash'

const SubjectContainer = (props) => {
    let pathname = props.location.pathname
    const { getSubjectList, subjectList, loading, error, deleteSubjectData, addSubjectData, updateSubjectData } = props
    const [deleteValue, setDeleteValue] = useState(true)
    const [open, setOpen] = useState(true)
    const [currentSubject, setCurrentSubject] = useState(null)
    const [editing, setEditing] = useState(false)
    const [currentItem, setCurrentItem] = useState('')
    const [newValue, setNewValue] = useState('')
    const [name, setName] = useState('')
    const [activePage, setActivePage] = useState(1)
    const [nameError, setNameError] = useState('')
    const [updateNameError, setUpdateNameError] = useState('')
    let length
    const indexOfLastTodo = activePage * itemsCountPerPage
    const indexOfFirstTodo = indexOfLastTodo - itemsCountPerPage
    const currentTodos = subjectList.map((data) => {
        length = data.name.length
        return data.name.slice(indexOfFirstTodo, indexOfLastTodo)
    })

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getSubjectList()
            }
            else{
                history.push('/login') 
            }
        }
    }, [getSubjectList, pathname])

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    }

    const deleteSubject = (value) => {
        setDeleteValue(false)
        setOpen(true)
        setCurrentSubject(value)
    }

    const confirmDelete = (value) => {
        return (
            <Confirm open={open}
                    content='Are you sure want to delete this Subject?'
                    onCancel={close}
                    className="delete-popup"
                    cancelButton='No, cancel it!'
                    confirmButton="Yes, delete it!"
                    onConfirm={() => renderDeleteSubject(value)} />
        )
    }

    const close = () => {
        setOpen(false)
        setDeleteValue(true)
    }

    const renderDeleteSubject = (value) => {
        deleteSubjectData(value)
        setOpen(false)
        getSubjectList()
    }

    const editName = (item) => {
        setEditing(true)
        setCurrentItem(item)
        setNewValue(item)
    }

    const nameChange = (event) => {
        const target = event.target
        const value = target.value
        setNewValue(value)
        setUpdateNameError('')
    }

    const updateSubject = () => {
        if(!_.isEmpty(newValue)){
            updateSubjectData(currentItem, newValue)
            setEditing(false)
            getSubjectList()
        }
        else{
            setUpdateNameError('Please Enter Subject Name')
        }
    }

    const cancelNameChange = () => {
        setEditing(false)
        setCurrentItem('')
    }

    const getTableBody = () => {
        let serialNo = ((activePage - 1) * pageSize) + 1
        return(
            currentTodos.map((data, listIndex) => {
                return(
                    <Table.Body key={listIndex}>
                        {data.map((item, index) => {
                            return(
                                <Table.Row key={index} className="form-data">
                                    <Table.Cell> {serialNo + index} </Table.Cell>
                                    <Table.Cell> 
                                        {editing && currentItem === item  ? 
                                        <>
                                            <Input type="string"
                                                   value={newValue}
                                                   placeholder="Enter Name"
                                                   onChange={(event) => nameChange(event)} /> 
                                            {updateNameError !== '' ? <h5 className="errorMsg"> {updateNameError} </h5> : null} 
                                        </>
                                        : item} 
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button icon className="edit-btn" onClick={() => editName(item)}>
                                            <Icon name='edit' />
                                        </Button>
                                        <Button icon onClick={() => deleteSubject(item)} className="delete-btn">
                                            <Icon name='trash' />
                                        </Button>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {editing && currentItem === item ?
                                        <div>
                                            <Button primary onClick={() => updateSubject()}> Update </Button>
                                            <Button onClick={() => cancelNameChange()} className="reset-button"> Cancel </Button>
                                        </div>: null}
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                )
            })
        )
    }

    const getTable = () => {
        return(
            <Table basic='very'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell> S.No </Table.HeaderCell>
                        <Table.HeaderCell> Name </Table.HeaderCell>
                        <Table.HeaderCell> Actions </Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {getTableBody()}
                {deleteValue === false ? confirmDelete(currentSubject) : null}
            </Table>
        )
    }

    const handleChange = (event) => {
        const target = event.target
        const value = target.value
        setName(value)
        setNameError('')
    }

    const handleSubmit = () => {
        if(!_.isEmpty(name)){
            addSubjectData(name)
            setName('')
            getSubjectList()
        }
        else{
            setNameError('Please Enter Subject Name')
        }
    }

    return(
        <div className="wrapper">
            <div className="container">
                <Header />
                <Link className="link-container" to="/dashboard">
                    <Icon name="arrow left" />
                    Back to Dashboard
                </Link>
                {loading ? <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                {error !== "" ? <h3 className="errorMsg"> {error} </h3> : null}
                <div className="add-subject">
                    <Input placeholder="Enter Subject Name" type="text" value={name} onChange={(event) => handleChange(event)} />
                    {nameError !== '' ? <h5 className="errorMsg"> {nameError} </h5> : null}
                    <Button primary onClick={handleSubmit}> Submit </Button>
                </div>
                {subjectList !== undefined ? subjectList.length !== 0 ? getTable() : <h3> No Data </h3> :null}
                {error === "" ?
                    <Pagination activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={length}
                                pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange} />
                : error}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    subjectList: state.subjectReducer.subjectList,
    loading: state.subjectReducer.loading,
    error: state.subjectReducer.error
})

const mapDispatchToProps = {
    getSubjectList,
    deleteSubjectData,
    updateSubjectData,
    addSubjectData
}

export default connect(mapStateToProps, mapDispatchToProps)(SubjectContainer)