import _ from 'lodash'

const validate = (values) => {
    const errors = {errorName:{}};
    if(!values.question){
        errors.question = {
            message: 'This field is Required'
        }
    }
    if(!values.question_tags) {
        errors.question_tags = {
          message: 'This field is Required'
        }
    }
    if(!values.question_category) {
        errors.question_category = {
          message: 'This field is Required'
        }
    }
    if(!values.level) {
        errors.level = {
          message: 'This field is Required'
        }
    }
    if(!values.featured) {
        errors.featured = {
          message: 'This field is Required'
        }
    }
    if(!values.status) {
        errors.status = {
          message: 'This field is Required'
        }
    }
    if(!values.option_value1){
        errors.option_value1 = 'This field is Required'
    }
    if(!values.option_value2){
        errors.option_value2 = 'This field is Required'
    }
    if(!values.option_value3){
        errors.option_value3 = 'This field is Required'
    }
    if(!values.option_value4){
        errors.option_value4 = 'This field is Required'
    }
    if(!values.answer){
        errors.answer = 'This field is Required'
    }
    if(!values.option_value5 && values.answer === "option_value5"){
        errors.option_value5 = 'This field is Required or else select any other answer'
    }
    if(values.option_value1 === values.option_value2 || values.option_value1 === values.option_value3 || values.option_value1 === values.option_value4 || values.option_value1 === values.option_value5){
        errors.option_value1 = 'Option 1 value cannot be same'
    }
    if(values.option_value2 === values.option_value3 || values.option_value2 === values.option_value4 || values.option_value2 === values.option_value5){
        errors.option_value2 = 'Option 2 value cannot be same'
    }
    if(values.option_value3 === values.option_value4 || values.option_value3 === values.option_value5){
        errors.option_value3 = 'Option 3 value cannot be same'
    }
    if(values.option_value4 === values.option_value5 ){
        errors.option_value4 = 'Option 4 value cannot be same'
    }
    if(!values.question_type){
        errors.question_type = {
            message: 'This field is Required'
        }
    }
    if(!values.options) {
        errors.options = {
          message: 'This field is Required'
        }
    }
    if(values.upload_answer){
        if(_.isEmpty(values.answer_doc_url)){
            if(values.answer_doc_url.name === undefined){
                errors.answer_doc_url = {
                    message: 'This field is Required'
                }
            }
        }
    }
    return errors 
}

export default validate