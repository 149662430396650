export const GET_CATEGORY_LIST_REQUEST = 'GET_CATEGORY_LIST_REQUEST'
export const GET_CATEGORY_LIST_SUCCESS = 'GET_CATEGORY_LIST_SUCCESS'
export const GET_CATEGORY_LIST_ERROR = 'GET_CATEGORY_LIST_ERROR'

export const CURRENT_CATEGORY = 'CURRENT_CATEGORY'

export const ADD_CATEGORY_REQUEST = 'ADD_CATEGORY_REQUEST'
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS'
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR'

export const DELETE_CATEGORY_DATA_REQUEST = 'DELETE_CATEGORY_DATA_REQUEST'
export const DELETE_CATEGORY_DATA_SUCCESS = 'DELETE_CATEGORY_DATA_SUCCESS'

export const VIEW_SINGLE_CATEGORY_DATA_REQUEST = 'VIEW_SINGLE_CATEGORY_DATA_REQUEST'
export const VIEW_SINGLE_CATEGORY_DATA_SUCCESS = 'VIEW_SINGLE_CATEGORY_DATA_SUCCESS'

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST'
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR'