import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Header from '../Header/Header'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { authHeader, history } from '../../_helpers'
import UserForm from './UserForm'
import ReactLoading from "react-loading"
import { currentUser, createUser, viewSingleUserData, updateUser } from '../../actions/UserActions'
import _ from 'lodash'

const AddUser = (props) => {
    const { pathname } = props.location
    const { currentUser, createUser, error, loading, viewSingleUserData, updateUser } = props

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
            }
            else{
                history.push('/login') 
            }
        }
        if(props.match && props.match.params && props.match.params.id){
            const newUser = props.match.params.id
            viewSingleUserData(newUser)
        }
        else{
            currentUser()
        }
    },[pathname, props.match, currentUser, viewSingleUserData])

    const handleSubmit = (data) => {
        if(props.match.params.id === undefined){
            data['created_at'] = new Date()
            data['updated_at'] = new Date()
            data['status'] = 'Active'
            data['invite'] = false
            // data.role = data.role.value
            data['displayName'] = _.capitalize(data.first_name) + ' ' + _.capitalize(data.last_name)
            data['isDeleted'] = false
            createUser(data)
        }
        else{
            data['updated_at'] = new Date()
            data['displayName'] = _.capitalize(data.first_name) + ' ' + _.capitalize(data.last_name)
            let id = props.match.params.id
            updateUser(data, id) 
        }
    }

    return(
        <div className="wrapper">
            <div className="container">
                {error !== "" ? <h5 className="errorMsg"> {error} </h5>: null}
                {loading ?  <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                <Header />
                <Link className="link-container" to="/users"> <Icon name="arrow left" /> Back to User List </Link>
                <UserForm onSubmit={handleSubmit} id = {props.match.params.id} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.userReducer.loading,
    error: state.userReducer.error
})

const mapDispatchToProps = {
    currentUser,
    createUser,
    viewSingleUserData,
    updateUser
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUser)