import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Header from '../Header/Header'
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import EducationForm from './EducationForm'
import { authHeader, history } from '../../_helpers'
import { db } from '../firebase'
import ReactLoading from "react-loading"
import { currentEducation, addEducation, viewSingleEducation, updateEducation } from '../../actions/EducationActions'

const AddEducation = (props) => {
    const { pathname } = props.location
    const { currentEducation, addEducation, viewSingleEducation, updateEducation, error, loading } = props

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
            }
            else{
                history.push('/login') 
            }
        }
        if(props.match && props.match.params && props.match.params.id){
            let id = props.match.params.id
            viewSingleEducation(id)
        }
        else{
            currentEducation()
        }
    },[pathname, props.match, viewSingleEducation, currentEducation])

    const handleSubmit = (data) => {
        let uid = localStorage.getItem('uid')
        let id = props.match.params.id
        if(id === undefined){
            data['created_by'] = db.doc('users/' + uid)
            data['modified_by'] = db.doc('users/' + uid)
            data['created_date_time'] = new Date()
            data['modified_date_time'] = new Date()
            data['isDeleted'] = false
            addEducation(data)
        }
        else{
            data['modified_date_time'] = new Date()
            updateEducation(data, id)
        }
    }

    return(
        <div className="wrapper">
            <div className="container">
                {error !== "" ? <h5 className="errorMsg"> {error} </h5>: null}
                {loading ?  <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                <Header />
                <Link className="link-container" to="/educationalQualificationList"> <Icon name="arrow left" /> Back to Education List </Link>
                <EducationForm onSubmit={handleSubmit} id={props.match.params.id} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.educationReducer.loading,
    error: state.educationReducer.error
})

const mapDispatchToProps = {
    currentEducation,
    addEducation,
    viewSingleEducation,
    updateEducation
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEducation)