import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { Checkbox } from 'semantic-ui-react'

const CheckboxContainer = (props) => {
    const { input, meta: { touched, error }, label } = props
    const { value } = input
    const [checkedValue, setCheckedValue] = useState()

    useEffect(() => {
        if(value === ""){
            setCheckedValue(false)
        }
        else{
            setCheckedValue(value)
        }
    },[value])

    const handleCheckboxChange = () => {
        input.onChange(!value)
        setCheckedValue(!value)
    }

    return(
        <div className="checkbox-container">
            <Checkbox onChange={() => handleCheckboxChange()}
                      className={classnames({error:touched && error})}
                      label={label}
                      checked={checkedValue} />
            <div>
                {touched && error && <span className="errorMsg">{error.message}</span>}
            </div>
        </div>
    )
        
}

export default CheckboxContainer