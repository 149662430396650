import { GET_CATEGORY_LIST_REQUEST, GET_CATEGORY_LIST_SUCCESS, GET_CATEGORY_LIST_ERROR, CURRENT_CATEGORY, ADD_CATEGORY_REQUEST, ADD_CATEGORY_SUCCESS, ADD_CATEGORY_ERROR, VIEW_SINGLE_CATEGORY_DATA_REQUEST, VIEW_SINGLE_CATEGORY_DATA_SUCCESS, DELETE_CATEGORY_DATA_SUCCESS, UPDATE_CATEGORY_REQUEST, UPDATE_CATEGORY_SUCCESS, UPDATE_CATEGORY_ERROR } from '../constants/CategoryConstants'

const initialState = {
    loading: false,
    viewSingleList: {id: '', name:"", display_name:"", createdBy:"", createdDateTime:"", modifiedBy:"", modifiedDateTime:""},
    categoryList: [],
    error: '',
    disabled: false
}

const Category = (state = initialState, action) => {
    switch(action.type){
        case GET_CATEGORY_LIST_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_CATEGORY_LIST_SUCCESS:
            return{
                ...state,
                loading: false,
                categoryList: action.list
            }
        case GET_CATEGORY_LIST_ERROR:
            return{
                error: action.err
            }
        case CURRENT_CATEGORY:
            return{
                ...state,
                disabled: false,
                viewSingleList: initialState.viewSingleList
            }
        case ADD_CATEGORY_REQUEST:
            return{
                ...state,
                loading: true
            }
        case ADD_CATEGORY_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case ADD_CATEGORY_ERROR:
            return{
                ...state,
                error: action.err
            }
        case VIEW_SINGLE_CATEGORY_DATA_REQUEST:
            return{
                ...state,
                loading: true
            }
        case VIEW_SINGLE_CATEGORY_DATA_SUCCESS:
            return{
                ...state,
                loading: false,
                disabled: true,
                viewSingleList: action.newList
            }
        case DELETE_CATEGORY_DATA_SUCCESS:
            let array = []
            state.categoryList.filter(list => {
                list.Name.filter(item => {
                    if(item.id !== action.id){
                        array.push(item)
                    }
                })
            })
            let newArr = [{
                'Name': array
            }]
            return{
                ...state,
                categoryList: newArr
            }
        case UPDATE_CATEGORY_REQUEST:
            return{
                ...state,
                loading: true
            }
        case UPDATE_CATEGORY_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case UPDATE_CATEGORY_ERROR:
            return{
                err: action.err
            }
        default:
            return state
    }
}

export default Category