import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Confirm, Icon, Table } from 'semantic-ui-react'
import Header from '../Header/Header'
import ReactLoading from "react-loading"
import { authHeader, history } from '../../_helpers'
import { getCategoryList, deleteCategoryData } from '../../actions/CategoryActions'

const CategoryList = (props) => {
    let pathname = props.location.pathname
    const { getCategoryList, categoryList, loading, deleteCategoryData, error } = props
    const [deleteValue, setDeleteValue] = useState(true)
    const [open, setOpen] = useState(true)
    const [currentUserId, setCurrentUserId] = useState(null)
    
    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getCategoryList()
            }
            else{
                history.push('/login') 
            }
        }
    }, [getCategoryList, pathname])

    const deleteCategory = (id) => {
        setDeleteValue(false)
        setOpen(true)
        setCurrentUserId(id)
    }

    const confirmDelete = (id) => {
        return (
            <Confirm open={open}
                    content='Are you sure want to delete this Category?'
                    onCancel={close}
                    className="delete-popup"
                    cancelButton='No, cancel it!'
                    confirmButton="Yes, delete it!"
                    onConfirm={() => renderDeleteCategory(id)} />
        )
    }

    const close = () => {
        setOpen(false)
        setDeleteValue(true)
    }

    const renderDeleteCategory = (id) => {
        deleteCategoryData(id)
        setOpen(false)
    }

    const getTableBody = () => {
        return(
            categoryList.map((value, index) => {
                return(
                    <Table.Body key={index}>
                        {value.Name.map((data, indexValue) => {
                            return(
                                <Table.Row key={indexValue} className="form-data">
                                    <Table.Cell> {indexValue + 1} </Table.Cell>
                                    <Table.Cell> {data.name} </Table.Cell>
                                    <Table.Cell> {data.display_name} </Table.Cell>
                                    <Table.Cell>
                                        <Link to={{ pathname: `/editcategory/${data.id}`}}>
                                            <Button icon className="edit-btn">
                                                <Icon name='edit' />
                                            </Button>
                                        </Link>
                                        <Button icon onClick={() => deleteCategory(data.id)} className="delete-btn">
                                            <Icon name='trash' />
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                )
            })
        )
    }

    const getTable = () => {
        return(
            <Table basic='very'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell> S.No </Table.HeaderCell>
                        <Table.HeaderCell> Category name </Table.HeaderCell>
                        <Table.HeaderCell> Display Name </Table.HeaderCell>
                        <Table.HeaderCell> Actions </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {getTableBody()}
                {deleteValue === false ? confirmDelete(currentUserId) : null}
            </Table>
        )
    }

    return(
        <div className="wrapper">
            <div className="container">
                <Header />
                <Link className="link-container" to="/dashboard">
                    <Icon name="arrow left" />
                    Back to Dashboard
                </Link>
                <Link to="/addcategory">
                    <Button primary floated='right' className="add-btn">
                        <Icon name="plus" />
                        Add Category
                    </Button>
                </Link>
                {loading ? <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                {error !== "" ? <h3 className="errorMsg"> {error} </h3> : null}
                {categoryList !== undefined ? categoryList.length !== 0 ? (
                    <div>
                        {categoryList.map((list, index) => {
                            if(list.Name.length === 0){
                                return <h3 key={index}> No Data </h3>
                            }
                            else{
                                return <div key={index}> {getTable()} </div>
                            }
                        })}
                    </div>
                ) : <h3> No Data </h3> :null}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    categoryList: state.categoryReducer.categoryList,
    loading: state.categoryReducer.loading,
    error: state.categoryReducer.error
})

const mapDispatchToProps = {
    getCategoryList,
    deleteCategoryData
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList)