export const EXAM_DATA_REQUEST = 'EXAM_DATA_REQUEST'
export const EXAM_DATA_SUCCESS = 'EXAM_DATA_SUCCESS'
export const EXAM_DATA_ERROR = 'EXAM_DATA_ERROR'

export const VIEW_SINGLE_EXAM_REQUEST = 'VIEW_SINGLE_FORM_REQUEST'
export const VIEW_SINGLE_EXAM_SUCCESS = 'VIEW_SINGLE_FORM_SUCCESS'

export const ADD_EXAM_DATA = 'ADD_EXAM_DATA'
export const ADD_EXAM_DATA_SUCCESS = 'ADD_EXAM_DATA_SUCCESS'
export const ADD_EXAM_ERROR = 'ADD_EXAM_ERROR'

export const UPDATE_EXAM_DATA = 'UPDATE_EXAM_DATA'
export const UPDATE_EXAM_DATA_SUCCESS = 'UPDATE_EXAM_DATA_SUCCESS'
export const UPDATE_EXAM_DATA_ERROR = 'UPDATE_EXAM_DATA_ERROR'

export const DELETE_EXAM_DATA = 'DELETE_EXAM_DATA'
export const DELETE_EXAM_DATA_SUCCESS = 'DELETE_EXAM_DATA_SUCCESS'

export const CURRENT_EXAM_REQUEST = 'CURRENT_EXAM_REQUEST'
export const CURRENT_EXAM_SUCCESS = 'CURRENT_EXAM_SUCCESS'

export const UPDATE_EXAM_STATUS_REQUEST = 'UPDATE_EXAM_STATUS_REQUEST'
export const UPDATE_EXAM_STATUS_SUCCESS = 'UPDATE_EXAM_STATUS_SUCCESS'