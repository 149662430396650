export const degree=[
    {value: 'Regular Degree', label: 'Regular Degree'},
    {value: 'Distance', label: 'Distance'}
]

export const duration=[
    {value: '2 Years', label: '2 Years'},
    {value: '3 Years', label: '3 Years'},
    {value: '4 Years', label: '4 Years'}
]

export const tags = [
    {value: 'Part Time', label: 'Part Time'},
    {value: 'Full Time', label: 'Full Time'},
    {value: 'Distance Learning', label: 'Distance Learning'}
]