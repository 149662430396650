export const GET_COURSE_LIST_REQUEST = 'GET_COURSE_LIST_REQUEST'
export const GET_COURSE_LIST_SUCCESS = 'GET_COURSE_LIST_SUCCESS'
export const GET_COURSE_LIST_ERROR = 'GET_COURSE_LIST_ERROR'

export const CURRENT_COURSE_REQUEST = 'CURRENT_COURSE_REQUEST'
export const CURRENT_COURSE_SUCCESS = 'CURRENT_COURSE_SUCCESS'

export const ADD_COURSE_REQUEST = 'ADD_COURSE_REQUEST'
export const ADD_COURSE_SUCCESS = 'ADD_COURSE_SUCCESS'
export const ADD_COURSE_ERROR = 'ADD_COURSE_ERROR'

export const DELETE_COURSE_DATA_REQUEST = 'DELETE_COURSE_DATA_REQUEST'
export const DELETE_COURSE_DATA_SUCCESS = 'DELETE_COURSE_DATA_SUCCESS'

export const VIEW_SINGLE_COURSE_DATA_REQUEST = 'VIEW_SINGLE_COURSE_DATA_REQUEST'
export const VIEW_SINGLE_COURSE_DATA_SUCCESS = 'VIEW_SINGLE_COURSE_DATA_SUCCESS'

export const UPDATE_COURSE_REQUEST = 'UPDATE_COURSE_REQUEST'
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS'
export const UPDATE_COURSE_ERROR = 'UPDATE_COURSE_ERROR'

export const ADD_COURSE_DETAILS_REQUEST = 'ADD_COURSE_DETAILS_REQUEST'
export const ADD_COURSE_DETAILS_SUCCESS = 'ADD_COURSE_DETAILS_SUCCESS'
export const ADD_COURSE_DETAILS_ERROR = 'ADD_COURSE_DETAILS_ERROR'

export const VIEW_COURSE_DETAILS_REQUEST = 'VIEW_COURSE_DETAILS_REQUEST'
export const VIEW_COURSE_DETAILS_SUCCESS = 'VIEW_COURSE_DETAILS_SUCCESS'

export const UPDATE_COURSE_STATUS_REQUEST = 'UPDATE_COURSE_STATUS_REQUEST'
export const UPDATE_COURSE_STATUS_SUCCESS = 'UPDATE_COURSE_STATUS_SUCCESS'

export const GET_ALL_COURSE_NAME_REQUEST = 'GET_ALL_COURSE_NAME_REQUEST'
export const GET_ALL_COURSE_NAME_SUCCESS = 'GET_ALL_COURSE_NAME_SUCCESS'
export const GET_ALL_COURSE_NAME_ERROR = 'GET_ALL_COURSE_NAME_ERROR'

export const SAVE_COURSE_CROP_IMAGE_DATA = 'SAVE_COURSE_CROP_IMAGE_DATA'

export const SECTION_COURSE_IMAGE_EXISTS = 'SECTION_COURSE_IMAGE_EXISTS'

export const DELETE_COURSE_IMAGE_REQUEST = 'DELETE_COURSE_IMAGE_REQUEST'
export const DELETE_COURSE_IMAGE_SUCCESS = 'DELETE_COURSE_IMAGE_SUCCESS'
export const DELETE_COURSE_IMAGE_ERROR = 'DELETE_COURSE_IMAGE_ERROR'