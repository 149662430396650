import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Grid, Header, Form, Icon, Segment } from 'semantic-ui-react'
import { Field, reduxForm, FieldArray } from 'redux-form'
import classnames from 'classnames'
import Label from '../../library/Label'
import Input from '../../library/Input'
import ReduxFormSelect from '../../library/select/ReduxFormSelect'
import TextAreaContainer from '../../library/textarea/TextAreaContainer'
import ImageContainer from '../../library/image/ImageContainer'
import { tags } from '../../docs/courseData'
import validate from './validate'
import ReactLoading from "react-loading"
import { viewCourseDetails } from '../../../actions/courseActions'

class CourseForm extends Component{
    componentDidMount(){
        const { id } = this.props
        this.props.viewCourseDetails(id)
    }

    renderField({ input, label, placeholder, type, meta: { touched, error }, className }) {
        return (
            <Form.Field className={classnames({error:touched && error})}>
                <Label className={className}>{label} </Label>
                <Input {...input} placeholder={placeholder} type={type} />
                {touched && error && <span className="errorMsg">{error.message}</span>}
            </Form.Field>
        )
    }

    renderLevel({ input, placeholder, type, meta: { touched, error } }){
        return(
            <div className="level-container">
                <input {...input} type={type} placeholder={placeholder} />
                {touched && error && <div className="errorMsg">{error}</div>}
            </div>
        )
    }

    renderOtherOptions({ fields, renderLevel }){
        const { name } = fields
        let newName = name
        return(
            <div>
                <div className="levels-container">
                    <label> Key </label>
                    <Field
                        name='other_options_key'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Key"
                    />
                    <label> Value </label>
                    <Field
                        name='other_options_value'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Value"
                    />
                </div>
                {newName === "other_options" && fields.length <= 2 ? <Icon name="plus" className="add-level-icon add-levels" onClick={() => fields.push({})} /> : null}
                {fields.map((option, index) => (
                    <div key={index}>
                        <Field
                            name={`${option}.key`}
                            type="text"
                            component={renderLevel}
                            placeholder="Enter Key"
                        />
                        <Field
                            name={`${option}.value`}
                            type="text"
                            component={renderLevel}
                            placeholder="Enter Value"
                        />
                        <Icon name="minus" className="minus-level-icon" onClick={() => fields.remove(index)} />
                    </div>
                ))}
            </div>
        )
    }

    renderHighlights({ fields, renderLevel }){
        const { name } = fields
        let newName = name
        return(
            <div>
                <div className="levels-container">
                    <label> Key </label>
                    <Field
                        name='highlights_key'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Key"
                    />
                    <label> Value </label>
                    <Field
                        name='highlights_value'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Value"
                    />
                </div>
                {newName === "highlights" && fields.length <= 6 ? <Icon name="plus" className="add-level-icon add-levels" onClick={() => fields.push({})} /> : null}
                {fields.map((highlight, index) => (
                    <div key={index}>
                        <Field
                            name={`${highlight}.key`}
                            type="text"
                            component={renderLevel}
                            placeholder="Enter Key"
                        />
                        <Field
                            name={`${highlight}.value`}
                            type="text"
                            component={renderLevel}
                            placeholder="Enter Value"
                        />
                        <Icon name="minus" className="minus-level-icon" onClick={() => fields.remove(index)} />
                    </div>
                ))}
            </div>
        )
    }

    renderHowToApply({ fields, renderLevel }){
        const { name } = fields
        let newName = name
        return(
            <div>
                <div className="levels-container">
                    <label> Key </label>
                    <Field
                        name='how_to_apply_key'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Key"
                    />
                    <label> Value </label>
                    <Field
                        name='how_to_apply_value'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Value"
                    />
                </div>
                {newName === "how_to_apply" && fields.length <= 2 ? <Icon name="plus" className="add-level-icon add-levels" onClick={() => fields.push({})} /> : null}
                {fields.map((apply, index) => (
                    <div key={index}>
                        <Field
                            name={`${apply}.key`}
                            type="text"
                            component={renderLevel}
                            placeholder="Enter Key"
                        />
                        <Field
                            name={`${apply}.value`}
                            type="text"
                            component={renderLevel}
                            placeholder="Enter Value"
                        />
                        <Icon name="minus" className="minus-level-icon" onClick={() => fields.remove(index)} />
                    </div>
                ))}
            </div>
        )
    }

    renderEntranceExam({ fields, renderLevel }){
        const { name } = fields
        let newName = name
        return(
            <div>
                <Field
                    name='entrance_exam_key'
                    type="text"
                    component={renderLevel}
                    placeholder="Enter Name"
                />
                {newName === "entrance_exam_name" && fields.length <= 1 ? <Icon name="plus" className="add-level-icon" onClick={() => fields.push({})} /> : null}
                {fields.map((entrance, index) => (
                    <div key={index}>
                        <Field
                            name={`${entrance}.key`}
                            type="text"
                            component={renderLevel}
                            placeholder="Enter Name"
                        />
                        <Icon name="minus" className="minus-level-icon" onClick={() => fields.remove(index)} />
                    </div>
                ))}
            </div>
        )
    }

    renderCareerOptions({ fields, renderLevel }){
        const { name } = fields
        let newName = name
        return(
            <div>
                <Field
                    name='career_options_key'
                    type="text"
                    component={renderLevel}
                    placeholder="Enter Job Opportunity"
                />
                {newName === "career_options" && fields.length <= 10 ? <Icon name="plus" className="add-level-icon" onClick={() => fields.push({})} /> : null}
                {fields.map((career, index) => (
                    <div key={index}>
                        <Field
                            name={`${career}.key`}
                            type="text"
                            component={renderLevel}
                            placeholder="Enter Job Opportunity"
                        />
                        <Icon name="minus" className="minus-level-icon" onClick={() => fields.remove(index)} />
                    </div>
                ))}
            </div>
        )
    }

    render(){
        const { loading, handleSubmit, reset, submitting, pristine, id } = this.props
        return(
            <form onSubmit={handleSubmit}>
                {loading ?  <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container"> Course Summary </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Field name="course_summary_title" component={this.renderField} type="text" label="Title" placeholder="Enter title" className="required_field" />
                                <Label className="required_field"> Summary </Label>
                                <Field name="course_summary" component={TextAreaContainer} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="required_field"> Tags </Label>
                                <Field name="tags" component={ReduxFormSelect} isMulti={true} options={tags} placeholder="Enter Tags" />
                                <Label> Section Image </Label>
                                <Field name="course_summary_img_url" folderName="courses" maxWidth={318} maxHeight={221} form_type="courses" component={ImageContainer} Id={id} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container"> Entry Requirement </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Field name="entry_title" component={this.renderField} type="text" label="Title" placeholder="Enter title" className="required_field" />
                                <Label className="required_field"> Summary </Label>
                                <Field name="entry_summary" component={TextAreaContainer} />
                                <Label> Section Image </Label>
                                <Field name="entry_summary_img_url" folderName="courses" maxWidth={272} maxHeight={302} form_type="courses" component={ImageContainer} Id={id} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="label-heading"> Other Options </Label>
                                <FieldArray name="other_options" component={this.renderOtherOptions} renderLevel={this.renderLevel} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container label-heading required_field"> Highlights </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <FieldArray name="highlights" component={this.renderHighlights} renderLevel={this.renderLevel} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'></Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container label-heading"> How to Apply </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <FieldArray name="how_to_apply" component={this.renderHowToApply} renderLevel={this.renderLevel} />
                                <Label className="required_field"> Summary </Label>
                                <Field name="apply_summary" component={TextAreaContainer} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Label> Section Image </Label>
                                <Field name="apply_img_url" folderName="courses" maxWidth={366} maxHeight={179} form_type="courses" component={ImageContainer} Id={id} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container"> Top Entrance Exams </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="required_field"> Exam Name </Label>
                                <FieldArray name="entrance_exam_name" component={this.renderEntranceExam} renderLevel={this.renderLevel} />
                                <Label className="required_field"> Summary </Label>
                                <Field name="top_entrance_summary" component={TextAreaContainer} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Label> Section Image </Label>
                                <Field name="entrance_img_url" folderName="courses" maxWidth={299} maxHeight={186} form_type="courses" component={ImageContainer} Id={id} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container"> Career Options </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="required_field"> Job Opportunities </Label>
                                <FieldArray name="career_options" component={this.renderCareerOptions} renderLevel={this.renderLevel} />
                                <Label className="required_field"> Summary </Label>
                                <Field name="career_options_summary" component={TextAreaContainer} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Label> Section Image </Label>
                                <Field name="career_img_url" folderName="courses" maxWidth={245} maxHeight={188} form_type="courses" component={ImageContainer} Id={id} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <div className='btns-container'>   
                    <Button size='large' primary disabled={loading || submitting}> Submit </Button>
                    <Button size='large' color="grey" type="button" disabled={pristine || submitting} onClick={reset} className="reset-button"> Reset </Button>           
                </div>             
            </form>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.courseReducer.loading,
    initialValues: state.courseReducer.courseDetailList
})

const mapDispatchToProps = {
    viewCourseDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: 'simple', validate, enableReinitialize: true })(CourseForm))