import { GET_CATEGORY_LIST_REQUEST, CURRENT_CATEGORY, ADD_CATEGORY_REQUEST, DELETE_CATEGORY_DATA_REQUEST, VIEW_SINGLE_CATEGORY_DATA_REQUEST, UPDATE_CATEGORY_REQUEST } from '../constants/CategoryConstants'

export const getCategoryList = () => {
    return{
        type: GET_CATEGORY_LIST_REQUEST
    }
}

export const currentCategory = () => {
    return{
        type: CURRENT_CATEGORY
    }
}

export const addCategoryList = (data) => {
    return{
        type: ADD_CATEGORY_REQUEST,
        data
    }
}

export const deleteCategoryData = (id) => {
    return{
        type: DELETE_CATEGORY_DATA_REQUEST,
        id
    }
}

export const viewSingleCategoryData = (id) => {
    return{
        type: VIEW_SINGLE_CATEGORY_DATA_REQUEST,
        id
    }
}

export const updateCategoryList = (list) => {
    return{
        type: UPDATE_CATEGORY_REQUEST,
        list
    }
}