import { call, put, takeLatest } from 'redux-saga/effects'
import "firebase/auth"
import firebase from '../components/firebase'
import { history } from '../_helpers'
import { db } from '../components/firebase'
import _ from 'lodash'
import { getcount , getData } from './PaginationWithSearch'
import { GET_DISABILITY_LIST_REQUEST, GET_DISABILITY_LIST_SUCCESS, GET_DISABILITY_LIST_ERROR, ADD_DISABILITY_REQUEST, ADD_DISABILITY_SUCCESS, ADD_DISABILITY_ERROR, DELETE_DISABILITY_DATA_REQUEST, DELETE_DISABILITY_DATA_SUCCESS, VIEW_SINGLE_DISABILITY_REQUEST, VIEW_SINGLE_DISABILITY_SUCCESS, UPDATE_DISABILITY_REQUEST, UPDATE_DISABILITY_SUCCESS, UPDATE_DISABILITY_ERROR, CURRENT_DISABILITY_REQUEST, CURRENT_DISABILITY_SUCCESS } from '../constants/DisabilityConstants'

async function getList(){
    let array = []
    let categoryList = db.collection("disability_type").where("isDeleted", "==", false)
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

async function getTotalNodes(count, searchTerm, list, activePage){
    const totalnodes = await getcount(searchTerm, "disability_type")
    if(totalnodes.length === undefined){
        totalnodes.docs.map(doc => {
            return count.push(doc.id)
        })
    }
    else{
        totalnodes.map(id => {
            return count.push(id)
        })
    }
    await getData(activePage, list, searchTerm, "disability_type")
}

function* getDisabilityList(values){
    try{
        const { activePage, searchTerm } = values
        let list = [], count = []
        yield call(getTotalNodes, count, searchTerm, list, activePage)
        yield put({ type: GET_DISABILITY_LIST_SUCCESS, list, count })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_DISABILITY_LIST_ERROR, err })
    }
}

function addData(data){
    return firebase.firestore().collection('disability_type').add(data)
    .then(response => response)
}

function* addDisabilityData(data){
    try{
        yield call(addData, data.data)
        yield put({ type: ADD_DISABILITY_SUCCESS })
        history.push('/disabilityList')
    }
    catch(error){
        const err = error.message
        yield put({ type: ADD_DISABILITY_ERROR, err })
    }
}

function getNewList(list, id){
    let obj = {}
    _.forEach(list, function(value){
        if(value.id === id){
            obj = value
        }
    })
    return obj
}

function* deleteDisability(data){
    const { id } = data
    const list = yield call(getList)
    const newList = yield call(getNewList, list, id)
    newList.isDeleted = true
    firebase.firestore().collection("disability_type").doc(id).update(newList)
    yield put({ type: DELETE_DISABILITY_DATA_SUCCESS, id })
}

function viewData(id){
    return firebase.firestore().collection("disability_type").doc(id).get()
    .then(response => response)
}

function* viewSingleDisability(data){
    const { id } = data
    let value = yield call(viewData, id)
    const singleDisability = value.data()
    localStorage.setItem('disability_id', id)
    yield put({ type: VIEW_SINGLE_DISABILITY_SUCCESS, singleDisability })
}

function updateData (value){
    const { data } = value
    const id = localStorage.getItem('disability_id')
    return firebase.firestore().collection("disability_type").doc(id).update(data)
}

function* updateDisability(data){
    try{
        yield call(updateData, data)
        yield put({ type: UPDATE_DISABILITY_SUCCESS })
        history.push('/disabilityList')
    }
    catch(error){
        const err = error.message
        yield put({ type: UPDATE_DISABILITY_ERROR, err })
    }
}

function* getCurrentDisability(){
    localStorage.removeItem('disability_id')
    yield put({ type: CURRENT_DISABILITY_SUCCESS })
}

export default () => {
    function * watcher() {
      yield takeLatest(GET_DISABILITY_LIST_REQUEST, getDisabilityList)
      yield takeLatest(ADD_DISABILITY_REQUEST, addDisabilityData)
      yield takeLatest(DELETE_DISABILITY_DATA_REQUEST, deleteDisability)
      yield takeLatest(VIEW_SINGLE_DISABILITY_REQUEST, viewSingleDisability)
      yield takeLatest(UPDATE_DISABILITY_REQUEST, updateDisability)
      yield takeLatest(CURRENT_DISABILITY_REQUEST, getCurrentDisability)
    }
    return {watcher}
}