export const GET_DISCIPLINE_LIST_REQUEST = 'GET_DISCIPLINE_LIST_REQUEST'
export const GET_DISCIPLINE_LIST_SUCCESS = 'GET_DISCIPLINE_LIST_SUCCESS'
export const GET_DISCIPLINE_LIST_ERROR = 'GET_DISCIPLINE_LIST_ERROR'

export const CURRENT_DISCIPLINE_REQUEST = 'CURRENT_DISCIPLINE_REQUEST'
export const CURRENT_DISCIPLINE_SUCCESS = 'CURRENT_DISCIPLINE_SUCCESS'

export const ADD_DISCIPLINE_REQUEST = 'ADD_DISCIPLINE_REQUEST'
export const ADD_DISCIPLINE_SUCCESS = 'ADD_DISCIPLINE_SUCCESS'
export const ADD_DISCIPLINE_ERROR = 'ADD_DISCIPLINE_ERROR'

export const DELETE_DISCIPLINE_DATA_REQUEST = 'DELETE_DISCIPLINE_DATA_REQUEST'
export const DELETE_DISCIPLINE_DATA_SUCCESS = 'DELETE_DISCIPLINE_DATA_SUCCESS'

export const VIEW_SINGLE_DISCIPLINE_DATA_REQUEST = 'VIEW_SINGLE_DISCIPLINE_DATA_REQUEST'
export const VIEW_SINGLE_DISCIPLINE_DATA_SUCCESS = 'VIEW_SINGLE_DISCIPLINE_DATA_SUCCESS'

export const UPDATE_DISCIPLINE_REQUEST = 'UPDATE_DISCIPLINE_REQUEST'
export const UPDATE_DISCIPLINE_SUCCESS = 'UPDATE_DISCIPLINE_SUCCESS'
export const UPDATE_DISCIPLINE_ERROR = 'UPDATE_DISCIPLINE_ERROR'

export const VIEW_DISCIPLINE_DATA_REQUEST = 'VIEW_DISCIPLINE_DATA_REQUEST'
export const VIEW_DISCIPLINE_DATA_SUCCESS = 'VIEW_DISCIPLINE_DATA_SUCCESS'

export const GET_ALL_DISCIPLINE_NAME_REQUEST = 'GET_ALL_DISCIPLINE_NAME_REQUEST'
export const GET_ALL_DISCIPLINE_NAME_SUCCESS = 'GET_ALL_DISCIPLINE_NAME_SUCCESS'
export const GET_ALL_DISCIPLINE_NAME_ERROR = 'GET_ALL_DISCIPLINE_NAME_ERROR'

export const SAVE_DISCIPLINE_CROP_IMAGE_DATA = 'SAVE_DISCIPLINE_CROP_IMAGE_DATA'

export const SECTION_DISCIPLINE_IMAGE_EXISTS = 'SECTION_DISCIPLINE_IMAGE_EXISTS'

export const DELETE_DISCIPLINE_IMAGE_REQUEST = 'DELETE_DISCIPLINE_IMAGE_REQUEST'
export const DELETE_DISCIPLINE_IMAGE_SUCCESS = 'DELETE_DISCIPLINE_IMAGE_SUCCESS'
export const DELETE_DISCIPLINE_IMAGE_ERROR = 'DELETE_DISCIPLINE_IMAGE_ERROR'