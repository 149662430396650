import { GET_EDUCATIONAL_LIST_REQUEST, GET_EDUCATIONAL_LIST_SUCCESS, GET_EDUCATIONAL_LIST_ERROR, CURRENT_EDUCATION_SUCCESS, ADD_EDUCATION_REQUEST, ADD_EDUCATION_SUCCESS, ADD_EDUCATION_ERROR, DELETE_EDUCATION_DATA_SUCCESS, VIEW_SINGLE_EDUCATION_REQUEST, VIEW_SINGLE_EDUCATION_SUCCESS, UPDATE_EDUCATION_REQUEST, UPDATE_EDUCATION_SUCCESS, UPDATE_EDUCATION_ERROR } from '../constants/EducationConstants'

const initialState = {
    loading: false,
    viewEducationList: {name:"", display_name:"", created_by:"", created_date_time:"", modified_by:"", rank:"", modified_date_time:""},
    educationList: [],
    error: '',
    disabled: false,
    count: []
}

const education = (state = initialState, action) => {
    switch(action.type){
        case GET_EDUCATIONAL_LIST_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_EDUCATIONAL_LIST_SUCCESS:
            return{
                ...state,
                loading: false,
                educationList: action.list,
                count: action.count,
                error: ""
            }
        case GET_EDUCATIONAL_LIST_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case CURRENT_EDUCATION_SUCCESS:
            return{
                ...state,
                disabled: false,
                viewEducationList: initialState.viewEducationList
            }
        case ADD_EDUCATION_REQUEST:
            return{
                ...state,
                loading: true
            }
        case ADD_EDUCATION_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case ADD_EDUCATION_ERROR:
            return{
                ...state,
                loading: false,
                error: action.err
            }
        case DELETE_EDUCATION_DATA_SUCCESS:
            return{
                ...state,
                educationList: state.educationList.filter(education => education.id !== action.id)
            }
        case VIEW_SINGLE_EDUCATION_REQUEST:
            return{
                ...state,
                loading: true
            }
        case VIEW_SINGLE_EDUCATION_SUCCESS:
            return{
                ...state,
                loading: false,
                disabled: true,
                viewEducationList: action.singleEducation
            }
        case UPDATE_EDUCATION_REQUEST:
            return{
                ...state,
                loading: true
            }
        case UPDATE_EDUCATION_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case UPDATE_EDUCATION_ERROR:
            return{
                loading: false,
                error: action.err
            }
        default:
            return state
    }
}

export default education