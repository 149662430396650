import { GET_SUBJECT_LIST_REQUEST, GET_SUBJECT_LIST_SUCCESS, GET_SUBJECT_LIST_ERROR, ADD_SUBJECT_REQUEST, ADD_SUBJECT_SUCCESS, ADD_SUBJECT_ERROR, DELETE_SUBJECT_SUCCESS, UPDATE_SUBJECT_REQUEST, UPDATE_SUBJECT_SUCCESS, UPDATE_SUBJECT_ERROR } from '../constants/SubjectConstants'

const initialState = {
    loading: false,
    subjectList: [],
    error: ''
}

const subject = (state = initialState, action) => {
    switch(action.type){
        case GET_SUBJECT_LIST_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_SUBJECT_LIST_SUCCESS:
            return{
                ...state,
                loading: false,
                subjectList: action.list
            }
        case GET_SUBJECT_LIST_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case ADD_SUBJECT_REQUEST:
            return{
                ...state,
                loading: true
            }
        case ADD_SUBJECT_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case ADD_SUBJECT_ERROR:
            return{
                ...state,
                loading: false,
                error: action.err
            }
        case DELETE_SUBJECT_SUCCESS:
            return{
                ...state,
                subjectList: state.subjectList.filter(subject => subject.name.map(item => item !== action.value))
            }
        case UPDATE_SUBJECT_REQUEST:
            return{
                ...state,
                loading: true
            }
        case UPDATE_SUBJECT_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case UPDATE_SUBJECT_ERROR:
            return{
                ...state,
                loading: false,
                error: action.err
            }
        default:
            return state
    }
}

export default subject