import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Header from '../../Header/Header'
import { Link } from 'react-router-dom'
import { Button, Confirm, Icon, Input, Table, Tab } from 'semantic-ui-react'
import { authHeader, history } from '../../../_helpers'
import ReactLoading from "react-loading"
import Pagination from 'react-js-pagination'
import { itemsCountPerPage, pageRangeDisplayed, pageSize } from '../../../constants/HelperConstants'
import { getQuizList, deleteQuiz } from '../../../actions/QuizActions'
import _ from 'lodash'

const QuizesList = (props) => {
    let pathname = props.location.pathname
    const { getQuizList, deleteQuiz, loading, error, count, quizList } = props
    const [deleteValue, setDeleteValue] = useState(true)
    const [open, setOpen] = useState(true)
    const [currentUserId, setCurrentUserId] = useState(null)
    const [activePage, setActivePage] = useState(1)
    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getQuizList(activePage, searchTerm)
            }
            else{
                history.push('/login') 
            }
        }
    }, [pathname, getQuizList, activePage, searchTerm])

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    }

    const handleChange = (event) => {
        const target =  event.target
        const { value } = target
        setSearchTerm(value)
    }

    const deleteQuizData = (id) => {
        setDeleteValue(false)
        setOpen(true)
        setCurrentUserId(id)
    }

    const confirmDelete = (id) => {
        return (
            <Confirm open={open}
                    content='Are you sure want to delete this Quiz?'
                    onCancel={close}
                    className="delete-popup"
                    cancelButton='No, cancel it!'
                    confirmButton="Yes, delete it!"
                    onConfirm={() => renderDeleteQuiz(id)} />
        )
    }

    const close = () => {
        setOpen(false)
        setDeleteValue(true)
    }

    const renderDeleteQuiz = (id) => {
        deleteQuiz(id)
        setOpen(false)
    }

    const getTableBody = () => {
        let serialNo = ((activePage - 1) * pageSize) + 1
        return(
            quizList.map((data, index) => {
                return(
                    <Table.Row key={data.id} className="form-data">
                        <Table.Cell> {serialNo + index} </Table.Cell>
                        <Table.Cell> {data.name} </Table.Cell>
                        <Table.Cell> {data.settings.eligibility === undefined ? '--' : data.settings.eligibility} </Table.Cell>
                        <Table.Cell> {data.settings.duration.hours !== 0 && data.settings.duration.hours !== undefined ? data.settings.duration.hours + ' ' + 'hour' + ' ' + data.settings.duration.minutes + ' ' + 'minutes' : data.settings.duration.minutes === 0 ? '--' : data.settings.duration.minutes + ' ' + 'minutes'} </Table.Cell>
                        <Table.Cell> {data.settings.number_of_questions} </Table.Cell>
                        <Table.Cell> {data.settings.access_type} </Table.Cell>
                        <Table.Cell>
                            <Link to={{ pathname: `/editquiz/${data.id}`}}>
                                <Button icon className="edit-btn">
                                    <Icon name='edit' />
                                </Button>
                            </Link>
                            <Button icon onClick={() => deleteQuizData(data.id)} className="delete-btn">
                                <Icon name='trash' />
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                )
            })
        )
    }

    const getTable = () => {
        return(
            <Table basic='very'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell> S.No </Table.HeaderCell>
                        <Table.HeaderCell> Quiz Name </Table.HeaderCell>
                        <Table.HeaderCell> Eligibility </Table.HeaderCell>
                        <Table.HeaderCell> Duration </Table.HeaderCell>
                        <Table.HeaderCell> Total Questions </Table.HeaderCell>
                        <Table.HeaderCell> Access Type </Table.HeaderCell>
                        <Table.HeaderCell> Actions </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {getTableBody()}
                    {deleteValue === false ? confirmDelete(currentUserId) : null}
                </Table.Body>
            </Table>
        )
    }

    return(
        <div className="wrapper">
            <div className="container">
                <Header />
                <Link className="link-container exam-content" to="/dashboard">
                    <Icon name="arrow left" />
                    Back to Dashboard
                </Link>
                <div>
                    <Input type="text"
                           placeholder="Enter text"
                           className="search-box"
                           onChange={handleChange}
                           action={{
                            color: "blue",
                            icon: "search"
                          }}
                           value={searchTerm} 
                        />
                    <Link to="/addquiz">
                        <Button primary floated='right' className="add-btn">
                            <Icon name="plus" />
                            Add Quiz
                        </Button>
                    </Link>
                </div>
                {loading ? <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                {error !== "" ? <h3 className="errorMsg"> {error} </h3> : null}
                {quizList !== undefined ? quizList.length !== 0 ? getTable() : <h3> No Data </h3> :null}
                <div>
                    {error === "" && count.length > pageSize ?
                    <Pagination activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={count.length}
                                pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange} />
                    : error}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    quizList: state.quizReducer.quizList,
    loading: state.quizReducer.loading,
    error: state.quizReducer.error,
    count: state.quizReducer.count
})

const mapDispatchToProps = {
    getQuizList,
    deleteQuiz
}

export default connect(mapStateToProps, mapDispatchToProps)(QuizesList)