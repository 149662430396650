export const GET_TAG_LIST_REQUEST = 'GET_TAG_LIST_REQUEST'
export const GET_TAG_LIST_SUCCESS = 'GET_TAG_LIST_SUCCESS'
export const GET_TAG_LIST_ERROR = 'GET_TAG_LIST_ERROR'

export const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST'
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS'

export const ADD_TAG_REQUEST = 'ADD_TAG_REQUEST'
export const ADD_TAG_SUCCESS = 'ADD_TAG_SUCCESS'
export const ADD_TAG_ERROR = 'ADD_TAG_ERROR'

export const UPDATE_TAG_REQUEST = 'UPDATE_TAG_REQUEST'
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS'
export const UPDATE_TAG_ERROR = 'UPDATE_TAG_ERROR'

export const TAG_SEARCH_REQUEST = 'TAG_SEARCH_REQUEST'
export const TAG_SEARCH_SUCCESS = 'TAG_SEARCH_SUCCESS'
export const TAG_SEARCH_ERROR = 'TAG_SEARCH_ERROR'