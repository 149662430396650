export const GET_QUIZ_CATEGORY_LIST_REQUEST = 'GET_QUIZ_CATEGORY_LIST_REQUEST'
export const GET_QUIZ_CATEGORY_LIST_SUCCESS = 'GET_QUIZ_CATEGORY_LIST_SUCCESS'
export const GET_QUIZ_CATEGORY_LIST_ERROR = 'GET_QUIZ_CATEGORY_LIST_ERROR'

export const CURRENT_QUIZ_CATEGORY_REQUEST = 'CURRENT_QUIZ_CATEGORY_REQUEST'
export const CURRENT_QUIZ_CATEGORY_SUCCESS = 'CURRENT_QUIZ_CATEGORY_SUCCESS'

export const ADD_QUIZ_CATEGORY_REQUEST = 'ADD_QUIZ_CATEGORY_REQUEST'
export const ADD_QUIZ_CATEGORY_SUCCESS = 'ADD_QUIZ_CATEGORY_SUCCESS'
export const ADD_QUIZ_CATEGORY_ERROR = 'ADD_QUIZ_CATEGORY_ERROR'

export const DELETE_QUIZ_CATEGORY_REQUEST = 'DELETE_QUIZ_CATEGORY_REQUEST'
export const DELETE_QUIZ_CATEGORY_SUCCESS = 'DELETE_QUIZ_CATEGORY_SUCCESS'

export const VIEW_SINGLE_QUIZ_CATEGORY_REQUEST = 'VIEW_SINGLE_QUIZ_CATEGORY_REQUEST'
export const VIEW_SINGLE_QUIZ_CATEGORY_SUCCESS = 'VIEW_SINGLE_QUIZ_CATEGORY_SUCCESS'

export const UPDATE_QUIZ_CATEGORY_REQUEST = 'UPDATE_QUIZ_CATEGORY_REQUEST'
export const UPDATE_QUIZ_CATEGORY_SUCCESS = 'UPDATE_QUIZ_CATEGORY_SUCCESS'
export const UPDATE_QUIZ_CATEGORY_ERROR = 'UPDATE_QUIZ_CATEGORY_ERROR'

export const GET_ALL_CATEGORY_NAME_REQUEST = 'GET_ALL_CATEGORY_NAME_REQUEST'
export const GET_ALL_CATEGORY_NAME_SUCCESS = 'GET_ALL_CATEGORY_NAME_SUCCESS'
export const GET_ALL_CATEGORY_NAME_ERROR = 'GET_ALL_CATEGORY_NAME_ERROR'


