import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Header from '../../Header/Header'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import CourseForm from '../coursesDetails/CourseForm'
import { authHeader, history } from '../../../_helpers'
import { addCourseDetails } from '../../../actions/courseActions'

const AddCourseDetails = (props) => {
    const { pathname } = props.location
    const { addCourseDetails, match, error, cropCourseImageData, uploadError } = props
    const { params } = match
    const { id } = params
    const [showError, setShowError] = useState(false)

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
            }
            else{
                history.push('/login') 
            }
        }
    },[pathname, props.match, id])

    useEffect(() => {
        if(!uploadError){
            setShowError(false)
        }
    })

    const handleSubmit = (data) => {
        data['id'] = id
        if(uploadError){
            setShowError(true)
        }
        else{
            setShowError(false)
            addCourseDetails(data, cropCourseImageData)
        }
    }

    return(
        <div className="wrapper">
            <div className="container">
                {showError ? <h5 className="errorMsg"> Please click on upload button to upload image </h5>: null}   
                {error !== "" ? <h5 className="errorMsg"> {error} </h5>: null}
                <Header />
                <Link className="link-container" to="/courseList"> <Icon name="arrow left" /> Back to Course List </Link>
                <CourseForm onSubmit={handleSubmit} id={id} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.courseReducer.loading,
    error: state.courseReducer.error,
    cropCourseImageData: state.courseReducer.cropCourseImageData,
    uploadError: state.helperReducer.uploadError
})

const mapDispatchToProps = {
    addCourseDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCourseDetails)