const validate = (values) => {
    const errors = {name:{}};
    if(!values.exam_name) {
      errors.exam_name = {
        message: 'This field is Required'
      }
    }
    if(!values.job_sector){
        errors.job_sector = {
            message: 'This field is Required'
        }
    }
    if(!values.job_position){
        errors.job_position = {
            message: 'This field is Required'
        }
    }
    if(!values.exam_body){
        errors.exam_body = {
            message: 'This field is Required'
        }
    }
    if(!values.educational_qualification){
        errors.educational_qualification = {
            message: 'This field is Required'
        }
    }
    if(!values.subjects){
        errors.subjects = {
            message: 'This field is Required'
        }
    }
    if(!values.nationality){
        errors.nationality = {
            message: 'This field is Required'
        }
    }
    if(!values.minimum_age){
        errors.minimum_age = {
            message: 'This field is Required'
        }
    }
    if(!values.maximum_age){
        errors.maximum_age = {
            message: 'This field is Required'
        }
    }
    if(!values.marital_status){
        errors.marital_status = {
            message: 'This field is Required'
        }
    }
    if(!values.certification){
        errors.certification = {
            message: 'This field is Required'
        }
    }
    if(!values.exam_language){
        errors.exam_language = {
            message: 'This field is Required'
        }
    }
    if(!values.level){
        errors.level = {
            message: 'This field is Required'
        }
    }
    if(!values.job_type){
        errors.job_type = {
            message: 'This field is Required'
        }
    }
    if(!values.exam_time){
        errors.exam_time = {
            message: 'This field is Required'
        }
    }
    return errors
}

export default validate