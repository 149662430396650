import { call, put, takeLatest, delay } from 'redux-saga/effects'
import "firebase/auth"
import * as firebase from 'firebase'
import { db } from '../components/firebase'
import { GET_SUBJECT_LIST_REQUEST, GET_SUBJECT_LIST_SUCCESS, GET_SUBJECT_LIST_ERROR, DELETE_SUBJECT_REQUEST, DELETE_SUBJECT_SUCCESS, ADD_SUBJECT_REQUEST, ADD_SUBJECT_SUCCESS, ADD_SUBJECT_ERROR, UPDATE_SUBJECT_REQUEST, UPDATE_SUBJECT_SUCCESS, UPDATE_SUBJECT_ERROR } from '../constants/SubjectConstants'

async function getList(){
    let array = []
    let categoryList = db.collection("subjects")
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

function* getSubjectList(){
    try{
        yield delay(2000)
        const list = yield call(getList)
        let id 
        list.map(item => {
            id = item.id
            return id
        })
        localStorage.setItem('subject_id', id)
        yield put({ type: GET_SUBJECT_LIST_SUCCESS, list })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_SUBJECT_LIST_ERROR, err })
    }
}

function subjectDelete(value){
    let subject_id = localStorage.getItem('subject_id')
    let subjectRef = db.collection("subjects").doc(subject_id)
    subjectRef.update({ "name": firebase.firestore.FieldValue.arrayRemove(value) })
}

function* deleteSubject(data){
    const { value } = data
    yield call(subjectDelete, value)
    yield put({ type: DELETE_SUBJECT_SUCCESS, value })
}

function updateSubject(value){
    let subject_id = localStorage.getItem('subject_id')
    let subjectRef = db.collection("subjects").doc(subject_id)
    subjectRef.update({ "name": firebase.firestore.FieldValue.arrayUnion(value) })
}

function* addSubject(data){
    try{
        const { value } = data
        yield call(updateSubject, value)
        yield put({ type: ADD_SUBJECT_SUCCESS })
    }
    catch(error){
        const err = error.message
        yield put({ type: ADD_SUBJECT_ERROR, err })
    }
}

function* updateSubjectData(data){
    try{
        const { oldValue, newValue } = data
        yield call(subjectDelete, oldValue)
        yield call(updateSubject, newValue)
        yield put({ type: UPDATE_SUBJECT_SUCCESS })
    }
    catch(error){
        const err = error.message
        yield put({ type: UPDATE_SUBJECT_ERROR, err })
    }
}

export default () => {
    function * watcher() {
      yield takeLatest(GET_SUBJECT_LIST_REQUEST, getSubjectList)
      yield takeLatest(DELETE_SUBJECT_REQUEST, deleteSubject)
      yield takeLatest(ADD_SUBJECT_REQUEST, addSubject)
      yield takeLatest(UPDATE_SUBJECT_REQUEST, updateSubjectData)
    }
    return {watcher}
}