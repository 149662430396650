import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Input from '../library/Input'
import Button from '../library/Button'
import { loginUser } from '../../actions/UserActions'
import ReactLoading from "react-loading"
import { authHeader } from '../../_helpers'

const Login = (props) => {
    const { loginUser, loginerror, loggingIn } = props
    let pathname = props.location.pathname
    const [data, setData] = useState("")

    useEffect(() => {
        if(authHeader){
            authHeader(pathname)
        }
    })
    const handleChange = (event) => {
        const target = event.target
        const { name, value } = target
        setData({ ...data, [name]: value })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        loginUser(data)
    }

    return(
        <div className="login-page">
            <div className="form">
                {loginerror !== "" ? <h5 className="errorMsg">{loginerror}</h5> : null}
                {loggingIn ?  <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                <form className="login-form">
                    <Input name="email"
                           type="email"
                           placeholder="Enter Username"
                           onChange={handleChange} />
                    <Input name="password"
                           type="password"
                           onChange={handleChange}
                           placeholder="Enter Password" />
                    <Button onClick={handleSubmit}> Submit </Button>
                    {/* <p className="message">Not registered? <Link to="/signup">Create an account</Link></p> */}
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loginerror: state.userReducer.loginerror,
    loggingIn: state.userReducer.loggingIn
})

const mapDispatchToProps = {
    loginUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)