export const GET_QUIZ_QUESTION_LIST_REQUEST = 'GET_QUIZ_QUESTION_LIST_REQUEST'
export const GET_QUIZ_QUESTION_LIST_SUCCESS = 'GET_QUIZ_QUESTION_LIST_SUCCESS'
export const GET_QUIZ_QUESTION_LIST_ERROR = 'GET_QUIZ_QUESTION_LIST_ERROR'

export const CURRENT_QUIZ_QUESTION_REQUEST = 'CURRENT_QUIZ_QUESTION_REQUEST'
export const CURRENT_QUIZ_QUESTION_SUCCESS = 'CURRENT_QUIZ_QUESTION_SUCCESS'

export const ADD_QUIZ_QUESTION_REQUEST = 'ADD_QUIZ_QUESTION_REQUEST'
export const ADD_QUIZ_QUESTION_SUCCESS = 'ADD_QUIZ_QUESTION_SUCCESS'
export const ADD_QUIZ_QUESTION_ERROR = 'ADD_QUIZ_QUESTION_ERROR'

export const DELETE_QUIZ_QUESTION_REQUEST = 'DELETE_QUIZ_QUESTION_REQUEST'
export const DELETE_QUIZ_QUESTION_SUCCESS = 'DELETE_QUIZ_QUESTION_SUCCESS'

export const VIEW_SINGLE_QUIZ_QUESTION_REQUEST = 'VIEW_SINGLE_QUIZ_QUESTION_REQUEST'
export const VIEW_SINGLE_QUIZ_QUESTION_SUCCESS = 'VIEW_SINGLE_QUIZ_QUESTION_SUCCESS'

export const UPDATE_QUIZ_QUESTION_REQUEST = 'UPDATE_QUIZ_QUESTION_REQUEST'
export const UPDATE_QUIZ_QUESTION_SUCCESS = 'UPDATE_QUIZ_QUESTION_SUCCESS'
export const UPDATE_QUIZ_QUESTION_ERROR = 'UPDATE_QUIZ_QUESTION_ERROR'

export const SAVE_IMAGE_REQUEST = 'SAVE_IMAGE_REQUEST'

export const CHANGE_UPLOAD_ANSWER_REQUEST = 'CHANGE_UPLOAD_ANSWER_REQUEST'

export const SAVE_QUESTION_TYPE = 'SAVE_QUESTION_TYPE'

export const GET_FILTERED_QUESTION_LIST_REQUEST = 'GET_FILTERED_QUESTION_LIST_REQUEST'
export const GET_FILTERED_QUESTION_LIST_SUCCESS = 'GET_FILTERED_QUESTION_LIST_SUCCESS'
export const GET_FILTERED_QUESTION_LIST_ERROR = 'GET_FILTERED_QUESTION_LIST_ERROR'
