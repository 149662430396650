import React, { useState } from 'react'
import { connect } from 'react-redux'
import { resetPassword } from '../../actions/UserActions'
import { Icon } from 'semantic-ui-react'
import Header from '../Header/Header'
import { Link } from 'react-router-dom'
import ReactLoading from "react-loading"

const ChangePassword = (props) => {
    const { passwordError, loggingIn } = props
    const [data, setData] = useState({
        currentPassword: "", 
        newPassword: "", 
        confirmnewPassword: ""
    })
    const [error, setError] = useState({
        currentPasswordError: "", 
        newPasswordError: "", 
        confirmnewPasswordError: "",
        err: ""
    })

    const handleChange = (event) => {
        const target = event.target
        const { name, value } = target
        setData({ ...data, [name]: value })
    }

    const passwordValidation = (data) => {
        const err = "This field is Required"
        const { currentPassword, newPassword, confirmnewPassword } = data
        if(currentPassword === "" || newPassword === "" || confirmnewPassword === ""){
            if(currentPassword === ""){
                setError({
                  ...error,
                  currentPasswordError: err
                })
            }
            else if(newPassword === ""){
                setError({
                  ...error,
                  newPasswordError: err
                })
            }
            else if(confirmnewPassword === ""){
                setError({
                  ...error,
                  confirmnewPasswordError: err
                })
            }
        }
        else if(newPassword !== "" && confirmnewPassword !== ""){
            if(newPassword === confirmnewPassword){
                props.resetPassword(newPassword, currentPassword)
            }
            else{
                setError({ 
                    ...error, 
                    err: "Password dosen't matches" 
                })
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        passwordValidation(data)
    }

    return(
        <div className="wrapper">
            <div className="container">
                <Header />
                <Link className="link-container" to="/dashboard"> <Icon name="arrow left" /> Back to Dashboard </Link>
                <div className="container bootstrap snippet password-container">
                    {loggingIn ? <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                    {passwordError !== "" ? passwordError : null}
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-md-offset-2">
                            <div className="panel panel-info">
                                <div className="panel-heading">
                                    <h3 className="panel-title">
                                        <span className="glyphicon glyphicon-th"></span>
                                        Change password   
                                    </h3>
                                </div>
                                <div className="panel-body">
                                    <div className="row">
                                        {error.err && <p className="errorMsg"> {error.err} </p>}
                                        <div className="col-xs-6 col-sm-6 col-md-6 login-box">
                                        <div className="form-group">
                                            <div className="input-group">
                                            <div className="input-group-addon">
                                                <span className="glyphicon glyphicon-lock"></span>
                                            </div>
                                                <input className="form-control" 
                                                    name="currentPassword" 
                                                    type="password" 
                                                    value={data.currentPassword}
                                                    onChange={handleChange}
                                                    placeholder="Current Password" />
                                            </div>
                                            {error.currentPasswordError && <p className="errorMsg"> {error.currentPasswordError} </p>}
                                        </div>
                                        <div className="form-group">
                                            <div className="input-group">
                                            <div className="input-group-addon">
                                                <span className="glyphicon glyphicon-log-in"></span>
                                            </div>
                                                <input className="form-control"
                                                    name="newPassword" 
                                                    type="password" 
                                                    value={data.newPassword}
                                                    onChange={handleChange}
                                                    placeholder="New Password" />
                                            </div>
                                            {error.newPasswordError && <p className="errorMsg"> {error.newPasswordError} </p>}
                                        </div>
                                        <div className="form-group">
                                            <div className="input-group">
                                            <div className="input-group-addon">
                                                <span className="glyphicon glyphicon-log-in"></span>
                                            </div>
                                                <input className="form-control"
                                                    type="password" 
                                                    value={data.confirmnewPassword}
                                                    name="confirmnewPassword" 
                                                    onChange={handleChange}
                                                    placeholder="Re-Enter New Password" />
                                            </div>
                                            {error.confirmnewPasswordError && <p className="errorMsg"> {error.confirmnewPasswordError} </p>}
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <div className="row">
                                        <div className="col-xs-8 col-sm-8 col-md-8">
                                            <button className="btn icon-btn-save btn-success" type="submit" onClick={handleSubmit}>
                                            <span className="btn-save-label"><i className="glyphicon glyphicon-floppy-disk"></i></span>save</button>
                                        </div>
                                        <div className="col-xs-2 col-sm-2 col-md-2">
                                            <button className="btn icon-btn-save btn-success" type="submit">
                                            <span className="btn-save-label"><i className="glyphicon glyphicon-floppy-disk"></i></span>cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    passwordError: state.userReducer.passwordError,
    loggingIn: state.userReducer.loggingIn
})

const mapDispatchToProps = {
    resetPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)