import React from 'react'
import Select, {components} from 'react-select'
import createClass from "create-react-class"
import classnames from 'classnames'

const ReduxFormSelect = props => {
    const { input, options, placeholder, disabled, meta: { touched, error }, isMulti, values, newQuiz, newQuizQuestion } = props

    const Option = createClass({
      render() {
        return (
          <div>
            <components.Option {...this.props}>
              <input
                type="checkbox"
                checked={this.props.isSelected}
                onChange={e => null}
              />{" "}
              <label>{this.props.value} </label>
            </components.Option>
          </div>
        )
      }
    })

    const MultiValue = props => {
        return (
          <components.MultiValue {...props}>
            <span>{props.data.label}</span>
          </components.MultiValue>
        )
      }

    return (
      <div>
      <Select 
        {...input} 
        className={classnames({error:touched && error})}
        isMulti={isMulti}
        components={{ Option, MultiValue }}
        closeMenuOnSelect={false}
        placeholder={placeholder}
        isSearchable
        defaultValue={values === "eligibility_value" && newQuiz === undefined ? options[2] : values === "status_value" && newQuizQuestion === undefined ? options[2] : null}
        onChange={value => input.onChange(value)} 
        onBlur={() => input.onBlur(input.value)}
        options={options}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        isDisabled={disabled}
      />
      {touched && error && <span className="errorMsg">{error.message}</span>}
      </div>
    )
  }

export default ReduxFormSelect