export const GET_SUBJECT_LIST_REQUEST = 'GET_SUBJECT_LIST_REQUEST'
export const GET_SUBJECT_LIST_SUCCESS = 'GET_SUBJECT_LIST_SUCCESS'
export const GET_SUBJECT_LIST_ERROR = 'GET_SUBJECT_LIST_ERROR'

export const DELETE_SUBJECT_REQUEST = 'DELETE_SUBJECT_REQUEST'
export const DELETE_SUBJECT_SUCCESS = 'DELETE_SUBJECT_SUCCESS'

export const ADD_SUBJECT_REQUEST = 'ADD_SUBJECT_REQUEST'
export const ADD_SUBJECT_SUCCESS = 'ADD_SUBJECT_SUCCESS'
export const ADD_SUBJECT_ERROR = 'ADD_SUBJECT_ERROR'

export const UPDATE_SUBJECT_REQUEST = 'UPDATE_SUBJECT_REQUEST'
export const UPDATE_SUBJECT_SUCCESS = 'UPDATE_SUBJECT_SUCCESS'
export const UPDATE_SUBJECT_ERROR = 'UPDATE_SUBJECT_ERROR'