import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Confirm, Icon, Input, Modal, Table, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Header from '../Header/Header'
import ReactLoading from "react-loading"
import { authHeader, history } from '../../_helpers'
import Select from 'react-select'
import { userStatus, options } from '../docs/helperData'
import _ from 'lodash'
import { getUsersList, updateUserStatus, sendInvitation, saveQuizSet, saveUserQuiz, getAssignedQuizzes, updateUserQuiz, removeQuizSet, updateButtonData, deleteUser } from '../../actions/UserActions'
import { getQuizList } from '../../actions/QuizActions'
import Pagination from 'react-js-pagination'
import { itemsCountPerPage, pageRangeDisplayed, pageSize } from '../../constants/HelperConstants'

const UserList = (props) => {
    let pathname = props.location.pathname
    const { loading, error, getUsersList, usersList, updateUserStatus, sendInvitation, disabled, count, getQuizList, quizcount, quizList, quizerror, quizSet, saveQuizSet, saveUserQuiz, getAssignedQuizzes, updateUserQuiz, userDocId, removeQuizSet, updateButton, updateButtonData, deleteUser } = props
    const [Id, setId] = useState()
    const [selectedOption, setSelectedOption] = useState()
    const [activePage, setActivePage] = useState(1)
    const [searchTerm, setSearchTerm] = useState("")
    const [quizactivePage, setQuizActivePage] = useState(1)
    const [quizsearchTerm, setQuizSearchTerm] = useState("")
    const [open, setOpen] = useState(false)
    const [openValue, setOpenValue] = useState(false)
    const [userId, setuserId] = useState("")
    const [currentUserId, setCurrentUserId] = useState(null)
    const [deleteUserValue, setDeleteUserValue] = useState(true)
    const [check, setCheck] = useState(false)
    const [deletedUsers, setDeletedUsers] = useState({ value: 'Not Deleted', label: 'Not Deleted' })
    
    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getUsersList(activePage, searchTerm, false)
                getQuizList(quizactivePage, quizsearchTerm, "employee")
                // if(deletedUsers.value === "Not Deleted"){
                //     getUsersList(activePage, searchTerm, false)
                // }
                // else{
                //     getUsersList(activePage, searchTerm, true)
                // }
                // getQuizList(quizactivePage, quizsearchTerm, "employee")
            }
            else{
                history.push('/login') 
            }
        }
    },[pathname, getUsersList, activePage, searchTerm, getQuizList, quizactivePage, quizsearchTerm])

    useEffect(() => {
        if(disabled){
            if(deletedUsers.value === "Not Deleted"){
                getUsersList(activePage, searchTerm, false)
            }
            else{
                getUsersList(activePage, searchTerm, true)
            }
        }
    })

    const userStatusChange = (value, id) => {
        setId(id)
        setSelectedOption(value)
        usersList.map(item => {
            if(item.id === id){
                item.status = value.value
            }
            return item
        })
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    }

    const handleChange = (event) => {
        const target =  event.target
        const { value } = target
        setSearchTerm(value)
    }

    const updateStatusValue = (id) => {
        updateUserStatus(id, selectedOption)
        setId('')
    }

    const sendInvite = (email, id, role) => {
        sendInvitation(email, id, role)
    }

    const handleQuizPageChange = (pageNumber) => {
        setQuizActivePage(pageNumber)
    }

    const handleQuizChange = (event) => {
        const target =  event.target
        const { value } = target
        setQuizSearchTerm(value)
    }

    const createQuizJson = (evt, newquizList, id) => {
        if(evt.target.checked){
            if(quizSet === '' || quizSet === undefined || quizSet.length === 0){
                newquizList.map(item => {
                    if(item.id === id){
                        item.isChecked = true
                    }
                    else{
                        item.isChecked = false
                    }
                    return item
                })
                const newQuizSet = _.filter(newquizList, ['isChecked', true])
                const uniqQuizSet = _.uniqBy(newQuizSet, 'id')
                saveQuizSet(uniqQuizSet)
            }
            else{
                newquizList.map(item => {
                    if(item.id === id){
                        item.isChecked = true
                    }
                    return item
                })
                const newQuizSet = _.filter(newquizList, ['isChecked', true])
                const uniqQuizSet = _.uniqBy(newQuizSet, 'id')  
                saveQuizSet(uniqQuizSet)
            }
        }
        else{
            quizSet.map(item => {
                if(item.id === id){
                    item.isChecked = false
                }
                return item
            })
            const newQuizSet = _.filter(quizSet, ['isChecked', true])
            const uniqQuizSet = _.uniqBy(newQuizSet, 'id')
            saveQuizSet(uniqQuizSet)
        }
    }

    const getQuizTableBody = () => {
        const regex = /(<([^>]+)>)/ig
        const newquizList = _.cloneDeep(quizList)
        const newQuizSet = _.filter(quizSet, ['isChecked', true])
        const uniqQuizSet = _.uniqBy(newQuizSet, 'id')
        newquizList.map(list => {
            if(!_.isEmpty(quizSet) && quizSet !== undefined){
                uniqQuizSet.map(data => {
                    if(list.id === data.id){
                        list.isChecked = true
                    }
                })
            }
        })
        return(
            newquizList.map((data) => {
                return(
                    <Table.Row key={data.id} className="form-data">
                        <Table.Cell>
                            <input type="checkbox" 
                                   value={data.name.replace(regex, '')}
                                   defaultChecked={data.isChecked}
                                   onClick={(evt) => createQuizJson(evt, newquizList, data.id)} />
                        </Table.Cell>
                        <Table.Cell> {data.name} </Table.Cell>
                        <Table.Cell> {data.settings.duration.hours !== 0 && data.settings.duration.hours !== undefined ? data.settings.duration.hours + ' ' + 'hour' + ' ' + data.settings.duration.minutes + ' ' + 'minutes' : data.settings.duration.minutes === 0 ? '--' : data.settings.duration.minutes + ' ' + 'minutes'} </Table.Cell>
                        <Table.Cell> {data.settings.number_of_questions} </Table.Cell>
                        <Table.Cell> {data.settings.access_type} </Table.Cell>
                    </Table.Row>
                )
            })
        )
    }

    const getQuizTable = () => {
        return(
            <Table basic='very'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell> Quiz Name </Table.HeaderCell>
                        <Table.HeaderCell> Duration </Table.HeaderCell>
                        <Table.HeaderCell> Total Questions </Table.HeaderCell>
                        <Table.HeaderCell> Access Type </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {getQuizTableBody(quizList)}
                </Table.Body>
            </Table>
        )
    }

    const handleOpen = (data) => {
        const { id, assigned_quiz } = data
        setOpen(!open)
        setuserId(id)
        updateButtonData(false)
        if(assigned_quiz === true){
            getAssignedQuizzes(id)
        }
        removeQuizSet()
        setQuizActivePage(1)
        setQuizSearchTerm("")
    }

    const closeModal = () => {
        setOpen(!open)
        removeQuizSet()
        if(deletedUsers.value === "Not Deleted"){
            getUsersList(activePage, searchTerm, false)
        }
        else{
            getUsersList(activePage, searchTerm, true)
        }
    }

    const saveList = () => {
        const newQuizSet = _.filter(quizSet, ['isChecked', true])
        const uniqQuizSet = _.uniqBy(newQuizSet, 'id')
        if(uniqQuizSet.length !== 0){
            saveUserQuiz(userId, uniqQuizSet)
            setOpen(!open)
        }
    }

    const updateList = () => {
        const newQuizSet = _.filter(quizSet, ['isChecked', true])
        const uniqQuizSet = _.uniqBy(newQuizSet, 'id')
        updateUserQuiz(userId, uniqQuizSet, userDocId)
        setOpen(!open)
    }

    const getModal = (data) => {
        return(
            <Modal open={open}
                   trigger={
                    data.assigned_quiz === true ?
                    <Button primary type="button" onClick={() => handleOpen(data)}> Update Quiz </Button>:
                    <Button primary type="button" onClick={() => handleOpen(data)}> Assign Quiz </Button>}
                   closeOnDimmerClick={false}
                   size='large'
                   onClose={closeModal}
                //    onOpen={handleOpen}
                   closeIcon>
                <Modal.Header> <span>Select Quiz</span> </Modal.Header>
                <Modal.Content className="modal-container">
                    <Input type="text"
                           placeholder="Enter text"
                           className="search-box"
                           onChange={handleQuizChange}
                           action={{
                            color: "blue",
                            icon: "search"
                          }}
                           value={quizsearchTerm} 
                        />  
                    {quizList !== undefined ? quizList.length !== 0 ? getQuizTable() : <h3> No Data </h3> :null}
                    {updateButton ? 
                    <Button type="button" onClick={updateList}>Update</Button>:
                    <Button type="button" onClick={saveList}>Save</Button>}
                    <Button type="button" onClick={closeModal}>Cancel</Button>
                    <div>
                        {quizerror === "" && quizcount.length > pageSize ?
                        <Pagination activePage={quizactivePage}
                                    itemsCountPerPage={itemsCountPerPage}
                                    totalItemsCount={quizcount.length}
                                    pageRangeDisplayed={pageRangeDisplayed}
                                    onChange={handleQuizPageChange} />
                        : quizerror}
                    </div>
                </Modal.Content>
            </Modal>
        )
    }

    const deleteUserData = (id) => {
        setDeleteUserValue(false)
        setOpenValue(true)
        setCurrentUserId(id)
    }

    const confirmUserDelete = (id) => {
        return (
            <Confirm open={openValue}
                    content='Are you sure want to delete this User Permanently?'
                    onCancel={closeUserPopup}
                    className="delete-popup"
                    cancelButton='No, cancel it!'
                    confirmButton="Yes, delete it!"
                    onConfirm={() => renderDeleteUser(id)} />
        )
    }

    const closeUserPopup = () => {
        setOpenValue(false)
        setDeleteUserValue(true)
    }

    const renderDeleteUser = (id) => {
        deleteUser(id)
        setOpenValue(false)
    }

    const getTableBody = () => {
        let serialNo = ((activePage - 1) * pageSize) + 1
        return(
            <Table.Body>
                {usersList.map((data, index) => {
                    return(
                        <Table.Row key={data.id} className="form-data">
                            <Table.Cell> {serialNo + index} </Table.Cell>
                            <Table.Cell> {data.displayName === undefined || data.displayName === null || _.trim(data.displayName) === '' ? '--' : data.displayName } </Table.Cell>
                            <Table.Cell> {data.email !== undefined ? data.email : '--'} </Table.Cell>
                            <Table.Cell> {data.role !== undefined ? data.role : '--'} </Table.Cell>
                            <Table.Cell>
                                {data && data.isDeleted ? 
                                <Button disabled={data && data.isDeleted} icon className="edit-btn" size="big">
                                    <Icon name='edit' />
                                 </Button>

                                 :
                                 <Link to={{ pathname: `/edituser/${data.id}`}}>
                                    <Button disabled={data && data.isDeleted} icon className="edit-btn" size="big">
                                        <Icon name='edit' />
                                    </Button>
                                </Link>
                                }
                                
                                <Button disabled={data && data.isDeleted} icon onClick={() => deleteUserData(data.id)} className="delete-btn">
                                    <Icon name='trash' />
                                </Button>
                            </Table.Cell>
                            <Table.Cell className="user-status">
                                <Select isDisabled={data && data.isDeleted} options={userStatus} 
                                        onChange={(value) => userStatusChange(value, data.id)}
                                        value={userStatus.filter(function(option) {
                                                return option.value === data.status
                                            })} 
                                />
                            </Table.Cell>
                            <Table.Cell className="user-status-btn">
                                {Id === data.id ? <Button primary key={data.id} onClick={() => updateStatusValue(data.id)}> Update </Button> : null}
                            </Table.Cell>
                            <Table.Cell>
                                {data.invite ? 
                                <Button color='green' disabled={true}> Invited </Button> 
                                : <Button primary onClick={() => sendInvite(data.email, data.id, data.role)}> Invite </Button>}
                            </Table.Cell>
                            {data.role === "Employee" ?
                            <Table.Cell>
                                {getModal(data)}
                            </Table.Cell>
                            : null}
                        </Table.Row>
                    )
                })}
            </Table.Body>
        )
    }

    const getTable = () => {
        return(
            <Table basic='very'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell> S.No </Table.HeaderCell>
                        <Table.HeaderCell> Name </Table.HeaderCell>
                        <Table.HeaderCell> Email </Table.HeaderCell>
                        <Table.HeaderCell> Role </Table.HeaderCell>
                        <Table.HeaderCell> Actions </Table.HeaderCell>
                        <Table.HeaderCell> Status </Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {getTableBody()}
                {deleteUserValue === false ? confirmUserDelete(currentUserId) : null}
            </Table>
        )
    }

    const filterChange = (value) => {
        setDeletedUsers(value)
        if(value.value === "Not Deleted"){
            getUsersList(activePage, searchTerm, false)
        }
        else{
            getUsersList(activePage, searchTerm, true)
        }
    }

    const showDeleteuser = (evt, value) => {
       //console.log('valuee :: ',!value)
       setCheck(!value)
       if(!value){
            getUsersList(activePage, searchTerm, true)
       }else{
            getUsersList(activePage, searchTerm, false)
       }
       
    }
    return(
        <div className="wrapper">
            <div className="container">
                <Header />
                <Link className="link-container exam-content" to="/dashboard">
                    <Icon name="arrow left" />
                    Back to Dashboard
                </Link>
                <div>
                    <Input type="text"
                           placeholder="Enter text"
                           className="search-box"
                           onChange={handleChange}
                           action={{
                            color: "blue",
                            icon: "search"
                          }}
                           value={searchTerm} />
                    <Grid>
                        <Grid.Row className="user-filter-dropdown-row">
                            
                            {/* <Grid.Column width={3} className="user-filter-dropdown-column">
                                   
                                
                            </Grid.Column>
                            <Grid.Column width={3} className="user-filter-dropdown-column">
                                <Select options={options}
                                        value={deletedUsers}
                                        onChange={(value) => filterChange(value)} /> 
                            
                                <input type="checkbox" 
                                   name="check"
                                   defaultChecked={check}
                                   onChange={(e) => showDeleteuser(e,check)}/>
                                
                            </Grid.Column> */}
                             <Grid.Column width={3} className="user-filter-dropdown-column">
                             <div className="show-deleted-user">
                                <div>
                                <input type="checkbox" 
                                   name="check"
                                   defaultChecked={check}
                                   onChange={(e) => showDeleteuser(e,check)}/>
                                </div>
                                <div classname="deleted-user-heading">
                                    <p>Show Deleted User</p>
                                </div>
                            </div>
                            </Grid.Column> 
                            <Grid.Column width={12} className="">
                            
                                <Link to="/adduser">
                                    <Button primary floated='right' className="add-btn user-filter-btn">
                                        <Icon name="add" />
                                        Add User 
                                    </Button>
                                </Link>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
                {loading ? <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                {error !== "" ? <h3 className="errorMsg"> {error} </h3> : null}
                {usersList !== undefined ? usersList.length !== 0 ? getTable() : <h3> No Data </h3> :null}
                <div>
                    {error === "" && count.length > pageSize ?
                    <Pagination activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={count.length}
                                pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange} />
                    : error}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.userReducer.loading,
    error: state.userReducer.error,
    disabled: state.userReducer.disabled,
    usersList: state.userReducer.usersList,
    count: state.userReducer.count,
    quizList: state.quizReducer.quizList,
    quizcount: state.quizReducer.count,
    quizerror: state.quizReducer.error,
    quizSet: state.userReducer.quizSet,
    userDocId: state.userReducer.userDocId,
    updateButton: state.userReducer.updateButton
})

const mapDispatchToProps = {
    getUsersList,
    updateUserStatus,
    sendInvitation,
    getQuizList,
    saveQuizSet,
    saveUserQuiz,
    getAssignedQuizzes,
    updateUserQuiz,
    removeQuizSet,
    updateButtonData,
    deleteUser
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList)