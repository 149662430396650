import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { saveCourseCropImageData, sectionCourseImageExists, deleteCourseImage } from '../../../actions/courseActions'
import { saveDisciplineCropImageData, sectionDisciplineImageExists, deleteDisciplineImage } from '../../../actions/DisciplineActions'
import { ImageError, removeCropImage } from '../../../actions/helperActions'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import _ from 'lodash'
import { Confirm } from 'semantic-ui-react'

class ImageContainer extends PureComponent {
  // componentWillReceiveProps(props){
  //   const { input } = props
  //   const { value } = input
  //   if(typeof(value) === "string"){
  //     this.setState({ croppedImageUrl: value })
  //   }
  // }

  state = {
    src: null,
    crop: {
      unit: '%',
      width: 50,
      height: 100,
      x: 0,
      y: 0
    },
    file:'',
    imageError: '',
    showUploadButton: false,
    showImage: true,
    cropSize: '',
    showImageSize: false,
    showCropImage: true,
    deleteValue: true,
    open: true,
  }

  onSelectFile = e => {
    const { form_type } = this.props
    const { name } = this.props.input
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      let files = e.target.files[0]
      localStorage.setItem('filename', files.name)
      this.setState({ showImage: false, showImageSize: true, showCropImage: true })
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(files);
      this.props.removeCropImage(false, name)
      if(form_type === "discipline"){
        this.props.sectionDisciplineImageExists(true, name)
      }
      else{
        this.props.sectionCourseImageExists(true, name)
      }
      // this.setState({ croppedImageUrl: ''})
    }
  }

  onImageLoaded = image => {
    this.imageRef = image;
  }

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  }

  onCropChange = (crop) => {
    this.setState({ crop, cropSize: crop });
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      )
      let blob = await fetch(croppedImageUrl).then(r => r.blob())
      this.setState({ croppedImageUrl, file: blob, showUploadButton: true });
      this.props.ImageError(true)
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    var imgData=ctx.getImageData(0,0,canvas.width,canvas.height);
    var data=imgData.data;
    for(var i=0;i<data.length;i+=4){
        if(data[i+3]<255){
            data[i]=255;
            data[i+1]=255;
            data[i+2]=255;
            data[i+3]=255;
        }
    }
    ctx.putImageData(imgData,0,0)

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg')
    })
  }

  renderUploadImage(event){
      event.preventDefault()
      const { file } = this.state
      const { folderName, input, saveCourseCropImageData, saveDisciplineCropImageData, form_type } = this.props
      const { name } = input
      let filename = localStorage.getItem('filename')
      file.lastModifiedDate = new Date()
      file.lastModified =  new Date().getTime()
      file.name = filename
      var fileSize = file.size / 1024 / 1024
      if(file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg"){
        if(fileSize < 2){
            let obj = {
                'file': file,
                'name': name,
                'folderName': folderName
            }
            if(form_type === "courses"){
              saveCourseCropImageData(obj)
            }
            else{
              saveDisciplineCropImageData(obj)
            }
            input.onChange(file)
            this.setState({ imageError: '', showCropImage: false, showUploadButton: false })
            this.props.ImageError(false)
        }
        else{
            this.setState({ imageError: 'Please choose a valid image with size less than 2MB' })
        }
      }
      else{
        this.setState({ imageError: 'Please choose a valid image with size less than 2MB' })
    }
  }

  confirmDelete(id){
    const { open } = this.state
    return (
      <Confirm open={open}
               content='Are you sure want to delete this Image?'
               onCancel={this.close.bind(this)}
               className="delete-popup"
               cancelButton='No, cancel it!'
               confirmButton="Yes, delete it!"
               onConfirm={this.renderDeleteImage.bind(this)} />
    )
  }

  close(){
    this.setState({ open: false, deleteValue: true })
  }

  renderImageDelete(value, viewSingleList, name, Id){
    const { form_type } = this.props
    let file_name = value.substr(0, value.lastIndexOf('.'));
    var path_splitted = value.split('.');
    var extension = path_splitted.pop();
    let onlyExtension = extension.split('?')
    let file_url = file_name + "." + onlyExtension[0]
    file_url = file_url.match(/[^/]+(jpg|png|gif|jpeg)$/);
    file_url = file_url[0].replace(/%2F/g, "/")
    if(form_type === "discipline"){
      this.props.deleteDisciplineImage(file_url, viewSingleList, name, Id)
    }
    else{
      this.props.deleteCourseImage(file_url, viewSingleList, name, Id)
    }
  }

  renderDeleteImage(){
    const { value, name } = this.props.input
    const { viewSingleList, Id, form_type, cropDisciplineImageData, cropCourseImageData, courseDetailList } = this.props
    this.setState({ showUploadButton: false, showCropImage: false, showImageSize: false, croppedImageUrl: '' })
    this.props.ImageError(false)
    this.props.removeCropImage(true, name)
    this.fileInput.value = ""
    if(cropDisciplineImageData === undefined && cropDisciplineImageData.length === 0 && form_type === "discipline"){
      localStorage.removeItem(name)
      this.props.saveDisciplineCropImageData([], "remove")
    }
    else if(cropDisciplineImageData !== undefined && cropDisciplineImageData.length !== 0 && form_type === "discipline"){
      localStorage.removeItem(name)
      let newData = _.filter(cropDisciplineImageData, function(o) {
        return o.name !== name
      });
      this.props.saveDisciplineCropImageData(newData, "remove")
    }
    if(cropCourseImageData === undefined && cropCourseImageData.length === 0 && form_type === "courses"){
      localStorage.removeItem(name)
      this.props.saveCourseCropImageData([], "remove")
    }
    else if(cropCourseImageData !== undefined && cropCourseImageData.length !== 0 && form_type === "courses"){
      localStorage.removeItem(name)
      let newData = _.filter(cropCourseImageData, function(o) {
        return o.name !== name
      });
      this.props.saveCourseCropImageData(newData, "remove")
    }
    if(form_type === "discipline"){
      this.props.sectionDisciplineImageExists(false, name)
    }
    else{
      this.props.sectionCourseImageExists(false, name)
    }
    this.setState({ open: false })

    if(Id !== undefined && Id !== null && !_.isEmpty(value)){
      if(viewSingleList.course_img_url !== null && name === "course_img_url"){
        this.renderImageDelete(value, viewSingleList, name, Id)
      }
      if(viewSingleList.job_img_url !== null && name === "job_img_url"){
        this.renderImageDelete(value, viewSingleList, name, Id)
      }
      if(courseDetailList.course_summary_img_url !== null && name === "course_summary_img_url"){
        this.renderImageDelete(value, courseDetailList, name, Id)
      }
      if(courseDetailList.entry_summary_img_url !== null && name === "entry_summary_img_url"){
        this.renderImageDelete(value, courseDetailList, name, Id)
      }
      if(courseDetailList.apply_img_url !== null && name === "apply_img_url"){
        this.renderImageDelete(value, courseDetailList, name, Id)
      }
      if(courseDetailList.entrance_img_url !== null && name === "entrance_img_url"){
        this.renderImageDelete(value, courseDetailList, name, Id)
      }
      if(courseDetailList.career_img_url !== null && name === "career_img_url"){
        this.renderImageDelete(value, courseDetailList, name, Id)
      }
    }
  }

  renderRemoveImage(){
    this.setState({ open: true, deleteValue: false })
  }

  render() {
    const { crop, croppedImageUrl, src, showUploadButton, imageError, showImage, cropSize, showImageSize, showCropImage, deleteValue } = this.state
    const { meta: { touched, error }, maxHeight, maxWidth, input, uploadError, removeCourseImage, removeJobImage, courseSectionImage, jobSectionImage, removeCourseSummaryImage, removeEntryImage, removeApplyImage, removeEntranceImage, removeCareerImage, courseSummarySectionImage, entrySectionImage, applySectionImage, entranceSectionImage, careerSectionImage } = this.props
    const { name, value } = input
    let removeImage

    if(name === "course_img_url"){
      removeImage = removeCourseImage
    }
    else if(name === "job_img_url"){
      removeImage = removeJobImage
    }
    else if(name === "course_summary_img_url"){
      removeImage = removeCourseSummaryImage
    }
    else if(name === "entry_summary_img_url"){
      removeImage = removeEntryImage
    }
    else if(name === "apply_img_url"){
      removeImage = removeApplyImage
    }
    else if(name === "entrance_img_url"){
      removeImage = removeEntranceImage
    }
    else if(name === "career_img_url"){
      removeImage = removeCareerImage
    }

    if(typeof(value) === "string" && showImage){
      this.setState({ croppedImageUrl: value })
    }

    return (
      <div className="App">
        <div>
          {imageError && <div className="errorMsg">{imageError}</div>}
          <p className="img-size"> (Recommended Size of image to upload in this section is {maxWidth} / {maxHeight}) </p>
          <input type="file" accept="image/gif, image/jpeg, image/png" onChange={this.onSelectFile.bind(this)} id={name} name="imagee" ref={ref=> this.fileInput = ref} />
          {showImageSize && !removeImage ? <p className="crop-img-size"> Your image size is {cropSize.width} / {cropSize.height} (Recommended Size: {_.round(maxWidth,2)} / {_.round(maxHeight,2)}) </p> : null}
        </div>
        {showCropImage && src && (
          <ReactCrop
            src={src}
            crop={crop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
          />
        )}
        {croppedImageUrl && !removeImage && (
          <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
        )}
        {deleteValue === false ? this.confirmDelete() : null}
        <div style={{ display: 'flex' }}>
          {showUploadButton && !removeImage ? <button  style={{ display: 'block' }} onClick={this.renderUploadImage.bind(this)}> Upload </button> : null}
          {courseSectionImage && name === "course_img_url" ? <button type="button" style={{ marginLeft: '12px' }} onClick={this.renderRemoveImage.bind(this)}> Remove </button> : null}
          {jobSectionImage && name === "job_img_url" ? <button type="button" style={{ marginLeft: '12px' }} onClick={this.renderRemoveImage.bind(this)}> Remove </button> : null}
          {courseSummarySectionImage && name === "course_summary_img_url" ? <button type="button" style={{ marginLeft: '12px' }} onClick={this.renderRemoveImage.bind(this)}> Remove </button> : null}
          {entrySectionImage && name === "entry_summary_img_url" ? <button type="button" style={{ marginLeft: '12px' }} onClick={this.renderRemoveImage.bind(this)}> Remove </button> : null}
          {applySectionImage && name === "apply_img_url" ? <button type="button" style={{ marginLeft: '12px' }} onClick={this.renderRemoveImage.bind(this)}> Remove </button> : null}
          {entranceSectionImage && name === "entrance_img_url" ? <button type="button" style={{ marginLeft: '12px' }} onClick={this.renderRemoveImage.bind(this)}> Remove </button> : null}
          {careerSectionImage && name === "career_img_url" ? <button type="button" style={{ marginLeft: '12px' }} onClick={this.renderRemoveImage.bind(this)}> Remove </button> : null}
        </div>
        <div>
            {/* {uploadError ?  <h5 className="errorMsg">Please click on upload button to upload image</h5> : null} */}
            {touched && error && <span className="errorMsg">{error.message}</span>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    uploadError: state.helperReducer.uploadError,
    removeCourseImage: state.helperReducer.removeCourseImage,
    removeJobImage: state.helperReducer.removeJobImage,
    removeCourseSummaryImage: state.helperReducer.removeCourseSummaryImage,
    removeEntryImage: state.helperReducer.removeEntryImage,
    removeApplyImage: state.helperReducer.removeApplyImage,
    removeEntranceImage: state.helperReducer.removeEntranceImage,
    removeCareerImage: state.helperReducer.removeCareerImage,
    courseSectionImage: state.disciplineReducer.courseSectionImage,
    jobSectionImage: state.disciplineReducer.jobSectionImage,
    viewSingleList: state.disciplineReducer.viewSingleList,
    courseSummarySectionImage: state.courseReducer.courseSummarySectionImage,
    entrySectionImage: state.courseReducer.entrySectionImage,
    applySectionImage: state.courseReducer.applySectionImage,
    entranceSectionImage: state.courseReducer.entranceSectionImage,
    careerSectionImage: state.courseReducer.careerSectionImage,
    courseDetailList: state.courseReducer.courseDetailList,
    cropDisciplineImageData: state.disciplineReducer.cropDisciplineImageData,
    cropCourseImageData: state.courseReducer.cropCourseImageData
})

const mapDispatchToProps = {
    saveCourseCropImageData,
    ImageError,
    removeCropImage,
    saveDisciplineCropImageData,
    deleteCourseImage,
    deleteDisciplineImage,
    sectionDisciplineImageExists,
    sectionCourseImageExists
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageContainer)
