import { GET_QUIZ_QUESTION_LIST_REQUEST, CURRENT_QUIZ_QUESTION_REQUEST, ADD_QUIZ_QUESTION_REQUEST, DELETE_QUIZ_QUESTION_REQUEST, VIEW_SINGLE_QUIZ_QUESTION_REQUEST, UPDATE_QUIZ_QUESTION_REQUEST, SAVE_IMAGE_REQUEST, CHANGE_UPLOAD_ANSWER_REQUEST, SAVE_QUESTION_TYPE, GET_FILTERED_QUESTION_LIST_REQUEST } from '../constants/QuizQuestionConstants'

export const getQuizQuestionList = (activePage, searchTerm, tagListForFilter) => {
    return{
        type: GET_QUIZ_QUESTION_LIST_REQUEST,
        activePage, 
        searchTerm,
        tagListForFilter
    }
}

export const currentQuizQuestion = () => {
    return{
        type: CURRENT_QUIZ_QUESTION_REQUEST
    }
}

export const addQuizQuestion = (data, imageData) => {
    return{
        type: ADD_QUIZ_QUESTION_REQUEST,
        data,
        imageData
    }
}

export const deleteQuizQuestion = (id) => {
    return{
        type: DELETE_QUIZ_QUESTION_REQUEST,
        id
    }
}

export const viewSingleQuizQuestion = (id) => {
    return{
        type: VIEW_SINGLE_QUIZ_QUESTION_REQUEST,
        id
    }
}

export const updateQuizQuestion = (data, imageData) => {
    return{
        type: UPDATE_QUIZ_QUESTION_REQUEST,
        data,
        imageData
    }
}

export const saveImage = (obj) => {
    return{
        type: SAVE_IMAGE_REQUEST,
        obj
    }
}

export const changeUploadAnswer = (value) => {
    return{
        type: CHANGE_UPLOAD_ANSWER_REQUEST  ,
        value   
    }
}

export const saveQuestionType = (value) => {
    return{
        type: SAVE_QUESTION_TYPE,
        value
    }
}

export const getFilteredQuestionList = (category, questionType, level, featured, activePage, searchTerm, selectTag) => {
    return{
        type: GET_FILTERED_QUESTION_LIST_REQUEST,
        category, 
        questionType, 
        level, 
        featured, 
        activePage, 
        searchTerm,
        selectTag
    }
}