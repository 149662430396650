const validate = (values) => {
    const errors = {errorName:{}};
    if(!values.first_name){
        errors.first_name = {
            message: 'This field is Required'
        }
    }
    if(!values.last_name){
        errors.last_name = {
            message: 'This field is Required'
        }
    }
    if(!values.email){
        errors.email = {
            message: 'This field is Required'
        }
    }
    return errors
}

export default validate