import { GET_COURSE_LIST_REQUEST, CURRENT_COURSE_REQUEST, ADD_COURSE_REQUEST, DELETE_COURSE_DATA_REQUEST, VIEW_SINGLE_COURSE_DATA_REQUEST, UPDATE_COURSE_REQUEST, ADD_COURSE_DETAILS_REQUEST, VIEW_COURSE_DETAILS_REQUEST, UPDATE_COURSE_STATUS_REQUEST, GET_ALL_COURSE_NAME_REQUEST, SAVE_COURSE_CROP_IMAGE_DATA, SECTION_COURSE_IMAGE_EXISTS, DELETE_COURSE_IMAGE_REQUEST } from '../constants/CourseConstants'

export const getCourseList = (activePage, searchTerm) => {
    return{
        type: GET_COURSE_LIST_REQUEST,
        activePage, 
        searchTerm
    }
}

export const currentCourse = () => {
    return{
        type: CURRENT_COURSE_REQUEST
    }
}

export const addCourseList = (data) => {
    return{
        type: ADD_COURSE_REQUEST,
        data
    }
}

export const deleteCourseData = (id) => {
    return{
        type: DELETE_COURSE_DATA_REQUEST,
        id
    }
}

export const viewSingleCourseData = (id) => {
    return{
        type: VIEW_SINGLE_COURSE_DATA_REQUEST,
        id
    }
}

export const updateCourseList = (data) => {
    return{
        type: UPDATE_COURSE_REQUEST,
        data
    }
}

export const addCourseDetails = (data, cropCourseImageData) => {
    return{
        type: ADD_COURSE_DETAILS_REQUEST,
        data,
        cropCourseImageData
    }
}

export const viewCourseDetails = (id) => {
    return{
        type: VIEW_COURSE_DETAILS_REQUEST,
        id
    }
}

export const updateCourseStatus = (id, value) => {
    return{
        type: UPDATE_COURSE_STATUS_REQUEST,
        id, 
        value
    }
}

export const getAllCourseName = () => {
    return{
        type: GET_ALL_COURSE_NAME_REQUEST
    }
}

export const saveCourseCropImageData = (newObj, key) => {
    return{
        type: SAVE_COURSE_CROP_IMAGE_DATA,
        newObj,
        key
    }
}

export const sectionCourseImageExists = (value, name) => {
    return{
        type: SECTION_COURSE_IMAGE_EXISTS,
        value,
        name
    }
}

export const deleteCourseImage = (file_url, list, name, id) => {
    return{
        type: DELETE_COURSE_IMAGE_REQUEST,
        file_url,
        list,
        name,
        id
    }
}