import { EXAM_DATA_REQUEST, EXAM_DATA_SUCCESS, EXAM_DATA_ERROR, VIEW_SINGLE_EXAM_REQUEST, VIEW_SINGLE_EXAM_SUCCESS, ADD_EXAM_DATA, ADD_EXAM_DATA_SUCCESS, ADD_EXAM_ERROR, DELETE_EXAM_DATA_SUCCESS, UPDATE_EXAM_DATA, UPDATE_EXAM_DATA_SUCCESS, UPDATE_EXAM_DATA_ERROR, CURRENT_EXAM_SUCCESS, UPDATE_EXAM_STATUS_REQUEST, UPDATE_EXAM_STATUS_SUCCESS } from '../constants/ExamConstants'

const initialState = {
    examData: [],
    viewSingleData:{nationality:"", created_at:"", updated_at:"", exam_name: "", job_sector: "", job_department: "", job_position: "", exam_body:"", educational_qualification:"", min_marks_obtained:"", subjects:"", type_of_degree:"", other_requirement:"", experience_required:"", final_year_candidate:false, minimum_age: "", maximum_age: "", marital_status: "", male_height:"", female_height:"", male_chest:"", female_chest:"", body_weight: "", eye_sight: "", handicapped: false, color_blindness: false, certification: "", certification_name: "", exam_language: "", level: "", job_type: "", exam_time: "", isDeleted: ""},
    viewData:{nationality:"", created_at:"", updated_at:"", exam_name: "", job_sector: "", job_department: "", job_position: "", exam_body:"", educational_qualification:"", min_marks_obtained:"", subjects:"", type_of_degree:"", other_requirement:"", experience_required:"", final_year_candidate:false, minimum_age: "", maximum_age: "", marital_status: "", male_height:"", female_height:"", male_chest:"", female_chest:"", body_weight: "", eye_sight: "", handicapped: false, color_blindness: false, certification: "", certification_name: "", exam_language: "", level: "", job_type: "", exam_time: "", isDeleted: ""},
    error: "",
    getExamError: "",
    loading: false,
    isLoading: false,
    count: []
}

const Form = (state = initialState, action) => {
    switch(action.type){
        case EXAM_DATA_REQUEST:
            return{
                ...state,
                loading: true
            }
        case EXAM_DATA_SUCCESS:
            return{
                ...state,
                examData: action.array,
                loading: false,
                getExamError: "",
                count: action.count
            }
        case EXAM_DATA_ERROR:
            return{
                loading: false,
                getExamError: action.err
            }
        case VIEW_SINGLE_EXAM_REQUEST:
            return{
                ...state,
                loading: true
            }
        case VIEW_SINGLE_EXAM_SUCCESS:
            return{
                ...state,
                viewSingleData: action.obj,
                loading: false
            }
        case ADD_EXAM_DATA:
            return{
                ...state,
                loading: true
            }
        case ADD_EXAM_DATA_SUCCESS: 
            return{
                ...state,
                loading: false
            }
        case ADD_EXAM_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case DELETE_EXAM_DATA_SUCCESS:
            return{
                ...state,
                examData: state.examData.filter(user => user.id !== action.id)
            }
        case UPDATE_EXAM_DATA:
            return{
                ...state,
                loading: true
            }
        case UPDATE_EXAM_DATA_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case UPDATE_EXAM_DATA_ERROR:
            return{
                error: action.err
            }
        case CURRENT_EXAM_SUCCESS:
            return{
                ...state,
                viewSingleData: initialState.viewSingleData
            }
        case UPDATE_EXAM_STATUS_SUCCESS:
            return{
                ...state,
                loading: false
            }
        default:
            return state
    }
}

export default Form