import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Header from '../Header/Header'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import FormContainer from './FormContainer'
import ReactLoading from "react-loading"
import { db } from '../firebase'
import { authHeader, history } from '../../_helpers'
import { viewSingleCourseData, currentCourse, addCourseList, updateCourseList } from '../../actions/courseActions'
import { getEducationalData } from '../../actions/helperActions'

const AddCourse = (props) => {
    const { pathname } = props.location
    const { viewSingleCourseData, currentCourse, addCourseList, updateCourseList, getEducationalData, loading, error } = props

    useEffect(() => {
        if(authHeader){
            if(localStorage.getItem('token')){
                authHeader(pathname)
                getEducationalData()
            }
            else{
                history.push('/login') 
            }
        }
        if(props.match && props.match.params && props.match.params.id){
            const newCategory = props.match.params.id
            viewSingleCourseData(newCategory)
        }
        else{
            currentCourse()
        }
    },[pathname, props.match, viewSingleCourseData, currentCourse, getEducationalData])

    const handleSubmit = (data) => {
        let uid = localStorage.getItem('uid')
        let course_id = localStorage.getItem('course_id');
        if(course_id === null || course_id === ""){
            data['created_by'] = db.doc('users/' + uid)
            data['modified_by'] = db.doc('users/' + uid)
            data['created_date_time'] = new Date()
            data['modified_date_time'] = new Date()
            data['isDeleted'] = false
            data['status'] = 'Draft'
            addCourseList(data)
        }
        else{
            data['modified_date_time'] = new Date()
            updateCourseList(data)
        }
    }

    return(
        <div className="wrapper">
            <div className="container">
                {error !== "" ? <h5 className="errorMsg"> {error} </h5>: null}
                {loading ?  <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                <Header />
                <Link className="link-container" to="/courseList"> <Icon name="arrow left" /> Back to Course List </Link>
                <FormContainer onSubmit={handleSubmit} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.courseReducer.loading,
    error: state.courseReducer.error
})

const mapDispatchToProps = {
    currentCourse,
    viewSingleCourseData,
    addCourseList,
    updateCourseList,
    getEducationalData
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCourse)