import { GET_QUIZ_LIST_REQUEST, CURRENT_QUIZ_REQUEST, ADD_QUIZ_REQUEST, DELETE_QUIZ_REQUEST, VIEW_SINGLE_QUIZ_REQUEST, UPDATE_QUIZ_REQUEST, GET_QUESTIONS_REQUEST, GET_ALL_QUESTIONS_REQUEST, GET_SAVED_QUESTIONS_REQUEST, MAKE_QUESTION_SET_REQUEST, SET_CATEGORY_VALUE, SET_QUESTION_TYPE_VALUE, SET_LEVEL_VALUE, SET_FEATURED_VALUE, SAVE_TOTAL_QUESTIONS, SHOW_NO_OF_QUESTIONS, RANDOM_QUESTION_VALUE, SAVE_TIME_LIMIT, GENERATE_RANDOM_QUESTIONS_REQUEST, SAVE_ACCESS_TYPE, SHOW_QUESTIONS, SAVE_CROP_IMAGE_DATA, REMOVE_CROP_IMAGE, DELETE_BANNER_IMAGE_REQUEST, BANNER_IMAGE_EXISTS, SAVE_ALLOWED_USER, SET_TAG_VALUE } from '../constants/QuizConstants'

export const getQuizList = (activePage, searchTerm, key) => {
    return{
        type: GET_QUIZ_LIST_REQUEST,
        activePage, 
        searchTerm,
        key
    }
}

export const currentQuiz = () => {
    return{
        type: CURRENT_QUIZ_REQUEST
    }
}

export const addQuiz = (data, cropImageData) => {
    return{
        type: ADD_QUIZ_REQUEST,
        data,
        cropImageData
    }
}

export const deleteQuiz = (id) => {
    return{
        type: DELETE_QUIZ_REQUEST,
        id
    }
}

export const viewSingleQuiz = (id) => {
    return{
        type: VIEW_SINGLE_QUIZ_REQUEST,
        id
    }
}

export const updateQuiz = (data, cropImageData) => {
    return{
        type: UPDATE_QUIZ_REQUEST,
        data,
        cropImageData
    }
}

export const getQuestions = (question_category, question_type, level, featured, tagValue) => {
    console.log('tagvalue in getQuestions:: ',tagValue)
    return{
        type: GET_QUESTIONS_REQUEST,
        question_category, 
        question_type,
        level, 
        featured,
        tagValue
    }
}

export const getAllQuestions = (question_category, question_type, level, featured, tagValue) => {
    console.log('tagvalue in getAllQuestions:: ',tagValue)
    return{
        type: GET_ALL_QUESTIONS_REQUEST,
        question_category, 
        question_type,
        level, 
        featured,
        tagValue
    }
}

export const getSavedQuestions = (questions) => {
    return{
        type: GET_SAVED_QUESTIONS_REQUEST,
        questions
    }
}

export const makeQuestionSet = (list) => {
    return{
        type: MAKE_QUESTION_SET_REQUEST,
        list
    }
}

export const setCategoryValue = (value) => {
    return{
        type: SET_CATEGORY_VALUE,
        value
    }
}

export const setQuestionTypeValue = (value) => {
    return{
        type: SET_QUESTION_TYPE_VALUE,
        value
    }
}

export const setLevelValue = (value) => {
    return{
        type: SET_LEVEL_VALUE,
        value
    }
}

export const setFeaturedValue = (value) => {
    return{
        type: SET_FEATURED_VALUE,
        value
    }
}

export const setTagValue = (value) => {
    return{
        type: SET_TAG_VALUE,
        value
    }
}

export const saveTotalQuestions = (value) => {
    return{
        type: SAVE_TOTAL_QUESTIONS,
        value
    }
}

export const showNoOfQuestions = (value) => {
    return{
        type: SHOW_NO_OF_QUESTIONS,
        value
    }
}

export const randomQuestionValue = (value) => {
    return{
        type: RANDOM_QUESTION_VALUE,
        value
    }
}

export const saveTimeLimit = (value) => {
    return{
        type: SAVE_TIME_LIMIT,
        value
    }
}

export const generateRandomQuestions = (question_category, question_type, level, featured, totalQuestions) => {
    return{
        type: GENERATE_RANDOM_QUESTIONS_REQUEST,
        question_category, 
        question_type,
        level, 
        featured,
        totalQuestions
    }
}

export const saveAccessType = (value) => {
    return{
        type: SAVE_ACCESS_TYPE,
        value
    }
}

export const saveAllowedUser = (value) => {
    return{
        type: SAVE_ALLOWED_USER,
        value
    }
}

export const showQuestionsData = (value) => {
    return{
        type: SHOW_QUESTIONS,
        value
    }
}

export const saveCropImageData = (newObj) => {
    return{
        type: SAVE_CROP_IMAGE_DATA,
        newObj
    }
}

export const removeCropImage = (value) => {
    return{
        type: REMOVE_CROP_IMAGE,
        value
    }
}

export const deleteBannerImage = (file_url, list) => {
    return{
        type: DELETE_BANNER_IMAGE_REQUEST,
        file_url,
        list
    }
}

export const bannerImageExists = (value) => {
    return{
        type: BANNER_IMAGE_EXISTS,
        value
    }
}
