import { GET_EDUCATIONAL_DATA_REQUEST, UPLOAD_IMAGE_REQUEST, GET_SUBJECT_REQUEST, IMAGE_ERROR, REMOVE_CROP_IMAGE } from '../constants/HelperConstants'

export const getEducationalData = () => {
    return{
        type: GET_EDUCATIONAL_DATA_REQUEST
    }
}

export const uploadImage = (data, name, folderName) => {
    return{
        type: UPLOAD_IMAGE_REQUEST,
        data,
        name,
        folderName
    }
}

export const getSubjectList = () => {
    return{
        type: GET_SUBJECT_REQUEST
    }
}

export const ImageError = (value) => {
    return{
        type: IMAGE_ERROR,
        value
    }
}

export const removeCropImage = (value, name) => {
    return{
        type: REMOVE_CROP_IMAGE,
        value,
        name
    }
}