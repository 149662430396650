import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReduxFormSelect from '../library/select/ReduxFormSelect'
import TextAreaContainer from '../library/textarea/TextAreaContainer'
import ImageContainer from '../library/image/ImageContainer'
import classnames from 'classnames'
import { tags } from '../docs/disciplineData'
import { Button, Form, Grid, Header, Icon, Segment } from 'semantic-ui-react'
import { Field, reduxForm, FieldArray } from 'redux-form'
import Label from '../library/Label'
import Input from '../library/Input'
import validate from './validate'

class FormContainer extends Component{
    renderField({ input, label, placeholder, type, meta: { touched, error }, disabled, className }) {
        return (
            <Form.Field className={classnames({error:touched && error})}>
                <Label className={className}>{label} </Label>
                <Input {...input} placeholder={placeholder} type={type} disabled={disabled} />
                {touched && error && <span className="errorMsg">{error.message}</span>}
            </Form.Field>
        )
    }

    renderLevel({ input, placeholder, type, meta: { touched, error } }){
        return(
            <div className="level-container">
                <Input {...input} type={type} placeholder={placeholder} />
                {touched && error && <div className="errorMsg">{error}</div>}
            </div>
        )
    }

    renderProjectionLevel({ input, placeholder, type, meta: { touched, error } }){
        return(
            <div className="level-container projection-container">
                <Input {...input} type={type} placeholder={placeholder} />
                {touched && error && <div className="errorMsg">{error}</div>}
            </div>
        )
    }

    renderProspects({ fields, renderLevel }){
        const { name } = fields
        let newName = name
        return(
            <div>
                <div className="levels-container">
                    <label> Key </label>
                    <Field
                        name='prospects_level1_key'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Key"
                    />
                    <label> Value </label>
                    <Field
                        name='prospects_level1_value'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Value"
                    />
                </div>
                {/* {newName === "prospects_level" ? <Icon name="plus" className="add-level-icon add-levels" onClick={() => fields.push({})} /> : null} */}
                <div>
                    <Field
                        name='prospects_level2_key'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Key"
                    />
                    <Field
                        name='prospects_level2_value'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Value"
                    />
                </div>
                <div>
                    <Field
                        name='prospects_level3_key'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Key"
                    />
                    <Field
                        name='prospects_level3_value'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Value"
                    />
                </div>
                <div>
                    <Field
                        name='prospects_level4_key'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Key"
                    />
                    <Field
                        name='prospects_level4_value'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Value"
                    />
                </div>
                {/* {fields.map((level, index) => (
                    <div key={index}>
                        <Field
                            name={`${level}.key`}
                            type="text"
                            component={renderLevel}
                            placeholder="Enter Key"
                        />
                        <Field
                            name={`${level}.value`}
                            type="text"
                            component={renderLevel}
                            placeholder="Enter Value"
                        />
                        <Icon name="minus" className="minus-level-icon" onClick={() => fields.remove(index)} />
                    </div>
                ))} */}
            </div>
        )
    }

    renderSalary({ fields, renderLevel }){
        const { name } = fields
        let newName = name
        return(
            <div>
                <div className="salary-level-container">
                    <label> Key </label>
                    <Field
                        name='salary_level1_key'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Key"
                    />
                    <label> Text </label>
                    <Field
                        name='salary_level1_text'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Text"
                    />
                    <label> Value </label>
                    <Field
                        name='salary_level1_value'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Value"
                    />
                </div>
                {/* {newName === "salary_range" ? <Icon name="plus" className="add-level-icon add-salary-levels" onClick={() => fields.push({})} /> : null} */}
                <div>
                    <Field
                        name='salary_level2_key'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Key"
                    />
                    <Field
                        name='salary_level2_text'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Text"
                    />
                    <Field
                        name='salary_level2_value'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Value"
                    />
                </div>
                <div>
                    <Field
                        name='salary_level3_key'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Key"
                    />
                    <Field
                        name='salary_level3_text'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Text"
                    />
                    <Field
                        name='salary_level3_value'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Value"
                    />
                </div>
                <div>
                    <Field
                        name='salary_level4_key'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Key"
                    />
                    <Field
                        name='salary_level4_text'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Text"
                    />
                    <Field
                        name='salary_level4_value'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Value"
                    />
                </div>
                {/* {fields.map((salary, index) => (
                    <div key={index}>
                        <Field
                            name={`${salary}.key`}
                            type="text"
                            component={renderLevel}
                            placeholder="Enter Key"
                        />
                        <Field
                            name={`${salary}.text`}
                            type="text"
                            component={renderLevel}
                            placeholder="Enter text"
                        />
                        <Field
                            name={`${salary}.value`}
                            type="text"
                            component={renderLevel}
                            placeholder="Enter Value"
                        />
                        <Icon name="minus" className="minus-level-icon" onClick={() => fields.remove(index)} />
                    </div>
                ))} */}
            </div>
        )
    }

    renderJob({ fields, renderLevel }){
        const { name } = fields
        let newName = name
        return(
            <div>
                <Field
                    name='job_level1_key'
                    type="text"
                    component={renderLevel}
                    placeholder="Enter Key"
                />
                {newName === "job_name" ? <Icon name="plus" className="add-level-icon" onClick={() => fields.push({})} /> : null}
                {fields.map((job, index) => (
                    <div key={index}>
                        <Field
                            name={`${job}.key`}
                            type="text"
                            component={renderLevel}
                            placeholder="Enter Key"
                        />
                        <Icon name="minus" className="minus-level-icon" onClick={() => fields.remove(index)} />
                    </div>
                ))}
            </div>
        )
    }

    renderOpportunity({ fields, renderLevel }){
        const { name } = fields
        let newName = name
        return(
            <div>
                <div className="levels-container">
                    {/* <label> Key </label>
                    <Field
                        name='opportunity_key'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Key"
                    />
                    <label> Value </label> */}
                    <Field
                        name='opportunity_value'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Value"
                    />
                </div>
                {newName === "opportunity_service" ? <Icon name="plus" className="add-level-icon add-levels projection-level" onClick={() => fields.push({})} /> : null}
                {fields.map((opportunity, index) => (
                    <div key={index}>
                        {/* <Field
                            name={`${opportunity}.key`}
                            type="text"
                            component={renderLevel}
                            placeholder="Enter Key"
                        /> */}
                        <Field
                            name={`${opportunity}.value`}
                            type="text"
                            component={renderLevel}
                            placeholder="Enter Value"
                        />
                        <Icon name="minus" className="minus-level-icon" onClick={() => fields.remove(index)} />
                    </div>
                ))}
            </div>
        )
    }

    render(){
        const { loading, handleSubmit, reset, submitting, pristine, disabled, Id } = this.props
        let discipline_id = localStorage.getItem('discipline_id')
        return(
            <form onSubmit={handleSubmit}>
                <Segment>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Field name="name" component={this.renderField} type="text" disabled={disabled} label="Discipline" placeholder="Enter Discipline Name" className="required_field" />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Field name="display_name" component={this.renderField} type="text" label="Display Name of discipline" placeholder="Enter Name" className="required_field" />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container"> Course Summary </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Field name="course_title" component={this.renderField} type="text" label="Title" placeholder="Enter Title" className="required_field" />
                                <Label className="required_field"> Summary </Label>
                                <Field name="summary" component={TextAreaContainer} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="required_field"> Tags </Label>
                                <Field name="tags" component={ReduxFormSelect} isMulti={true} options={tags} placeholder="Enter Tags" />
                                <Label> Section Image </Label>
                                <Field name="course_img_url" folderName="discipline" maxWidth={340} maxHeight={332} form_type="discipline" component={ImageContainer} Id={Id} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container"> Growth Prospects </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Field name="growth_title" component={this.renderField} type="text" label="Title" placeholder="Enter Title" className="required_field" />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="label-heading"> Prospects Level </Label>
                                <FieldArray name="prospects_level" component={this.renderProspects} renderLevel={this.renderLevel} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container label-heading required_field"> Salary range and Income </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={7} textAlign='left'>
                                <Field name="salary_title" component={this.renderField} type="text" label="Title" placeholder="Enter Title" />
                            </Grid.Column>
                            <Grid.Column width={9} textAlign='left'>
                            <FieldArray name="salary_range" component={this.renderSalary} renderLevel={this.renderLevel} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container"> Jobs </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Field name="jobs_title_text" component={this.renderField} type="text" label="Title Text" placeholder="Enter Title" className="required_field" />
                                <Label className="required_field"> Jobs Name </Label>
                                <FieldArray name="job_name" component={this.renderJob} renderLevel={this.renderLevel} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Label> Section Image </Label>
                                <Field name="job_img_url" folderName="discipline" maxWidth={245} maxHeight={188} form_type="discipline" component={ImageContainer} Id={Id} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container"> Key Projections </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Field name="market_title_text" component={this.renderField} type="text" label="Title Text" placeholder="Enter Title" className="required_field" />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="label-heading required_field"> Projection Points </Label>
                                <FieldArray name="opportunity_service" component={this.renderOpportunity} renderLevel={this.renderProjectionLevel} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                {discipline_id === "" || discipline_id === null ?
                    <div className='btns-container'>   
                        <Button size='large' primary disabled={loading || submitting}> Submit </Button>
                        <Button size='large' color="grey" type="button" disabled={pristine || submitting} onClick={reset} className="reset-button"> Reset </Button>           
                    </div>             
                    :<div className='btns-container'>
                        <Button size='large' primary disabled={loading}> Update </Button>
                        <Button size='large' color="grey" onClick={reset} className="reset-button"> Cancel </Button>
                    </div>
                }
            </form>
        )
    }
}

const mapStateToProps = (state) => ({
    disabled: state.disciplineReducer.disabled,
    loading: state.disciplineReducer.loading,
    initialValues: state.disciplineReducer.viewSingleList
})

export default connect(mapStateToProps)(reduxForm({form: 'simple', validate, enableReinitialize: true })(FormContainer))