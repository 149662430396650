import { call, put, takeLatest, delay } from 'redux-saga/effects'
import "firebase/auth"
import firebase from '../components/firebase'
import { history } from '../_helpers'
import { db } from '../components/firebase'
import _ from 'lodash'
import { GET_CATEGORY_LIST_REQUEST, GET_CATEGORY_LIST_SUCCESS, GET_CATEGORY_LIST_ERROR, ADD_CATEGORY_REQUEST, ADD_CATEGORY_SUCCESS, ADD_CATEGORY_ERROR, DELETE_CATEGORY_DATA_REQUEST, DELETE_CATEGORY_DATA_SUCCESS, VIEW_SINGLE_CATEGORY_DATA_REQUEST, VIEW_SINGLE_CATEGORY_DATA_SUCCESS, UPDATE_CATEGORY_REQUEST, UPDATE_CATEGORY_SUCCESS, UPDATE_CATEGORY_ERROR } from '../constants/CategoryConstants'
import { generateUniqueId } from './UniqueId'

async function getList(){
    let array = []
    let categoryList = db.collection("category")
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

function* getcategory(){
    try{
        const list = yield call(getList)
        yield put ({ type: GET_CATEGORY_LIST_SUCCESS, list })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_CATEGORY_LIST_ERROR, err })
    }
}

function setvalue(data, obj){
    const { createdBy, modifiedBy, createdDateTime, modifiedDateTime, display_name, name } = data
    if(createdBy !== "" && modifiedBy !== "" && createdDateTime !== "" && modifiedDateTime !== "" && display_name !== "" && name !== ""){
        obj['createdBy'] = createdBy
        obj['modifiedBy'] = modifiedBy
        obj['createdDateTime'] = createdDateTime
        obj['modifiedDateTime'] = modifiedDateTime
        obj['name'] = name.value
        obj['display_name']  = display_name
        return obj
    }
}

function* addCategoryData(data){
    data.id = generateUniqueId()
    let obj = {
        'Name': [data]
    }
    const list = yield call(getList)
    if(list.length === 0){
        return firebase.firestore().collection("category").add(obj)
        .then(response => response)
    }
    else{
        let newList = {
            'Name': []
        }
        let id = ''
        _.forEach(list, function(listValue){
            listValue.Name.push(data)
            newList.Name = listValue.Name
            id = listValue.id
        })
        return firebase.firestore().collection("category").doc(id).update(newList)
    }
}

function* addCategory(data){
    try{
        let obj = {}
        setvalue(data.data, obj)
        yield call(addCategoryData, obj)
        yield put({ type: ADD_CATEGORY_SUCCESS })
        yield delay(500)
        history.push('/categoryList')
    }
    catch(error){
        const err = error.message
        yield put({ type: ADD_CATEGORY_ERROR, err })
    }
}

function deleteData(list, id){
    let array = []
    _.forEach(list, function(value){
        array = value.Name.filter(item => item.id !== id)
    })
    return array
}

function* deleteCategoryData(data){
    const { id } = data
    const list = yield call(getList)
    let dbId
    _.forEach(list, function(value){
        dbId = value.id
    })
    const newValue = yield call(deleteData, list, id)
    const newList = {
        'Name': newValue
    }
    firebase.firestore().collection("category").doc(dbId).update(newList)
    yield put({ type: DELETE_CATEGORY_DATA_SUCCESS, id })
}

function viewList(list, id){
    let obj = {}
    _.forEach(list, function(value){
        _.forEach(value.Name, function(items){
            if(items.id === id){
                obj = items
            }
        })
    })
    return obj
}

function* viewSingleData(data){
    const { id } = data
    const list = yield call(getList)
    const newList = yield call(viewList, list, id)
    newList.name = {'label': newList.name, 'value': newList.name}
    yield put({ type: VIEW_SINGLE_CATEGORY_DATA_SUCCESS, newList })
}

function updateData(object, list, id){
    let itemArr = _.cloneDeep(object)
    let array = []
    _.forEach(itemArr, function(value,  index){
        _.forEach(value.Name, function(items, itemsIndex){
            if(items.id === id){
                items = list
            }
            array.push(items)
        })
    })
    let newArr = {
        'Name': array
    }
    return newArr
}

function* updateCategory(data){
    try{
        const object = yield call(getList)
        const { list } = data
        const { id } = list
        list.name = list.name.value
        const newList = updateData(object, list, id)
        let dbId
        _.forEach(object, function(value){
            dbId = value.id
        })
        firebase.firestore().collection("category").doc(dbId).update(newList)
        yield put({ type: UPDATE_CATEGORY_SUCCESS })
        yield delay(500)
        history.push("/categoryList")
    }
    catch(error){
        const err = error.message
        yield put({ type: UPDATE_CATEGORY_ERROR, err })
    }
}

export default () => {
    function * watcher() {
      yield takeLatest(GET_CATEGORY_LIST_REQUEST, getcategory)
      yield takeLatest(ADD_CATEGORY_REQUEST, addCategory)
      yield takeLatest(DELETE_CATEGORY_DATA_REQUEST, deleteCategoryData)
      yield takeLatest(VIEW_SINGLE_CATEGORY_DATA_REQUEST, viewSingleData)
      yield takeLatest(UPDATE_CATEGORY_REQUEST, updateCategory)
    }
    return {watcher}
}