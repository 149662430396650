import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { Checkbox, Label } from 'semantic-ui-react'

const StatusCheckebbox = (props) => {
    const { input, meta: { touched, error }, label } = props
    const { value } = input
    console.log('vall:: ',value)
    const [checkedValue, setCheckedValue] = useState()

    useEffect(() => {
        if(value === ""){
            console.log('iffff:: ',value)
            setCheckedValue(false)
        }
        else{
            console.log('elseee: ',value)
            setCheckedValue(value)
        }
    },[value])

    const handleCheckboxChange = () => {
        console.log('valuee:: ',value)
        input.onChange(!value)
        setCheckedValue(!value)
    }

    return(
        <div className="checkbox-container">
            <div className="checkbox-toggle">
                <Checkbox toggle onChange={() => handleCheckboxChange()}
                      className={classnames({error:touched && error})}
                      label={label}
                      checked={checkedValue} />
            </div>
            <div className="quiz-status">
                <Label className=""> {checkedValue ? 'Published' : 'Unpublished'} </Label>
            </div>
            <div>
                
                {touched && error && <span className="errorMsg">{error.message}</span>}
            </div>
        </div>
    )
        
}

export default StatusCheckebbox