import React, { Component } from 'react'

class Label extends Component{
    render(){
        const { children, className, classname } = this.props
        return(
            <div className={classname}>
                <label className={className}> {children} </label>
            </div>
        )
    }
}

export default Label