import { GET_DISCIPLINE_LIST_REQUEST, GET_DISCIPLINE_LIST_SUCCESS, GET_DISCIPLINE_LIST_ERROR, ADD_DISCIPLINE_REQUEST, ADD_DISCIPLINE_SUCCESS, ADD_DISCIPLINE_ERROR, DELETE_DISCIPLINE_DATA_SUCCESS, VIEW_SINGLE_DISCIPLINE_DATA_REQUEST, VIEW_SINGLE_DISCIPLINE_DATA_SUCCESS, UPDATE_DISCIPLINE_REQUEST, UPDATE_DISCIPLINE_SUCCESS, UPDATE_DISCIPLINE_ERROR, CURRENT_DISCIPLINE_SUCCESS, VIEW_DISCIPLINE_DATA_REQUEST, VIEW_DISCIPLINE_DATA_SUCCESS, GET_ALL_DISCIPLINE_NAME_REQUEST, GET_ALL_DISCIPLINE_NAME_SUCCESS, GET_ALL_DISCIPLINE_NAME_ERROR, SAVE_DISCIPLINE_CROP_IMAGE_DATA, SECTION_DISCIPLINE_IMAGE_EXISTS, DELETE_DISCIPLINE_IMAGE_REQUEST, DELETE_DISCIPLINE_IMAGE_SUCCESS, DELETE_DISCIPLINE_IMAGE_ERROR } from '../constants/DisciplineConstants'

const initialState = {
    loading: false,
    viewSingleList: {
        created_at: '',
        modified_at: '',
        name: '',
        display_name: '',
        course_title: '',
        summary: '',
        tags: '',
        course_img_url: '',
        growth_title: '',
        prospects_level: '',
        prospects_level1_key: '',
        prospects_level1_value: '',
        prospects_level2_key: '',
        prospects_level2_value: '',
        prospects_level3_key: '',
        prospects_level3_value: '',
        prospects_level4_key: '',
        prospects_level4_value: '',
        salary_range: '',
        salary_level1_key: '',
        salary_level1_text: '',
        salary_level1_value: '',
        salary_level2_key: '',
        salary_level2_text: '',
        salary_level2_value: '',
        salary_level3_key: '',
        salary_level3_text: '',
        salary_level3_value: '',
        salary_level4_key: '',
        salary_level4_text: '',
        salary_level4_value: '',
        jobs_title_text: '',
        job_name: '',
        job_level1_key: '',
        job_img_url: '',
        market_title_text: '',
        opportunity_service: '',
        opportunity_key: '',
        opportunity_value: ''
      },
    disciplineList: [],
    error: '',
    disabled: false,
    singleDisciplineList: '',
    count: [],
    disciplineNames: [],
    cropDisciplineImageData: [],
    courseSectionImage: false,
    jobSectionImage: false
}

const Discipline = (state = initialState, action) => {
    switch(action.type){
        case GET_DISCIPLINE_LIST_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_DISCIPLINE_LIST_SUCCESS:
            return{
                ...state,
                loading: false,
                disciplineList: action.list,
                count: action.count,
                error: ""
            }
        case GET_DISCIPLINE_LIST_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case CURRENT_DISCIPLINE_SUCCESS:
            return{
                ...state,
                disabled: false,
                viewSingleList: initialState.viewSingleList,
                cropDisciplineImageData: [],
                courseSectionImage: false,
                jobSectionImage: false
            }
        case ADD_DISCIPLINE_REQUEST:
            return{
                ...state,
                loading: true
            }
        case ADD_DISCIPLINE_SUCCESS:
            return{
                ...state,
                loading: false,
                cropDisciplineImageData: []
            }
        case ADD_DISCIPLINE_ERROR:
            return{
                ...state,
                loading: false,
                error: action.err
            }
        case VIEW_SINGLE_DISCIPLINE_DATA_REQUEST:
            return{
                ...state,
                loading: true
            }
        case VIEW_SINGLE_DISCIPLINE_DATA_SUCCESS:
            return{
                ...state,
                loading: false,
                disabled: true,
                viewSingleList: action.obj,
                courseSectionImage: action.courseImage,
                jobSectionImage: action.jobImage  
            }
        case DELETE_DISCIPLINE_DATA_SUCCESS:
            return{
                ...state,
                disciplineList: state.disciplineList.filter(discipline => discipline.id !== action.id)
            }
        case UPDATE_DISCIPLINE_REQUEST:
            return{
                ...state,
                loading: true
            }
        case UPDATE_DISCIPLINE_SUCCESS:
            return{
                ...state,
                loading: false,
                cropDisciplineImageData: []
            }
        case UPDATE_DISCIPLINE_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case VIEW_DISCIPLINE_DATA_REQUEST:
            return{
                ...state,
                loading: true
            }
        case VIEW_DISCIPLINE_DATA_SUCCESS:
            return{
                ...state,
                loading: false,
                singleDisciplineList: action.singleList
            }
        case GET_ALL_DISCIPLINE_NAME_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_ALL_DISCIPLINE_NAME_SUCCESS:
            return{
                ...state,
                loading: false,
                disciplineNames: action.list
            }
        case GET_ALL_DISCIPLINE_NAME_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case SAVE_DISCIPLINE_CROP_IMAGE_DATA:
            let imageData = []
            if(action.key === "remove"){
                imageData = action.newObj
            }
            else{
                imageData = state.cropDisciplineImageData.concat(action.newObj)
            }
            return{
                ...state,
                cropDisciplineImageData: imageData
            }
        case SECTION_DISCIPLINE_IMAGE_EXISTS:
            let course_image = state.courseSectionImage, job_image = state.jobSectionImage
            if(action.name === "course_img_url"){
                course_image = action.value
            }
            else if(action.name === "job_img_url"){
                job_image = action.value
            }
            return{
                ...state,
                courseSectionImage: course_image,
                jobSectionImage: job_image
            }
        case DELETE_DISCIPLINE_IMAGE_REQUEST:
            return{
                ...state,
                loading: true
            }
        case DELETE_DISCIPLINE_IMAGE_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case DELETE_DISCIPLINE_IMAGE_ERROR:
            return{
                loading: false,
                error: action.err
            }
        default:
            return state
    }
}

export default Discipline