import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Grid, Header, Form, Segment } from 'semantic-ui-react'
import { nationality, exam_language, level, job_type, exam_time } from '../docs/examData'
import { Field, reduxForm } from 'redux-form'
import classnames from 'classnames'
import ReduxFormSelect from '../library/select/ReduxFormSelect'
import CheckboxContainer from '../library/checkbox/CheckboxContainer'
import RadioContainer from '../library/radio/RadioContainer'
import Label from '../library/Label'
import Input from '../library/Input'
import validate from './validate'

class FormContainer extends Component {
    constructor(){
        super()
        this.state = {
            showCertification: false
        }
        this.certificationCallback = this.certificationCallback.bind(this)
    }

    renderField({ input, label, type, meta: { touched, error }, showExample, className  }) {
        return (
            <Form.Field className={classnames({error:touched && error})}>
                <Label className={className}>{label} </Label>
                <div className={showExample !== undefined ? "input-field" : null}>
                    <Input {...input} placeholder={label} type={type}/>
                    {showExample !== undefined ? <p>(Ex: 6/12)</p> : null}
                </div>
                {touched && error && <span className="errorMsg">{error.message}</span>}
            </Form.Field>
        )
    }

    certificationCallback(name, value){
        if(name === 'certification' && value === 'Yes'){
            this.setState({ showCertification: true })
        }
        else{
            this.setState({ showCertification: false })
        }
    }

    render(){
        const { loading, handleSubmit, reset, submitting, pristine, educationalList, subjectList } = this.props
        const { showCertification } = this.state
        let exam_id = localStorage.getItem('exam_id')
        return(
            <form onSubmit={handleSubmit}>
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container"> Job Details </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Field name="exam_name" component={this.renderField} type="text" label="Name of Exam" className="required_field" />
                                <Field name="job_department" component={this.renderField} type="text" label="Job Department"/>
                                <Field name="exam_body" component={this.renderField} type="text" label="Exam body" className="required_field" />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Field name="job_sector" component={this.renderField} type="text" label="Job Sector" className="required_field" />
                                <Field name="job_position" component={this.renderField} type="text" label="Job Position" className="required_field" />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container"> Required Qualification </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="required_field"> Minimum Qualification </Label>
                                <Field name="educational_qualification" component={ReduxFormSelect} isMulti={true} options={educationalList} placeholder="Enter Minimum Qualification" />
                                <Label className="required_field"> Equivalent Subjects </Label>
                                <Field name="subjects" component={ReduxFormSelect} isMulti={true} options={subjectList} placeholder="Enter Subjects" />
                                <Field name="other_requirement" component={this.renderField} type="text" label="Others Requirement"/>
                                <Field name="final_year_candidate" component={CheckboxContainer} label="Final year candidates can also apply ?" />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Field name="min_marks_obtained" component={this.renderField} type="text" label="Min. Marks obtained"/>
                                <Field name="type_of_degree" component={this.renderField} type="text" label="Degree or Dipolma Type"/>
                                <Field name="experience_required" component={this.renderField} type="text" label="Experience Required"/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container"> Personal Details </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="required_field"> Nationality </Label>
                                <Field name="nationality" component={ReduxFormSelect} isMulti={true} options={nationality} placeholder="Enter Nationality" />
                                <Label className="required_field"> Marital Status </Label>
                                <Field name="marital_status" component={RadioContainer} radio={true} option1="Marrried" option2="Unmarried" option3="Both" show3Options={true} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="required_field"> Age Limits </Label>
                                <div className="age_inputs">
                                    <label> Min </label>
                                    <Field name="minimum_age" component={this.renderField} type="text" />
                                    <label> Max </label>
                                    <Field name="maximum_age" component={this.renderField} type="text" />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container"> Physical Eligibility </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Label> Height (in cm) </Label>
                                <div className="age_inputs">
                                    <label> Male </label>
                                    <Field name="male_height" component={this.renderField} type="text" />
                                    <label> Female </label>
                                    <Field name="female_height" component={this.renderField} type="text" />
                                </div>
                                <Label> Chest Grid (Expended) </Label>
                                <div className="age_inputs">
                                    <label> Male </label>
                                    <Field name="male_chest" component={this.renderField} type="text" />
                                    <label> Female </label>
                                    <Field name="female_chest" component={this.renderField} type="text" />
                                </div>
                                <Field name="handicapped" component={CheckboxContainer} label="Handicapped allowed" />
                                <Field name="color_blindness" component={CheckboxContainer} label="Color blindness allowed" />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Field name="eye_sight" component={this.renderField} type="text" label="Eye Sight  (Worst-eye)" showExample={true} />
                                <Label> Body Weight </Label>
                                <div className="age_inputs">
                                    <label> Min </label>
                                    <Field name="min_body_weight" component={this.renderField} type="text" />
                                    <label> Max </label>
                                    <Field name="max_body_weight" component={this.renderField} type="text" />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Header as='h1' textAlign='left' className="heading-container"> Misscellaneous Details </Header>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="required_field"> Certification Done </Label>
                                <Field name="certification" component={RadioContainer} radio={false} option1="Yes" option2="No" show3Options={false} callback={this.certificationCallback} />
                                <Label className="required_field"> Exam Language </Label>
                                <Field name="exam_language" component={ReduxFormSelect} isMulti={true} options={exam_language} placeholder="Enter Exam Language" />
                                <Label className="required_field"> Job Type </Label>
                                <Field name="job_type" component={ReduxFormSelect} isMulti={false} options={job_type} placeholder="Enter Type of Job" />
                        </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                {showCertification ? <Field name="certification_name" component={this.renderField} type="text" label="Name of certification (Optional)"/> : null}
                                <Label className="required_field"> Level of entry </Label>
                                <Field name="level" component={ReduxFormSelect} isMulti={false} options={level} placeholder="Enter Level" />
                                <Label className="required_field"> Exam Frequency </Label>
                                <Field name="exam_time" component={ReduxFormSelect} isMulti={false} options={exam_time} placeholder="Enter Exam Frequency" />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                {exam_id === "" || exam_id === null ?
                    <div className='btns-container'>   
                        <Button size='large' primary disabled={loading || submitting}> Submit </Button>
                        <Button size='large' color="grey" type="button" disabled={pristine || submitting} onClick={reset} className="reset-button"> Reset </Button>           
                    </div>             
                    :<div className='btns-container'>
                        <Button size='large' primary disabled={loading}> Update </Button>
                        <Button size='large' color="grey" onClick={reset} className="reset-button"> Cancel </Button>
                    </div>
                }
            </form>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.examReducer.loading,
    educationalList: state.helperReducer.educationalList,
    subjectList: state.helperReducer.subjectList,
    initialValues: state.examReducer.viewSingleData
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: 'simple', validate, enableReinitialize: true })(FormContainer))