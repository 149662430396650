import { GET_SUBJECT_LIST_REQUEST, DELETE_SUBJECT_REQUEST, ADD_SUBJECT_REQUEST, UPDATE_SUBJECT_REQUEST } from '../constants/SubjectConstants'

export const getSubjectList = () => {
    return{
        type: GET_SUBJECT_LIST_REQUEST
    }
}

export const deleteSubjectData = (value) => {
    return{
        type: DELETE_SUBJECT_REQUEST,
        value
    }
}

export const addSubjectData = (value) => {
    return{
        type: ADD_SUBJECT_REQUEST,
        value
    }
}

export const updateSubjectData = (oldValue, newValue) => {
    return{
        type: UPDATE_SUBJECT_REQUEST,
        oldValue,
        newValue
    }
}