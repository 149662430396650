import React, { useEffect } from 'react'
import { Card, Divider, Header, Grid, Container, Table, Label } from 'semantic-ui-react'
import Chart from 'chart.js';


const AnalyticsHeader = () => {

    

    return (
        <Container >

            <div className="wrapper">
                <div className="container">
                    <Header />
                    <Grid>
                        <Card.Group >
                            <Card >
                                <Card.Content>
                                    <Card.Header>Customers  </Card.Header>
                                    <i class="  square teal huge home left floated chart line icon" ></i>
                                    <div><h4 class='center floated'> 345k</h4>
                                    
                                        <small> Feb 1 - Apr 1</small>,<i class="globe icon"></i><small> WorldWide </small>
                                    </div>
                                    <Card.Meta>
                                        <i class="angle up icon"></i>
                                        <span class="text-success bold"> 18.2%</span>
                                        <small>Since last month</small>
                                    </Card.Meta>
                                </Card.Content>
                            </Card>


                            <Card >
                                <Card.Content>
                                    <Card.Header> Revenue </Card.Header>
                                    <i class="square inverted teal huge home left floated browser icon"></i>
                                    <div class = "center floated">$43,594</div>
                                    <div>
                                        <small> Feb 1 - Apr 1</small>,<i class="globe icon"></i><small> WorldWide </small>
                                    </div>
                                    <div>
                                        <i class="angle up icon"></i>
                                        <span class="text-success bold">28.4%</span>
                                        <small>Since last month</small>

                                    </div>
                                </Card.Content>
                            </Card>

                            <Card >

                                <Card.Content>
                                    
                                    <Card.Header> Traffic Share </Card.Header>
                                    <i class="square inverted teal huge home left floated browser icon"></i>
                                    <Card.Content> <i class="inverted teal  desktop icon"></i> Desktop 60%</Card.Content>
                                    <Card.Content> <i class="mobile alternate icon"></i> Mobile Web 30%</Card.Content>
                                    <Card.Content><i class=" inverted blue tablet alternate icon"></i>Tablet Web 10%</Card.Content>

                                </Card.Content>
                            </Card>
                        </Card.Group>
                    </Grid>
                </div>
            </div>
        </Container>

    )

}


export default AnalyticsHeader