import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { register } from '../../actions/UserActions'
import Input from '../library/Input'
import Button from '../library/Button'
import { authHeader } from '../../_helpers'

const Signup = (props) => {
    const { register, registererror } = props
    let pathname = props.location.pathname
    const [registerForm, setRegisterForm] = useState("")

    useEffect(() => {
      if(authHeader){
        authHeader(pathname)
      }
    })

    const handleChange = (event) => {
        const target = event.target
        const { name, value } = target
        setRegisterForm({ ...registerForm, [name]: value })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        register(registerForm)
    }

    return(
        <div className="login-page">
          <div className="form">
            {registererror !== "" ? <h5 className="errorMsg">{registererror}</h5> : null}
            <form className="register-form">
            <Input name="username"
                    type="text"
                    placeholder="Enter Username"
                    onChange={handleChange}/>
              <Input name="email"
                    type="email"
                    placeholder="Enter Email"
                    onChange={handleChange}/>
              <Input name="password"
                    type="password"
                    placeholder="Enter Password"
                    onChange={handleChange}/>
              <Button variant="primary" onClick={onSubmit}>
                Submit
              </Button>
              <p className="message">Already registered? <Link to="/login">Sign In</Link></p>
            </form>
          </div>
      </div>
    )
}

const mapStateToProps = (state) => ({
  registererror: state.userReducer.registererror
})

const mapDispatchToProps = {
    register
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup)