import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Grid, Icon, Segment } from 'semantic-ui-react'
import { Field, reduxForm, FieldArray } from 'redux-form'
import Label from '../../library/Label'
import Input from '../../library/Input'
import ReduxFormSelect from '../../library/select/ReduxFormSelect'
import TextAreaContainer from '../../library/textarea/TextAreaContainer'
import RadioContainer from '../../library/radio/RadioContainer'
import CheckboxContainer from '../../library/checkbox/CheckboxContainer'
import UploadFileContainer from '../../library/uploadFile/UploadFileContainer'
import { status } from '../../docs/helperData'
import { question_level, question_type } from '../../docs/quizData'
import { getTagList } from '../../../actions/TagActions'
import { getAllCategoryName } from '../../../actions/QuizCategoryActions'
import { changeUploadAnswer, saveQuestionType } from '../../../actions/QuizQuestionActions'
import validate from './validate'

class FormContainer extends Component {
    constructor(props){
        super(props)
        this.state = {}
        this.handleChange = this.handleChange.bind(this)
        this.renderUploadAnswer = this.renderUploadAnswer.bind(this)
    }

    componentDidMount(){
        this.props.getAllCategoryName()
        this.props.getTagList()
    }

    renderLevel({ input, placeholder, type, meta: { touched, error }, className }){
        return(
            <div className="option-container">
                <Input {...input} type={type} placeholder={placeholder} className={className} />
                {touched && error && <div className="errorMsg">{error}</div>}
            </div>
        )
    }

    renderCheckbox({ input, meta: { touched, error }, className }){
        return(
            <div className="checkbox-container">
                <input {...input} type="radio" className={className} />
                {touched && error && <div className="errorMsg">{error}</div>}
            </div>
        )
    }

    rendertrueFalseLevel({ label, meta: { touched, error }, classname }){
        return(
            <div className="true_false_option-container">
                <Label className={classname}>{label}</Label>
                {touched && error && <div className="errorMsg">{error}</div>}
            </div>
        )
    }

    renderOptions({ fields, renderLevel, renderCheckbox }){
        return(
            <div>
                <div className="options-container">
                    <label> Option Value </label>
                    <Field
                        name='option_value1'
                        type="text"
                        className="option_1"
                        component={renderLevel}
                        placeholder="Enter Option Value"
                    />
                    <label> Answer </label>
                    <Field name="answer" component={renderCheckbox} type="radio" value="option_value1" className="single-checkbox-1" /> 
                </div>
                <br />
                <div className="options-container">
                    <Field
                        name='option_value2'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Option Value"
                    />
                    <Field name="answer" component={renderCheckbox} type="radio" value="option_value2" className="single-checkbox"/> 
                </div>
                <br />
                <div className="options-container">
                    <Field
                        name='option_value3'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Option Value"
                    />
                    <Field name="answer" component={renderCheckbox} type="radio" value="option_value3" className="single-checkbox" /> 
                </div>
                <br />
                <div className="options-container">
                    <Field
                        name='option_value4'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Option Value"
                    />
                    <Field name="answer" component={renderCheckbox} type="radio" value="option_value4" className="single-checkbox" /> 
                </div>
                <br />
                <div className="options-container">
                    <Field
                        name='option_value5'
                        type="text"
                        component={renderLevel}
                        placeholder="Enter Option Value"
                    />
                    <Field name="answer" component={renderCheckbox} type="radio" value="option_value5" className="single-checkbox" /> 
                </div>
                {fields.map((option, index) => (
                    <div key={index}>
                        <Field
                            name={`${option}.value`}
                            type="text"
                            component={renderLevel}
                            placeholder="Enter Key"
                        />
                        <Field
                            name={`${option}.answer`}
                            type="text"
                            component={renderCheckbox}
                            placeholder="Enter Value"
                        />
                        <Icon name="minus" className="minus-level-icon" onClick={() => fields.remove(index)} />
                    </div>
                ))}
            </div>
        )
    }

    renderTrueFalseOptions({ fields, rendertrueFalseLevel, renderCheckbox }){
        return(
            <div>
                <div className="true_false_options-container">
                    <label> Option Value </label>
                    <Field
                        name='true_false_option1'
                        label="True"
                        classname="option_1"
                        component={rendertrueFalseLevel}
                    />
                    <label> Answer </label>
                    <Field name="true_false_answer" component={renderCheckbox} type="radio" value="option_value1" className="single-checkbox-1" /> 
                </div>
                <br />
                <div className="true_false_options-container">
                    <Field
                        name='true_false_option2'
                        label="False"
                        component={rendertrueFalseLevel}
                    />
                    <Field name="true_false_answer" component={renderCheckbox} type="radio" value="option_value2" className="single-checkbox" /> 
                </div>
                {fields.map((option, index) => (
                    <div key={index}>
                        <Field
                            name={`${option}.value`}
                            type="text"
                            component={rendertrueFalseLevel}
                            placeholder="Enter Key"
                        />
                        <Field
                            name={`${option}.answer`}
                            type="text"
                            component={rendertrueFalseLevel}
                            placeholder="Enter Value"
                        />
                        <Icon name="minus" className="minus-level-icon" onClick={() => fields.remove(index)} />
                    </div>
                ))}
            </div>
        )
    }

    handleChange(name){
        this.props.saveQuestionType(name.value)
    }

    renderUploadAnswer(value){
        this.props.changeUploadAnswer(value)
    }

    render(){
        const { loading, handleSubmit, reset, submitting, pristine, tagList, categoryName, uploadAnswerValue, answerTypeValue, newQuizQuestion, disabled, initialValues } = this.props
        let quizQuestion_id = localStorage.getItem('quizQuestion_id')
        let quiz_tags = [], quiz_category = []
        if(tagList !== undefined){
            if(tagList.length !== 0){
                tagList.map(item => {
                    quiz_tags.push({ 'label': item.name, 'value': item.name, 'id': item.id })
                })
            }
        }
        if(categoryName !== undefined){
            if(categoryName.length !== 0){
                categoryName.map(item => {
                    if(!item.isDeleted){
                        quiz_category.push({ 'label': item.name, 'value': item.name, 'id': item.id })
                    }
                })
            }
        }
        return(
            <form onSubmit={handleSubmit}>
                <Segment>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="required_field"> Question </Label>
                                <Field name="question" component={TextAreaContainer} />
                                <Label className="required_field"> Tags </Label>
                                <Field name="question_tags" component={ReduxFormSelect} isMulti={true} disabled={initialValues.status.value === "Published" ? disabled : false} options={quiz_tags} placeholder="Enter Tags" />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="required_field"> Category </Label>
                                <Field name="question_category" component={ReduxFormSelect} disabled={initialValues.status.value === "Published" ? disabled : false} isMulti={false} options={quiz_category} placeholder="Enter Category" />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="required_field"> Level </Label>
                                <Field name="level" component={ReduxFormSelect} disabled={initialValues.status.value === "Published" ? disabled : false} isMulti={false} options={question_level} placeholder="Enter Level" />
                                <Label className="required_field"> Featured </Label>
                                <Field name="featured" component={RadioContainer} disabled={initialValues.status.value === "Published" ? disabled : false} radio={false} option1="Yes" option2="No" show3Options={false} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="required_field"> Status </Label>
                                <Field name="status" component={ReduxFormSelect} isMulti={false} options={status} disabled={initialValues.status.value === "Published" ? disabled : false} placeholder="Enter Status" values="status_value" newQuizQuestion={newQuizQuestion} />
                                <Label className="required_field"> Question Type </Label>
                                <Field name="question_type" component={ReduxFormSelect} disabled={initialValues.status.value === "Published" ? disabled : false} isMulti={false} options={question_type} placeholder="Enter Type" onChange={this.handleChange} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                {answerTypeValue === "MCQ" ?
                <Segment>
                    <Grid className="addExam-container">
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <Label className="label-heading required_field"> Options </Label>
                                <FieldArray name="options" component={this.renderOptions} renderLevel={this.renderLevel} renderCheckbox={this.renderCheckbox} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='left'></Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                : answerTypeValue === "True/False" ?
                <Segment>
                <Grid className="addExam-container">
                    <Grid.Row>
                        <Grid.Column width={8} textAlign='left'>
                            <Label className="label-heading required_field"> Options </Label>
                            <FieldArray name="options" component={this.renderTrueFalseOptions} rendertrueFalseLevel={this.rendertrueFalseLevel} renderCheckbox={this.renderCheckbox} />
                        </Grid.Column>
                        <Grid.Column width={8} textAlign='left'></Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment> 
            : null}
            <Segment>
                <Grid className="addExam-container">
                    <Grid.Row>
                        <Grid.Column width={8} textAlign='left'>
                            <Label> Answer Description </Label>
                            <Field name="answer_description" component={TextAreaContainer} />
                        </Grid.Column>
                        <Grid.Column width={8} textAlign='left'>
                            {/* <Field name="upload_answer" component={CheckboxContainer} label="Upload Answer" onChange={this.renderUploadAnswer} />
                            <Field name="required" component={CheckboxContainer} label="Required" />
                            {uploadAnswerValue ? 
                            <>
                            <Label> Upload Answer Doc </Label>
                            <Field name="answer_doc_url" maxWidth={366} maxHeight={179} component={UploadFileContainer} folderName="Quiz" />
                            </>
                            : null} */}
                            </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            {quizQuestion_id === "" || quizQuestion_id === null ?
                <div className='btns-container'>   
                    <Button size='large' primary disabled={loading || submitting}> Submit </Button>
                    <Button size='large' color="grey" type="button" disabled={pristine || submitting} onClick={reset} className="reset-button"> Reset </Button>           
                </div>             
                :<div className='btns-container'>
                    <Button size='large' primary disabled={loading}> Update </Button>
                    <Button size='large' color="grey" onClick={reset} className="reset-button"> Cancel </Button>
                </div>
            }
            </form>
        )
    }
}

const mapStateToProps = (state) => ({
    disabled: state.quizQuestionReducer.disabled,
    loading: state.quizQuestionReducer.loading,
    initialValues: state.quizQuestionReducer.viewQuizQuestionList,
    tagList: state.tagReducer.tagList,
    categoryName: state.quizCategoryReducer.categoryName,
    uploadAnswerValue: state.quizQuestionReducer.uploadAnswerValue,
    answerTypeValue: state.quizQuestionReducer.answerTypeValue
})

const mapDispatchToProps = {
    getTagList,
    getAllCategoryName,
    changeUploadAnswer,
    saveQuestionType
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: 'simple',validate, enableReinitialize: true })(FormContainer))