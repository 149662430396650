export const GET_EDUCATIONAL_DATA_REQUEST = 'GET_EDUCATIONAL_DATA_REQUEST'
export const GET_EDUCATIONAL_DATA_SUCCESS = 'GET_EDUCATIONAL_DATA_SUCCESS'
export const GET_EDUCATIONAL_DATA_ERROR = 'GET_EDUCATIONAL_DATA_ERROR'

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR'

export const GET_SUBJECT_REQUEST = 'GET_SUBJECT_REQUEST'
export const GET_SUBJECT_SUCCESS = 'GET_SUBJECT_SUCCESS'
export const GET_SUBJECT_ERROR = 'GET_SUBJECT_ERROR'

export const itemsCountPerPage = 10
export const pageRangeDisplayed = 5
export const pageSize = 10

export const IMAGE_ERROR = 'IMAGE_ERROR'

export const REMOVE_CROP_IMAGE = 'REMOVE_CROP_IMAGE'
