import { call, put, takeLatest, delay } from 'redux-saga/effects'
import "firebase/auth"
import * as firebase from 'firebase'
import { db } from '../components/firebase'
import _ from 'lodash'
import { GET_TAG_LIST_REQUEST, GET_TAG_LIST_SUCCESS, GET_TAG_LIST_ERROR, ADD_TAG_REQUEST, ADD_TAG_SUCCESS, ADD_TAG_ERROR, DELETE_TAG_REQUEST, DELETE_TAG_SUCCESS, UPDATE_TAG_REQUEST, UPDATE_TAG_SUCCESS, UPDATE_TAG_ERROR } from '../constants/TagConstants'

async function getList(){
    let array = []
    let categoryList = db.collection("quiz_tags").where("isDeleted", "==", false).orderBy('created_at', 'desc')
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

function* getTagsList(){
    try{
        const list = yield call(getList)
        yield put({ type: GET_TAG_LIST_SUCCESS, list })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_TAG_LIST_ERROR, err })
    }
}

function addTagdata(data){
    return firebase.firestore().collection("quiz_tags").add(data)
    .then(response => response)
}

function* addTag(values){
    try{
        const { data } = values
        yield call(addTagdata, data)
        yield put({ type: ADD_TAG_SUCCESS })
        yield call(getTagsList)
    }
    catch(error){
        const err = error.message
        yield put({ type: ADD_TAG_ERROR, err })
    }
}

function updateTagData(id, data){
    return firebase.firestore().collection("quiz_tags").doc(id).set(data, {merge: true})
    .then(response => response)
}

function* updateTag(values){
    try{
        const { Id, data } = values
        yield call(updateTagData, Id, data)
        yield put({ type: UPDATE_TAG_SUCCESS })
        yield call(getTagsList)
    }
    catch(error){
        const err = error.message
        yield put({ UPDATE_TAG_ERROR, err })
    }
}

function getNewList(list, id){
    let obj = {}
    _.forEach(list, function(value){
        if(value.id === id){
            obj = value
        }
    })
    return obj
}

function* deleteTag(values){
    const { id } = values
    const list = yield call(getList)
    const newList = yield call(getNewList, list, id)
    newList.isDeleted = true
    firebase.firestore().collection("quiz_tags").doc(id).update(newList)
    yield put({ type: DELETE_TAG_SUCCESS, id })
    yield delay(1500)
    yield call(getTagsList)
}

export default () => {
    function * watcher() {
      yield takeLatest(GET_TAG_LIST_REQUEST, getTagsList)
      yield takeLatest(ADD_TAG_REQUEST, addTag)
      yield takeLatest(UPDATE_TAG_REQUEST, updateTag)
      yield takeLatest(DELETE_TAG_REQUEST, deleteTag)
    }
    return {watcher}
}