import { GET_COURSE_LIST_REQUEST, GET_COURSE_LIST_SUCCESS, GET_COURSE_LIST_ERROR, CURRENT_COURSE_SUCCESS, ADD_COURSE_REQUEST, ADD_COURSE_SUCCESS, ADD_COURSE_ERROR, DELETE_COURSE_DATA_SUCCESS, VIEW_SINGLE_COURSE_DATA_REQUEST, VIEW_SINGLE_COURSE_DATA_SUCCESS, UPDATE_COURSE_REQUEST, UPDATE_COURSE_SUCCESS, UPDATE_COURSE_ERROR, ADD_COURSE_DETAILS_REQUEST, ADD_COURSE_DETAILS_SUCCESS, ADD_COURSE_DETAILS_ERROR, VIEW_COURSE_DETAILS_SUCCESS, VIEW_COURSE_DETAILS_REQUEST, UPDATE_COURSE_STATUS_REQUEST, UPDATE_COURSE_STATUS_SUCCESS, GET_ALL_COURSE_NAME_REQUEST, GET_ALL_COURSE_NAME_ERROR, GET_ALL_COURSE_NAME_SUCCESS, SAVE_COURSE_CROP_IMAGE_DATA, SECTION_COURSE_IMAGE_EXISTS, DELETE_COURSE_IMAGE_REQUEST, DELETE_COURSE_IMAGE_SUCCESS, DELETE_COURSE_IMAGE_ERROR } from '../constants/CourseConstants'

const initialState = {
    loading: false,
    viewSingleList: {
        created_by: "",
        created_date_time: "",
        modified_by: "",
        modified_date_time: "",
        discipline: "",
        branch: "",
        name: "",
        display_name: "",
        program_level: "",
        duration: "",
        admin_procedure: "",
        description: "",
        required_program_level: "",
        required_course: "",
        required_marks_obtained: "",
        mode_of_degree: "",
        max_age: "",
        min_age: "",
        catergory_criteria: [
          {
            "category": "General",
            "max_age": "",
            "min_age": "",
            "required_marks_obtained": ""
          },
          {
            "category": "OBC",
            "max_age": "",
            "min_age": "",
            "required_marks_obtained": ""
          },
          {
            "category": "SC/ST",
            "max_age": "",
            "min_age": "",
            "required_marks_obtained": ""
          }
        ],
        fees: ""
      },
    courseList: [],
    error: '',
    disabled: false,
    courseDetailList: {course_summary_title:"", course_summary:"", tags:"", course_summary_img_url:"", entry_title:"", entry_summary:"", entry_summary_img_url: "", other_options:"", other_options_key:"", other_options_value:"", highlights:"", highlights_key:"", highlights_value:"", how_to_apply_key:"", how_to_apply_value:"", how_to_apply:"", apply_summary:"", apply_img_url:"", top_entrance_summary:"", entrance_exam_name:"", entrance_exam_key:"", entrance_img_url:"", career_options:"", career_options_key:"", career_options_summary:"", career_img_url:""},
    count: [],
    courseName: [],
    cropCourseImageData: [],
    courseSummarySectionImage: false,
    entrySectionImage: false,
    applySectionImage: false,
    entranceSectionImage: false,
    careerSectionImage: false,
    status: ''
}

const Course = (state = initialState, action) => {
    switch(action.type){
        case GET_COURSE_LIST_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_COURSE_LIST_SUCCESS:
            return{
                ...state,
                loading: false,
                courseList: action.list,
                count: action.count,
                error: ""
            }
        case GET_COURSE_LIST_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case CURRENT_COURSE_SUCCESS:
            return{
                ...state,
                disabled: false,
                viewSingleList: initialState.viewSingleList
            }
        case ADD_COURSE_REQUEST:
            return{
                ...state,
                loading: true
            }
        case ADD_COURSE_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case ADD_COURSE_ERROR:
            return{
                ...state,
                loading: false,
                error: action.err
            }
        case VIEW_SINGLE_COURSE_DATA_REQUEST:
            return{
                ...state,
                loading: true
            }
        case VIEW_SINGLE_COURSE_DATA_SUCCESS:
            return{
                ...state,
                loading: false,
                disabled: true,
                viewSingleList: action.obj
            }
        case DELETE_COURSE_DATA_SUCCESS:
            return{
                ...state,
                courseList: state.courseList.filter(course => course.id !== action.id)
            }
        case UPDATE_COURSE_REQUEST:
            return{
                ...state,
                loading: true
            }
        case UPDATE_COURSE_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case UPDATE_COURSE_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case ADD_COURSE_DETAILS_REQUEST:
            return{
                ...state,
                loading: true
            }
        case ADD_COURSE_DETAILS_SUCCESS:
            return{
                ...state,
                loading: false,
                cropCourseImageData: []
            }
        case ADD_COURSE_DETAILS_ERROR:
            return{
                ...state,
                loading: false,
                error: action.err
            }
        case VIEW_COURSE_DETAILS_REQUEST:
            return{
                ...state,
                loading: true
            }
        case VIEW_COURSE_DETAILS_SUCCESS:
            return{
                ...state,
                loading: false,
                courseDetailList: action.obj,
                courseSummarySectionImage: action.course_summary_image,
                entrySectionImage: action.entry_image,
                applySectionImage: action.apply_image,
                entranceSectionImage: action.entrance_image,
                careerSectionImage: action.career_image
            }
        case UPDATE_COURSE_STATUS_REQUEST:
            return{
                ...state,
                loading: true
            }
        case UPDATE_COURSE_STATUS_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case GET_ALL_COURSE_NAME_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_ALL_COURSE_NAME_SUCCESS:
            return{
                ...state,
                loading: false,
                courseName: action.list
            }
        case GET_ALL_COURSE_NAME_ERROR:
            return{
                loading: false,
                error: action.err
            }
        case SAVE_COURSE_CROP_IMAGE_DATA:
            let imageData = []
            if(action.key === "remove"){
                imageData = action.newObj
            }
            else{
                imageData = state.cropCourseImageData.concat(action.newObj)
            }
            return{
                ...state,
                cropCourseImageData: imageData
            }
        case SECTION_COURSE_IMAGE_EXISTS:
            let course_summary_image = state.courseSummarySectionImage, entry_image = state.entrySectionImage, apply_image = state.applySectionImage, entrance_image = state.entranceSectionImage, career_image = state.careerSectionImage
            if(action.name === "course_summary_img_url"){
                course_summary_image = action.value
            }
            else if(action.name === "entry_summary_img_url"){
                entry_image = action.value
            }
            else if(action.name === "apply_img_url"){
                apply_image = action.value
            }
            else if(action.name === "entrance_img_url"){
                entrance_image = action.value
            }
            else if(action.name === "career_img_url"){
                career_image = action.value
            }
            return{
                ...state,
                courseSummarySectionImage: course_summary_image,
                entrySectionImage: entry_image,
                applySectionImage: apply_image,
                entranceSectionImage: entrance_image,
                careerSectionImage: career_image
            }
        case DELETE_COURSE_IMAGE_REQUEST:
            return{
                ...state,
                loading: true
            }
        case DELETE_COURSE_IMAGE_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case DELETE_COURSE_IMAGE_ERROR:
            return{
                loading: false,
                error: action.err
            }
        default:
            return state
    }
}

export default Course